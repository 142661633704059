import React, { useEffect, useState } from "react";
import { List, Typography, Box } from "@mui/material";
import NotificationItem from "./NotificationItem";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface AllNotificationsProps {
  email: string;
}

export type DeviceNotificationType = {
  _id: string;
  deviceId: string;
  type: string;
  title: string;
  message: string;
  recipients: Array<{
    email: string;
    isRead: boolean;
  }>;
  createdAt: string;
  isRead: boolean;
};

const AllNotifications: React.FC<AllNotificationsProps> = ({ email }) => {
  // Have to make this more robust and less hardcoded when we have User notifications as well
  const [notifications, setNotifications] = useState<DeviceNotificationType[]>(
    []
  );

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/web-notification/device/all?email=${email}`
        );
        setNotifications(response.data.deviceWebNotifications);
      } catch (error) {
        console.error("Failed to fetch web notifications:", error);
      }
    };

    fetchNotifications();
  }, [email]);

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0
            }}
          >
            <Typography variant="h5" sx={{ mb: 2 }}>
              Alerts
            </Typography>
          </Box>
          <List
            sx={{
              p: 2,
              maxHeight: "75vh",
              overflowX: "hidden",
              overflowY: "scroll",
              backgroundColor: "white"
            }}
          >
            {notifications.length === 0 ? (
              <Box sx={{ textAlign: "center" }}>
                All clear! No new notifications at the moment. It’s iClean!
              </Box>
            ) : (
              notifications.map((notification: any) => (
                <NotificationItem
                  key={notification._id}
                  notification={notification}
                />
              ))
            )}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default AllNotifications;
