import { Box, Typography } from "@mui/material";

const TotalCartridgeChangesCard = ({
  totalChanges
}: {
  totalChanges: number;
}) => (
  <Box
    sx={{
      p: 2,
      height: "100%",
      borderRadius: "8px",
      border: "1px solid #d1d1d1",
      backgroundColor: "background.paper",
      color: "text.primary",
      boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
    }}
  >
    <Typography variant="h6" sx={{ mb: 2, fontWeight: 700, fontSize: "13px" }}>
      Total Cartridge Changes
    </Typography>
    <Typography
      variant="h4"
      sx={{ mb: 1, fontWeight: 700, color: "primary.main" }}
    >
      {totalChanges}
    </Typography>
    <Typography variant="body2" color="text.secondary">
      Total changes across all selected devices
    </Typography>
  </Box>
);

export default TotalCartridgeChangesCard;
