import { ICleanDevice, ICleanUsage } from "../common/types/DeviceTypes";
import { fetchAllDeviceTests } from "../services/device-test.service";

export const determineIoTVersion = async (serial: string) => {
  const numericSerial = parseInt(serial.replace(/-FB$/, ""));

  // Fetch all device tests
  const allDeviceTests = await fetchAllDeviceTests();

  // Check if the serial number exists in the fetched device tests and has device usage
  const isVersion9 = allDeviceTests.some(
    (test) =>
      test.deviceId === serial &&
      test.deviceUsage &&
      test.deviceUsage.length > 0
  );
  // If found in the device tests, it is Version 9
  if (isVersion9) {
    return "V9";
  }

  if (
    (numericSerial >= 18300 && numericSerial <= 19800) ||
    (numericSerial >= 73600 && numericSerial <= 77065) ||
    (numericSerial >= 958767 && numericSerial <= 966825)
  ) {
    return "V6";
  } else if (
    (numericSerial >= 19801 && numericSerial <= 20400) ||
    (numericSerial >= 77066 && numericSerial <= 77672) ||
    (numericSerial >= 966826 && numericSerial <= 967566)
  ) {
    return "V7";
  } else if (
    (numericSerial >= 20401 && numericSerial <= 20660) ||
    (numericSerial >= 77673 && numericSerial <= 78027) ||
    (numericSerial >= 967567 && numericSerial <= 969593)
  ) {
    return "V8";
  }

  return "UNKNOWN";
};

interface CartridgeChangeLog {
  timestamp: string; // ISO timestamp string
  cartridgeChanged: boolean;
  newCartridgeType: string;
  cyclesRemaining: number;
  litersDispensed: number;
}

export function detectCartridgeChanges(usageData: ICleanUsage[]) {
  const cartridgeChanges: CartridgeChangeLog[] = [];
  let previousCyclesRemaining: number | null = null;
  let totalLitersDispensed = 0; // Initialize total liters dispensed for the current cartridge

  usageData
    .filter((usage) => usage.cyclesRemaining < 930) // Ignore entries with cyclesRemaining < 930
    .forEach((usage) => {
      if (usage.cyclesRemaining > 0) {
        totalLitersDispensed += usage.litersDispensed; // Accumulate liters dispensed for the current cartridge
      }

      if (
        previousCyclesRemaining !== null &&
        usage.cyclesRemaining >= 540 &&
        usage.cyclesRemaining <= 550 &&
        previousCyclesRemaining >= 0 &&
        previousCyclesRemaining <= 100
      ) {
        // Determine cartridge type based on cycles remaining
        const newCartridgeType =
          usage.cyclesRemaining > 550 ? "SAO-4" : "SA0-24";

        cartridgeChanges.push({
          timestamp: usage.uploadTimestamp,
          cartridgeChanged: true,
          newCartridgeType,
          cyclesRemaining: usage.cyclesRemaining,
          litersDispensed: totalLitersDispensed // Total liters dispensed for this cartridge
        });

        totalLitersDispensed = 0; // Reset total liters dispensed after a cartridge change
      }

      previousCyclesRemaining = usage.cyclesRemaining;
    });

  return cartridgeChanges;
}

export function getCartridgeLifeRemaining(device: ICleanDevice) {
  const currentCartridgeType = device.deviceInformation.currentCartridgeType;

  const totalCycles = (() => {
    switch (currentCartridgeType) {
      case "SAO-24 FB":
      case "SAO-24":
      case "SAO-24 NON-FB":
        return 550;
      case "SAO-04 FB":
      case "SAO-4":
      case "AO/Desiccant":
      case "SAO-04 NON-FB":
        return 920;
      default:
        return 920;
    }
  })();

  const cyclesRemaining =
    device.deviceUsage.length > 0
      ? device.deviceUsage[device.deviceUsage.length - 1]?.cyclesRemaining || 0
      : 0;

  // Make sure to do .toFixed(0) in your code where ever you use it
  const cartridgeLifeRemaining = (cyclesRemaining / totalCycles) * 100;

  return cartridgeLifeRemaining;
}
