import React, { useEffect, useState } from "react";
import { Box, Typography, Snackbar, Alert, AlertColor } from "@mui/material";
import StyledTab from "../../../shared/Components/Styled/StyledTab";
import { StyledTabs } from "../../../shared/Components/Styled/StyledIconTab";
import useAuthStore from "../../../store/authStore";

import GenerateInventoryReport from "./GenerateInventoryReport";
import InventoryReportHistoryTable from "./InventoryReportHistory";

const InventoryReports = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const { user } = useAuthStore.getState();

  useEffect(() => {
    const fetchReports = async () => {
      try {
        if (user && selectedTab === 1) {
        }
      } catch (error) {
        console.error("Error fetching warranty claims:", error);
      }
    };

    fetchReports();
  }, [user, selectedTab]); // Add selectedTab to dependencies

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleGenerateReport = () => {
    setSnackbar({
      open: true,
      message: "Report generated successfully!",
      severity: "success"
    });
  };
  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // borderBottom: 0.5,
              // borderColor: "divider",
              p: 0,
              mb: 2
            }}
          >
            <Typography variant="h5">Inventory Reports</Typography>
          </Box>

          <StyledTabs
            value={selectedTab}
            onChange={handleChangeTab}
            aria-label="Profile tabs"
            variant="scrollable" // Enable scrolling
            scrollButtons="auto" // Enable auto scrolling
          >
            {/* <PlainTab label="Overview" /> */}
            <StyledTab label="Generate Report" />
            <StyledTab label="History" />
          </StyledTabs>
          <Box>
            {selectedTab === 0 && (
              <GenerateInventoryReport onSubmit={handleGenerateReport} />
            )}
            {selectedTab === 1 && (
              <InventoryReportHistoryTable reportCategory="InventoryReport" />
            )}
          </Box>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};

export default InventoryReports;
