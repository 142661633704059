export enum ProductType {
  NONE = "NONE",
  SAO4 = "SAO-4",
  SAO24 = "SAO-24",
  LOTUS_PRO = "lotus PRO",
  ICLEAN_MINI = "iClean mini",
  ICLEAN_PRO = "iClean PRO",
  ICLEAN_FLO3 = "iClean FLO3"
}
export enum AccountType {
  NONE = "NONE",
  ISS = "ISS",
  Sodexo = "Sodexo",
  FoodBuy = "FoodBuy",
  CushmanWakefield = "CushmanWakefield",
  Frasers = "Frasers",
  WeWork = "WeWork",
  JLL = "JLL",
  McDonalds = "McDonalds",
  Accor = "Accor",
  NonGlobalAccount = "NonGlobalAccount"
}

export interface SalesData {
  _id?: string;
  product: ProductType;
  serialNumber?: string;
  account: {
    accountType: AccountType;
    accountName: string;
    accountEmail: string;
    accountPhone: string;
    accountAddress: string;
    siteContact: string;
  };
  notes: string;
  distributorEmail: string;
  distributorCountry?: string;
  status?: string;
  date?: Date;
  createdAt?: string;
  updatedAt?: string;
}

export interface AccountsData {
  _id?: string;
  accountType: AccountType;
  accountName: string;
  accountEmail: string;
  accountPhone: string;
  accountAddress: string;
  siteContact: string;
}
