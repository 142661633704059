import React, { useEffect, useState } from "react";
import { Box, Typography, Snackbar, Alert, AlertColor } from "@mui/material";
import StyledTab from "../../shared/Components/Styled/StyledTab";
import { StyledTabs } from "../../shared/Components/Styled/StyledIconTab";
import useAuthStore from "../../store/authStore";
import ReportHistoryTable from "./History/ReportHistoryTable";
import GenerateReport from "./GenerateReport/GenerateReport";

const DeviceReports = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedDeviceReportTab", newValue.toString());
  };

  const { user } = useAuthStore.getState();

  useEffect(() => {
    const fetchReports = async () => {
      try {
        if (user && selectedTab === 1) {
        }
      } catch (error) {
        console.error("Error fetching warranty claims:", error);
      }
    };

    fetchReports();
  }, [user, selectedTab]); // Add selectedTab to dependencies

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
              mb: 2
            }}
          >
            <Typography variant="h6">Device Reports</Typography>
          </Box>

          <StyledTabs
            value={selectedTab}
            onChange={handleChangeTab}
            aria-label="Profile tabs"
            variant="scrollable" // Enable scrolling
            scrollButtons="auto" // Enable auto scrolling
          >
            <StyledTab label="Generate Report" />
            <StyledTab label="History" />
          </StyledTabs>
          <Box mt={2}>
            {selectedTab === 0 && (
              <GenerateReport reportCategory="DeviceReport" />
            )}
            {selectedTab === 1 && (
              <ReportHistoryTable reportCategory="DeviceReport" />
            )}
          </Box>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};

export default DeviceReports;
