import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Tooltip,
  Snackbar,
  Alert,
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  TextField,
  InputAdornment,
  IconButton
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenIcon,
  DriveFileRenameOutline as DriveFileRenameOutlineIcon,
  Description as DescriptionIcon
} from "@mui/icons-material";
import { deleteReport, getAllReports } from "../../../services/reports.service";
import useAuthStore from "../../../store/authStore";
import openPDFWindow from "../../../shared/Components/OpenPDFWindow";
import Papa from "papaparse";
import StyledButton from "../../../shared/Components/Styled/StyledButton";

interface ReportData {
  _id: string;
  reportInformation: {
    name: string;
    description?: string;
    reportType: string;
    generatedAt: Date;
    generatedBy: string;
    startDate?: Date; // Add this if it's part of your report data structure
    endDate?: Date; // Add this if it's part of your report data structure
  };
  reportData: any;
  reportCategory: string;
}

interface WarrantyReportHistoryTableProps {
  reportCategory: "WarrantyReport";
}

const WarrantyReportHistoryTable: React.FC<WarrantyReportHistoryTableProps> = ({
  reportCategory
}) => {
  const [data, setData] = useState<ReportData[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedForDeletion, setSelectedForDeletion] = useState<string | null>(
    null
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor
  });
  const { user, userRole } = useAuthStore();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isReportDeleted, setIsReportDeleted] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ReportData | null>(null);
  const [editedName, setEditedName] = useState<string>("");
  const [editedDescription, setEditedDescription] = useState<string>("");

  const fetchData = useCallback(async () => {
    try {
      const response = await getAllReports(
        user?.email || "Email Unavailable",
        userRole || "Role Unavailable"
      );
      const filteredData = response.filter(
        (report: ReportData) => report.reportCategory === reportCategory
      );
      setData(filteredData);
    } catch (error) {
      console.error("Error fetching report data:", error);
    }
  }, [user, userRole, reportCategory]);

  useEffect(() => {
    fetchData();
    // Reset the isReportDeleted state after fetching data
    if (isReportDeleted) {
      setIsReportDeleted(false);
    }
  }, [fetchData, isReportDeleted, user, userRole]);

  const handleOpenDeleteModal = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      setSelectedForDeletion(report._id);
      setDeleteModalOpen(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedForDeletion(null);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteReport(id);
      setIsReportDeleted(true);
      // Show success message
      setSnackbar({
        open: true,
        message: "Report deleted successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Error deleting report:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete report",
        severity: "error"
      });
    } finally {
      handleCloseDeleteModal();
    }
  };

  const handleEdit = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      setSelectedReport(report);
      setEditedName(report.reportInformation.name);
      setEditedDescription(report.reportInformation.description || "");
      setEditModalOpen(true);
    }
  };

  const handleSave = () => {
    if (selectedReport) {
      // Save logic here
      // For now, just log the edited report
      console.log("Saved report:", {
        ...selectedReport,
        reportInformation: {
          ...selectedReport.reportInformation,
          name: editedName,
          description: editedDescription
        }
      });
      // Optionally update the data state to reflect the changes
      setData((prevData) =>
        prevData.map((report) =>
          report._id === selectedReport._id
            ? {
                ...report,
                reportInformation: {
                  ...report.reportInformation,
                  name: editedName,
                  description: editedDescription
                }
              }
            : report
        )
      );
      setEditModalOpen(false);
    }
  };

  const handleOpen = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      openPDFWindow(report);
    } else {
      console.error("Report not found or could not be loaded.");
    }
  };

  // Function to export the reports data
  const exportToCSV = (report: ReportData) => {
    // Check if reportData.data is an array
    if (
      Array.isArray(report.reportData?.data) &&
      report.reportData.data.length > 0
    ) {
      // General report information fields
      const generalFields = {
        startDate: report.reportInformation.startDate
          ? new Date(report.reportInformation.startDate).toLocaleDateString()
          : "N/A",
        endDate: report.reportInformation.endDate
          ? new Date(report.reportInformation.endDate).toLocaleDateString()
          : "N/A",
        reportDate: report.reportInformation.generatedAt
          ? new Date(report.reportInformation.generatedAt).toLocaleDateString()
          : "N/A",
        generatedBy: report.reportInformation.generatedBy || "N/A",
        reportType: report.reportInformation.reportType || "N/A"
      };

      // Get dynamic column headers from the keys of the first item in reportData.data
      const dynamicHeaders = Object.keys(report.reportData.data[0]);

      // Combine the dynamic headers and the general headers into one row
      const combinedHeaders = [
        ...dynamicHeaders,
        ...Object.keys(generalFields)
      ];

      // Create the first row of data with the general fields
      const generalFieldsRow = {
        ...Object.fromEntries(dynamicHeaders.map((header) => [header, ""])), // Fill dynamic columns with empty values
        ...generalFields // General field values
      };

      // Map over the dynamic data to format it into CSV rows
      const csvData = report.reportData.data.map((item: any) => {
        const row: any = {};

        dynamicHeaders.forEach((header) => {
          row[header] = Array.isArray(item[header])
            ? item[header].join(", ")
            : item[header];
        });

        return row;
      });

      // Convert to CSV format, ensuring general fields come in the first row
      const csv = Papa.unparse({
        fields: combinedHeaders, // Combined headers (dynamic + general)
        data: [generalFieldsRow, ...csvData] // General fields row first, followed by dynamic data rows
      });

      // Create a Blob and download link for the CSV
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${report.reportInformation.name}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error(
        "report.reportData.data is not an array or is empty:",
        report.reportData.data
      );
      alert("Report data is not available in the expected format for export.");
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Report Name",
      flex: 1.5,
      minWidth: 150,
      valueGetter: (_value, row) => row.reportInformation?.name || ""
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 200,
      valueGetter: (_value, row) => row.reportInformation?.description || ""
    },
    {
      field: "reportType",
      headerName: "Report Type",
      flex: 1.5,
      minWidth: 125,
      valueGetter: (_value, row) => row.reportInformation?.reportType || ""
    },
    {
      field: "generatedAt",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      valueGetter: (_value, row) => row.reportInformation?.generatedAt,
      valueFormatter: (value) => (value ? new Date(value).toLocaleString() : "")
    },
    {
      field: "generatedBy",
      headerName: "Generated By",
      flex: 1.5,
      minWidth: 200,
      valueGetter: (_value, row) => row.reportInformation?.generatedBy || ""
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 175,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            height: "100%",
            width: "100%"
          }}
        >
          <Tooltip title="Open Report">
            <IconButton size="small" onClick={() => handleOpen(params.row._id)}>
              <DescriptionIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Export to CSV">
            <IconButton size="small" onClick={() => exportToCSV(params.row)}>
              <OpenIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit Report">
            <IconButton size="small" onClick={() => handleEdit(params.row._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete Report">
            <IconButton
              size="small"
              onClick={() => handleOpenDeleteModal(params.row._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  ];

  return (
    <Box sx={{ p: 0 }}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Box sx={{ mt: 2 }}>
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row._id}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "700"
            }
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            }
          }}
          pageSizeOptions={[5, 10, 25]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelection) => {
            setSelected(newSelection.map((id) => String(id)));
          }}
          rowSelectionModel={selected}
        />
      </Box>

      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDeleteModal}>Cancel</StyledButton>
          <StyledButton
            onClick={() => {
              if (selectedForDeletion) {
                handleDelete(selectedForDeletion);
                handleCloseDeleteModal();
              }
            }}
            variantType="destructive"
          >
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
      <Modal open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 220, sm: 400 },
            bgcolor: "background.paper",
            border: "1px solid gray",
            boxShadow: 24,
            pl: 4,
            pr: 4,
            pb: 4,
            borderRadius: 1
          }}
        >
          <h3>Edit Report Details</h3>
          <TextField
            fullWidth
            margin="normal"
            label="Report Name"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DriveFileRenameOutlineIcon />
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            multiline
            rows={4}
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DescriptionIcon />
                </InputAdornment>
              )
            }}
          />
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 1
            }}
          >
            <StyledButton onClick={() => setEditModalOpen(false)}>
              Cancel
            </StyledButton>
            <StyledButton onClick={handleSave} variantType="primary">
              Save
            </StyledButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default WarrantyReportHistoryTable;
