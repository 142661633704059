import React from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from "@mui/material";
import {
  ChangeRequest,
  ChangeRequestStatus
} from "../../common/types/ChangeRequestTypes";
import { formatJson } from ".";
import StyledButton from "../../shared/Components/Styled/StyledButton";
import useDeviceStore from "../../store/useDeviceStore";

interface ChangeRequestDetailsModalProps {
  open: boolean;
  handleClose: () => void;
  changeRequest: ChangeRequest;
  handleApprove: (id: string) => void;
  handleReject: (id: string) => void;
}

const ChangeRequestDetailsModal: React.FC<ChangeRequestDetailsModalProps> = ({
  open,
  handleClose,
  changeRequest,
  handleApprove,
  handleReject
}) => {
  const { allDevices } = useDeviceStore();

  // Function to format the request type (split camel case)
  const formatRequestType = (type: string) => {
    return type.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  const formatJsonDiff = (jsonString: string) => {
    try {
      const jsonObject = JSON.parse(jsonString);
      const device = allDevices.find(
        (device) => device.deviceInformation.deviceId === jsonObject.deviceId
      );

      if (!device) return formatJson(jsonString);

      let output = "";
      Object.entries(jsonObject).forEach(([key, value]) => {
        if (key === "deviceId") {
          output += `${key}: ${value}\n`;
          return;
        }

        const currentValue = device[key as keyof typeof device];
        if (JSON.stringify(currentValue) !== JSON.stringify(value)) {
          if (typeof value === "object" && value !== null) {
            Object.entries(value as Record<string, any>).forEach(
              ([nestedKey, nestedValue]) => {
                const currentNestedValue = (
                  currentValue as Record<string, any>
                )?.[nestedKey];
                if (
                  JSON.stringify(currentNestedValue) !==
                  JSON.stringify(nestedValue)
                ) {
                  output += `- ${nestedKey}: ${JSON.stringify(currentNestedValue, null, 2)}\n`;
                  output += `+ ${nestedKey}: ${JSON.stringify(nestedValue, null, 2)}\n`;
                }
              }
            );
          } else {
            output += `- ${key}: ${JSON.stringify(currentValue, null, 2)}\n`;
            output += `+ ${key}: ${JSON.stringify(value, null, 2)}\n`;
          }
        }
      });

      return output.replace(/[{}"]/g, "");
    } catch (error) {
      console.error("Invalid JSON format:", error);
      return jsonString;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{formatRequestType(changeRequest.type)}</DialogTitle>
      <DialogContent>
        <Divider />
        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            <strong>User:</strong> {changeRequest.userEmail}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>User Role:</strong> {changeRequest.userRole}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Date Created:</strong>{" "}
            {new Date(changeRequest.createdAt).toLocaleString()}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: "flex",
            p: 0.5,
            pb: 2,
            justifyContent: "center",
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
            fontFamily: "monospace"
          }}
        >
          <Typography
            variant="body1"
            component="pre"
            style={{ whiteSpace: "pre-wrap", fontWeight: 600 }}
          >
            {formatJsonDiff(JSON.stringify(changeRequest.details, null, 2))
              .split("\n")
              .map((line, i) => (
                <div
                  key={i}
                  style={{
                    color: line.startsWith("-")
                      ? "#ff0000"
                      : line.startsWith("+")
                        ? "#2ea043"
                        : "inherit",
                    backgroundColor: line.startsWith("-")
                      ? "#ffdce0"
                      : line.startsWith("+")
                        ? "#dcffe4"
                        : "transparent"
                  }}
                >
                  {line}
                </div>
              ))}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={handleClose}>Close</StyledButton>
        <StyledButton
          disabled={changeRequest.status !== ChangeRequestStatus.Pending}
          onClick={() => handleReject(changeRequest._id)}
          variantType="outline-red"
        >
          Reject
        </StyledButton>
        <StyledButton
          disabled={changeRequest.status !== ChangeRequestStatus.Pending}
          onClick={() => handleApprove(changeRequest._id)}
          variantType="primary"
        >
          Approve
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeRequestDetailsModal;
