import { Box, Divider, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.color = "#282828";
const commonCardStyle = {
  p: 2,
  borderRadius: "8px",
  border: "1px solid #d1d1d1",
  backgroundColor: "background.paper",
  color: "text.primary",
  boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
};

interface CartridgeChangesChartProps {
  cartridgeChanges: { timestamp: string }[];
}

const CartridgeChangesChart = ({
  cartridgeChanges
}: CartridgeChangesChartProps) => {
  // Group changes by month for current year
  const currentYear = dayjs().year();
  const monthlyChanges = cartridgeChanges.reduce(
    (acc, change) => {
      const changeDate = dayjs(change.timestamp);
      if (changeDate.year() === currentYear) {
        const monthKey = changeDate.format("MM");
        acc[monthKey] = (acc[monthKey] || 0) + 1;
      }
      return acc;
    },
    {} as Record<string, number>
  );

  // Create array for all months in the year
  const monthsInYear = Array.from({ length: 12 }, (_, i) => {
    const monthNum = (i + 1).toString().padStart(2, "0");
    return monthNum;
  });

  const data = {
    labels: monthsInYear.map((month) =>
      dayjs(`2024-${month}-01`).format("MMM")
    ),
    datasets: [
      {
        label: "Cartridge Changes",
        data: monthsInYear.map((month) => monthlyChanges[month] || 0),
        backgroundColor: "rgba(30, 58, 138, 0.9)"
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top" as const
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true
        }
      }
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 0,
        bottom: 0
      }
    },
    title: {
      display: false
    }
  };

  return (
    <Box
      sx={{
        ...commonCardStyle,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: 1,
          color: "#282828",
          fontWeight: "700",
          fontSize: "13px"
        }}
      >
        Cartridge Changes ({currentYear})
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <div style={{ height: "250px" }}>
        <Bar data={data} options={options} />
      </div>
    </Box>
  );
};

export default CartridgeChangesChart;
