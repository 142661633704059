import React, { useEffect, useState } from "react";
import { Box, Snackbar, Alert, AlertColor } from "@mui/material";
import InstallerCard from "./InstallerCard";
import { DeviceData } from "../../../common/types/DeviceTypes";
import { Distributor } from "../../../store/useDistributor";
import { fetchDeviceInstaller } from "../../../services/device.service";

interface DeviceInstallersProps {
  device: DeviceData;
}

const DeviceInstallers: React.FC<DeviceInstallersProps> = ({ device }) => {
  const [installer, setInstaller] = useState<Distributor | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor
  });

  useEffect(() => {
    const loadInstaller = async () => {
      try {
        const installerData = await fetchDeviceInstaller(
          device.deviceInformation.deviceId
        );
        setInstaller(installerData);
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Error fetching installer or distributor",
          severity: "error"
        });
      }
    };

    loadInstaller();
  }, [device]);

  return (
    <Box>
      {installer && <InstallerCard distributor={installer} />}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DeviceInstallers;
