import React, { useState } from "react";
import {
  Box,
  Stack,
  Tabs,
  useTheme,
  CircularProgress,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import useDeviceStore from "../../store/useDeviceStore";
import useAuthStore from "../../store/authStore";
import UsageTab from "./Usage";
import MapTab from "./Map";
import CartridgeTab from "./Cartridge";
import SummaryTab from "./Summary";
import downloadPDF from "./DownloadPDF";
import downloadCSV from "./DownloadCSV";
import { ICleanDevice } from "../../common/types/DeviceTypes";
import StyledButton from "../../shared/Components/Styled/StyledButton";
import StyledTab from "../../shared/Components/Styled/StyledTab";
import StyledIconButton from "../../shared/Components/Styled/StyledIconButton";
import {
  makeStyles,
  Button,
  Select,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  Divider
} from "@fluentui/react-components";
import type { SelectProps } from "@fluentui/react-components";
import {
  DataBarVerticalAscendingFilled,
  ArrowClockwiseFilled,
  Dismiss24Regular,
  TreeEvergreen20Filled
} from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";

const useStyles = makeStyles({
  control: {
    maxWidth: "175px"
  },
  drawerInputs: {
    width: "100%",
    marginBottom: "14px"
  },
  sustainabilityIconTextAlign: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px"
  },
  treeIcon: {
    color: "#2E7D32"
  },
  drawerDividerMargin: {
    margin: "14px 0"
  }
});

const Dashboard: React.FC = () => {
  const {
    allDevices,
    selectedIcleanDevices,
    dateFilter,
    setDateFilter,
    setIntervalFilter,
    isLoading,
    isGallons,
    setIsGallons
  } = useDeviceStore();
  const { userRole } = useAuthStore();
  const theme = useTheme();

  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [viewMode, setViewMode] = useState<string>("usage");
  const [selectedInterval, setSelectedInterval] = useState<string>("daily");
  const [isReportDrawerOpen, setIsReportDrawerOpen] = useState<boolean>(false);
  const [selectedReportInterval, setSelectedReportInterval] =
    useState<string>("daily");

  const styles = useStyles();

  const toggleReportDrawer = (newOpen: boolean) => () => {
    setIsReportDrawerOpen(newOpen);
  };

  const handleReportIntervalChange: SelectProps["onChange"] = (
    _event,
    data
  ) => {
    const newInterval = data.value as "daily" | "weekly" | "monthly";
    setSelectedReportInterval(newInterval);
  };

  const tabLabels = ["Summary", "Usage", "Map"];
  if (userRole && ["Admin", "SuperAdmin"].includes(userRole)) {
    tabLabels.push("Cartridge");
  }

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    if (newValue === 0) {
      setViewMode("summary");
    } else if (newValue === 1) {
      setViewMode("usage");
    } else if (newValue === 2) {
      setViewMode("map");
    } else if (newValue === 3) {
      setViewMode("cartridge");
    }
  };

  const calculateLitresUsed = (device: ICleanDevice) => {
    if (!device.deviceUsage.length) return 0;
    return device.deviceUsage.reduce((acc, usage) => {
      const usageDate = dayjs(usage.uploadTimestamp);
      if (selectedReportInterval === "daily" && dateFilter) {
        if (usageDate.isSame(dayjs(dateFilter), "day")) {
          return acc + usage.litersDispensed;
        }
      } else if (selectedReportInterval === "weekly" && dateFilter) {
        const startOfWeek = dayjs(dateFilter).startOf("week");
        const endOfWeek = dayjs(dateFilter).endOf("week");
        if (
          usageDate.isSameOrAfter(startOfWeek) &&
          usageDate.isSameOrBefore(endOfWeek)
        ) {
          return acc + usage.litersDispensed;
        }
      } else if (selectedReportInterval === "monthly" && dateFilter) {
        const startOfMonth = dayjs(dateFilter).startOf("month");
        const endOfMonth = dayjs(dateFilter).endOf("month");
        if (
          usageDate.isSameOrAfter(startOfMonth) &&
          usageDate.isSameOrBefore(endOfMonth)
        ) {
          return acc + usage.litersDispensed;
        }
      }
      return acc;
    }, 0);
  };

  const handleReportGeneration = (
    reportType: string,
    fileType: "pdf" | "csv"
  ) => {
    // Prepare report data based on the report type
    const reportData = selectedIcleanDevices.map((device) => ({
      deviceInformation: device.deviceInformation,
      deviceLocation: device.deviceLocation,
      deviceUsage: device.deviceUsage,
      litresUsed:
        reportType === "sustainability"
          ? calculateTotalLitersUsed(device)
          : calculateLitresUsed(device),
      cartridgeLife: {
        percentage: calculateCartridgeLifePercentage(
          device.deviceUsage.length > 0
            ? device.deviceUsage[device.deviceUsage.length - 1]
                ?.cyclesRemaining || 0
            : 0,
          device.deviceInformation.currentCartridgeType
        )
      }
    }));

    // Nested conditions for handling file type and report type
    if (fileType === "pdf") {
      switch (reportType) {
        case "daily":
        case "weekly":
        case "monthly":
          downloadPDF(reportData, reportType, isGallons, dateFilter);
          break;
        case "sustainability":
          downloadPDF(reportData, "Total", isGallons, dateFilter);
          break;
        default:
          console.warn("Unsupported report type for PDF generation");
      }
    } else if (fileType === "csv") {
      switch (reportType) {
        case "daily":
        case "weekly":
        case "monthly":
          downloadCSV(reportData, reportType, isGallons, dateFilter);
          break;
        case "sustainability":
          downloadCSV(reportData, "sustainability", isGallons, dateFilter);
          break;
        default:
          console.warn("Unsupported report type for CSV generation");
      }
    } else {
      console.warn("Unsupported file type selected");
    }
  };

  const calculateTotalLitersUsed = (device: ICleanDevice) => {
    return device.deviceUsage.reduce((total: number, usage: any) => {
      return total + (usage.litersDispensed || 0);
    }, 0);
  };

  const calculateCartridgeLifePercentage = (
    cyclesRemaining: number,
    currentCartridgeType?:
      | "SAO-24 FB"
      | "SAO-24 NON-FB"
      | "SAO-04 FB"
      | "SAO-4"
      | "SAO-24"
      | "AO/Desiccant"
      | "NONE"
      | "SAO-04 NON-FB"
  ): number => {
    const totalCycles = (() => {
      switch (currentCartridgeType) {
        case "SAO-24 FB":
        case "SAO-24 NON-FB":
        case "SAO-24":
          return 550;
        case "SAO-04 FB":
        case "SAO-04 NON-FB":
        case "SAO-4":
        case "AO/Desiccant":
          return 920;
        default:
          return 920;
      }
    })();

    return (cyclesRemaining / totalCycles) * 100;
  };

  const [unit, setUnit] = useState<"liters" | "gallons">("liters");

  const handleUnitChange: SelectProps["onChange"] = (_event, data) => {
    const newValue = data.value as "liters" | "gallons";
    setUnit(newValue);
    setIsGallons(newValue === "gallons");
  };

  const handleIntervalChange: SelectProps["onChange"] = (_event, data) => {
    const newInterval = data.value as "daily" | "weekly" | "monthly";
    setSelectedInterval(newInterval);
    setIntervalFilter(newInterval);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
          gap: 3
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <CircularProgress
            size={60}
            thickness={4}
            sx={{
              color: "#2E7D32",
              animation: "pulse 1.5s ease-in-out infinite",
              "@keyframes pulse": {
                "0%": {
                  transform: "scale(0.95)",
                  opacity: 0.8
                },
                "50%": {
                  transform: "scale(1.05)",
                  opacity: 1
                },
                "100%": {
                  transform: "scale(0.95)",
                  opacity: 0.8
                }
              }
            }}
          />
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              color: "#2E7D32",
              fontWeight: 500,
              textAlign: "center",
              animation: "fadeInOut 2s ease-in-out infinite",
              "@keyframes fadeInOut": {
                "0%": { opacity: 0.5 },
                "50%": { opacity: 1 },
                "100%": { opacity: 0.5 }
              }
            }}
          >
            Preparing Your Lotus Pro Dashboard...
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mt: 1,
              color: "#666",
              textAlign: "center",
              maxWidth: 300
            }}
          >
            Creating a cleaner, safer world with SAO™ Technology
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: "hidden",
        px: { xs: 0.1, md: 1, sm: 1 },
        backgroundColor: theme.palette.background.default
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 0 10px 0",
          overflowX: "auto",
          gap: 2
        }}
      >
        {/* Tabs Section */}
        <Box
          sx={{
            flexShrink: 0,
            overflowX: "auto",
            paddingRight: 2,
            "&::-webkit-scrollbar": {
              height: "6px"
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.2)",
              borderRadius: "3px"
            }
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Dashboard tabs"
            sx={{
              minHeight: "32px",
              "& .MuiTabs-indicator": {
                display: "none"
              },
              "& .MuiTab-root": {
                marginRight: "6px",
                "&:last-child": {
                  marginRight: 0
                }
              }
            }}
          >
            {tabLabels.map((label, index) => (
              <StyledTab key={index} label={label} />
            ))}
          </Tabs>
        </Box>
        {/* Controls Section - Only Shown on Lotus Pro (Dashboard) Tab */}
        {(selectedTab === 1 || selectedTab === 4) && (
          <Box
            sx={{
              flexShrink: 0,
              overflowX: "auto",
              overflowY: "hidden",
              paddingLeft: 2,
              "&::-webkit-scrollbar": {
                height: "6px"
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.2)",
                borderRadius: "3px"
              }
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                flexWrap: "nowrap",
                minWidth: "fit-content"
              }}
            >
              <Select
                style={{ fontFamily: "Inter" }}
                value={selectedInterval}
                onChange={handleIntervalChange}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </Select>
              <DatePicker
                value={dateFilter}
                className={styles.control}
                placeholder="Select a date..."
                onSelectDate={(date: Date | null | undefined) =>
                  setDateFilter(date || null)
                }
                style={{ fontFamily: "Inter" }}
              />
              <Select
                appearance="outline"
                value={unit}
                onChange={handleUnitChange}
                style={{ fontFamily: "Inter" }}
              >
                <option value="liters">Liters</option>
                <option value="gallons">Gallons</option>
              </Select>
              <StyledButton
                onClick={toggleReportDrawer(true)}
                icon={<DataBarVerticalAscendingFilled />}
                iconColor={theme.palette.primary.main}
              >
                Generate Report
              </StyledButton>
              <OverlayDrawer
                position={"end"}
                open={isReportDrawerOpen}
                onOpenChange={(_, { open }) => setIsReportDrawerOpen(open)}
              >
                <DrawerHeader>
                  <DrawerHeaderTitle
                    action={
                      <Button
                        appearance="subtle"
                        aria-label="Close"
                        icon={<Dismiss24Regular />}
                        onClick={() => setIsReportDrawerOpen(false)}
                      />
                    }
                  >
                    Generate Report
                  </DrawerHeaderTitle>
                </DrawerHeader>

                <DrawerBody>
                  <p className={styles.sustainabilityIconTextAlign}>
                    <TreeEvergreen20Filled className={styles.treeIcon} />
                    Sustainability Report
                  </p>
                  <Button
                    appearance="subtle"
                    onClick={() =>
                      handleReportGeneration("sustainability", "pdf")
                    }
                  >
                    Sustainability Report (PDF)
                  </Button>
                  <Button
                    appearance="subtle"
                    onClick={() =>
                      handleReportGeneration("sustainability", "csv")
                    }
                  >
                    Sustainability Report (CSV)
                  </Button>
                  <Divider className={styles.drawerDividerMargin} />
                  <Select
                    className={styles.drawerInputs}
                    appearance="outline"
                    value={selectedReportInterval}
                    onChange={handleReportIntervalChange}
                  >
                    <option value="daily">Daily Report</option>
                    <option value="weekly">Weekly Report</option>
                    <option value="monthly">Monthly Report</option>
                  </Select>
                  <Button
                    appearance="subtle"
                    onClick={() =>
                      handleReportGeneration(selectedReportInterval, "pdf")
                    }
                  >
                    {selectedReportInterval.charAt(0).toUpperCase() +
                      selectedReportInterval.slice(1)}{" "}
                    Report (PDF)
                  </Button>
                  <Button
                    appearance="subtle"
                    onClick={() =>
                      handleReportGeneration(selectedReportInterval, "csv")
                    }
                  >
                    {selectedReportInterval.charAt(0).toUpperCase() +
                      selectedReportInterval.slice(1)}{" "}
                    Report (CSV)
                  </Button>
                </DrawerBody>
              </OverlayDrawer>
              <StyledIconButton onClick={refreshPage}>
                <ArrowClockwiseFilled />
              </StyledIconButton>
            </Stack>
          </Box>
        )}
      </Box>

      {viewMode === "summary" && <SummaryTab devices={allDevices} />}
      {viewMode === "usage" && <UsageTab />}
      {viewMode === "map" && <MapTab />}
      {viewMode === "cartridge" && <CartridgeTab />}
    </Box>
  );
};

export default Dashboard;
