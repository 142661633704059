import React, { useState, useMemo } from "react";
import {
  TextField,
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  MenuItem,
  Select,
  useTheme
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import StepperMenu from "../../../shared/Components/StepperMenu";
import dayjs from "dayjs";
import Assessment from "@mui/icons-material/Assessment";
import ImageSearch from "@mui/icons-material/ImageSearch";
import { GetApp } from "@mui/icons-material";
import { fetchAllDeviceReworks } from "../../../services/device-rework.service";
import { DeviceRework } from "../../../common/types/DeviceReworkTypes";
import Papa from "papaparse";
import useAuthStore from "../../../store/authStore";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import { createInventoryReport } from "../../../services/reports.service"; // Ensure the path is correct

interface GenerateReportProps {
  onSubmit: () => void;
}

interface ReportPreviewData {
  part: string;
  partNo: string;
  recoveredQuantity: number;
  usedQuantity: number;
  delta: number; // Difference between recovered and used
}

const GenerateInventoryReport: React.FC<GenerateReportProps> = ({
  onSubmit
}) => {
  const [reworks, setReworks] = useState<DeviceRework[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [reportName, setReportName] = useState<string>("");
  const [reportDescription, setReportDescription] = useState<string>("");
  const [reportType, setReportType] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dataPreview, setDataPreview] = useState<ReportPreviewData[]>([]);
  const [reportGenerated, setReportGenerated] = useState<boolean>(false);
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);

  const [dateOption, setDateOption] = useState<string>("thisYear");
  const steps = ["Report Details", "Preview & Generate"];

  const { user } = useAuthStore();
  const theme = useTheme();

  useMemo(() => {
    const fetchReworks = async () => {
      if (!user) return;
      const response = await fetchAllDeviceReworks();
      setReworks(response);
    };

    fetchReworks();
  }, [user]);

  // Handlers
  const handleStepClick = (index: number) => setActiveStep(index);
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setActiveStep(0);
      resetForm();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };
  const handleBack = () => setActiveStep((prev) => prev - 1);
  const handleCancel = () => {
    setActiveStep(0);
    resetForm();
  };

  const resetForm = () => {
    setReportName("");
    setReportDescription("");
    setReportType("");
    setStartDate(null);
    setEndDate(null);
    setDataPreview([]);
    setReportGenerated(false);
    setFeedbackMessage(null);
  };

  const calculateInventoryReport = (
    startDate: Date | null,
    endDate: Date | null
  ): ReportPreviewData[] => {
    if (!startDate || !endDate) {
      console.warn("Start date or end date is not set.");
      return [];
    }

    // Adjust endDate if startDate and endDate are the same
    const adjustedEndDate =
      startDate.getTime() === endDate.getTime()
        ? new Date(endDate.setHours(23, 59, 59, 999)) // End of the day
        : endDate;

    // Filter reworks within the specified date range
    const filteredReworks = reworks.filter((rework) => {
      const reworkDate = new Date(rework.reworkDate);
      return reworkDate >= startDate && reworkDate <= adjustedEndDate;
    });

    // Create maps to keep track of quantities for recovered and used parts
    const recoveredPartsMap = new Map<
      string,
      { partNo: string; quantity: number }
    >();
    const usedPartsMap = new Map<
      string,
      { partNo: string; quantity: number }
    >();

    // Process filtered reworks
    filteredReworks.forEach((rework) => {
      rework.parts?.forEach((part) => {
        const partData = {
          partNo: part.number ?? "N/A",
          quantity: part.quantity ?? 0
        };

        if (
          rework.reworkType === "REPAIRED" &&
          !rework.correctiveAction.includes("WIP")
        ) {
          // Update used parts map
          const existingData = usedPartsMap.get(part.name) ?? {
            partNo: partData.partNo,
            quantity: 0
          };
          usedPartsMap.set(part.name, {
            partNo: partData.partNo,
            quantity: existingData.quantity + partData.quantity
          });
        } else if (
          rework.reworkType === "SCRAPPED" ||
          rework.correctiveAction.includes("WIP")
        ) {
          // Update recovered parts map
          const existingData = recoveredPartsMap.get(part.name) ?? {
            partNo: partData.partNo,
            quantity: 0
          };
          recoveredPartsMap.set(part.name, {
            partNo: partData.partNo,
            quantity: existingData.quantity + partData.quantity
          });
        }
      });
    });

    // Generate the final report data, combining recovered and used parts
    const reportData: ReportPreviewData[] = [];
    const allParts = new Set([
      ...Array.from(recoveredPartsMap.keys()),
      ...Array.from(usedPartsMap.keys())
    ]);

    allParts.forEach((partName) => {
      const recoveredData = recoveredPartsMap.get(partName) ?? {
        partNo: "N/A",
        quantity: 0
      };
      const usedData = usedPartsMap.get(partName) ?? {
        partNo: "N/A",
        quantity: 0
      };
      const delta = recoveredData.quantity - usedData.quantity;

      // Use part number from recovered data if available, otherwise fallback to used data
      const partNo =
        recoveredData.partNo !== "N/A" ? recoveredData.partNo : usedData.partNo;

      reportData.push({
        part: partName,
        partNo: partNo, // Use the selected part number
        recoveredQuantity: recoveredData.quantity,
        usedQuantity: usedData.quantity,
        delta
      });
    });

    return reportData;
  };

  const previewData = async () => {
    if (!reportType || !startDate || !endDate) {
      console.warn("Start date, end date, or report type is not set.");
      setFeedbackMessage("Please set start date, end date, and report type.");
      return;
    }

    let previewData: ReportPreviewData[] = calculateInventoryReport(
      startDate,
      endDate
    );

    // Filter the data based on the selected report type
    if (reportType === "Recovered") {
      previewData = previewData.filter((item) => item.recoveredQuantity > 0);
    } else if (reportType === "Used") {
      previewData = previewData.filter((item) => item.usedQuantity > 0);
    } else if (reportType === "Delta") {
      // No additional filtering needed for Delta
    } else {
      console.warn("Invalid report type selected");
      setFeedbackMessage("Invalid report type selected.");
      return;
    }

    // Set feedback message if no data is available
    if (previewData.length === 0) {
      setFeedbackMessage("No data available to preview.");
    } else {
      setFeedbackMessage(null); // Clear any previous feedback if data is available
    }

    setDataPreview(previewData);
  };

  const generateReport = async () => {
    try {
      const payload = {
        generatedBy: user?.email || "unknown",
        name: reportName,
        description: reportDescription,
        reportDate: new Date().toISOString(),
        reportData: dataPreview, // Use the previewed data
        reportType: reportType as
          | "Recovered Parts"
          | "Used Parts"
          | "Total Inventory",
        startDate: startDate ? startDate.toISOString() : undefined,
        endDate: endDate ? endDate.toISOString() : undefined
      };

      // Send payload to backend to create the report
      await createInventoryReport(payload);
      setReportGenerated(true); // Mark as generated
      onSubmit();
      handleCancel(); // Reset the form
    } catch (error) {
      console.error("Error generating report:", error);
    }
  };

  const handleDateOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedOption = event.target.value;
    setDateOption(selectedOption);

    if (selectedOption === "thisYear") {
      const currentYear = dayjs().year();
      setStartDate(dayjs(`${currentYear}-01-01`).toDate());
      setEndDate(dayjs(`${currentYear}-12-31`).toDate());
    } else if (selectedOption === "thisMonth") {
      const currentMonth = dayjs().month();
      const currentYear = dayjs().year();
      setStartDate(dayjs(`${currentYear}-${currentMonth + 1}-01`).toDate());
      setEndDate(dayjs().endOf("month").toDate());
    } else if (selectedOption === "today") {
      setStartDate(dayjs().startOf("day").toDate());
      setEndDate(dayjs().endOf("day").toDate());
    } else if (selectedOption === "specificDate") {
      setStartDate(null);
      setEndDate(null);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleDownload = () => {
    if (dataPreview.length === 0) {
      console.warn("No data available to download");
      return;
    }

    // Prepare CSV data based on the selected report type
    let csvData: { [key: string]: any }[] = [];

    if (reportType === "Recovered") {
      csvData = dataPreview.map((item) => ({
        Part: item.part,
        "Part Number": item.partNo,
        "Recovered Quantity": item.recoveredQuantity
      }));
    } else if (reportType === "Used") {
      csvData = dataPreview.map((item) => ({
        Part: item.part,
        "Part Number": item.partNo,
        "Used Quantity": item.usedQuantity
      }));
    } else if (reportType === "Delta") {
      csvData = dataPreview.map((item) => ({
        Part: item.part,
        "Part Number": item.partNo,
        Delta: item.delta
      }));
    } else {
      console.warn("Invalid report type for download");
      return;
    }

    // Add report metadata as a header string
    const metadata = `Report Type: ${reportType}\nStart Date: ${
      startDate ? startDate.toDateString() : "N/A"
    }\nEnd Date: ${endDate ? endDate.toDateString() : "N/A"}\n\n`;

    // Convert data to CSV format using PapaParse
    const csv = Papa.unparse(csvData);

    // Combine the metadata and CSV data
    const completeCsv = metadata + csv;

    // Create a blob from the complete CSV string
    const blob = new Blob([completeCsv], { type: "text/csv;charset=utf-8;" });

    // Create a downloadable link
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${reportType}_Report_${new Date().toISOString().split("T")[0]}.csv`
    );
    link.style.visibility = "hidden";

    // Append link to the body and trigger download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography variant="h6">Report Details</Typography>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Report Name"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Description"
                value={reportDescription}
                onChange={(e) => setReportDescription(e.target.value)}
                multiline
                rows={2}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Report Type</InputLabel>
              <Select
                value={reportType}
                onChange={(e) => setReportType(e.target.value as string)}
                label="Report Type"
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Recovered">Recovered Parts</MenuItem>
                <MenuItem value="Used">Used Parts</MenuItem>
                <MenuItem value="Delta">Total Inventory (Delta)</MenuItem>
              </Select>
            </FormControl>

            {/* Radio buttons for Date selection */}
            <FormControl component="fieldset" margin="normal">
              <RadioGroup
                row
                value={dateOption}
                onChange={handleDateOptionChange}
              >
                <FormControlLabel
                  value="thisYear"
                  control={<Radio />}
                  label="This Year"
                />
                <FormControlLabel
                  value="thisMonth"
                  control={<Radio />}
                  label="This Month"
                />
                <FormControlLabel
                  value="today"
                  control={<Radio />}
                  label="Today"
                />
                <FormControlLabel
                  value="specificDate"
                  control={<Radio />}
                  label="Specific Date"
                />
              </RadioGroup>
            </FormControl>

            {/* Conditionally render DatePickers based on dateOption */}
            {dateOption === "specificDate" && (
              <>
                <FormControl sx={{ mr: 2 }} margin="normal">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      views={["year", "month", "day"]}
                      value={dayjs(startDate)}
                      onChange={(newDate) =>
                        setStartDate(newDate?.toDate() || null)
                      }
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl margin="normal">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      views={["year", "month", "day"]}
                      value={dayjs(endDate)}
                      onChange={(newDate) =>
                        setEndDate(newDate?.toDate() || null)
                      }
                    />
                  </LocalizationProvider>
                </FormControl>
              </>
            )}
          </Box>
        );
      case 1:
        return (
          <Box>
            <Box
              sx={{
                display: "flex",

                justifyContent: "center",

                gap: 1.5,
                mb: 1.5
              }}
            >
              <StyledButton
                onClick={previewData}
                icon={<ImageSearch />}
                iconColor={theme.palette.primary.main}
              >
                Preview Data
              </StyledButton>
              <StyledButton
                onClick={generateReport}
                icon={<Assessment />}
                iconColor={theme.palette.primary.main}
                disabled={reportGenerated}
              >
                Generate Report
              </StyledButton>
              <StyledButton
                onClick={handleDownload}
                disabled={!dataPreview}
                icon={<GetApp />}
                iconColor={theme.palette.primary.main}
              >
                Download Report
              </StyledButton>
            </Box>
            {dataPreview.length > 0 && (
              <>
                {/* Conditional rendering based on the report type */}
                {reportType === "Recovered" && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Recovered Parts
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      From: {startDate?.toLocaleDateString()} - to:{" "}
                      {endDate?.toLocaleDateString()}
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 4 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Part</TableCell>
                            <TableCell>Part Number</TableCell>{" "}
                            {/* New column for part number */}
                            <TableCell>Recovered Quantity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataPreview.map((item, index) => (
                            <TableRow key={`${item.part}-recovered-${index}`}>
                              <TableCell>{item.part}</TableCell>
                              <TableCell>{item.partNo}</TableCell>{" "}
                              {/* Display part number */}
                              <TableCell>{item.recoveredQuantity}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}

                {reportType === "Used" && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Used Parts
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      From: {startDate?.toLocaleDateString()} - to:{" "}
                      {endDate?.toLocaleDateString()}
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 4 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Parts</TableCell>
                            {/* New column for part number */}
                            <TableCell>Part Number</TableCell>
                            <TableCell>Used Quantity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataPreview.map((item, index) => (
                            <TableRow key={`${item.part}-used-${index}`}>
                              <TableCell>{item.part}</TableCell>
                              <TableCell>{item.partNo}</TableCell>
                              <TableCell>{item.usedQuantity}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}

                {reportType === "Delta" && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Total Inventory (Delta)
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      From: {startDate?.toLocaleDateString()} - to:{" "}
                      {endDate?.toLocaleDateString()}
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Parts</TableCell>
                            {/* New column for part number */}
                            <TableCell>Part Number</TableCell>
                            <TableCell>Delta (Recovered - Used)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataPreview.map((item, index) => (
                            <TableRow key={`${item.part}-delta-${index}`}>
                              <TableCell>{item.part}</TableCell>
                              <TableCell>{item.partNo}</TableCell>
                              <TableCell>{item.delta}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </>
            )}
            {feedbackMessage && (
              <Typography sx={{ mt: 2, color: theme.palette.error.main }}>
                {feedbackMessage}
              </Typography>
            )}
          </Box>
        );

      default:
        return "Unknown step";
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        mt: 5,
        overflow: "hidden",
        width: "100%"
      }}
    >
      <StepperMenu
        steps={steps}
        activeStep={activeStep}
        handleStepClick={handleStepClick}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
        disableNext={
          (activeStep === 1 && reportName === "") ||
          (activeStep === 1 && reportDescription === "") ||
          (activeStep === 1 && reportType === "") ||
          (activeStep === 1 && startDate === null && endDate === null)
        }
        disableFinish={false}
        sxBox={{ mb: 2 }}
        sxPaper={{ width: { xs: "100%", md: 1000 }, height: "auto" }}
      >
        {getStepContent(activeStep)}
      </StepperMenu>
    </Box>
  );
};

export default GenerateInventoryReport;
