import React from "react";
import {
  Tabs,
  Tab as MuiTab,
  tabClasses,
  styled,
  SvgIconProps,
  TabProps as MuiTabProps
} from "@mui/material";

export const StyledTabs = styled(Tabs)({
  minHeight: "22px",
  "& .MuiTabs-indicator": {
    display: "none" // Remove the indicator line
  }
  // borderBottom: "0.1px solid #bdbdbd",
});

const StyledTab = styled(MuiTab)(({ theme }) => ({
  textTransform: "none",
  minWidth: 72,
  minHeight: "25px",
  background: "#e0e0e0", // Lighter background for a clean look
  // borderBottom: "0.1px solid #bdbdbd", // Subtle border for separation
  // borderLeft: "0.1px solid #bdbdbd",
  borderRight: "0.1px solid #bdbdbd",
  padding: "3px 12px",
  fontWeight: 500, // Slightly lighter font weight
  color: "#424242", // Gray text

  transition: "all 0.3s ease", // Smooth transition for hover effects
  "&:hover": {
    backgroundColor: "#eeeeee", // White background on hover
    // color: "rgba(30, 58, 138, 1)", // WordPress blue
    "& .MuiSvgIcon-root": {
      fill: "#0073aa" // Icon color changes on hover
    }
  },
  "&.Mui-selected": {
    backgroundColor: "#eeeeee", // White background for the selected tab
    color: "rgba(30, 58, 138, 1)", // WordPress blue for selected text
    fontWeight: 600 // Slightly bolder font for selected tab
  },
  "&.Mui-focusVisible": {
    outline: "2px solid #0073aa" // Focus outline in WordPress blue
  },
  [`& .${tabClasses.textColorInherit}`]: {
    opacity: 1 // Full opacity for better readability
  }
}));
interface StyledIconTabProps extends MuiTabProps {
  label: string;
  icon: React.ReactElement<SvgIconProps>;
  size?: number; // Size in pixels for the icon
}

const StyledIconTab: React.FC<StyledIconTabProps> = ({
  icon,
  label,
  size = 22,
  ...other
}) => (
  <StyledTab
    icon={React.cloneElement(icon, {
      style: {
        fill: "#bdbdbd", // Default grey fill for the icon
        stroke: "#000", // Black stroke color
        strokeWidth: "0.9px", // Thicker stroke to make it stand out
        width: `${size}px`, // Width from size prop
        height: `${size}px` // Height from size prop
      }
    })}
    label={label}
    iconPosition="start"
    {...other}
  />
);

export default StyledIconTab;
