import React, { useState, useRef } from "react";
import {
  Button,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  List,
  ListItemText,
  Typography,
  ListItemButton
} from "@mui/material";
import {
  LeafOne20Regular,
  Lightbulb20Regular,
  ChevronUpDown20Regular
} from "@fluentui/react-icons";
import { SelectedPortalType } from "../../../common/types/constants";

interface SwitchPortalProps {
  portalSelected: string;
  onChangePortal: (portal: SelectedPortalType) => void;
  isMobile: boolean;
}

const SwitchPortal: React.FC<SwitchPortalProps> = ({
  portalSelected,
  onChangePortal,
  isMobile
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (portal: SelectedPortalType) => {
    onChangePortal(portal);
    handleClose();
  };

  return (
    <>
      <Button
        sx={{
          display: "flex",
          width: !isMobile ? "150px" : "auto",
          justifyContent: !isMobile ? "space-between" : "center",
          alignItems: "center",
          px: isMobile ? 0 : 2,
          backgroundColor: isMobile
            ? "transparent"
            : "rgba(255, 255, 255, 0.1)",
          textTransform: "none",
          minWidth: isMobile ? "35px" : "auto",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.2)"
          }
        }}
        ref={anchorRef}
        onClick={handleToggle}
        color="inherit"
      >
        {!isMobile && (
          <Typography
            component="span"
            sx={{ fontSize: "14px", textAlign: "left" }}
          >
            {portalSelected}
          </Typography>
        )}
        <ChevronUpDown20Regular style={{ display: "block" }} />
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List sx={{ width: 200, p: 1 }}>
                  <Typography
                    sx={{
                      px: 2,
                      py: 1,
                      fontSize: "12px",
                      color: "text.secondary",
                      fontWeight: 600
                    }}
                  >
                    Portals
                  </Typography>
                  <ListItemButton
                    onClick={() => handleSelect("Lotus-Pro")}
                    sx={{
                      borderRadius: 1,
                      mb: 0.5,
                      backgroundColor:
                        portalSelected === "Lotus-Pro"
                          ? "rgba(25, 118, 210, 0.08)"
                          : "transparent"
                    }}
                  >
                    <LeafOne20Regular style={{ marginRight: 8 }} />
                    <ListItemText primary="Lotus Pro" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => handleSelect("iCleanLines")}
                    sx={{
                      borderRadius: 1,
                      backgroundColor:
                        portalSelected === "iCleanLines"
                          ? "rgba(25, 118, 210, 0.08)"
                          : "transparent"
                    }}
                  >
                    <Lightbulb20Regular style={{ marginRight: 8 }} />
                    <ListItemText primary="iCleanLines" />
                  </ListItemButton>
                </List>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default SwitchPortal;
