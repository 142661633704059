import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Modal,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  Grid
} from "@mui/material";
import { DeviceData } from "../../../common/types/DeviceTypes";
import { countryLabels } from "../../../common/types/constants";
import { fetchCustomersForUser } from "../../../services/customer.service";
import { Customer } from "../../../store/useCustomer";
import { createChangeRequest } from "../../../services/change-request.service";
import {
  ChangeRequestPayload,
  ChangeRequestTypes
} from "../../../common/types/ChangeRequestTypes";
import useAuthStore from "../../../store/authStore";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
interface EditDeviceModalProps {
  open: boolean;
  onClose: () => void;
  device: DeviceData;
  onSave: (updatedDevice: DeviceData) => void;
  onError: (message: string) => void;
}

const EditDeviceModal: React.FC<EditDeviceModalProps> = ({
  open,
  onClose,
  device,
  onSave,
  onError
}) => {
  const [deviceName, setDeviceName] = useState(
    device.deviceInformation.deviceName
  );
  const [serial, setSerial] = useState(device.deviceInformation.serial);
  const [customerEmail, setCustomerEmail] = useState(
    device.deviceInformation.customerEmail
  );
  const [currentCartridgeType, setCurrentCartridgeType] = useState(
    device.deviceInformation.currentCartridgeType
  );
  const [country, setCountry] = useState(device.deviceLocation?.country || "");
  const [state, setState] = useState(device.deviceLocation?.state || "");
  const [city, setCity] = useState(device.deviceLocation?.city || "");
  const [address, setAddress] = useState(device.deviceLocation?.address || "");
  const [postalCode, setPostalCode] = useState(
    device.deviceLocation?.postalCode || ""
  );
  const [latitude, setLatitude] = useState(
    device.deviceLocation?.latitude?.toString() || ""
  );
  const [longitude, setLongitude] = useState(
    device.deviceLocation?.longitude?.toString() || ""
  );
  const [customerList, setCustomerList] = useState<Customer[]>([]);
  const { user, userRole } = useAuthStore();

  const fetchCustomers = async () => {
    const customers = await fetchCustomersForUser();
    setCustomerList(customers);
  };

  React.useEffect(() => {
    fetchCustomers();
  }, []);

  const handleSave = async () => {
    if (!user || !userRole) return;
    const deviceInformation = {
      deviceName,
      serial,
      customerEmail,
      currentCartridgeType
    };

    const deviceLocation = {
      country,
      state,
      city,
      address,
      postalCode,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude)
    };

    try {
      const changeRequestPayload: ChangeRequestPayload = {
        type: ChangeRequestTypes.UpdateDeviceDetails,
        userEmail: user?.email,
        userRole: userRole,
        details: {
          deviceId: device.deviceInformation.deviceId,
          deviceInformation,
          deviceLocation
        }
      };

      await createChangeRequest(changeRequestPayload);
      onSave(device);

      onClose();
    } catch (error: any) {
      onError(`Error: ${error.message}`);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: 600 },
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
          overflowY: "auto",
          maxHeight: "90vh"
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Edit Device Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Device Name"
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Serial"
              value={serial}
              onChange={(e) => setSerial(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={customerList.map((customer) => customer.email)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Customer Email"
                  margin="normal"
                  required
                  error={!customerEmail}
                  helperText={!customerEmail && "This field is Required"}
                />
              )}
              value={customerEmail}
              onChange={(_event: any, newValue: string | null) => {
                setCustomerEmail(newValue || "");
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Current Cartridge Type</InputLabel>
              <Select
                label="Current Cartridge Type"
                value={currentCartridgeType}
                onChange={(e) =>
                  setCurrentCartridgeType(
                    e.target.value as typeof currentCartridgeType
                  )
                }
              >
                <MenuItem value="SAO-24">SAO-24</MenuItem>
                <MenuItem value="SAO-04">SAO-04 </MenuItem>
                <MenuItem value="AO/Desiccant Only ">
                  AO/Desiccant Only
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" component="h2" gutterBottom>
          Edit Device Location
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={countryLabels}
              getOptionLabel={(option) => option.label}
              onChange={(_event, value) => setCountry(value ? value.label : "")}
              value={
                countryLabels.find((option) => option.label === country) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  fullWidth
                  margin="normal"
                  required
                  error={!country}
                  helperText={!country && "This field is Required"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Postal Code"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Latitude"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Longitude"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
        <Box
          sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 1.5 }}
        >
          <StyledButton onClick={onClose}>Cancel</StyledButton>
          <StyledButton variantType="primary" onClick={handleSave}>
            Save
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditDeviceModal;
