import React, { useEffect } from "react";
import { Box, Chip, Snackbar, Alert } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ChangeRequest } from "../../common/types/ChangeRequestTypes";
import { fetchChangeRequestsForDistributor } from "../../services/change-request.service";
import useAuthStore from "../../store/authStore";

const columns: GridColDef[] = [
  {
    field: "createdAt",
    headerName: "Date Created",
    flex: 1,
    minWidth: 200,
    valueFormatter: (value) => new Date(value).toLocaleString()
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    minWidth: 125
  },
  {
    field: "details",
    headerName: "Details",
    flex: 2,
    minWidth: 300,
    valueGetter: (value) => JSON.stringify(value)
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 125,
    renderCell: (params) => (
      <Chip
        size="small"
        label={params.value}
        color={
          params.value === "Approved"
            ? "success"
            : params.value === "Pending"
              ? "warning"
              : "error"
        }
      />
    )
  },
  {
    field: "updatedAt",
    headerName: "Date Modified",
    flex: 1,
    minWidth: 200,
    valueFormatter: (value) => new Date(value).toLocaleString()
  }
];

function ChangeRequestsTable() {
  const [changeRequests, setChangeRequests] = React.useState<ChangeRequest[]>(
    []
  );
  const [selected, setSelected] = React.useState<string[]>([]);
  const [snackbar, setSnackbar] = React.useState<{
    open: boolean;
    message: string;
    severity: "success" | "error" | "warning" | "info" | undefined;
  }>({ open: false, message: "", severity: undefined });
  const { user } = useAuthStore();

  useEffect(() => {
    const fetchData = async () => {
      if (user?.email) {
        try {
          const result = await fetchChangeRequestsForDistributor(user.email);
          setChangeRequests(Array.isArray(result) ? result : []);
        } catch (error) {
          setChangeRequests([]);
        }
      }
    };
    fetchData();
  }, [user?.email]);

  return (
    <Box p={0}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#f5f5f5",
          mb: 2
        }}
      >
        <Snackbar
          open={snackbar.open}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>

      <DataGrid
        rows={changeRequests}
        columns={columns}
        getRowId={(row) => row._id}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "700"
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
        pageSizeOptions={[5, 10, 25]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newSelection) => {
          setSelected(newSelection.map((id) => String(id)));
        }}
        rowSelectionModel={selected}
      />
    </Box>
  );
}

export default ChangeRequestsTable;
