import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Paper,
  Box,
  Typography,
  TableContainer,
  TablePagination,
  TablePaginationProps,
  TextField
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import {
  fetchDeviceTestsByTester,
  completeDeviceTest
} from "../../../services/device-test.service";
import CreateDeviceTestModal from "./CreateDeviceTestModal";
import DocumentUpload from "./UploadHCUChecklist";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import {
  DeviceTest,
  ICleanUsage,
  PingStatus
} from "../../../common/types/DeviceTestTypes";
import useAuthStore from "../../../store/authStore";
import {
  OzoneResults,
  OzoneResultsFetch
} from "../../../common/types/DeviceTestTypes";
import OzoneResultsModal from "./ OzoneResultsModal";
import { addOzoneResults } from "../../../services/device-test.service";

const POLLING_INTERVAL = 3000;
const LATEST_TESTS_LIMIT = 2;

const ManageProdDeviceTests: React.FC = () => {
  const { user } = useAuthStore();
  const [deviceTests, setDeviceTests] = useState<DeviceTest[]>([]);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTest, setSelectedTest] = useState<DeviceTest | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isOzoneModalOpen, setOzoneModalOpen] = useState(false); // State for ozone modal

  const fetchLastTwoDeviceTests = useCallback(async () => {
    if (!user) return;
    try {
      const response = await fetchDeviceTestsByTester(
        user.email,
        LATEST_TESTS_LIMIT
      );
      setDeviceTests((prevTests) => {
        const updatedTests = [...prevTests];
        response.forEach((newTest) => {
          const existingIndex = updatedTests.findIndex(
            (test) => test.deviceId === newTest.deviceId
          );
          if (existingIndex !== -1) {
            updatedTests[existingIndex] = newTest;
          } else {
            updatedTests.unshift(newTest);
          }
        });
        return updatedTests;
      });
    } catch (error) {
      console.error("Error fetching latest tests: ", error);
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;

    const initialFetch = async () => {
      const response = await fetchDeviceTestsByTester(user.email);
      setDeviceTests(response);
    };

    initialFetch();

    const interval = setInterval(() => {
      fetchLastTwoDeviceTests();
    }, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, [user, fetchLastTwoDeviceTests]);

  const renderPingStatusChip = (pingStatus: PingStatus) => {
    let color: "success" | "error" | "default" = "default";
    if (pingStatus.status === "ONLINE") color = "success";
    if (pingStatus.status === "OFFLINE") color = "error";

    return <Chip size="small" label={pingStatus.status} color={color} />;
  };

  const handleEnterOzoneResults = (test: DeviceTest) => {
    setSelectedTest(test);
    setOzoneModalOpen(true);
  };

  const handleSaveOzoneResults = async (ozoneData: {
    aqueousOzone: string;
    orp: string;
    ph: string;
  }) => {
    if (!selectedTest) return;
    const payload: OzoneResults = {
      deviceId: selectedTest.deviceId,
      ozoneResults: ozoneData
    };
    try {
      await addOzoneResults(payload);
      setOzoneModalOpen(false);
    } catch (error) {
      console.error("Failed to save ozone results:", error);
    }
  };

  const renderOzoneResults = (ozoneResults?: OzoneResultsFetch) => {
    if (!ozoneResults) return null;

    return (
      <Box>
        <Typography variant="body2">
          Aqueous Ozone: {ozoneResults.aqueousOzone}
        </Typography>
        <Typography variant="body2">ORP: {ozoneResults.orp} </Typography>
        <Typography variant="body2">pH: {ozoneResults.ph}</Typography>
      </Box>
    );
  };

  const renderDeviceUsage = (usages: ICleanUsage[]) => {
    if (!usages || usages.length === 0) return null;

    const latestUsage = usages.reduce((latest, current) => {
      return new Date(current.uploadTimestamp) >
        new Date(latest.uploadTimestamp)
        ? current
        : latest;
    });

    return (
      <Box>
        <Typography variant="body2">
          Liters Dispensed: {latestUsage.litersDispensed.toFixed(2)}
        </Typography>
        <Typography variant="body2">
          Cycles Remaining: {latestUsage.cyclesRemaining}
        </Typography>
        <Typography variant="body2">
          Seconds Run: {latestUsage.secsRun}
        </Typography>
        <Typography variant="body2">
          Usage Timestamp:{" "}
          {new Date(latestUsage.usageTimestamp).toLocaleString()}
        </Typography>
        <Typography variant="body2">
          Upload Timestamp:{" "}
          {new Date(latestUsage.uploadTimestamp).toLocaleString()}
        </Typography>
      </Box>
    );
  };

  const handleCompleteTest = async (deviceId: string) => {
    try {
      const updates: Partial<DeviceTest> = { resultStatus: "Passed" }; // Adjust as needed
      await completeDeviceTest(deviceId, updates);
      fetchLastTwoDeviceTests();
    } catch (error) {
      console.error("Failed to complete test:", error);
    }
  };

  const handleChangePage: TablePaginationProps["onPageChange"] = (
    _event,
    newPage
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter by search query
  const searchFilteredTests = deviceTests.filter((test) =>
    test.deviceId.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Further filter by selected date if a date is selected
  const finalFilteredTests = selectedDate
    ? searchFilteredTests.filter((test) =>
        dayjs(test.createdAt).isSame(selectedDate, "day")
      )
    : searchFilteredTests;

  const totalPassed = finalFilteredTests.filter(
    (test) => test.resultStatus === "Passed"
  ).length;
  const totalInProgress = finalFilteredTests.filter(
    (test) => test.resultStatus === "In Progress"
  ).length;

  return (
    <Box sx={{ px: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1, gap: 1 }}>
        <StyledButton
          variantType="primary"
          icon={<Add />}
          onClick={() => setCreateModalOpen(true)}
        >
          New Device
        </StyledButton>
        <TextField
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: 240, // Increased width
            "& .MuiInputBase-root": {
              height: 30, // Set a
              fontSize: "0.875rem" // Smaller font size
            }
          }}
        />

        <Box sx={{ flexGrow: 1 }} />

        {/* For the Tests Passed and In Progress Papers */}
        <Paper
          elevation={0}
          sx={{
            minWidth: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: 0.5,
            border: "1px solid #e0e0e0",
            height: 30
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 400, mr: 1 }}>
            Tests Passed:
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: 900, fontSize: "1rem" }}
          >
            {totalPassed}
          </Typography>
        </Paper>

        <Paper
          elevation={0}
          sx={{
            minWidth: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: 0.5,
            border: "1px solid #e0e0e0",
            height: 30
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 400, mr: 1 }}>
            In Progress
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: 900, fontSize: "1rem" }}
          >
            {totalInProgress}
          </Typography>
        </Paper>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            // label="Filter by date"
            value={selectedDate}
            onChange={(newValue) => {
              setSelectedDate(newValue);
            }}
            sx={{
              ml: 1,
              "& .MuiInputBase-root": {
                height: 30, // Set a
                fontSize: "0.875rem" // Smaller font size
              }
            }}
          />
        </LocalizationProvider>
      </Box>

      <Modal
        open={isCreateModalOpen}
        onClose={() => setCreateModalOpen(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CreateDeviceTestModal
          onClose={() => setCreateModalOpen(false)}
          onCreate={fetchLastTwoDeviceTests}
        />
      </Modal>
      <Modal
        open={isUploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <div>
          {selectedTest && (
            <DocumentUpload
              deviceId={selectedTest.deviceId}
              onClose={() => setUploadModalOpen(false)}
            />
          )}
        </div>
      </Modal>
      <Modal
        open={isOzoneModalOpen}
        onClose={() => setOzoneModalOpen(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <div>
          {selectedTest && (
            <OzoneResultsModal
              open={isOzoneModalOpen}
              onClose={() => setOzoneModalOpen(false)}
              onSave={handleSaveOzoneResults}
            />
          )}
        </div>
      </Modal>
      <TableContainer
        sx={{ px: 0.5, border: "1px solid #e0e0e0", borderRadius: 0 }}
        component={Paper}
        elevation={0}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Device ID</TableCell>
              <TableCell>Tester</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Ping Status</TableCell>
              <TableCell>Device Usage</TableCell>
              <TableCell>Ozone Results</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finalFilteredTests
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((test, index) => (
                <TableRow key={test.deviceId}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{test.deviceId}</TableCell>
                  <TableCell>{test.tester}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={test.resultStatus}
                      color={
                        test.resultStatus === "Passed"
                          ? "success"
                          : test.resultStatus === "Failed"
                            ? "error"
                            : "default"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {test.pingStatus && renderPingStatusChip(test.pingStatus)}
                  </TableCell>
                  <TableCell>
                    {test.deviceUsage && renderDeviceUsage(test.deviceUsage)}
                  </TableCell>
                  <TableCell>
                    {test.ozoneResults && renderOzoneResults(test.ozoneResults)}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                        gap: 1
                      }}
                    >
                      <StyledButton
                        variantType="outline-primary"
                        onClick={() => handleEnterOzoneResults(test)}
                      >
                        Enter Ozone Results
                      </StyledButton>
                      <StyledButton
                        variantType="outline-primary"
                        onClick={() => handleCompleteTest(test.deviceId)}
                      >
                        Complete Test
                      </StyledButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={finalFilteredTests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default ManageProdDeviceTests;
