import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Tooltip,
  Snackbar,
  Alert,
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  TextField,
  InputAdornment
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenIcon,
  DriveFileRenameOutline as DriveFileRenameOutlineIcon,
  Description as DescriptionIcon
} from "@mui/icons-material";
import { deleteReport, getAllReports } from "../../../services/reports.service";
import useAuthStore from "../../../store/authStore";
import openPDFWindow from "../../../shared/Components/OpenPDFWindow";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import StyledIconButton from "../../../shared/Components/Styled/StyledIconButton";

interface ReportData {
  _id: string;
  reportInformation: {
    name: string;
    description?: string;
    reportType: string;
    generatedAt: Date;
    generatedBy: string;
    startDate?: Date;
    endDate?: Date;
  };
  reportData: any;
  reportCategory: string;
}

interface ReportHistoryTableProps {
  reportCategory: "CustomerReport" | "DeviceReport";
}

const ReportHistoryTable: React.FC<ReportHistoryTableProps> = ({
  reportCategory
}) => {
  const [data, setData] = useState<ReportData[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedForDeletion, setSelectedForDeletion] = useState<string | null>(
    null
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor
  });
  const { user, userRole } = useAuthStore();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isReportDeleted, setIsReportDeleted] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ReportData | null>(null);
  const [editedName, setEditedName] = useState<string>("");
  const [editedDescription, setEditedDescription] = useState<string>("");

  const fetchData = useCallback(async () => {
    try {
      const response = await getAllReports(
        user?.email || "Email Unavailable",
        userRole || "Role Unavailable"
      );
      const filteredData = response.filter(
        (report: ReportData) => report.reportCategory === reportCategory
      );
      setData(filteredData);
    } catch (error) {
      console.error("Error fetching report data:", error);
    }
  }, [user, userRole, reportCategory]);

  useEffect(() => {
    fetchData();
    if (isReportDeleted) {
      setIsReportDeleted(false);
    }
  }, [fetchData, isReportDeleted, user, userRole]);

  const handleOpenDeleteModal = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      setSelectedForDeletion(report._id);
      setDeleteModalOpen(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedForDeletion(null);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteReport(id);
      setIsReportDeleted(true);
      // Show success message
      setSnackbar({
        open: true,
        message: "Report deleted successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Error deleting report:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete report",
        severity: "error"
      });
    } finally {
      handleCloseDeleteModal();
    }
  };

  const handleEdit = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      setSelectedReport(report);
      setEditedName(report.reportInformation.name);
      setEditedDescription(report.reportInformation.description || "");
      setEditModalOpen(true);
    }
  };

  const handleSave = () => {
    if (selectedReport) {
      // Save logic here
      // For now, just log the edited report
      console.log("Saved report:", {
        ...selectedReport,
        reportInformation: {
          ...selectedReport.reportInformation,
          name: editedName,
          description: editedDescription
        }
      });
      // Optionally update the data state to reflect the changes
      setData((prevData) =>
        prevData.map((report) =>
          report._id === selectedReport._id
            ? {
                ...report,
                reportInformation: {
                  ...report.reportInformation,
                  name: editedName,
                  description: editedDescription
                }
              }
            : report
        )
      );
      setEditModalOpen(false);
    }
  };

  const handleOpen = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      openPDFWindow(report);
    } else {
      console.error("Report not found or could not be loaded.");
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 175,
      valueGetter: (_value, row) => row.reportInformation.name
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 125,
      valueGetter: (_value, row) => row.reportInformation.description
    },
    {
      field: "reportType",
      headerName: "Report Type",
      flex: 1,
      minWidth: 125,
      valueGetter: (_value, row) => row.reportInformation.reportType
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 125,
      valueGetter: (_value, row) =>
        new Date(row.reportInformation.generatedAt).toLocaleDateString()
    },
    {
      field: "generatedBy",
      headerName: "Generated By",
      flex: 1,
      minWidth: 200,
      valueGetter: (_value, row) => row.reportInformation.generatedBy
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 125,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Open">
            <StyledIconButton
              variantType="transparent"
              onClick={() => handleOpen(params.row._id)}
            >
              <OpenIcon />
            </StyledIconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <StyledIconButton
              variantType="transparent"
              onClick={() => handleEdit(params.row._id)}
            >
              <EditIcon />
            </StyledIconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <StyledIconButton
              variantType="transparent"
              onClick={() => handleOpenDeleteModal(params.row._id)}
            >
              <DeleteIcon />
            </StyledIconButton>
          </Tooltip>
        </>
      )
    }
  ];

  return (
    <Box sx={{ p: 0 }}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row._id}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "700"
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
        pageSizeOptions={[5, 10, 25]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newSelection) => {
          setSelected(newSelection.map((id) => String(id)));
        }}
        rowSelectionModel={selected}
      />

      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDeleteModal}>Cancel</StyledButton>
          <StyledButton
            onClick={() => {
              if (selectedForDeletion) {
                handleDelete(selectedForDeletion);
                handleCloseDeleteModal();
              }
            }}
            variantType="destructive"
          >
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
      <Modal open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 220, sm: 400 },
            bgcolor: "background.paper",
            border: "1px solid gray",
            boxShadow: 24,
            pl: 4,
            pr: 4,
            pb: 4,
            borderRadius: 1
          }}
        >
          <h3>Edit Report Details</h3>
          <TextField
            fullWidth
            margin="normal"
            label="Report Name"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DriveFileRenameOutlineIcon />
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            multiline
            rows={4}
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DescriptionIcon />
                </InputAdornment>
              )
            }}
          />
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 1
            }}
          >
            <StyledButton onClick={() => setEditModalOpen(false)}>
              Cancel
            </StyledButton>
            <StyledButton onClick={handleSave} variantType="primary">
              Save
            </StyledButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ReportHistoryTable;
