import {
  Box,
  Grid,
  Tooltip,
  Chip,
  Typography,
  Autocomplete,
  CircularProgress,
  Collapse,
  IconButton,
  TextField
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import useDeviceStore from "../../../store/useDeviceStore";
import CartridgeIndicator from "../../Shared/CartridgeIndicator";
import dayjs from "dayjs";
import { detectCartridgeChanges } from "../../../utils/helper";
import { useState, useEffect, useRef, useMemo } from "react";
import TotalCartridgeChangesCard from "./Cards/TotalCartridgeChangesCard";
import PeakUsageMonthsCard from "./Cards/PeakUsageMonthsCard";
import CartridgeDeviceTable from "./Table/CartridgeDeviceTable";
import LitersDispensedChart from "./Charts/LitersDispensedChart";
import CartridgeChangesChart from "./Charts/CartridgeChangesChart";
import { ManageSearch } from "@mui/icons-material";

const CartridgeTab = () => {
  const { allDevices } = useDeviceStore();
  const [selected, setSelected] = useState<string[]>([]);
  const [deviceSearch, setDeviceSearch] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showDeviceSearch, setShowDeviceSearch] = useState<boolean>(false);
  const searchBarRef = useRef<HTMLDivElement | null>(null); // Ref for the search bar
  const [cartridgeStats, setCartridgeStats] = useState({
    totalChanges: 0,
    cartridgeTypes: {} as Record<string, number>
  });
  const [cartridgeChangeLogs, setCartridgeChangeLogs] = useState<
    {
      timestamp: string;
      deviceId: string;
      deviceName: string;
      cartridgeType: string;
      litersDispensed: number;
    }[]
  >([]);

  // Initialize with all devices selected
  useEffect(() => {
    if (allDevices.length > 0) {
      setSelected(
        allDevices
          .filter((device) => device._id)
          .map((device) => device._id as string)
      );
    }
  }, [allDevices]);

  // Calculate cartridge statistics when selection changes
  useEffect(() => {
    const selectedDevices = allDevices.filter(
      (device) => device._id && selected.includes(device._id)
    );
    let totalChanges = 0;
    const cartridgeTypes: Record<string, number> = {};
    const allChangeLogs: {
      timestamp: string;
      deviceId: string;
      deviceName: string;
      cartridgeType: string;
      litersDispensed: number;
    }[] = [];

    selectedDevices.forEach((device) => {
      const changes = detectCartridgeChanges(device.deviceUsage);
      totalChanges += changes.length;

      changes.forEach((change) => {
        const cartridgeType = change.newCartridgeType;
        cartridgeTypes[cartridgeType] =
          (cartridgeTypes[cartridgeType] || 0) + 1;
        allChangeLogs.push({
          timestamp: change.timestamp,
          deviceId: device.deviceInformation.deviceId,
          deviceName: device.deviceInformation.deviceName,
          cartridgeType: device.deviceInformation.currentCartridgeType || "N/A",
          litersDispensed: change.litersDispensed
        });
      });
    });

    setCartridgeStats({ totalChanges, cartridgeTypes });
    setCartridgeChangeLogs(allChangeLogs);
  }, [selected, allDevices]);

  // Handle input changes and set loading state
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    if (value === "") {
      setDeviceSearch(value);
      setLoading(false);
      return;
    }
    setLoading(true);

    // Simulate a delay to mimic loading and then set the search term
    setTimeout(() => {
      setDeviceSearch(value);
      setLoading(false);
    }, 500); // Adjust the delay as needed
  };

  const handleToggleDeviceSearch = (show: boolean) => {
    setShowDeviceSearch((prev) => !prev);
  };

  // function to get status of the device
  const getStatusLabel = (status: string): string => {
    if (!status) return "N/A";
    switch (status.toUpperCase()) {
      case "ONLINE":
        return "ON";
      case "OFFLINE":
        return "OFF";
      default:
        return "N/A";
    }
  };

  const columns: GridColDef[] = [
    {
      field: "deviceId",
      headerName: "Device ID",
      sortable: false,
      flex: 1,
      minWidth: 80,
      renderCell: (params) => params.row.deviceInformation.deviceId,
      valueGetter: (_value, row) => row.deviceInformation.deviceId
    },
    {
      field: "deviceName",
      headerName: "Device Name",
      flex: 1,
      minWidth: 220,
      sortable: false,
      hideSortIcons: true,

      renderHeader: () => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            width: "100%",
            minWidth: "200px",
            height: "90px",
            padding: "0 4px"
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            Device Name
          </Typography>
          <IconButton
            size="small"
            onClick={() => handleToggleDeviceSearch(true)}
            sx={{
              marginLeft: 1,
              transition: "transform 0.2s",
              "&:hover": {
                transform: "scale(1.1)",
                backgroundColor: "transparent"
              }
            }}
          >
            <ManageSearch />
          </IconButton>

          {/* Search Input */}
          <Collapse
            in={showDeviceSearch}
            ref={searchBarRef}
            sx={{
              position: "absolute",
              top: 52,
              left: 0,
              right: 10,
              zIndex: 1000,
              backgroundColor: "white",
              boxShadow: 2,
              borderRadius: 1,
              p: 0.5,
              "& .MuiInputBase-root": {
                margin: "0 !important"
              },
              "& .MuiFormControl-root": {
                margin: "0 !important"
              }
            }}
          >
            <Autocomplete
              freeSolo
              options={allDevices.map(
                (device) => device.deviceInformation.deviceName
              )}
              inputValue={inputValue || ""}
              onInputChange={(_event, value) =>
                handleInputChange({
                  target: { value }
                } as React.ChangeEvent<HTMLInputElement>)
              }
              clearOnEscape // Allows clearing using the Escape key
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={!inputValue ? "Search Device" : ""} // Hide label when typing
                  //placeholder="Search Device..."
                  size="small"
                  variant="standard"
                  fullWidth
                  onChange={handleInputChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: loading ? (
                      <CircularProgress size={20} />
                    ) : null
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main"
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "primary.dark"
                    },
                    "& .MuiInputLabel-root": {
                      transform: "translate(0, 1.5px) scale(0.75)",
                      transformOrigin: "top left"
                    }
                  }}
                />
              )}
            />
          </Collapse>
        </Box>
      ),
      renderCell: (params) => params.row.deviceInformation.deviceName,
      valueGetter: (_value, row) => row.deviceInformation.deviceName
    },
    {
      field: "cartridgeType",
      headerName: "Cartridge Type",
      flex: 1,
      minWidth: 125,
      renderCell: (params) => params.row.deviceInformation.currentCartridgeType,
      valueGetter: (_value, row) => row.deviceInformation.currentCartridgeType
    },
    {
      field: "cartridgeLife",
      headerName: "Cartridge Life",
      flex: 1,
      minWidth: 125,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%"
            }}
          >
            <CartridgeIndicator
              cyclesRemaining={
                params.row.deviceUsage.length > 0
                  ? params.row.deviceUsage[params.row.deviceUsage.length - 1]
                      ?.cyclesRemaining || 0
                  : 0
              }
              currentCartridgeType={
                params.row.deviceInformation.currentCartridgeType
              }
              // isGallons={isGallons || false}
              isGallons={false}
            />
          </Box>
        );
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const statusLabel = getStatusLabel(params.row.pingStatus.status);

        return (
          <Tooltip
            title={
              <Typography>
                Last Ping:{" "}
                {dayjs(params.row.pingStatus.timestamp).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </Typography>
            }
          >
            <Chip
              label={statusLabel}
              size="small"
              sx={{
                color: "white",
                backgroundColor: (theme) => {
                  switch (statusLabel) {
                    case "ON":
                      return theme.palette.success.main;
                    case "OFF":
                      return theme.palette.error.main;
                    default:
                      return theme.palette.grey[500];
                  }
                },
                "& .MuiChip-label": {
                  padding: "0 8px"
                }
              }}
            />
          </Tooltip>
        );
      },
      valueGetter: (_value, row) => row.pingStatus.status
    }
  ];

  // Add selection change handler
  const handleSelectionChange = (newSelection: any) => {
    setSelected(newSelection.map((id: any) => String(id)));
  };

  // Calculate peak usage months
  const getPeakUsageMonths = () => {
    const monthlyChanges = cartridgeChangeLogs.reduce(
      (acc, change) => {
        const monthKey = dayjs(change.timestamp).format("MMM YYYY");
        acc[monthKey] = (acc[monthKey] || 0) + 1;
        return acc;
      },
      {} as Record<string, number>
    );

    // Sort months by number of changes
    const sortedMonths = Object.entries(monthlyChanges)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 3); // Get top 3 months

    return sortedMonths;
  };

  const peakMonths = getPeakUsageMonths();

  const filteredDevices = useMemo(() => {
    return allDevices.filter((device) => {
      // Make search case-insensitive
      const deviceName = device.deviceInformation.deviceName.toLowerCase();
      const searchQuery = deviceSearch?.toLowerCase() || "";

      return (
        // Include real-time search filter
        !deviceSearch || deviceName.includes(searchQuery)
      );
    });
  }, [deviceSearch, allDevices]);

  return (
    <Box sx={{ flexGrow: 1, pb: 1 }}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1.5}>
            {/* Card row - Add marginBottom to create space before graphs */}
            <Grid item xs={12}>
              <Grid
                container
                spacing={1.5}
                sx={{ display: "flex", alignItems: "stretch" }}
              >
                <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%"
                    }}
                  >
                    <TotalCartridgeChangesCard
                      totalChanges={cartridgeStats.totalChanges}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%"
                    }}
                  >
                    <PeakUsageMonthsCard peakMonths={peakMonths} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* Graphs below */}
            <Grid item xs={12}>
              <LitersDispensedChart cartridgeChanges={cartridgeChangeLogs} />
            </Grid>

            <Grid item xs={12}>
              <CartridgeChangesChart cartridgeChanges={cartridgeChangeLogs} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <CartridgeDeviceTable
            rows={filteredDevices}
            columns={columns}
            selected={selected}
            handleSelectionChange={handleSelectionChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CartridgeTab;
