import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  TextField,
  InputAdornment
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenIcon,
  DriveFileRenameOutline as DriveFileRenameOutlineIcon,
  Description as DescriptionIcon
} from "@mui/icons-material";
import { deleteReport, getAllReports } from "../../../services/reports.service";
import useAuthStore from "../../../store/authStore";
import openPDFWindow from "../../../shared/Components/OpenPDFWindow";
import Papa from "papaparse";

interface InventoryReportData {
  _id: string;
  reportInformation: {
    name: string;
    description?: string;
    reportType: "Recovered Parts" | "Used Parts" | "Total Inventory";
    generatedAt: Date;
    generatedBy: string;
    startDate?: Date;
    endDate?: Date;
  };
  reportData: any;
  reportCategory: "InventoryReport";
}

interface InventoryReportHistoryTableProps {
  reportCategory: "InventoryReport";
}

const InventoryReportHistoryTable: React.FC<
  InventoryReportHistoryTableProps
> = ({ reportCategory }) => {
  const [data, setData] = useState<InventoryReportData[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedForDeletion, setSelectedForDeletion] = useState<string | null>(
    null
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor
  });
  const { user, userRole } = useAuthStore();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] =
    useState<InventoryReportData | null>(null);
  const [editedName, setEditedName] = useState<string>("");
  const [editedDescription, setEditedDescription] = useState<string>("");

  const fetchData = useCallback(async () => {
    try {
      const response = await getAllReports(user?.email || "", userRole || "");
      const filteredData = response.filter(
        (report: InventoryReportData) =>
          report.reportCategory === reportCategory
      );
      setData(filteredData);
    } catch (error) {
      console.error("Error fetching inventory reports:", error);
    }
  }, [user, userRole, reportCategory]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOpenDeleteModal = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      setSelectedForDeletion(report._id);
      setDeleteModalOpen(true);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteReport(id);
      setData((prevData) => prevData.filter((report) => report._id !== id));
      setSnackbar({
        open: true,
        message: "Report deleted successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Error deleting report:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete report",
        severity: "error"
      });
    } finally {
      setDeleteModalOpen(false);
    }
  };

  const handleEdit = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      setSelectedReport(report);
      setEditedName(report.reportInformation.name);
      setEditedDescription(report.reportInformation.description || "");
      setEditModalOpen(true);
    }
  };

  const handleSave = () => {
    if (selectedReport) {
      const updatedReport = {
        ...selectedReport,
        reportInformation: {
          ...selectedReport.reportInformation,
          name: editedName,
          description: editedDescription
        }
      };
      setData((prevData) =>
        prevData.map((report) =>
          report._id === selectedReport._id ? updatedReport : report
        )
      );
      setEditModalOpen(false);
    }
  };

  const exportToCSV = (report: InventoryReportData) => {
    if (
      Array.isArray(report.reportData?.data) &&
      report.reportData.data.length > 0
    ) {
      const generalFields = {
        startDate: report.reportInformation.startDate
          ? new Date(report.reportInformation.startDate).toLocaleDateString()
          : "N/A",
        endDate: report.reportInformation.endDate
          ? new Date(report.reportInformation.endDate).toLocaleDateString()
          : "N/A",
        reportDate: new Date(
          report.reportInformation.generatedAt
        ).toLocaleDateString(),
        generatedBy: report.reportInformation.generatedBy,
        reportType: report.reportInformation.reportType
      };

      const dynamicHeaders = Object.keys(report.reportData.data[0]);
      const combinedHeaders = [
        ...dynamicHeaders,
        ...Object.keys(generalFields)
      ];
      const generalFieldsRow = {
        ...Object.fromEntries(dynamicHeaders.map((h) => [h, ""])),
        ...generalFields
      };

      const csvData = report.reportData.data.map((item: any) => {
        const row: any = {};
        dynamicHeaders.forEach((header) => {
          row[header] = Array.isArray(item[header])
            ? item[header].join(", ")
            : item[header];
        });
        return row;
      });

      const csv = Papa.unparse({
        fields: combinedHeaders,
        data: [generalFieldsRow, ...csvData]
      });
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${report.reportInformation.name}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Report Name",
      flex: 1.5,
      minWidth: 150,
      valueGetter: (_value, row) => row.reportInformation?.name || ""
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 200,
      valueGetter: (_value, row) => row.reportInformation?.description || ""
    },
    {
      field: "reportType",
      headerName: "Report Type",
      flex: 1.5,
      minWidth: 125,
      valueGetter: (_value, row) => row.reportInformation?.reportType || ""
    },
    {
      field: "generatedAt",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      valueGetter: (_value, row) => row.reportInformation?.generatedAt,
      valueFormatter: (value) => (value ? new Date(value).toLocaleString() : "")
    },
    {
      field: "generatedBy",
      headerName: "Generated By",
      flex: 1.5,
      minWidth: 200,
      valueGetter: (_value, row) => row.reportInformation?.generatedBy || ""
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 175,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            height: "100%",
            width: "100%"
          }}
        >
          <Tooltip title="Open Report">
            <IconButton size="small" onClick={() => openPDFWindow(params.row)}>
              <DescriptionIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Export to CSV">
            <IconButton size="small" onClick={() => exportToCSV(params.row)}>
              <OpenIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit Report">
            <IconButton size="small" onClick={() => handleEdit(params.row._id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete Report">
            <IconButton
              size="small"
              onClick={() => handleOpenDeleteModal(params.row._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  ];

  return (
    <Box sx={{ p: 0 }}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Box sx={{ mt: 2 }}>
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row._id}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "700"
            }
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            }
          }}
          pageSizeOptions={[5, 10, 25]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelection) => {
            setSelected(newSelection.map((id) => String(id)));
          }}
          rowSelectionModel={selected}
        />
      </Box>
      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              selectedForDeletion && handleDelete(selectedForDeletion)
            }
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Modal open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 220, sm: 400 },
            bgcolor: "background.paper",
            border: "1px solid gray",
            boxShadow: 24,
            pl: 4,
            pr: 4,
            pb: 4,
            borderRadius: 1
          }}
        >
          <h3>Edit Report Details</h3>
          <TextField
            fullWidth
            margin="normal"
            label="Report Name"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DriveFileRenameOutlineIcon />
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            multiline
            rows={4}
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DescriptionIcon />
                </InputAdornment>
              )
            }}
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setEditModalOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              sx={{ ml: 2 }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default InventoryReportHistoryTable;
