import { useState } from "react";
import {
  TextField,
  Typography,
  Paper,
  Tooltip,
  IconButton,
  Box,
  CircularProgress
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { determineIoTVersion } from "../utils/helper";
import HomeIcon from "@mui/icons-material/Home";
import StyledButton from "../shared/Components/Styled/StyledButton";

const CheckSerialVersion = () => {
  const [serial, setSerial] = useState("");
  const [version, setVersion] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleCheckVersion = async () => {
    if (!serial) {
      setError(true);
      return;
    }
    setError(false);
    setLoading(true); // Set loading to true
    const resultVersion = await determineIoTVersion(serial);
    setVersion(resultVersion);
    setLoading(false); // Set loading to false after version is fetched
  };

  return (
    <Box
      sx={{
        display: "flex",
        p: 3,
        pt: { xs: "30%", sm: "20%", md: "10%" },
        justifyContent: "center",
        minHeight: "100vh",
        background:
          "linear-gradient(45deg, rgba(30, 58, 138, 1) 55%, rgba(30, 58, 138, 0.6) 90%)"
      }}
    >
      <Tooltip title="Back to Dashboard">
        <IconButton
          sx={{
            position: "absolute",
            top: 20,
            left: 20,
            color: "white",
            "&:hover": {
              color: "secondary.light"
            }
          }}
          onClick={() => navigate("/dashboard")}
        >
          <HomeIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Tooltip>

      <Paper
        elevation={3}
        sx={{ p: 3, height: "50%", width: { xs: "80%", sm: "60%", md: "30%" } }}
      >
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Check Version
        </Typography>
        <TextField
          fullWidth
          label="Enter Serial Number"
          variant="outlined"
          value={serial}
          onChange={(e) => setSerial(e.target.value)}
          error={error}
          helperText={error ? "Serial number is required" : " "}
        />
        <StyledButton
          size="large"
          variantType="primary"
          onClick={handleCheckVersion}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Check Version"
          )}
        </StyledButton>
        {version && !loading && (
          <Typography variant="h6" sx={{ mt: 2, textAlign: "center" }}>
            Version:{" "}
            {version === "UNKNOWN"
              ? "Unknown or invalid serial number"
              : version}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default CheckSerialVersion;
