import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";
import tersanoLogo from "../../../assets/images/tersano-logo.png";

// Register Garamond font
Font.register({
  family: "Garamond",
  fonts: [
    {
      src: "/fonts/garamond.ttf",
      fontWeight: 400
    }
  ]
});

const styles = StyleSheet.create({
  page: { backgroundColor: "#fff", padding: 30, fontFamily: "Garamond" },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20
  },
  logo: { width: 100 },
  title: { fontSize: 16, textAlign: "center", margin: 10, color: "black" },
  table: { width: "100%", marginTop: 20, borderStyle: "solid", borderWidth: 1 },
  tableRow: { flexDirection: "row" },
  tableColHeader: {
    width: "15%", // Default width for other columns
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5,
    backgroundColor: "#34495e"
  },
  tableColHeaderDouble: {
    width: "35%", // Double width for Distributor and Email
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5,
    backgroundColor: "#34495e"
  },
  tableCol: {
    width: "15%", // Default width for other columns
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5
  },
  tableColDouble: {
    width: "35%", // Double width for Distributor and Email
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#ecf0f1",
    textAlign: "center"
  },
  tableCell: { fontSize: 10, textAlign: "center", color: "#2c3e50" },
  period: { fontSize: 12, marginBottom: 5, color: "black" },
  generatedInfo: { fontSize: 10, color: "black" }
});

export interface WarrantyReportTemplateProps {
  reportType: "Credit" | "Return" | "Invalid Credit";
  reportName: string;
  reportData: any[];
  startDate: Date;
  endDate: Date;
  generatedBy: string;
  generatedAt: Date;
}

const WarrantyReportTemplate: React.FC<WarrantyReportTemplateProps> = ({
  reportType,
  reportName,
  reportData,
  startDate,
  endDate,
  generatedBy,
  generatedAt
}) => {
  const renderTableHeaders = () => {
    const isDoubleWidth = ["Credit", "Return"].includes(reportType);

    return (
      <View style={styles.tableRow}>
        <View
          style={
            isDoubleWidth ? styles.tableColHeaderDouble : styles.tableColHeader
          }
        >
          <Text style={styles.tableCellHeader}>Distributor</Text>
        </View>
        <View
          style={
            isDoubleWidth ? styles.tableColHeaderDouble : styles.tableColHeader
          }
        >
          <Text style={styles.tableCellHeader}>Email</Text>
        </View>
        {reportType === "Credit" && (
          <>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Warranties</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Total Credit</Text>
            </View>
          </>
        )}
        {reportType === "Return" && (
          <>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Device Models</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Returns</Text>
            </View>
          </>
        )}
        {reportType === "Invalid Credit" && (
          <>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Warranties</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Total Credit</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Invalid Warranties</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Invalid Credit</Text>
            </View>
          </>
        )}
      </View>
    );
  };

  const renderTableRows = () =>
    reportData.map((item, index) => (
      <View style={styles.tableRow} key={index}>
        <View
          style={
            ["Credit", "Return"].includes(reportType)
              ? styles.tableColDouble
              : styles.tableCol
          }
        >
          <Text style={styles.tableCell}>{item.distributor || "N/A"}</Text>
        </View>
        <View
          style={
            ["Credit", "Return"].includes(reportType)
              ? styles.tableColDouble
              : styles.tableCol
          }
        >
          <Text style={styles.tableCell}>{item.email || "N/A"}</Text>
        </View>
        {reportType === "Credit" && (
          <>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {item.numberOfWarranties || 0}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {item.creditAmount ? item.creditAmount.toFixed(2) : "0.00"}
              </Text>
            </View>
          </>
        )}
        {reportType === "Return" && (
          <>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {Array.isArray(item.deviceModels)
                  ? item.deviceModels.join(", ")
                  : item.deviceModels || "N/A"}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.numberOfReturns || 0}</Text>
            </View>
          </>
        )}
        {reportType === "Invalid Credit" && (
          <>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {item.numberOfWarranties || 0}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {item.creditAmount ? item.creditAmount.toFixed(2) : "0.00"}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {item.numberOfInvalidCredits || 0}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {item.totalInvalidCreditAmount
                  ? item.totalInvalidCreditAmount.toFixed(2)
                  : "0.00"}
              </Text>
            </View>
          </>
        )}
      </View>
    ));

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={tersanoLogo} />
          <View>
            <Text style={styles.period}>
              Period: {new Date(startDate).toLocaleDateString()} -{" "}
              {new Date(endDate).toLocaleDateString()}
            </Text>
            <Text style={styles.generatedInfo}>
              Generated by: {generatedBy}
            </Text>
            <Text style={styles.generatedInfo}>
              Date: {new Date(generatedAt).toLocaleDateString()}
            </Text>
          </View>
        </View>
        <Text style={styles.title}>{reportName} Report</Text>
        <View style={styles.table}>
          {renderTableHeaders()}
          {renderTableRows()}
        </View>
      </Page>
    </Document>
  );
};

export default WarrantyReportTemplate;
