import React from "react";
import { Container, Typography, Box } from "@mui/material";

const TrainingVideosTab = () => {
  return (
    <Container
      sx={{
        padding: "16px 0px",
        "& > *": { padding: "4px 0" }
      }}
    >
      {/* Main Title */}
      <Typography variant="h4" fontWeight="bold">
        Training Videos
      </Typography>

      {/* Page Introduction */}
      <Typography variant="body1" gutterBottom>
        Welcome to the Training Videos page! Here you will find helpful videos
        that guide you through important actions related to your account. Click
        on any of the videos below to learn how to:
      </Typography>

      {/* Install New Device Video */}
      <Typography variant="h6" fontWeight="bold">
        1. Install New Device
      </Typography>
      <Box sx={{ maxWidth: 640, mb: 4 }}>
        <Box
          sx={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden"
          }}
        >
          <iframe
            src="https://tersanocanada-my.sharepoint.com/personal/emmanuelj_tersano_com/_layouts/15/embed.aspx?UniqueId=4dcc98b9-98fd-4ce4-821e-7f0545680e1f&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
            width="640"
            height="360"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            title="Install New Device"
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: "100%",
              width: "100%"
            }}
          ></iframe>
        </Box>
      </Box>

      {/* Add New Customer Video */}
      <Typography variant="h6" fontWeight="bold">
        2. Add New Customer
      </Typography>

      <Box sx={{ maxWidth: 640, mb: 4 }}>
        <Box
          sx={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden"
          }}
        >
          <iframe
            src="https://tersanocanada-my.sharepoint.com/personal/emmanuelj_tersano_com/_layouts/15/embed.aspx?UniqueId=4dcc98b9-98fd-4ce4-821e-7f0545680e1f&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
            width="640"
            height="360"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            title="Add New Customer"
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: "100%",
              width: "100%"
            }}
          ></iframe>
        </Box>
      </Box>

      {/* Add Additional Users */}
      <Typography variant="h6" fontWeight="bold">
        3. Add Additional Users
      </Typography>

      <Box sx={{ maxWidth: 640, mb: 4 }}>
        <Box
          sx={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden"
          }}
        >
          <iframe
            src="https://tersanocanada-my.sharepoint.com/personal/emmanuelj_tersano_com/_layouts/15/embed.aspx?UniqueId=437185cc-2351-4cb9-934b-5ba8e5eaa5d2&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
            width="640"
            height="360"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            title="Add New Customer"
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: "100%",
              width: "100%"
            }}
          ></iframe>
        </Box>
      </Box>

      {/* View Your Change Requests */}
      <Typography variant="h6" fontWeight="bold">
        4. View Your Change Requests
      </Typography>

      <Box sx={{ maxWidth: 640, mb: 4 }}>
        <Box
          sx={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden"
          }}
        >
          <iframe
            src="https://tersanocanada-my.sharepoint.com/personal/emmanuelj_tersano_com/_layouts/15/embed.aspx?UniqueId=8bed95c2-6009-4b1f-977e-0abcac3f6e3f&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
            width="640"
            height="360"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            title="Add New Customer"
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: "100%",
              width: "100%"
            }}
          ></iframe>
        </Box>
      </Box>

      <Typography variant="body1" gutterBottom>
        We hope these videos help you make the most of your experience. If you
        have any questions or need further assistance, please reach out to our
        support team.
      </Typography>
    </Container>
  );
};

export default TrainingVideosTab;
