import React, { useState } from "react";
import { Box, Typography, Collapse, Grid, Divider } from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from "@mui/icons-material";
import StyledIconButton from "../../../shared/Components/Styled/StyledIconButton";

// SectionHeader component
const SectionHeader = ({ title }: { title: string }) => (
  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
    <Typography
      variant="h6"
      sx={{
        flexShrink: 0,
        pr: 2,
        textAlign: "center",
        color: "primary.main",
        fontWeight: "700",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "13px"
      }}
    >
      {title}
    </Typography>
    <Divider
      sx={{ flexGrow: 1, borderBottomWidth: 3, bgcolor: "secondary.main" }}
    />
  </Box>
);

// Grouped FAQ data by section
type FAQItem = {
  question: string;
  answer: string;
};

type FAQData = {
  [key: string]: FAQItem[];
};

const faqData: FAQData = {
  "iClean Dashboard": [
    {
      question:
        "Does the iClean Dashboard require end users to have a subscription?",
      answer:
        "At this time, the iClean Dashboard does not require end users to have a subscription."
    },
    {
      question: "How much does the iClean Dashboard cost?",
      answer:
        "Accessing the iClean Dashboard is free. To compare pricing on the lotus PRO V9 and lotus PRO V8, please refer to Tersano's September 2024 Price List."
    },
    {
      question: "What languages is the iClean Dashboard available in?",
      answer:
        "The iClean Dashboard automatically translates to your local language."
    },
    {
      question: "Can I view my Dashboard in gallons instead of liters?",
      answer:
        "Yes. On the Dashboard, in the corner of your dispenser list, select More Options to switch between imperial and metric units of measurement."
    },
    {
      question:
        "Will iClean Dashboard auto-ship my client a new cartridge when cartridge life gets low?",
      answer:
        "Our development team is researching this feature for possible future implementation."
    },
    {
      question:
        "When will iClean Dashboard training resources be available for my customers?",
      answer:
        "Tersano is currently developing training resources for end users with the goal to publish by mid-October."
    }
  ],
  "Sustainability Report": [
    {
      question: "Is the Sustainability Report third-party validated?",
      answer:
        "An outside source is currently validating the iClean Dashboard Sustainability Report."
    },
    {
      question: "When will the Sustainability Report become a CO₂ calculator?",
      answer: "Soon! We will update you on the progress as we move forward."
    }
  ],
  "lotus PRO V9": [
    {
      question: "Do all lotus PROs have IoT?",
      answer: "Only the lotus PRO V9 is designed with an IoT board built in."
    },
    {
      question: "Is lotus PRO V9 ready to ship?",
      answer: "Yes, lotus PRO V9 is available to order and ready to ship."
    }
  ],
  IoT: [
    {
      question: "What are the Wi-Fi requirements?",
      answer:
        "Wi-Fi must have a frequency band of 2.4 GHz, and a connection that does not use captive portal."
    },
    {
      question: "Can I connect to IoT if I don't have Wi-Fi?",
      answer:
        "Currently, you can only connect to IoT via Wi-Fi; however, we are working on a SIM card upgrade option."
    },
    {
      question: "For the SIM card upgrade option, who would own the SIM card?",
      answer: "The end user."
    },
    {
      question: "How does Tersano ensure data security?",
      answer:
        "Your data is sent to a token and encrypted. Only the dispenser can communicate with the portal, and users must be authorized by Tersano to gain access."
    },
    {
      question: "If my Wi-Fi disconnects, will IoT continue to save my data?",
      answer:
        "Yes, the data is collected for 31 days after Wi-Fi is disconnected."
    }
  ],
  "IoT Upgrades": [
    {
      question: "What does the IoT Upgrade Kit cost?",
      answer:
        "Please refer to Tersano's September 2024 Price List for IoT Upgrade Kit pricing."
    },
    {
      question: "Are IoT Upgrade Kits available to order?",
      answer:
        "Yes. Please submit your order along with your serial number to orders@tersano.com."
    },
    {
      question:
        "Do I have to send my serial number to Tersano to order my upgrade kit?",
      answer: "Yes, we require your serial number for programming purposes."
    },
    {
      question: "Can I stock IoT Upgrade Kits?",
      answer: "No, they are only made to order."
    },
    {
      question: "Can I retrieve usage data from before I upgraded to IoT?",
      answer:
        "No. Collecting data requires an IoT board which is only available on the lotus PRO V9 front cover."
    }
  ]
};

// Function to render section information
const renderSectionInfo = (label: string, value: string | undefined) => (
  <Grid item xs={12}>
    <Typography variant="body1">{label}</Typography>
    <Typography variant="subtitle2" color="textSecondary">
      {value || "N/A"}
    </Typography>
  </Grid>
);

const FAQSection: React.FC = () => {
  const [expandedSection, setExpandedSection] = useState<number | null>(0);

  const handleToggleSection = (index: number) => {
    setExpandedSection(expandedSection === index ? null : index);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 1000, mx: "auto", mt: 5 }}>
      <Typography variant="h4" align="center" gutterBottom>
        We're here to answer all your questions about Tersano.
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        Learn more about our products, technology, and how we can help you clean
        better with our innovative solutions.
      </Typography>

      {/* Loop through each section of FAQs */}
      {Object.keys(faqData).map((section, sectionIndex) => (
        <Box
          key={sectionIndex}
          mt={2}
          mb={4}
          borderBottom={3}
          borderColor="secondary.main"
          pb={2}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => handleToggleSection(sectionIndex)}
          >
            {/* Use SectionHeader for each section */}
            <SectionHeader title={section} />
            <StyledIconButton variantType="transparent" size="small">
              {expandedSection === sectionIndex ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </StyledIconButton>
          </Box>
          <Collapse
            in={expandedSection === sectionIndex}
            timeout="auto"
            unmountOnExit
          >
            <Box mt={2}>
              <Grid container spacing={2}>
                {faqData[section].map((item, index) =>
                  renderSectionInfo(
                    `Q${index + 1}: ${item.question}`,
                    `A${index + 1}: ${item.answer}`
                  )
                )}
              </Grid>
            </Box>
          </Collapse>
        </Box>
      ))}
    </Box>
  );
};

export default FAQSection;
