import { useState, useMemo } from "react";
import {
  Box,
  Tooltip,
  Chip,
  Typography,
  AlertColor,
  Dialog,
  DialogTitle,
  DialogActions
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { deleteSalesReport } from "../../../../services/distributor.service";
import useAuthStore from "../../../../store/authStore";
import StyledButton from "../../../../shared/Components/Styled/StyledButton";
import StyledIconButton from "../../../../shared/Components/Styled/StyledIconButton";
import EditSalesModal from "../Modal/EditSalesModal";
import {
  SalesData,
  AccountsData
} from "../../../../common/types/DistributorSalesTypes";

const productTypeColors: { [key: string]: string } = {
  NONE: "#e0e0e0",
  "SAO-4": "#d1c4e9",
  "SAO-24": "#c5cae9",
  "lotus PRO": "#bbdefb",
  "iClean mini": "#b2dfdb",
  "iClean PRO": "#b3e5fc",
  "iClean FLO3": "#b2ebf2"
};

const accountTypeColors: { [key: string]: string } = {
  ISS: "#0057A7",
  Sodexo: "#ef5350",
  FoodBuy: "#6d4c41",
  CushmanWakefield: "#ab47bc",
  Frasers: "#827717",
  WeWork: "#2e7d32",
  JLL: "#00695c",
  McDonalds: "#f06292",
  Accor: "#607d8b",
  NonGlobalAccount: "#424242"
};

interface MonthlySalesReportTableProps {
  searchTerm: string;
  distributorFilter: string;
  countryFilter: string;
  setSnackbar: (snackbar: {
    open: boolean;
    message: string;
    severity: AlertColor;
  }) => void;
  sales: SalesData[];
  accounts: AccountsData[];
  onDeleteSuccess: () => void;
  onEditSuccess: () => void;
}

const MonthlySalesReportTable = ({
  searchTerm,
  distributorFilter,
  countryFilter,
  setSnackbar,
  sales,
  accounts,
  onDeleteSuccess,
  onEditSuccess
}: MonthlySalesReportTableProps) => {
  const { userRole } = useAuthStore();
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedSales, setSelectedSales] = useState<SalesData | null>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedDeleteReport, setSelectedDeleteReport] =
    useState<SalesData | null>(null);

  const handleDeleteReport = async () => {
    if (selectedDeleteReport?._id) {
      try {
        await deleteSalesReport(selectedDeleteReport._id);
        setSnackbar({
          open: true,
          message: "Report deleted successfully",
          severity: "success"
        });
        onDeleteSuccess();
      } catch (error) {
        console.error("Failed to delete report:", error);
        setSnackbar({
          open: true,
          message: "Failed to delete report",
          severity: "error"
        });
      } finally {
        setOpenDeleteDialog(false);
        setSelectedDeleteReport(null);
      }
    }
  };

  const handleDeleteClick = (report: SalesData) => {
    setSelectedDeleteReport(report);
    setOpenDeleteDialog(true);
  };

  const handleEditReportSuccess = async () => {
    onEditSuccess();
    setSnackbar({
      open: true,
      message: "Report edited successfully",
      severity: "success"
    });
  };

  const handleEditReportError = (message: string) => {
    console.error("Failed to edit report:", message);
    setSnackbar({
      open: true,
      message,
      severity: "error"
    });
  };

  const columns: GridColDef[] = [
    // Conditionally add distributorEmail column for Admin users
    ...(userRole === "Admin"
      ? [
          {
            field: "distributorEmail",
            headerName: "Distributor Email",
            flex: 1,
            minWidth: 200,
            valueGetter: (_value: any, row: SalesData) => row.distributorEmail
          }
        ]
      : []),
    {
      field: "accountType",
      headerName: "Account Type",
      flex: 1,
      minWidth: 175,
      valueGetter: (_value, row) => row.account.accountType,
      renderCell: (params) => (
        <Chip
          label={params.value}
          size="small"
          style={{
            backgroundColor: accountTypeColors[params.value],
            color: "white"
          }}
        />
      )
    },
    {
      field: "accountName",
      headerName: "Account Name",
      flex: 1,
      minWidth: 125,
      valueGetter: (_value, row) => row.account.accountName
    },
    {
      field: "accountEmail",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      valueGetter: (_value, row) => row.account.accountEmail
    },
    {
      field: "accountPhone",
      headerName: "Phone",
      flex: 1,
      minWidth: 125,
      valueGetter: (_value, row) => row.account.accountPhone
    },
    {
      field: "accountAddress",
      headerName: "Address",
      flex: 1,
      minWidth: 150,
      valueGetter: (_value, row) => row.account.accountAddress
    },
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      minWidth: 125,
      renderCell: (params) => (
        <Chip
          label={params.value}
          size="small"
          sx={{
            fontSize: 14,
            backgroundColor: productTypeColors[params.value] || "#e0e0e0"
          }}
        />
      )
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1.5,
      minWidth: 200,
      valueFormatter: (value) => new Date(value).toLocaleString()
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
      minWidth: 125
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1.5,
      minWidth: 125,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <StyledIconButton
              variantType="transparent"
              onClick={() => {
                setSelectedSales(params.row);
                setOpenEditModal(true);
              }}
            >
              <EditIcon />
            </StyledIconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <StyledIconButton
              variantType="transparent"
              onClick={() => handleDeleteClick(params.row)}
            >
              <DeleteIcon />
            </StyledIconButton>
          </Tooltip>
        </>
      )
    }
  ];

  const filteredSales = useMemo(() => {
    return sales.filter((sale) => {
      const matchesSearchTerm =
        sale.account.accountName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.account.accountType
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.account.accountEmail
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.account.accountPhone
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.account.accountAddress
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.account.siteContact
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.notes.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.distributorEmail
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        (sale.distributorCountry ?? "")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());

      const matchesDistributorFilter =
        !distributorFilter || sale.distributorEmail === distributorFilter;

      const matchesCountryFilter =
        !countryFilter || sale.distributorCountry === countryFilter;

      return (
        matchesSearchTerm && matchesDistributorFilter && matchesCountryFilter
      );
    });
  }, [sales, searchTerm, distributorFilter, countryFilter]);

  return (
    <>
      <Box>
        <DataGrid
          rows={filteredSales}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            }
          }}
          pageSizeOptions={[5, 10, 25]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelection) => {
            setSelected(newSelection.map((id) => String(id)));
          }}
          rowSelectionModel={selected}
        />
      </Box>
      {/* Edit Sales Report Modal */}
      {selectedSales && (
        <EditSalesModal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          onSuccess={handleEditReportSuccess}
          onError={handleEditReportError}
          salesReport={selectedSales}
          accounts={accounts}
        />
      )}
      {/* Delete Sales Report Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <Typography sx={{ px: 3, pt: 1 }}>
          Are you sure you want to delete this report?
        </Typography>
        <DialogActions>
          <StyledButton
            onClick={() => setOpenDeleteDialog(false)}
            variantType="outline-primary"
          >
            Cancel
          </StyledButton>
          <StyledButton onClick={handleDeleteReport} variantType="destructive">
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MonthlySalesReportTable;
