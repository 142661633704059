import React from "react";
import { Box } from "@mui/material";
import { CompoundButton } from "@fluentui/react-components";
import {
  DeviceMeetingRoomRemoteRegular,
  ArrowSwapRegular
} from "@fluentui/react-icons";

interface Props {
  buttonSelected: React.Dispatch<React.SetStateAction<number>>;
  setInstallationType: (type: "new" | "replace") => void;
}

const SelectInstallationType: React.FC<Props> = ({
  buttonSelected,
  setInstallationType
}) => {
  const handleChange = (value: "new" | "replace") => {
    setInstallationType(value);
    buttonSelected(1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        my: 4,
        width: "100%"
      }}
    >
      <CompoundButton
        icon={<DeviceMeetingRoomRemoteRegular />}
        secondaryContent="Register a new unit on Portal"
        size="large"
        onClick={() => handleChange("new")}
        style={{ width: 280 }}
      >
        New Device
      </CompoundButton>
      <CompoundButton
        icon={<ArrowSwapRegular />}
        secondaryContent="Replace a unit at a location"
        size="large"
        onClick={() => handleChange("replace")}
        style={{ width: 280 }}
      >
        Replace Device
      </CompoundButton>
    </Box>
  );
};

export default SelectInstallationType;
