import { Box, Divider, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import dayjs from "dayjs";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface LitersDispensedChartProps {
  cartridgeChanges: Array<{
    timestamp: string;
    deviceId: string;
    deviceName: string;
    litersDispensed: number;
    cartridgeType: string;
  }>;
}

const LitersDispensedChart = ({
  cartridgeChanges
}: LitersDispensedChartProps) => {
  // Sort changes by date
  const sortedData = [...cartridgeChanges].sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

  const chartData = {
    labels: sortedData.map((change) =>
      dayjs(change.timestamp).format("DD/MM/YY")
    ),
    datasets: [
      {
        label: "Liters Dispensed",
        data: sortedData.map((change) => change.litersDispensed),
        backgroundColor: "rgba(30, 58, 138, 0.8)",
        borderColor: "rgba(30, 58, 138, 1)",
        borderWidth: 1,
        borderRadius: 4,
        hoverBackgroundColor: "rgba(30, 58, 138, 1)"
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        intersect: false,
        mode: "index" as const,
        backgroundColor: "rgba(51, 51, 51, 0.95)",
        titleColor: "#ffffff",
        bodyColor: "#e0e0e0",
        titleFont: {
          size: 13,
          weight: "bold" as const
        },
        bodyFont: {
          size: 16
        },
        padding: 12,
        borderColor: "rgba(255, 255, 255, 0.1)",
        borderWidth: 1,
        displayColors: false,
        callbacks: {
          title: (tooltipItems: any) => {
            const dataIndex = tooltipItems[0].dataIndex;
            const data = sortedData[dataIndex];
            return dayjs(data.timestamp).format("DD/MM/YY HH:mm");
          },
          label: (context: any) => {
            const dataIndex = context.dataIndex;
            const data = sortedData[dataIndex];
            return [
              `Device: ${data.deviceName}`,
              `Device ID: ${data.deviceId}`,
              `Type: ${data.cartridgeType}`,
              `Liters: ${data.litersDispensed.toFixed(2)}`
            ];
          }
        }
      },
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Liters",
          color: "#666"
        },
        ticks: {
          color: "#666"
        },
        grid: {
          color: "#ccc"
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0
        }
      }
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: "8px",
        border: "1px solid #d1d1d1",
        backgroundColor: "background.paper",
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: 1,
          color: "#282828",
          fontWeight: "700",
          fontSize: "13px"
        }}
      >
        Liters Dispensed per Cartridge Change
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ width: "100%", height: 300 }}>
        <Bar data={chartData} options={options} />
      </Box>
    </Box>
  );
};

export default LitersDispensedChart;
