import React, { useEffect, useState } from "react";
import { Box, TextField, Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../store/authStore";
import axios from "axios";

interface VerifyProps {
  userId: string;
  email: string;
}

const Verify: React.FC<VerifyProps> = ({ userId, email }) => {
  const [code, setCode] = useState<string[]>(["", "", "", "", "", ""]);
  const [error, setError] = useState<string | null>(null);
  const [cooldown, setCooldown] = useState<number>(0);
  const login = useAuthStore((state) => state.login);
  const setSnackbar = useAuthStore((state) => state.setSnackbar);
  const navigate = useNavigate();

  useEffect(() => {
    // Focus the first input when the page loads
    const firstInput = document.getElementById("digit-0");
    firstInput?.focus();

    // Set initial cooldown
    setCooldown(120);

    // Redirect to login if userId is not available
    if (!userId) {
      setSnackbar({
        open: true,
        message: "Invalid access. Please log in again.",
        severity: "error"
      });
      setTimeout(() => navigate("/login"), 3000);
    }
  }, [userId, navigate, setSnackbar]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (cooldown > 0) {
      timer = setInterval(() => {
        setCooldown((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [cooldown]);

  const handleChange = (value: string, index: number) => {
    if (/^\d*$/.test(value)) {
      const updatedCode = [...code];
      updatedCode[index] = value;
      setCode(updatedCode);

      // Automatically focus the next input
      if (value && index < 5) {
        const nextInput = document.getElementById(`digit-${index + 1}`);
        nextInput?.focus();
      }
    }
  };

  const handleSubmit = async () => {
    const verificationCode = code.join("");
    if (verificationCode.length < 6) {
      setError("Please enter all 6 digits.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/verify-login`,
        {
          userId,
          code: verificationCode
        }
      );
      if (response.data.token) {
        const { token, user, expiresIn } = response.data.token;
        login(user, token, expiresIn);
        setSnackbar({
          open: true,
          message: "Login successful",
          severity: "success"
        });
        navigate("/dashboard");
      } else {
        setSnackbar({
          open: true,
          message: "Invalid code. Try again.",
          severity: "error"
        });
      }
    } catch (error: any) {
      setSnackbar({
        open: true,
        message: error.response?.data?.details || "Verification failed",
        severity: "error"
      });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent, index: number) => {
    if (event.key === "Backspace" && !code[index] && index > 0) {
      const prevInput = document.getElementById(`digit-${index - 1}`);
      prevInput?.focus();
    }
  };

  const handleResendCode = async () => {
    if (cooldown > 0) return;

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/resend-code`, {
        userId,
        email
      });
      setSnackbar({
        open: true,
        message: "Verification code resent successfully",
        severity: "success"
      });
      setCooldown(120); // 2 minutes cooldown
    } catch (error: any) {
      setSnackbar({
        open: true,
        message: error.response?.data?.details || "Failed to resend code",
        severity: "error"
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh"
      }}
    >
      <Box
        sx={{
          padding: "2rem",
          backgroundColor: "white",
          borderRadius: 2,
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          maxWidth: "400px",
          width: "100%"
        }}
      >
        <Typography
          variant="h5"
          textAlign="center"
          fontWeight="600"
          marginBottom="1rem"
        >
          2-Step Verification
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          marginBottom="1.5rem"
        >
          Enter the 6-digit code sent to your email.
        </Typography>
        <Grid container spacing={1} justifyContent="center">
          {code.map((digit, index) => (
            <Grid item key={index} xs={2}>
              <TextField
                id={`digit-${index}`}
                value={digit}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyPress(e, index)}
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: "center",
                    fontSize: "1.5rem",
                    color: "#000"
                  }
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: { xs: "0px", sm: "10px" }
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
        {error && (
          <Typography color="error" textAlign="center" marginTop="1rem">
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: "2rem" }}
          onClick={handleSubmit}
        >
          Verify
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ marginTop: "1rem" }}
          onClick={handleResendCode}
          disabled={cooldown > 0}
        >
          {cooldown > 0 ? `Resend code in ${cooldown} sec` : "Resend code"}
        </Button>
      </Box>
    </Box>
  );
};

export default Verify;
