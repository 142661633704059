import React, { useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import dayjs from "dayjs";
import ICleanTable from "./Table/ICleanTable";
import OverallUsageGraph from "./Charts/OverallUsageGraph";
import DeviceUsageGraph from "./Charts/DeviceUsageGraph";
import AverageUsageCard from "./Cards/AverageUsageCard";
import TotalUsageCard from "./Cards/TotalUsageCard";
import useDeviceStore from "../../../store/useDeviceStore";

const Usage: React.FC = () => {
  const { selectedIcleanDevices, dateFilter, intervalFilter, isGallons } =
    useDeviceStore();
  const theme = useTheme();

  const [selectedBarDate, setSelectedBarDate] = useState<Date | null>(null);
  const handleBarClick = (date: Date) => setSelectedBarDate(date);

  // Use selectedBarDate only if the interval is "daily"
  const selectedDate =
    intervalFilter === "daily" && selectedBarDate
      ? dayjs(selectedBarDate)
      : dayjs(dateFilter);

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: "hidden",
        px: { xs: 0.1, md: 1, sm: 1 },
        backgroundColor: theme.palette.background.default
      }}
    >
      <Grid container spacing={1.25} alignItems="flex-start">
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={1.25}>
            <Grid item xs={12} lg={6}>
              <AverageUsageCard
                selectedDevices={selectedIcleanDevices}
                isGallons={isGallons}
                color="rgba(30, 58, 138, 1)"
                intervalFilter={intervalFilter}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TotalUsageCard
                selectedDevices={selectedIcleanDevices}
                isGallons={isGallons}
                color={theme.palette.success.main}
              />
            </Grid>
            <Grid item xs={12}>
              <OverallUsageGraph
                onBarClick={handleBarClick}
                isGallons={isGallons}
                dateFilter={dateFilter}
              />
            </Grid>
            <Grid item xs={12}>
              <DeviceUsageGraph
                selectedDate={selectedDate}
                isGallons={isGallons}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={1.25}>
            <Grid item xs={12}>
              <ICleanTable
                dateFilter={dateFilter}
                intervalFilter={intervalFilter}
                isGallons={isGallons}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Usage;
