import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, CircleMarker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Paper } from "@mui/material";
import { DeviceData } from "../../../../common/types/DeviceTypes";

interface Marker {
  id: string;
  position: {
    lat: number;
    lng: number;
  };
  name: string;
  status: "ONLINE" | "OFFLINE";
}

const Map: React.FC<{ devices: DeviceData[] }> = ({ devices }) => {
  const [markers, setMarkers] = useState<Marker[]>([]);
  const [markersCenter, setMarkersCenter] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    setMarkers(
      devices.map((device) => ({
        id: device.deviceInformation.deviceId,
        position: {
          lat: device.deviceLocation?.latitude || 0,
          lng: device.deviceLocation?.longitude || 0
        },
        name: device.deviceInformation.deviceName,
        status: device.pingStatus.status === "ONLINE" ? "ONLINE" : "OFFLINE"
      }))
    );

    // Calc center point of all devices
    let validDevices = devices.filter(
      (device) =>
        device.deviceLocation?.latitude && device.deviceLocation?.longitude
    );

    if (validDevices.length > 0) {
      let latTotal = validDevices.reduce(
        (acc, device) => acc + device.deviceLocation!.latitude!,
        0
      );
      let lngTotal = validDevices.reduce(
        (acc, device) => acc + device.deviceLocation!.longitude!,
        0
      );
      let latAvg = latTotal / validDevices.length;
      let lngAvg = lngTotal / validDevices.length;
      setMarkersCenter({ lat: latAvg, lng: lngAvg });
    } else {
      setMarkersCenter({ lat: 0, lng: 0 });
    }
  }, [devices]);

  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    if (mapRef.current && markers.length > 0) {
      const bounds = L.latLngBounds(markers.map((m) => m.position));
      mapRef.current.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [markers]);

  const commonStyles = {
    borderRadius: "4px",
    border: "1px solid #eeeeee",
    backgroundColor: "background.paper",
    color: "text.primary"
  };

  return (
    <Paper elevation={0} sx={{ ...commonStyles, overflow: "hidden" }}>
      <MapContainer
        center={markersCenter}
        zoom={2}
        style={{ height: "400px", width: "100%" }}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
        />

        {markers.map((marker) => (
          <CircleMarker
            key={marker.id}
            center={marker.position}
            color={"rgba(30, 58, 138, 0.5)"}
            radius={3}
            fillOpacity={1}
          >
            <Popup>{`${marker.name} - ${marker.status}`}</Popup>
          </CircleMarker>
        ))}
      </MapContainer>
    </Paper>
  );
};

export default Map;
