import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  MenuItem,
  Divider,
  Typography,
  Grid,
  Autocomplete,
  FormHelperText,
  FormControl
} from "@mui/material";
import {
  ProductType,
  AccountType,
  SalesData,
  AccountsData
} from "../../../../common/types/DistributorSalesTypes";
import { updateSalesReport } from "../../../../services/distributor.service";
import StyledButton from "../../../../shared/Components/Styled/StyledButton";
import { StyledTextFieldNew } from "../../../../shared/Components/Styled/StyledTextFieldNew";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface EditSalesModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (data: SalesData) => void;
  onError: (message: string) => void;
  salesReport: SalesData; // The data of the report being edited
  accounts: AccountsData[]; // Accounts list from the parent
}

const EditSalesModal: React.FC<EditSalesModalProps> = ({
  open,
  onClose,
  onSuccess,
  onError,
  salesReport,
  accounts
}) => {
  const [editableReport, setEditableReport] = useState<SalesData>(salesReport);
  const accountOptions = [
    { accountName: "New Account", label: "New Account" },
    ...accounts
  ];
  const [isAddingAccount, setIsAddingAccount] = useState(false);

  useEffect(() => {
    setEditableReport(salesReport); // Set initial data when modal opens
  }, [salesReport]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === "account.accountType") {
      setEditableReport((prev) => ({
        ...prev,
        account: {
          accountType: value as AccountType,
          accountName: "",
          accountEmail: "",
          accountPhone: "",
          accountAddress: "",
          siteContact: ""
        }
      }));
    } else if (name.startsWith("account.")) {
      //setEditableReport((prev) => ({ ...prev, [name]: value }));
      const field = name.split(".")[1];
      setEditableReport((prev) => ({
        ...prev,
        account: { ...prev.account, [field]: value }
      }));
    } else {
      setEditableReport((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDateChange = (date: any) => {
    setEditableReport((prev) => ({ ...prev, date: date }));
  };

  const handleUpdate = async () => {
    if (editableReport.product === ProductType.NONE) {
      onError("Please select a product.");
      return;
    }

    if (editableReport.account.accountType === AccountType.NONE) {
      onError("Please select an account type.");
      return;
    }

    if (
      !editableReport.account.accountName ||
      !editableReport.account.accountEmail ||
      !editableReport.account.accountPhone ||
      !editableReport.account.accountAddress ||
      !editableReport.account.siteContact
    ) {
      onError("Please fill in all account details.");
      return;
    }
    try {
      const updatedReport = await updateSalesReport(
        editableReport._id!,
        editableReport
      );
      onSuccess(updatedReport); // Notify parent component
      onClose();
      resetForm();
    } catch (error) {
      console.error("Error updating sales report:", error);
      onError("Failed to update sales report");
    }
  };

  const populateFormFields = (account: AccountsData) => {
    setEditableReport((prev) => ({
      ...prev,
      account: {
        accountType: account.accountType,
        accountName: account.accountName,
        accountEmail: account.accountEmail,
        accountPhone: account.accountPhone,
        accountAddress: account.accountAddress,
        siteContact: account.siteContact
      }
    }));
  };

  const resetForm = () => {
    setEditableReport(salesReport); // Reset to the original data
    setIsAddingAccount(false); // Reset account addition state
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Sales Report</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="off">
          <Typography variant="h6" gutterBottom color="primary">
            Product Information
          </Typography>

          <Grid container spacing={1} mb={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Product Type"
                name="product"
                value={editableReport.product}
                onChange={handleChange}
                margin="normal"
              >
                {Object.values(ProductType).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {editableReport.product === ProductType.LOTUS_PRO && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Serial Number"
                  name="serialNumber"
                  value={editableReport.serialNumber}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ width: "100%" }} margin="none">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  views={["year", "month", "day"]}
                  value={
                    editableReport.date ? dayjs(editableReport.date) : null
                  }
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" gutterBottom color="primary">
            Account Information
          </Typography>

          <Grid container spacing={1} mb={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Account Type"
                name="account.accountType"
                value={editableReport.account.accountType}
                onChange={handleChange}
                margin="normal"
              >
                {Object.values(AccountType).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={accountOptions.filter(
                  (option) =>
                    option.accountName === "New Account" || // Always include "New Account"
                    ("accountType" in option &&
                      option.accountType === editableReport.account.accountType)
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    key={"label" in option ? option.label : option.accountName}
                    style={{
                      fontWeight: "label" in option ? 700 : 400,
                      color:
                        "label" in option ? "rgba(30, 58, 138, 1)" : "inherit"
                    }}
                  >
                    {"label" in option
                      ? option.label
                      : `${option.accountName} - ${option.accountEmail}`}
                  </li>
                )}
                getOptionLabel={(option) =>
                  "label" in option ? option.label : option.accountName
                }
                value={
                  isAddingAccount
                    ? { accountName: "", label: "New Account" }
                    : accounts.find(
                        (c) =>
                          c.accountName === editableReport.account.accountName
                      ) || null
                }
                onChange={(_, newValue) => {
                  if (
                    newValue &&
                    "label" in newValue &&
                    newValue.label === "New Account"
                  ) {
                    setIsAddingAccount(true);
                    setEditableReport((prev) => ({
                      ...prev,
                      account: {
                        accountType: editableReport.account.accountType,
                        accountName: "",
                        accountEmail: "",
                        accountPhone: "",
                        accountAddress: "",
                        siteContact: ""
                      }
                    }));
                  } else if (newValue && !("label" in newValue)) {
                    setIsAddingAccount(false);
                    populateFormFields(newValue);
                  } else {
                    setIsAddingAccount(false);
                    setEditableReport((prev) => ({
                      ...prev,
                      account: {
                        accountType: AccountType.NONE,
                        accountName: "",
                        accountEmail: "",
                        accountPhone: "",
                        accountAddress: "",
                        siteContact: ""
                      }
                    }));
                  }
                }}
                renderInput={(params) => (
                  <StyledTextFieldNew
                    {...params}
                    label="Account Name"
                    required
                    fullWidth
                    margin="normal"
                  />
                )}
              />
              <FormHelperText>
                Select an existing account or create a new account
              </FormHelperText>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Account Name"
                name="account.accountName"
                value={editableReport.account.accountName}
                onChange={handleChange}
                margin="normal"
                disabled={!isAddingAccount}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Account Email"
                name="account.accountEmail"
                value={editableReport.account.accountEmail}
                onChange={handleChange}
                margin="normal"
                disabled={!isAddingAccount}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Account Phone"
                name="account.accountPhone"
                value={editableReport.account.accountPhone}
                onChange={handleChange}
                margin="normal"
                disabled={!isAddingAccount}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                label="Account Address"
                name="account.accountAddress"
                value={editableReport.account.accountAddress}
                onChange={handleChange}
                margin="normal"
                disabled={!isAddingAccount}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Site Contact"
                name="account.siteContact"
                value={editableReport.account.siteContact}
                onChange={handleChange}
                margin="normal"
                disabled={!isAddingAccount}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" gutterBottom color="primary">
            Additional Information
          </Typography>
          <Grid container spacing={1} mb={1}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Notes"
                name="notes"
                value={editableReport.notes}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <StyledButton
          onClick={() => {
            onClose();
            resetForm();
          }}
          variantType="outline-red"
        >
          Cancel
        </StyledButton>
        <StyledButton onClick={handleUpdate} variantType="primary">
          Update
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditSalesModal;
