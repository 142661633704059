import React, { useState, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  useMediaQuery,
  useTheme,
  styled,
  StepIconProps,
  Typography,
  Alert,
  Snackbar,
  AlertColor
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import SelectInstallationType from "./SelectInstallationType";
import RegisterDeviceModal from "../Shared/Modals/RegisterDeviceModal";
import ReplaceDevice from "../Shared/ReplaceDevice";
import WiFiSetup from "./WifiSetup";
import Finish from "./Finish";
import { DeviceData } from "../../common/types/DeviceTypes";
import {
  fetchDeviceDetails,
  sendDeviceInstallationEmail
} from "../../services/device.service";
import { SnackbarProvider } from "notistack";
import StyledButton from "../../shared/Components/Styled/StyledButton";

const QontoConnector = styled(Stepper)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

const QontoStepIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.completed && {
    color: "#006400" // Darker green
  }),
  ...(ownerState.active && {
    color: theme.palette.primary.main
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#006400", // Darker green
    zIndex: 1,
    fontSize: 24 // Increase the size
  },
  "& .QontoStepIcon-circle": {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "currentColor"
  }
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const steps = ["Choose Type", "Setup Device", "Connect Device", "Finish"];

interface InstallationWizardProps {
  devices: DeviceData[];
}

const InstallationWizard: React.FC<InstallationWizardProps> = ({ devices }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [installationType, setInstallationType] = useState<
    "new" | "replace" | null
  >(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [createdDevice, setCreatedDevice] = useState<any>(null);
  const [pingStatus, setPingStatus] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
    if (activeStep > 1) {
      setInstallationType(null);
    }
    if (activeStep === steps.length - 1) {
      handleFinish();
    }
  };

  const handleFinish = async () => {
    setActiveStep(0);
    if (createdDevice && pingStatus === "ONLINE") {
      const email = createdDevice.deviceInformation.installerEmail;
      const deviceName = createdDevice.deviceInformation.deviceId;
      try {
        await sendDeviceInstallationEmail(email, deviceName);
      } catch (error) {
        console.error("Error sending installation email", error);
      }
    }
    setCreatedDevice(null);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    if (activeStep === 1) {
      setInstallationType(null);
    }
  };

  const handleCancel = () => {
    setActiveStep(0);
    setInstallationType(null);
    setCreatedDevice(null);
  };

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  const handleDeviceSetupSuccess = (device: any) => {
    setCreatedDevice(device);
    if (installationType === "new") {
      setActiveStep(3);
    }
    setSnackbar({
      open: true,
      message: "Device successfully replaced.",
      severity: "success"
    });
  };

  const handleDeviceReplaceError = (message: string) => {
    setSnackbar({
      open: true,
      message,
      severity: "error"
    });
  };

  useEffect(() => {
    if (activeStep === 3 && createdDevice) {
      const checkPingStatus = async () => {
        try {
          const updatedDevice = await fetchDeviceDetails(
            createdDevice.deviceInformation.deviceId
          );
          setPingStatus(updatedDevice.pingStatus.status);
        } catch (error) {
          console.error("Error fetching device details", error);
          setPingStatus("Error fetching status");
        }
      };
      checkPingStatus();

      // Set interval to call checkPingStatus every second
      const intervalId = setInterval(checkPingStatus, 1000);

      // Cleanup interval on component unmount or when dependencies change
      return () => clearInterval(intervalId);
    }
  }, [activeStep, createdDevice]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <SelectInstallationType
            buttonSelected={setActiveStep}
            setInstallationType={setInstallationType}
          />
        );
      case 1:
        if (installationType === null || installationType === undefined) {
          return (
            <Typography
              sx={{
                textAlign: "center"
              }}
              variant="h6"
              color="error"
            >
              Please select an installation type
            </Typography>
          );
        }
        return installationType === "new" ? (
          <RegisterDeviceModal
            open={true}
            onClose={handleBack}
            onSuccess={handleDeviceSetupSuccess}
            onError={handleBack}
          />
        ) : (
          <ReplaceDevice
            devices={devices}
            onSuccess={handleDeviceSetupSuccess}
            onError={handleDeviceReplaceError}
          />
        );
      case 2:
        return (
          <Box>
            <WiFiSetup />
          </Box>
        );
      case 3:
        return (
          <Box>
            <Finish createdDevice={createdDevice} pingStatus={pingStatus} />
          </Box>
        );
      // default:
      //   return "Unknown step";
    }
  };

  return (
    <SnackbarProvider
      maxSnack={3} // Wrapping the app with SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      autoHideDuration={6000}
      preventDuplicate
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: isMobile ? 3 : 7,
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        {!isMobile && (
          <Box>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                color: "grey.900"
              }}
            >
              Welcome to Tersano's Lotus Pro Installation Wizard
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                color: "grey.800",
                fontWeight: 500
              }}
            >
              Following steps will guide you through the installation process,
              click next to continue.
            </Typography>
          </Box>
        )}

        <Paper
          sx={{
            mt: isMobile ? 0 : 5,
            width: isMobile ? "87%" : "70%",
            height: "auto",
            p: 1.5,
            borderRadius: 1
          }}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label} onClick={() => handleStepClick(index)}>
                <StepLabel
                  StepIconComponent={QontoStepIcon}
                  sx={{
                    cursor: "pointer",
                    ...(activeStep === index && {
                      backgroundColor: theme.palette.background.default,
                      color: "white",
                      borderRadius: 1,
                      p: 1
                    })
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      ...(activeStep === index && {
                        fontWeight: 600
                      })
                    }}
                  >
                    {index + 1}. {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 5 }}>
            {getStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
                gap: 1
              }}
            >
              <StyledButton disabled={activeStep === 0} onClick={handleBack}>
                Back
              </StyledButton>
              {activeStep !== steps.length - 1 && (
                <StyledButton
                  variantType="primary"
                  onClick={handleNext}
                  disabled={
                    (activeStep === 0 && installationType === null) ||
                    (activeStep === 1 && createdDevice === null)
                  }
                >
                  Next
                </StyledButton>
              )}
              {activeStep === steps.length - 1 && (
                <StyledButton
                  variantType="primary"
                  onClick={handleNext}
                  disabled={createdDevice === null || pingStatus !== "ONLINE"}
                >
                  Finish
                </StyledButton>
              )}
              <StyledButton variantType="outline-red" onClick={handleCancel}>
                Cancel
              </StyledButton>
            </Box>
          </Box>
        </Paper>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </SnackbarProvider>
  );
};

export default InstallationWizard;
