import axios from "axios";
import { Firmware } from "../common/types/FirmwareTypes";
import useAuthStore from "../store/authStore";

const API_URL = process.env.REACT_APP_API_URL;
const { userRole } = useAuthStore.getState();

export const getAllFirmwares = async (): Promise<Firmware[]> => {
  try {
    const response = await axios.get(`${API_URL}/firmware/`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch devices:", error);
    throw new Error("Failed to fetch devices");
  }
};

export const updateFirmware = async (fileName: string, deviceId: string) => {
  try {
    if (userRole !== "SuperAdmin" && userRole !== "Admin")
      throw new Error(
        "[Unauthorized] You do not have permissions to update firmware"
      );
    const response = await axios.post(`${API_URL}/firmware/ota`, {
      fileName,
      deviceId
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update firmware:", error);
    throw new Error("Failed to update firmware");
  }
};

export const updateFirmwareV2 = async (version: string, deviceId: string) => {
  try {
    if (userRole !== "SuperAdmin" && userRole !== "Admin")
      throw new Error(
        "[Unauthorized] You do not have permissions to update firmware"
      );
    const response = await axios.post(`${API_URL}/firmware/ota-v2`, {
      version,
      deviceId
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update firmware:", error);
    throw new Error("Failed to update firmware");
  }
};
