import React from "react";
import { Box } from "@mui/material";
import MapView from "./MapView";
import { DeviceData } from "../../../common/types/DeviceTypes";

interface DeviceLocationProps {
  device: DeviceData;
}

const DeviceLocation: React.FC<DeviceLocationProps> = ({ device }) => {
  const center = device.deviceLocation
    ? {
        lat: device.deviceLocation.latitude,
        lng: device.deviceLocation.longitude
      }
    : { lat: 39.8283, lng: -82.955803 };

  const zoom = 12;

  const markers = device.deviceLocation
    ? [
        {
          id: device.deviceInformation.deviceId,
          position: {
            lat: device.deviceLocation.latitude,
            lng: device.deviceLocation.longitude
          },
          name: device.deviceInformation.deviceName
        }
      ]
    : [];

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <MapView center={center} zoom={zoom} height="720px" markers={markers} />
      </Box>
    </>
  );
};

export default DeviceLocation;
