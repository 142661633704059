import { Alert, Tooltip, useTheme } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";

type DeviceOfflineAlertProps = {
  timestamp: string;
};

const DeviceOfflineAlert: React.FC<DeviceOfflineAlertProps> = ({
  timestamp
}) => {
  const theme = useTheme();

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "#FDEDED",
            border: "1px solid #e0e0e0"
          }
        }
      }}
      title={
        <Alert severity="error">
          Warning: DEVICE INACTIVE. Device has been inactive for more than 30
          days. Device last pinged at{" "}
          {new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true
          }).format(new Date(timestamp))}
          . Please check the device
        </Alert>
      }
    >
      <ErrorOutline
        sx={{
          color: theme.palette.error.main
        }}
      />
    </Tooltip>
  );
};

export default DeviceOfflineAlert;
