import { Box, Divider, Typography } from "@mui/material";
import useAuthStore from "../../../../store/authStore";
import { Bar } from "react-chartjs-2";
import { Distributor } from "../../../../store/useDistributor";
import { DeviceData } from "../../../../common/types/DeviceTypes";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const DistributorsByCountryChart: React.FC<{
  Distributors: Distributor[];
  devices: DeviceData[];
}> = ({ Distributors, devices }) => {
  const { userRole } = useAuthStore();
  const countByCountry = (data: any[], countryField: string) => {
    return data.reduce((acc: { [key: string]: number }, item) => {
      const country = item[countryField];
      if (!acc[country]) {
        acc[country] = 0;
      }
      acc[country]++;
      return acc;
    }, {});
  };

  const distributorsByCountry = countByCountry(Distributors, "country");

  // Passed index to make it so we can color the charts dynamically
  const prepareBarChartData = (
    data: { [key: string]: number },
    index: number
  ) => {
    return {
      labels: Object.keys(data),
      datasets: [
        {
          label: "Count",
          backgroundColor:
            index === 0 ? "rgba(30, 58, 138, 0.5)" : "rgba(30, 58, 138, 0.8)",
          data: Object.values(data)
        }
      ]
    };
  };

  const distributorsByCountryData = prepareBarChartData(
    distributorsByCountry,
    1
  );

  const prepareDeviceLocationData = (devices: any[]) => {
    const devicesByCity = devices.reduce(
      (acc: { [key: string]: number }, device) => {
        const city = device.deviceLocation?.city || "Unknown";
        acc[city] = (acc[city] || 0) + 1;
        return acc;
      },
      {}
    );

    return {
      labels: Object.keys(devicesByCity),
      datasets: [
        {
          label: "Count",
          backgroundColor: "rgba(30, 58, 138, 0.5)",
          data: Object.values(devicesByCity)
        }
      ]
    };
  };

  const commonStyles = {
    borderRadius: "8px",
    border: "1px solid #d1d1d1",
    backgroundColor: "background.paper",
    color: "text.primary",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
  };

  return (
    <Box
      sx={{
        ...commonStyles,
        p: 2,
        height: "250px",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          mb: 1,
          fontWeight: "700"
        }}
      >
        {userRole !== "Admin" && userRole !== "SuperAdmin"
          ? "Devices by City"
          : "Distributors by Country"}{" "}
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Bar
          data={
            userRole !== "Admin" && userRole !== "SuperAdmin"
              ? prepareDeviceLocationData(devices)
              : distributorsByCountryData
          }
          options={{
            indexAxis: "y",
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false
                },
                ticks: {
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
                }
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                mode: "nearest",
                intersect: true,
                axis: "y"
              }
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default DistributorsByCountryChart;
