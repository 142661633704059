import axios from "axios";
import { DeviceRework } from "../common/types/DeviceReworkTypes";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAllDeviceReworks = async (): Promise<DeviceRework[]> => {
  const response = await axios.get(`${API_URL}/device-Rework/all`);
  return response.data;
};

export const fetchDeviceReworksByTester = async (
  tester: string
): Promise<DeviceRework[]> => {
  const response = await axios.post(`${API_URL}/device-Rework/tester`, {
    tester: tester
  });
  return response.data;
};

export const createDeviceRework = async (payload: any) => {
  try {
    const response = await axios.post(`${API_URL}/device-Rework`, payload);
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

export const updateRework = async (
  reworkId: string,
  updates: Partial<DeviceRework>
) => {
  try {
    const response = await axios.put(
      `${API_URL}/device-Rework/update-Rework/${reworkId}`,
      updates
    );
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};
