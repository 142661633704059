import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from "@mui/material";
import { addCustomer } from "../../../services/customer.service";
import useAuthStore from "../../../store/authStore";
import StyledButton from "../../../shared/Components/Styled/StyledButton";

const CreateNewCustomerModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onSnackbarChange: (snackbarData: {
    open: boolean;
    message: string;
    severity: "success" | "error";
  }) => void;
}> = ({ open, onClose, onSnackbarChange }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [customerRole, setCustomerRole] = useState("Customer");
  const [error, setError] = useState<string | null>(null);

  const { user, userRole } = useAuthStore();

  const handleSubmit = async () => {
    if (!user) return;
    if (!firstName || !lastName || !email) {
      setError("Please fill in all required fields.");
      return;
    }

    //if useRole is GroupCustomer then lets call the additional customer API instead

    try {
      const customer = {
        email,
        firstName,
        lastName,
        role: customerRole,
        distributorEmail:
          userRole === "GroupCustomer"
            ? user?.distributorEmail || ""
            : user?.email || "",
        customerGroup: userRole === "GroupCustomer" ? user.email : "Unknown"
      };

      await addCustomer(customer);
      setFirstName("");
      setLastName("");
      setEmail("");
      setCustomerRole("Customer");
      onSnackbarChange({
        open: true,
        message: "Customer added successfully",
        severity: "success"
      });
      onClose();
    } catch (err) {
      onSnackbarChange({
        open: true,
        message: "Failed to add customer. Please try again.",
        severity: "error"
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Customer</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary">
          Default Password: <strong>Password@321</strong>
        </Typography>
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <TextField
          margin="dense"
          label="First Name"
          type="text"
          fullWidth
          variant="outlined"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Last Name"
          type="text"
          fullWidth
          variant="outlined"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormControl fullWidth variant="outlined" margin="dense">
          <InputLabel>Customer Role</InputLabel>
          <Select
            value={customerRole}
            onChange={(e) => setCustomerRole(e.target.value as string)}
            label="Customer Role"
          >
            <MenuItem value="Customer">Regular Customer</MenuItem>
            <MenuItem value="GroupCustomer">Group Customer</MenuItem>

            {user && userRole && ["Admin", "SuperAdmin"].includes(userRole) && (
              <MenuItem value="NationalAccount">National Account</MenuItem>
            )}

            {user && userRole && ["Admin", "SuperAdmin"].includes(userRole) && (
              <MenuItem value="GlobalAccount">Global Account</MenuItem>
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onClose}>Cancel</StyledButton>
        <StyledButton onClick={handleSubmit} variantType="primary">
          Add Customer
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewCustomerModal;
