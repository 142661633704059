import React from "react";
import { Box } from "@mui/material";
import NavItem from "./NavItem/NavItem";
import useAuthStore from "../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { Board20Regular, SignOut20Regular } from "@fluentui/react-icons";

interface ICleanLinesSidebarProps {
  activeTab: string;
  onTabClick: (tabName: string) => void;
  drawerOpen: boolean;
}

const ICleanLinesSidebar: React.FC<ICleanLinesSidebarProps> = ({
  activeTab,
  onTabClick,
  drawerOpen
}) => {
  const { user, userRole, logout } = useAuthStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Box sx={{ width: 250, pt: 1, pl: 2 }}>
      {user &&
        userRole &&
        (userRole === "SuperAdmin" || userRole === "Admin") && (
          <>
            <NavItem
              icon={<Board20Regular />}
              label="Dashboard"
              active={activeTab === "iCleanLines"}
              onClick={() => onTabClick("iCleanLines")}
              showLabel={drawerOpen}
            />
            {/* Add other iCleanLines specific navigation items */}
            <NavItem
              icon={<SignOut20Regular />}
              label="Log Out"
              onClick={handleLogout}
              showLabel={drawerOpen}
            />
          </>
        )}

      {/* Add other role-specific navigation items for iCleanLines */}
      {user && userRole && userRole === "Distributor" && (
        <>{/* Distributor specific items for iCleanLines */}</>
      )}

      {/* Continue with other roles... */}
    </Box>
  );
};

export default ICleanLinesSidebar;
