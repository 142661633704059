import React from "react";
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DeviceErrors as DeviceErrorsType } from "../../../../common/types/DeviceTypes";
import ErrorCard from "./ErrorCard";

interface DeviceErrorsProps {
  deviceErrors: DeviceErrorsType[];
}

const DeviceErrors: React.FC<DeviceErrorsProps> = ({ deviceErrors }) => {
  const today = new Date().toDateString();

  const todayErrors = deviceErrors.filter(
    (error) => new Date(error.date).toDateString() === today
  );

  // Sort other errors by date in descending order and take the first 50
  const otherErrors = deviceErrors
    .filter((error) => new Date(error.date).toDateString() !== today)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .slice(0, 10); // Only keep the most recent 50

  return (
    <Box sx={{ mb: 2 }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={{ bgcolor: "rgba(0, 0, 0, 0.12)", py: 0 }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h6">Today ({todayErrors.length})</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "rgba(0, 0, 0, 0.12)" }}>
          <Grid container spacing={2}>
            {todayErrors.map((error) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={error._id}>
                <ErrorCard error={error} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DeviceErrors;
