import React from "react";
import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@mui/material";
import GCHomePage from "../../../assets/images/gc-home-page.png";
import Dashboard from "../../../assets/images/c-dashboard.png";
import Summary from "../../../assets/images/c-summary.png";
import CDevices from "../../../assets/images/c-devices.png";
import CDeviceDetails from "../../../assets/images/c-device-details.png";
import CAddAdditionalCustomer from "../../../assets/images/c-add-additional-customer.png";
import GCManageUsers from "../../../assets/images/gc-manage-users.png";

const CompanyTab = () => {
  return (
    <Container
      sx={{
        padding: "16px 0px",
        "& > *": { padding: "4px 0" }
      }}
    >
      {/* Main Title */}
      <Typography variant="h4" fontWeight="bold">
        Company Portal
      </Typography>

      {/* Introduction */}
      <Typography variant="body1" gutterBottom>
        The Company Portal gives you full control over your devices. You can
        view and manage your devices, as well as access detailed data and
        statistics related to them. Here's a preview of what the homepage will
        look like once you log in.
      </Typography>

      {/* Home Page Section */}
      <Typography variant="h6" fontWeight="bold">
        Home Page:
      </Typography>
      <Box
        component="img"
        src={GCHomePage}
        alt="Company Home Page"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        The homepage is designed for simplicity, allowing you to navigate
        quickly and access everything you need with ease.
      </Typography>

      {/* Home Page List */}
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                1. <strong>Hamburger Menu</strong> – Click this icon to expand
                the menu, where you can navigate to different pages.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                2. <strong>Bell Icon</strong> – This icon alerts you to
                important notifications about your devices or account.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                3. <strong>View Devices</strong> – Click this button to access
                and manage your devices.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                4. <strong>Access Support Desk</strong> – Use this option to get
                help quickly.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                5. <strong>Visit Tersano Website</strong> – Click here to visit
                our website and learn more about our products and company.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                6. <strong>Set Up Profile</strong> – Click here to change your
                profile settings, password, or notification preferences.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="body1" gutterBottom>
        Now, let’s open the hamburger menu (1) and navigate to the Dashboard.
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Dashboard Overview Section */}
      <Typography variant="h6" fontWeight="bold">
        Dashboard Overview:
      </Typography>
      <Box
        component="img"
        src={Dashboard}
        alt="Dashboard Overview"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        The dashboard provides a comprehensive visualization of device usage,
        including location, ping status, and cartridge health. You'll be able to
        track device activity using bar and chart graphs, as well as monitor key
        metrics like total and average usage.
      </Typography>

      {/* Dashboard List */}
      <List>
        <ListItem>
          <ListItemText primary="1. You can see a few tabs here. We'll explore them shortly, but for now, we're on the Lotus Pro tab." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. View devices, their locations, liters used, cartridge life, and device status. Filters can be applied." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Usage charts show total liters used, average usage, peak activity times, and daily usage. Switch between daily, weekly, and monthly views." />
        </ListItem>
        <ListItem>
          <ListItemText primary="4. Generate daily, weekly, or monthly reports. Export reports in liters or gallons. Sustainability reports are also available." />
        </ListItem>
        <ListItem>
          <ListItemText primary="5. Toggle between imperial (gallons) and metric (liters) systems to view data based on your preference." />
        </ListItem>
      </List>

      <Typography variant="body1" gutterBottom>
        Now, you can click on the Summary tab in the menu bar (1).
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Summary Section */}
      <Typography variant="h6" fontWeight="bold">
        Summary:
      </Typography>
      <Box
        component="img"
        src={Summary}
        alt="Dashboard Summary"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        This page provides a quick summary of your account and devices,
        including the total number of devices, those that are online or offline,
        any errors reported today, and the latest error. It also displays all
        devices based on their geographic location.
      </Typography>

      <Typography variant="body1" gutterBottom>
        You can easily check whether devices are online or offline at a glance,
        ensuring you're always aware of your network's status. The map tab
        offers a larger, fullscreen version of the map for better visibility.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Now, you can open the hamburger menu and click on Manage Devices.
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Device Management Section */}
      <Typography variant="h6" fontWeight="bold">
        Device Management:
      </Typography>
      <Box
        component="img"
        src={CDevices}
        alt="Manage Devices"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        The device management feature is a powerful tool that allows you to
        manage your devices and their settings, logs, errors, replacement
        history, location, and more. Here, you can view all your devices and
        click on any device you need to manage.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Let’s click on one device for demonstration purposes.
      </Typography>

      <Box
        component="img"
        src={CDeviceDetails}
        alt="Device Details"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        Here, you can see all the details about the device, including an
        overview, logs, manual, and device information. You can also add
        additional customers by clicking on the Customers tab.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Let’s go ahead and click on the Customers tab.
      </Typography>

      <Box
        component="img"
        src={CAddAdditionalCustomer}
        alt="Add Additional Customer"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        You can click the Add Additional User button to add more users from
        there.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Lastly, let's open the hamburger menu and click on Manage Users.
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* User Management Section */}
      <Typography variant="h6" fontWeight="bold">
        User Management:
      </Typography>
      <Box
        component="img"
        src={GCManageUsers}
        alt="Manage Users"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        Here you can see all of your customers, and you can search for specific
        customers using the search bar or sort the list by applying filters. If
        you want to add a new user, simply click on the Add User button located
        at the top right corner, then follow the steps provided to complete the
        process of adding a user.
      </Typography>
    </Container>
  );
};

export default CompanyTab;
