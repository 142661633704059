import { Box, Typography, Tooltip, useTheme } from "@mui/material";
import { keyframes } from "@mui/system";

interface CartridgeIndicatorProps {
  cyclesRemaining: number;
  currentCartridgeType?:
    | "SAO-24 FB"
    | "SAO-24 NON-FB"
    | "SAO-04 FB"
    | "SAO-4"
    | "SAO-24"
    | "AO/Desiccant"
    | "NONE"
    | "SAO-04 NON-FB";
  isGallons: boolean;
}

const CartridgeIndicator: React.FC<CartridgeIndicatorProps> = ({
  cyclesRemaining,
  currentCartridgeType,
  isGallons
}) => {
  const theme = useTheme();

  const blink = keyframes`
  from {
    opacity: 1;
    transform: scale(1); 
  }
  to {
    opacity: 0.7;
    transform: scale(1.1); 
  }
`;

  const convertToGallons = (liters: number) => {
    return liters * 0.264172;
  };

  const totalCycles = (() => {
    switch (currentCartridgeType) {
      case "SAO-24 FB":
      case "SAO-24":
      case "SAO-24 NON-FB":
        return 550;
      case "SAO-04 FB":
      case "SAO-4":
      case "AO/Desiccant":
      case "SAO-04 NON-FB":
        return 920;
      default:
        return 920;
    }
  })();

  const percentage = (cyclesRemaining / totalCycles) * 100;

  let status = "NO DATA";
  let backgroundColor = theme.palette.error.main;
  let animation: string | undefined = undefined;

  if (percentage > 70) {
    status = "HIGH";
    backgroundColor = theme.palette.success.main;
  } else if (percentage <= 70 && percentage > 30) {
    status = "OKAY";
    backgroundColor = theme.palette.warning.light;
  } else if (percentage <= 30 && percentage > 0) {
    status = "LOW";
    backgroundColor = theme.palette.error.main;
    animation = `${blink} 1s linear infinite`;
  } else if (percentage === 0) {
    status = "EMPTY";
    backgroundColor = theme.palette.error.main;
  }

  const getTooltipLabel = () => (
    <Typography
      sx={{
        fontSize: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        p: 1,
        gap: 0.1
      }}
    >
      <strong>Status:</strong> {status}
      <br />
      <strong>Cycles left:</strong> {cyclesRemaining}
      <br />
      <strong>{isGallons ? "Gallons" : "Liters"} left:</strong>{" "}
      {isGallons
        ? convertToGallons(cyclesRemaining * 5.5).toFixed(2)
        : (cyclesRemaining * 5.5).toFixed(2)}
      <br />
      <strong>Cartridge:</strong> {currentCartridgeType}
    </Typography>
  );

  return (
    <Tooltip title={getTooltipLabel()}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1
        }}
      >
        <Box
          sx={{
            width: 50,
            height: 10,
            backgroundColor:
              percentage === 0
                ? theme.palette.error.main
                : theme.palette.grey[300],
            borderRadius: 2
          }}
        >
          <Box
            sx={{
              width: `${percentage}%`,
              height: "100%",
              backgroundColor,
              borderRadius: 2,
              animation,
              transition: "width 0.5s ease-in-out"
            }}
          />
        </Box>
        <Typography
          variant="caption"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "0.8rem",
            fontWeight: 600
          }}
        >
          {`${percentage.toFixed(0)}%`}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default CartridgeIndicator;
