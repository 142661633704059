import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Typography,
  Snackbar,
  Alert,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  AlertColor,
  Tooltip
} from "@mui/material";
import { ICleanDevice, DeviceErrors } from "../../common/types/DeviceTypes";
import { fetchDeviceErrors } from "../../services/device.service";
import RegisterDeviceModal from "../Shared/Modals/RegisterDeviceModal";
import useAuthStore from "../../store/authStore";
import ViewToggle from "../../shared/Components/ViewToggle";
import { ManageAccessTab } from "./ManageAccess";
import UpdateFirmwareTab from "./UpdateFirmware";
import ReplaceDevice from "../Shared/ReplaceDevice";
import DeviceErrorsTable from "./DeviceErrors/Table/DeviceErrorsTable";
import DeviceErrorsCards from "./DeviceErrors/Cards/DeviceErrors";
import BulkAssignModal from "./Devices/Modals/BulkAssign/BulkAssignModal";
import DeviceCards from "./Devices/Cards/DeviceCards";
import DeviceTable from "./Devices/Table/DevicesTable";
import { fetchCustomersForUser } from "../../services/customer.service";
import { Customer } from "../../store/useCustomer";
import { StyledTabs } from "../../shared/Components/Styled/StyledIconTab";
import StyledTab from "../../shared/Components/Styled/StyledTab";
import StyledButton from "../../shared/Components/Styled/StyledButton";
import { Add } from "@mui/icons-material";

interface ManageDevicesProps {
  onDeviceSelect?: (device: ICleanDevice) => void;
  devices: ICleanDevice[];
}

export const ManageDevices: React.FC<ManageDevicesProps> = ({
  onDeviceSelect,
  devices
}) => {
  const [deviceErrors, setDeviceErrors] = useState<DeviceErrors[]>([]);
  const { user, userRole } = useAuthStore.getState();

  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);
  const [isBulkAssignModal, setIsBulkAssignModal] = useState<boolean>(false);
  const [customerEmailFilter, setCustomerEmailFilter] = useState<string | null>(
    null
  );
  const [locationFilter, setLocationFilter] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [customers, setCustomers] = useState<Customer[]>([]);

  // Bulk Assign Modal
  const handleOpenBulkModal = () => setIsBulkAssignModal(true);

  // Extract unique customer emails from devices
  const availableCustomerEmails = Array.from(
    new Set(
      devices
        .map((device) => device.deviceInformation.customerEmail)
        .filter(Boolean)
    )
  );

  // Extract unique locations from devices
  const availableLocations = Array.from(
    new Set(
      devices.map((device) => device.deviceLocation?.country).filter(Boolean)
    )
  );

  // Extract unique statuses from devices
  const availableStatuses = Array.from(
    new Set(devices.map((device) => device.pingStatus.status).filter(Boolean))
  );

  const handleCustomerEmailFilterChange = (
    event: SelectChangeEvent<string>
  ) => {
    setCustomerEmailFilter(
      event.target.value === "All" ? null : event.target.value
    );
  };

  const handleLocationFilterChange = (event: SelectChangeEvent<string>) => {
    setLocationFilter(event.target.value === "All" ? null : event.target.value);
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<string>) => {
    setStatusFilter(event.target.value === "All" ? null : event.target.value);
  };

  const filteredDevices = useMemo(() => {
    return devices.filter((device) => {
      return (
        (!customerEmailFilter ||
          device.deviceInformation.customerEmail === customerEmailFilter) &&
        (!locationFilter ||
          device.deviceLocation?.country === locationFilter) &&
        (!statusFilter || device.pingStatus.status === statusFilter)
      );
    });
  }, [devices, customerEmailFilter, locationFilter, statusFilter]);

  const handleIsFiltersVisibleChange = () => {
    setIsFiltersVisible((prev) => !prev);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const [selectedTab, setSelectedTab] = useState(
    parseInt(localStorage.getItem("selectedDeviceMonitoringTab") || "0")
  );

  const handleSuccess = () => {
    setSnackbar({
      open: true,
      message: "Device successfully created.",
      severity: "success"
    });
  };
  const handleError = (message: string) => {
    setSnackbar({ open: true, message, severity: "error" });
  };

  useEffect(() => {
    if (!user) {
      console.error("User not logged in");
      return;
    }

    const fetchAndSetDeviceErrors = async () => {
      try {
        const errorsData = await fetchDeviceErrors();
        setDeviceErrors(errorsData);
      } catch (error) {
        console.error("Error fetching device errors:", error);
      }
    };

    const loadCustomers = async () => {
      try {
        const fetchedCustomers = await fetchCustomersForUser();
        setCustomers(fetchedCustomers);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    fetchAndSetDeviceErrors();
    loadCustomers();
  }, [user]);

  const handleRowClickCard = (device: ICleanDevice) => {
    if (onDeviceSelect) {
      onDeviceSelect(device);
    }
  };

  const tabLabels = ["Devices", "Device Errors"];
  if (userRole && ["SuperAdmin", "Admin", "Distributor"].includes(userRole)) {
    tabLabels.push("Replace Device");
  }
  if (userRole && ["SuperAdmin", "Admin"].includes(userRole)) {
    tabLabels.push("Update Firmware");
    tabLabels.push("Manage Access");
  }

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedDeviceMonitoringTab", newValue.toString());
  };

  const [view, setView] = useState("card"); // view state

  return (
    <Box p={0}>
      <BulkAssignModal
        open={isBulkAssignModal}
        onClose={() => setIsBulkAssignModal(false)}
        devices={devices}
        customers={customers}
      />
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "space-between", md: "space-between" },
              alignItems: { xs: "stretch", md: "center" },
              p: 0,
              width: "100%"
            }}
          >
            <Typography sx={{ mb: 2, textAlign: { xs: "left" } }} variant="h6">
              Manage Your Devices
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                gap: 1,
                mb: 2,
                width: { xs: "100%", md: "auto" }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                  width: "auto"
                }}
              >
                {userRole && userRole !== "Customer" && (
                  <StyledButton
                    variantType="outline-primary"
                    onClick={handleOpenBulkModal}
                  >
                    Bulk Assign
                  </StyledButton>
                )}
                <StyledButton
                  variantType="outline-primary"
                  onClick={handleIsFiltersVisibleChange}
                >
                  Select Filters
                </StyledButton>
                <ViewToggle view={view} setView={setView} />
              </Box>
              {userRole &&
                ["Distributor", "SuperAdmin", "Admin"].includes(userRole) && (
                  <Box
                    sx={{
                      display: "flex",
                      width: { xs: "100%", sm: "auto" },
                      mt: { xs: 2, sm: 0 }
                    }}
                  >
                    <Tooltip title="Register New Device" placement="bottom">
                      <StyledButton
                        onClick={handleOpenModal}
                        variantType="primary"
                        icon={<Add />}
                        style={{ width: "100%" }}
                      >
                        Add Device
                      </StyledButton>
                    </Tooltip>
                  </Box>
                )}
            </Box>
          </Box>
          <Box>
            <StyledTabs
              value={selectedTab}
              onChange={handleChangeTab}
              aria-label="Device tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                "& .MuiTab-root": {
                  marginRight: "6px",
                  "&:last-child": {
                    marginRight: 0
                  }
                }
              }}
            >
              {tabLabels.map((label) => (
                <StyledTab key={label} label={label} />
              ))}
            </StyledTabs>
          </Box>
          {/* Filters Box Start */}
          {isFiltersVisible && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  display: "none"
                },
                pt: 1.25
              }}
            >
              <FormControl sx={{ minWidth: 140 }} size="small">
                <Select
                  value={customerEmailFilter || "All"}
                  onChange={handleCustomerEmailFilterChange}
                  sx={{ fontSize: "14px" }}
                >
                  <MenuItem value="All">All Customer Emails</MenuItem>
                  {availableCustomerEmails.map((email) => (
                    <MenuItem key={email} value={email}>
                      {email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 140 }} size="small">
                <Select
                  value={locationFilter || "All"}
                  onChange={handleLocationFilterChange}
                  sx={{ fontSize: "14px" }}
                >
                  <MenuItem value="All">All Location</MenuItem>
                  {availableLocations.map((location) => (
                    <MenuItem key={location} value={location}>
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 140 }} size="small">
                <Select
                  value={statusFilter || "All"}
                  onChange={handleStatusFilterChange}
                  sx={{ fontSize: "14px" }}
                >
                  <MenuItem value="All">Ping Status</MenuItem>
                  {availableStatuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {/* Filters Box End */}
          <RegisterDeviceModal
            open={modalOpen}
            onClose={handleCloseModal}
            onSuccess={handleSuccess}
            onError={handleError}
          />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 2, // Add some margin top for spacing between header and data grid
              width: "100%" // Ensure the inner box takes the full width of the parent box
            }}
          >
            {selectedTab === 0 && (
              <>
                {view === "table" && (
                  <DeviceTable
                    devices={filteredDevices}
                    onRowClick={handleRowClickCard}
                  />
                )}
                {view === "card" && (
                  <DeviceCards
                    devices={filteredDevices}
                    onRowClick={handleRowClickCard}
                  />
                )}
              </>
            )}
            {selectedTab === 1 && (
              <>
                {view === "table" && (
                  <DeviceErrorsTable deviceErrors={deviceErrors} />
                )}
                {view === "card" && (
                  <DeviceErrorsCards deviceErrors={deviceErrors} />
                )}
              </>
            )}
            {selectedTab === 2 && (
              <ReplaceDevice
                devices={filteredDevices}
                onSuccess={() => {}}
                onError={() => {}}
              />
            )}
            {selectedTab === 3 && (
              <UpdateFirmwareTab devices={filteredDevices} />
            )}
            {userRole &&
              (userRole === "Admin" || userRole === "SuperAdmin") &&
              selectedTab === 4 && (
                <ManageAccessTab devices={filteredDevices} />
              )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
