import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Paper,
  Snackbar,
  Alert,
  AlertColor
} from "@mui/material";
import { changePassword } from "../../../services/auth.service";
import useAuthStore from "../../../store/authStore";
import StyledButton from "../../../shared/Components/Styled/StyledButton";

const ChangePassword: React.FC = () => {
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor
  });

  const { user } = useAuthStore();
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswords({ ...passwords, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    if (passwords.newPassword !== passwords.confirmNewPassword) {
      setSnackbar({
        open: true,
        message: "New password and confirm password do not match.",
        severity: "error"
      });
      return;
    }

    try {
      await changePassword(
        user?.email || "",
        passwords.currentPassword,
        passwords.newPassword
      );
      setSnackbar({
        open: true,
        message: "Password changed successfully.",
        severity: "success"
      });
      handleClear();
    } catch (error: unknown) {
      if (error instanceof Error) {
        setSnackbar({
          open: true,
          message: error.message || "Failed to change password.",
          severity: "error"
        });
      } else {
        setSnackbar({
          open: true,
          message: "An unknown error occurred.",
          severity: "error"
        });
      }
    }
  };

  const handleClear = () => {
    setPasswords({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    });
  };

  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: 1, mt: 2 }}>
      <Box sx={{ p: 3, maxWidth: 500, margin: "auto" }}>
        <Typography variant="h6" gutterBottom>
          Change Password
        </Typography>
        <TextField
          fullWidth
          type="password"
          autoComplete="new-password"
          label="Current Password"
          name="currentPassword"
          value={passwords.currentPassword}
          onChange={handleInputChange}
          margin="normal"
        />
        <TextField
          fullWidth
          type="password"
          autoComplete="new-password"
          label="New Password"
          name="newPassword"
          value={passwords.newPassword}
          onChange={handleInputChange}
          margin="normal"
        />
        <TextField
          fullWidth
          type="password"
          autoComplete="new-password"
          label="Confirm Password"
          name="confirmNewPassword"
          value={passwords.confirmNewPassword}
          onChange={handleInputChange}
          margin="normal"
        />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <StyledButton variantType="primary" onClick={handleSubmit}>
            Change Password
          </StyledButton>
          <StyledButton variantType="outline-primary" onClick={handleClear}>
            Clear
          </StyledButton>
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ChangePassword;
