import axios from "axios";
import { Distributor } from "../store/useDistributor";
import useAuthStore from "../store/authStore";
const API_URL = process.env.REACT_APP_API_URL;

// API function to retrieve a distributor by user
export const fetchInstaller = async (): Promise<Distributor[]> => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.get(`${API_URL}/installer/all`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch installers:", error);
    throw new Error("Failed to fetch installers");
  }
};

export const addInstallerToDistributor = async (
  distributorEmail: string,
  installer: {
    email: string;
    firstName: string;
    lastName: string;
  }
) => {
  try {
    const response = await axios.post(`${API_URL}/installer/add`, {
      distributorEmail,
      ...installer // Assuming you're adding one installer at a time
    });
    return response.data;
  } catch (error) {
    console.error("Failed to add installer:", error);
    throw new Error("Failed to add installer");
  }
};

export const changeInstallerStatus = async (
  installerId: string,
  status: string
) => {
  try {
    const response = await axios.post(
      `${API_URL}/installer/accountStatus`,
      {
        installerId,
        status
      },
      {
        headers: {
          Authorization: `Bearer ${useAuthStore.getState().getAuthToken()}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error changing installer status:", error);
    throw error;
  }
};
