import React from "react";
import {
  Button as MuiButton,
  ButtonProps,
  styled,
  useTheme
} from "@mui/material";

interface CustomButtonProps extends ButtonProps {
  variantType?: "primary" | "destructive" | "outline-red" | "outline-primary";
  size?: "small" | "medium" | "large";
  icon?: React.ReactNode;
  iconColor?: string;
  customColor?: string;
  customHoverColor?: string;
  customFontColor?: string;
}

const CustomButton = styled(MuiButton, {
  shouldForwardProp: (prop) =>
    ![
      "variantType",
      "customColor",
      "customFontColor",
      "customHoverColor"
    ].includes(prop as string)
})<CustomButtonProps>(
  ({
    theme,
    variantType,
    size,
    customColor,
    customFontColor,
    customHoverColor
  }) => ({
    borderRadius: "4px",
    backgroundColor:
      customColor ||
      (variantType === "primary"
        ? theme.palette.primary.main
        : variantType === "destructive"
          ? theme.palette.error.main
          : variantType === "outline-red"
            ? "transparent"
            : variantType === "outline-primary"
              ? "transparent"
              : theme.palette.background.paper),
    color:
      customFontColor ||
      (variantType === "primary" || variantType === "destructive"
        ? "white"
        : variantType === "outline-red"
          ? theme.palette.error.main
          : variantType === "outline-primary"
            ? theme.palette.primary.main
            : theme.palette.text.primary),
    // Change the small to the same size as medium but small font - Fluent Style
    height: size === "small" ? "24px" : size === "large" ? "40px" : "32px",
    textWrap: "nowrap",
    padding:
      size === "small" ? "2px 8px" : size === "large" ? "8px 16px" : "6px 12px",
    fontSize:
      size === "small" ? "0.75rem" : size === "large" ? "1rem" : "0.875rem",
    fontWeight: 400,
    textTransform: "none",
    "&:hover": {
      backgroundColor:
        customHoverColor ||
        (variantType === "primary"
          ? "rgba(30, 58, 138, 0.9)"
          : variantType === "destructive"
            ? theme.palette.error.dark
            : variantType === "outline-red"
              ? theme.palette.action.hover
              : variantType === "outline-primary"
                ? theme.palette.action.hover
                : "F5F5F5"),
      border:
        variantType === "outline-red"
          ? `1.5px solid ${theme.palette.error.main}`
          : variantType === "outline-primary"
            ? `1.5px solid ${theme.palette.primary.main}`
            : "1px solid #c7c7c7"
    },
    "& .MuiButton-startIcon": {
      marginRight: "4px"
    },
    "& .MuiButton-endIcon": {
      marginLeft: "4px"
    },
    border:
      variantType === "outline-red"
        ? `1px solid ${theme.palette.error.main}`
        : variantType === "outline-primary"
          ? `1px solid ${theme.palette.primary.main}`
          : "1px solid #d1d1d1",
    "&.Mui-disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
      border: "none"
    }
  })
);

const StyledButton: React.FC<CustomButtonProps> = ({
  variantType,
  size = "medium",
  icon,
  iconColor,
  customColor,
  customHoverColor,
  customFontColor,
  children,
  ...props
}) => {
  const theme = useTheme();

  const iconStyle = {
    fontSize: size === "small" ? "16px" : size === "large" ? "24px" : "20px",
    color:
      iconColor ||
      (variantType === "primary" || variantType === "destructive"
        ? "white"
        : variantType === "outline-red"
          ? theme.palette.error.main
          : variantType === "outline-primary"
            ? theme.palette.primary.main
            : undefined)
  };

  return (
    <CustomButton
      variantType={variantType}
      size={size}
      customColor={customColor}
      customHoverColor={customHoverColor}
      customFontColor={customFontColor}
      startIcon={
        icon &&
        React.cloneElement(icon as React.ReactElement, { style: iconStyle })
      }
      {...props}
    >
      {children}
    </CustomButton>
  );
};

export default StyledButton;
