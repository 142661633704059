import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Autocomplete
} from "@mui/material";
import { UserDetails } from "../../../common/types/UserTypes"; // Import User type
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import { countryLabels } from "../../../common/types/constants";

interface EditUserModalProps {
  open: boolean;
  user?: UserDetails | null; // The user object to edit
  onClose: () => void; // Callback to close the modal
  onSubmit: (updatedUser: UserDetails) => Promise<void>; // Callback to handle form submission
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  open,
  user,
  onClose,
  onSubmit
}) => {
  const [formData, setFormData] = useState<UserDetails | null>(null);

  useEffect(() => {
    if (user) {
      setFormData(user); // Initialize form data when user changes
    }
  }, [user]);

  const handleChange =
    (field: keyof UserDetails) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) =>
        prev ? { ...prev, [field]: event.target.value } : prev
      );
    };

  const handleSubmit = async () => {
    if (formData) {
      await onSubmit(formData); // Pass updated user data to callback
      onClose(); // Close modal after submission
    }
  };

  if (!formData) return null; // Render nothing if formData is null

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit User Information</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              value={formData.firstName || ""}
              onChange={handleChange("firstName")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              value={formData.lastName || ""}
              onChange={handleChange("lastName")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              disabled
              type="email"
              value={formData.email || ""}
              onChange={handleChange("email")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone"
              value={formData.phone || ""}
              onChange={handleChange("phone")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address"
              value={formData.address || ""}
              onChange={handleChange("address")}
              fullWidth
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              options={countryLabels.map((country) => country.label)}
              value={formData.country || ""}
              onChange={(_, newValue) => {
                setFormData((prev) =>
                  prev ? { ...prev, country: newValue || "" } : prev
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="none"
                  required
                  helperText={formData.country === "" && "Country is required"}
                  error={formData.country === ""}
                  {...params}
                  label="Country"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="State"
              value={formData.state || ""}
              onChange={handleChange("state")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="City"
              value={formData.city || ""}
              onChange={handleChange("city")}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onClose} variantType="outline-red">
          Cancel
        </StyledButton>
        <StyledButton onClick={handleSubmit} variantType="primary">
          Save Changes
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserModal;
