import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const submitWarrantyClaim = async (
  serialNo: string,
  detailedIssue: string,
  faultType: string,
  photo: string | undefined,
  submittedDate: string,
  status: string,
  distributorEmail: string,
  customerEmail: string
): Promise<any> => {
  try {
    const data = {
      serialNo,
      detailedIssue,
      faultType,
      photo,
      submittedDate,
      status,
      distributorEmail,
      customerEmail
    };

    const response = await axios.post(`${API_URL}/warranty`, data, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response.data;
  } catch (error) {
    console.error("Failed to submit warranty claim:", error);
    throw new Error("Failed to submit warranty claim");
  }
};

export const fetchDeviceArrivals = async (
  email: string,
  role: string
): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/device-arrivals/all`, {
      params: { email, role }
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch warranty claims:", error);
    throw new Error("Failed to fetch warranty claims");
  }
};
