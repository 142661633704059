import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  Alert,
  Snackbar,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AlertColor,
  useTheme
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Assessment,
  ImageSearch
} from "@mui/icons-material";
import StepperMenu from "../../../shared/Components/StepperMenu";
import { ICleanDevice } from "../../../common/types/DeviceTypes";
import { SelectChangeEvent } from "@mui/material/Select";
import useAuthStore from "../../../store/authStore";
import { createReport } from "../../../services/reports.service";
import PDFWindowViewer from "../../../shared/Components/PDFWindowViewer";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import useDeviceStore from "../../../store/useDeviceStore";

const steps = ["Select Device", "Report Details", "Generate Report"];
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

interface GenerateReportProps {
  reportCategory: "CustomerReport" | "DeviceReport";
}

const GenerateReport: React.FC<GenerateReportProps> = ({ reportCategory }) => {
  const { allDevices: devices } = useDeviceStore();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [dataPreview, setDataPreview] = useState<any>(null);
  const [reportGenerated, setReportGenerated] = useState(false);
  const [reportType, setReportType] = useState<string>("");
  const [reportName, setReportName] = useState<string>("");
  const [reportDescription, setReportDescription] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [previewGenerated, setPreviewGenerated] = useState<boolean>(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor
  });
  const { user } = useAuthStore();
  const theme = useTheme();

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (!reportGenerated) {
        generateReport();
      }
      setSelectedDevices([]);
      setReportType("");
      setReportDescription("");
      setReportName("");
      setStartDate(null);
      setEndDate(null);
      setActiveStep(0);
      setReportGenerated(false);
      setDataPreview(null);
      setPreviewGenerated(false);
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleCancel = () => {
    setActiveStep(0);
    setSelectedDevices([]);
    setReportType("");
    setDataPreview(null);
    setReportGenerated(false);
    setReportDescription("");
    setReportName("");
    setStartDate(null);
    setEndDate(null);
    setDataPreview(null);
    setPreviewGenerated(false);
  };

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  const handleDeviceChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: React.SetStateAction<string[]>
  ) => {
    setSelectedDevices(value);
  };

  const handleReportTypeChange = (event: SelectChangeEvent<string>) => {
    setReportType(event.target.value as string);
  };

  const previewData = async () => {
    if (!reportType) {
      return;
    }

    let previewData;

    if (reportType === "Usage") {
      previewData = calculateUsage(selectedDevices, startDate, endDate);
    } else if (reportType === "Cartridge") {
      previewData = await calculateCartridgeHistory();
    } else if (reportType === "OverallUsage") {
      previewData = calculateTotalUsage(selectedDevices);
    } else {
      console.warn("Invalid report type selected");
      return;
    }

    setDataPreview(previewData);
    setPreviewGenerated(true);
    setIsPreviewOpen(true); // Open preview modal
    setSelectedReport({
      _id: "",
      reportInformation: {
        name: reportName,
        description: reportDescription,
        reportType: reportType,
        generatedAt: new Date(),
        generatedBy: user?.email || "Unknown",
        startDate: startDate || new Date(),
        endDate: endDate || new Date(),
        devices: selectedDevices
      },
      reportData: { data: previewData }
    }); // Set selected report data
  };

  const calculateUsage = (
    selectedDevices: string[],
    startDate: Date | null,
    endDate: Date | null
  ) => {
    if (!startDate || !endDate) {
      return {
        totalUsage: 0,
        totalAverageUsage: 0,
        usagePerDevice: []
      };
    }

    const normalizedStartDate = dayjs(startDate).startOf("day");
    const normalizedEndDate = dayjs(endDate).endOf("day");

    let totalUsage = 0;
    let totalAverageUsage = 0;
    const usagePerDevice: {
      deviceId: string;
      deviceName: string;
      totalUsage: number;
      averageUsage: number;
    }[] = [];

    // Calculate the number of days in the specified date range
    const numberOfDays =
      normalizedEndDate.diff(normalizedStartDate, "days") + 1;

    // Loop through each selected device
    selectedDevices.forEach((deviceId) => {
      const device = devices.find(
        (d) => d.deviceInformation.deviceId === deviceId
      );

      if (device && device.deviceType === "iClean") {
        const iCleanDevice = device;
        const deviceName = iCleanDevice.deviceInformation.deviceName; // Get deviceName
        let deviceTotalUsage = 0;

        // Calculate total usage for the current device within the date range
        (iCleanDevice as ICleanDevice).deviceUsage.forEach((usage) => {
          const usageDate = dayjs(usage.uploadTimestamp).startOf("day");

          if (
            usageDate.isSameOrAfter(normalizedStartDate) &&
            usageDate.isSameOrBefore(normalizedEndDate)
          ) {
            deviceTotalUsage += usage.litersDispensed;
          }
        });

        // Calculate average usage for the current device
        const deviceAverageUsage =
          numberOfDays > 0 ? deviceTotalUsage / numberOfDays : 0;

        // Add the device's data to the usage report
        usagePerDevice.push({
          deviceId,
          deviceName, // Include deviceName
          totalUsage: deviceTotalUsage,
          averageUsage: deviceAverageUsage
        });

        // Update the total usage and total average usage
        totalUsage += deviceTotalUsage;
        totalAverageUsage += deviceAverageUsage;
      }
    });

    // Return the calculated data
    return {
      totalUsage,
      totalAverageUsage,
      usagePerDevice
    };
  };

  const calculateTotalUsage = (selectedDevices: string[]) => {
    const usagePerDevice: {
      deviceId: string;
      deviceName: string;
      totalUsage: number;
      firstUsageDate: Date | null;
    }[] = [];

    let totalUsage = 0;

    // Loop through each selected device
    selectedDevices.forEach((deviceId) => {
      const device = devices.find(
        (d) => d.deviceInformation.deviceId === deviceId
      );

      if (device && device.deviceType === "iClean") {
        const iCleanDevice = device;
        const deviceName = iCleanDevice.deviceInformation.deviceName; // Get deviceName
        let deviceTotalUsage = 0;
        let firstUsageDate: Date | null = null; // Initialize first usage date

        // Calculate total usage for the current device since the beginning
        (iCleanDevice as ICleanDevice).deviceUsage.forEach((usage) => {
          deviceTotalUsage += usage.litersDispensed;

          // Check and set the earliest usage date
          const usageDate = new Date(usage.uploadTimestamp);
          if (!firstUsageDate || usageDate < firstUsageDate) {
            firstUsageDate = usageDate;
          }
        });

        // Add the device's data to the usage report
        usagePerDevice.push({
          deviceId,
          deviceName, // Include deviceName
          totalUsage: deviceTotalUsage,
          firstUsageDate // Include first usage date
        });

        // Update the overall total usage
        totalUsage += deviceTotalUsage;
      }
    });

    // Return the calculated data
    return {
      totalUsage,
      usagePerDevice
    };
  };

  const calculateCartridgeHistory = async () => {
    const historyData = [];

    for (const deviceId of selectedDevices) {
      const device = devices.find(
        (d) => d.deviceInformation.deviceId === deviceId
      );

      if (device && device.deviceType === "iClean") {
        const iCleanDevice = device as ICleanDevice;
        const replacementHistory = iCleanDevice.replacementHistory;

        if (replacementHistory) {
          historyData.push({
            deviceId: device.deviceInformation.deviceId,
            deviceName: device.deviceInformation.deviceName,
            serial: device.deviceInformation.serial,
            replacementHistory
          });
        }
      }
    }

    return historyData;
  };

  const generateReport = async () => {
    try {
      let reportData;

      switch (reportType) {
        case "Usage":
          reportData = calculateUsage(selectedDevices, startDate, endDate);
          break;

        case "Cartridge":
          reportData = await calculateCartridgeHistory();
          break;

        case "OverallUsage":
          reportData = calculateTotalUsage(selectedDevices);
          break;

        default:
          console.warn("Invalid report type selected");
          return;
      }

      const report = await createReport(
        reportName,
        reportDescription,
        endDate || new Date(),
        selectedDevices,
        user?.email || "Unknown",
        reportData,
        reportType, // Pass the reportType to the backend
        startDate ?? undefined,
        endDate ?? undefined
      );
      setReportGenerated(true);
      setSnackbar({
        open: true,
        message: "Report generated successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Error generating report:", error);
      setSnackbar({
        open: true,
        message: "Error generating report",
        severity: "error"
      });
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography sx={{ mb: 2 }} variant="h6">
              Select Device
            </Typography>
            <Autocomplete
              multiple
              options={devices.map(
                (device) => device.deviceInformation.deviceId
              )}
              disableCloseOnSelect
              getOptionLabel={(option) => {
                const device = devices.find(
                  (d) => d.deviceInformation.deviceId === option
                );
                return device
                  ? device.deviceInformation.deviceName ||
                      device.deviceInformation.deviceId
                  : option;
              }}
              renderOption={(props, option, { selected }) => {
                const device = devices.find(
                  (d) => d.deviceInformation.deviceId === option
                );
                const key = `${device?.deviceInformation.deviceId}-${
                  device?.deviceInformation.deviceName || ""
                }`;
                return (
                  <li {...props} key={key}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {device?.deviceInformation.deviceName || option}
                  </li>
                );
              }}
              value={selectedDevices}
              onChange={(event, value) => handleDeviceChange(event, value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Devices"
                  placeholder="Select devices"
                />
              )}
            />
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h6">Report Details</Typography>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Report Name"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Description"
                value={reportDescription}
                onChange={(e) => setReportDescription(e.target.value)}
                multiline
                rows={2}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="report-type-select-label">Report Type</InputLabel>
              <Select
                labelId="report-type-select-label"
                value={reportType}
                onChange={handleReportTypeChange}
                label="Report Type"
              >
                {reportCategory === "DeviceReport" && [
                  <MenuItem key="Usage" value="Usage">
                    Usage
                  </MenuItem>,
                  <MenuItem key="Cartridge" value="Cartridge">
                    Cartridge
                  </MenuItem>,
                  <MenuItem key="OverallUsage" value="OverallUsage">
                    Overall
                  </MenuItem>
                ]}
                {reportCategory === "CustomerReport" && [
                  <MenuItem key="All Customers" value="All Customers">
                    All Customers
                  </MenuItem>,
                  <MenuItem key="Active Customers" value="Active Customers">
                    Active Customers
                  </MenuItem>,
                  <MenuItem key="Inactive Customers" value="Inactive Customers">
                    Inactive Customers
                  </MenuItem>,
                  <MenuItem key="New Customers" value="New Customers">
                    New Customers
                  </MenuItem>
                ]}
              </Select>
            </FormControl>

            {/* Conditionally Render Date Pickers */}
            {reportType !== "OverallUsage" && (
              <>
                <FormControl sx={{ mr: 2 }} margin="normal">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      value={dayjs(startDate)}
                      onChange={(newDate) =>
                        setStartDate(newDate?.toDate() || null)
                      }
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl margin="normal">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      value={dayjs(endDate)}
                      onChange={(newDate) =>
                        setEndDate(newDate?.toDate() || null)
                      }
                    />
                  </LocalizationProvider>
                </FormControl>
              </>
            )}
          </Box>
        );
      case 2:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",

              width: "100%",

              flexDirection: "column"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1.5,
                mb: 1.5
              }}
            >
              <StyledButton
                onClick={previewData}
                icon={<ImageSearch />}
                iconColor={theme.palette.primary.main}
              >
                Preview Data
              </StyledButton>
              {reportGenerated ? (
                <Typography variant="body1">
                  Report Generated Successfully
                </Typography>
              ) : (
                <StyledButton
                  onClick={generateReport}
                  icon={<Assessment />}
                  iconColor={theme.palette.primary.main}
                >
                  Generate Report
                </StyledButton>
              )}
            </Box>
            {dataPreview !== null && dataPreview !== undefined && (
              <Box>
                {reportType === "Usage" && (
                  <>
                    {/* Table for Usage Data */}
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Device Name</TableCell>
                          <TableCell>Device ID</TableCell>
                          <TableCell>Total Usage (Liters)</TableCell>
                          <TableCell>Average Usage (Liters/Day)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataPreview.usagePerDevice.map(
                          (
                            device: {
                              deviceId: string;
                              deviceName: string;
                              totalUsage: number;
                              averageUsage: number;
                            },
                            index: number
                          ) => (
                            <TableRow key={index}>
                              <TableCell>{device.deviceName}</TableCell>
                              <TableCell>{device.deviceId}</TableCell>
                              <TableCell>
                                {device.totalUsage.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {device.averageUsage.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                        {/* Summary Row */}
                        <TableRow>
                          <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
                            Total
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            {dataPreview.totalUsage.toFixed(2)} Liters
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            {dataPreview.totalAverageUsage.toFixed(2)}{" "}
                            Liters/Day
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </>
                )}

                {reportType === "Cartridge" && Array.isArray(dataPreview) && (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Device ID</TableCell>
                          <TableCell>Device Name</TableCell>
                          <TableCell>Replacement Date</TableCell>
                          <TableCell>Reason for Replacement</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataPreview.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.deviceId}</TableCell>
                            <TableCell>{item.deviceName}</TableCell>
                            <TableCell>
                              {item.replacementHistory?.replacedDate
                                ? new Date(
                                    item.replacementHistory.replacedDate
                                  ).toLocaleDateString()
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {item.replacementHistory?.reasonForReplacement ||
                                "N/A"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {reportType === "OverallUsage" && (
                  <>
                    {/* Table for Overall Data */}
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Device Name</TableCell>
                          <TableCell>Device ID</TableCell>
                          <TableCell>Total Usage (Liters)</TableCell>
                          <TableCell>First Usage Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataPreview.usagePerDevice.map(
                          (
                            device: {
                              deviceId: string;
                              deviceName: string;
                              totalUsage: number;
                              firstUsageDate: string | null;
                            },
                            index: number
                          ) => (
                            <TableRow key={index}>
                              <TableCell>{device.deviceName}</TableCell>
                              <TableCell>{device.deviceId}</TableCell>
                              <TableCell>
                                {device.totalUsage.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {device.firstUsageDate
                                  ? new Date(
                                      device.firstUsageDate
                                    ).toLocaleDateString()
                                  : "N/A"}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                        {/* Summary Row */}
                        <TableRow>
                          <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
                            Total
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            {dataPreview.totalUsage.toFixed(2)} Liters
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </>
                )}
              </Box>
            )}
          </Box>
        );

      default:
        return "Unknown step";
    }
  };

  return (
    <Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <StepperMenu
        steps={steps}
        activeStep={activeStep}
        handleStepClick={handleStepClick}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
        disableNext={
          (activeStep === 0 && selectedDevices.length === 0) ||
          (activeStep === 1 && !reportType) ||
          (activeStep === 1 && reportType !== "OverallUsage" && !startDate) || // Step 1: Start date required unless OverallUsage
          (activeStep === 1 && reportType !== "OverallUsage" && !endDate) || // Step 1: End date required unless OverallUsage
          (activeStep === 1 && !reportName) ||
          (activeStep === 1 && !reportDescription)
        }
        disableFinish={activeStep !== steps.length - 1 || !reportGenerated}
      >
        {getStepContent(activeStep)}
      </StepperMenu>
      <Dialog
        open={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Report Preview</DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
            overflow: "hidden",
            height: "600px"
            // width: { xs: "100%" },
          }}
        >
          {selectedReport && (
            <PDFWindowViewer
              selectedReport={selectedReport}
              showToolbar={false}
            />
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton
            variantType="outline-red"
            iconColor={theme.palette.primary.main}
            onClick={() => setIsPreviewOpen(false)}
          >
            Close
          </StyledButton>
          <StyledButton
            onClick={generateReport}
            disabled={!previewGenerated || reportGenerated}
            icon={<Assessment />}
            iconColor={theme.palette.primary.main}
          >
            Generate Report
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GenerateReport;
