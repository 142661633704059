import { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  useMediaQuery,
  Theme,
  Modal,
  Slider
} from "@mui/material";
import { ICleanLinesDevice } from "../../../../common/types/DeviceTypes";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { setFlushCycle } from "../../../../services/device.service";
import StyledButton from "../../../../shared/Components/Styled/StyledButton";

interface DayOfWeek {
  full: string;
  initial: string;
  index?: number;
}

interface AddCycleModalProps {
  open: boolean;
  onClose: () => void;
  selectedDevice: ICleanLinesDevice;
  onSave: (newCycle: any) => void;
}

const AddCycleModal: React.FC<AddCycleModalProps> = ({
  open,
  onClose,
  selectedDevice,
  onSave
}) => {
  const [flushTimes, setFlushTimes] = useState<number[]>([10, 10, 10]);
  const [flushTypes, setFlushTypes] = useState<string[]>(["", "", ""]);
  const [set, setSet] = useState<string>("");
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<Dayjs>(
    dayjs("00:00:00", "HH:mm:ss")
  );

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const daysOfWeek: DayOfWeek[] = [
    { full: "Monday", initial: "Mon", index: 0 },
    { full: "Tuesday", initial: "Tue", index: 1 },
    { full: "Wednesday", initial: "Wed", index: 2 },
    { full: "Thursday", initial: "Thu", index: 3 },
    { full: "Friday", initial: "Fri", index: 4 },
    { full: "Saturday", initial: "Sat", index: 5 },
    { full: "Sunday", initial: "Sun", index: 6 }
  ];

  const onSlideChange =
    (index: number) => (_: Event, value: number | number[]) => {
      setFlushTimes((prev) => {
        const updated = [...prev];
        updated[index] = value as number;
        return updated;
      });
    };

  const handleFlushTypeChange =
    (index: number) => (event: SelectChangeEvent<string>) => {
      setFlushTypes((prev) => {
        const updated = [...prev];
        updated[index] = event.target.value;
        return updated;
      });
    };

  const handleSetChange = (event: SelectChangeEvent<string>) => {
    setSet(event.target.value);
  };

  const toggleDaySelection = (day: string) => {
    setSelectedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const handleTimeChange = (newTime: Dayjs | null) => {
    if (newTime) setSelectedTime(newTime);
  };

  const calculateAdjustedTime = (
    baseTime: Dayjs,
    durations: number[],
    index: number
  ): Dayjs => {
    const totalDurationInSeconds = durations
      .slice(0, index) // Sum durations of all previous flushes
      .reduce((acc, duration) => acc + duration, 0);
    const totalOffset = totalDurationInSeconds + index * 5; // Add 5 seconds overhead per flush
    return baseTime.add(totalOffset, "second");
  };

  const handleSave = async (event: React.FormEvent) => {
    if (!selectedDays.length) {
      alert("Please select at least one day.");
      return;
    }

    if (!set) {
      alert("Please select a set.");
      return;
    }

    if (flushTypes.every((type) => !type)) {
      alert("Please select at least one flush type.");
      return;
    }

    const params = {
      [`set-${set}`]: {
        d: selectedDays.map((day) =>
          daysOfWeek.findIndex((d) => d.full === day)
        ), // Day indexes
        f: flushTypes.map((type, index) => ({
          t: type,
          dur: flushTimes[index], // Duration in seconds
          ...Object.fromEntries(
            calculateAdjustedTime(selectedTime, flushTimes, index)
              .format("HH:mm:ss")
              .split(":")
              .map((value, i) => ["hms"[i], Number(value)])
          ) // Parse time into h, m, s
        }))
      }
    };
    try {
      const response = await setFlushCycle(
        selectedDevice.deviceInformation.serial,
        params
      );
      console.log("Response from backend:", response);
      // Handle success: reset the states
      setFlushTimes([10, 10, 10]);
      setFlushTypes(["", "", ""]);
      setSelectedDays([]);
      setSelectedTime(dayjs("00:00:00", "HH:mm:ss"));
      setSet("");
      onSave("success"); // Pass data to parent component
      onClose(); // Close modal
    } catch (error) {
      console.error("Error setting flush cycle:", error);
      onSave(error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "90%" : "40%",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            padding: 2,
            borderRadius: "8px",
            backgroundColor: "background.paper",
            color: "text.primary"
          }}
        >
          <Box display="flex" justifyContent="space-between" pb={2}>
            <Typography variant="h6" fontWeight="bold">
              Add New Cycle
            </Typography>
          </Box>

          <Box p={2} sx={{ width: "90" }}>
            {/* Days and Time Picker */}
            <Box
              display="flex"
              flexWrap="wrap"
              gap={1}
              justifyContent={"space-between"}
            >
              {daysOfWeek.map(({ full, initial }) => (
                <StyledButton
                  key={full}
                  onClick={() => toggleDaySelection(full)}
                  variantType={
                    selectedDays.includes(full) ? "primary" : "outline-primary"
                  }
                  color={selectedDays.includes(full) ? "primary" : "inherit"}
                >
                  {initial}
                </StyledButton>
              ))}
            </Box>
            <Box
              mt={2}
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: isMobile ? "center" : "space-between",
                gap: isMobile ? 2 : 1
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Time"
                  views={["hours", "minutes", "seconds"]}
                  minutesStep={1}
                  value={selectedTime}
                  onChange={handleTimeChange}
                />
              </LocalizationProvider>

              <FormControl sx={{ minWidth: isMobile ? "100%" : "35%" }}>
                <InputLabel>Set</InputLabel>
                <Select
                  label="Set"
                  variant="outlined"
                  fullWidth
                  value={set}
                  onChange={handleSetChange}
                >
                  <MenuItem value="">Select</MenuItem>
                  {["A", "B", "C", "D", "E", "F", "G", "H", "I"].map(
                    (option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>

            {/* Flush Configuration */}
            {[0, 1, 2].map((index) => (
              <Box
                key={index}
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent={"space-between"}
              >
                <FormControl sx={{ minWidth: 240 }}>
                  <InputLabel>Flush Type</InputLabel>
                  <Select
                    value={flushTypes[index]}
                    onChange={handleFlushTypeChange(index)}
                    label="Flush Type"
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="forward">Forward</MenuItem>
                    <MenuItem value="backward">Backward</MenuItem>
                  </Select>
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Typography>Duration: {flushTimes[index]} sec</Typography>
                  <Slider
                    value={flushTimes[index]}
                    min={10}
                    max={300}
                    onChange={onSlideChange(index)}
                  />
                </Box>
              </Box>
            ))}
          </Box>
          {/* Save Button */}
          <Box mt={3} display="flex" justifyContent="flex-end">
            <StyledButton onClick={handleSave} variantType="primary">
              Save
            </StyledButton>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default AddCycleModal;
