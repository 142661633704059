import React, { useState } from "react";
import {
  Card,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { Tooltip } from "@fluentui/react-components";

interface NavItemProps {
  icon: React.ReactElement;
  label: string;
  active?: boolean;
  nested?: boolean; // Add a nested prop
  children?: React.ReactNode;
  onClick: () => void;
  showLabel?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  icon,
  label,
  active,
  nested,
  children,
  onClick,
  showLabel = true // Default to true
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (children) {
      setOpen(!open);
    } else {
      onClick();
    }
  };

  // Conditional styles for nested items
  const nestedStyles = nested
    ? {
        pl: 1, // Add padding for nested items
        fontSize: "11.5px"
      }
    : {};

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          p: 0,
          borderRadius: 1,
          mb: 0.5,
          width: showLabel ? "98%" : "35px",
          borderColor: "transparent",
          bgcolor: active ? "secondary.light" : "transparent",
          ...nestedStyles, // Apply conditional styles
          "&:hover": {
            bgcolor: "secondary.light",
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
              color: "primary.dark" // Change color of icon and text on hover
            },
            "& .MuiSvgIcon-root": {
              fill: "rgba(30, 58, 138, 1)"
            },
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out"
          }
        }}
        onClick={handleClick}
      >
        <ListItem sx={{ pl: showLabel ? 2 : 1 }}>
          {showLabel ? (
            <ListItemIcon
              sx={{
                color: active ? "primary.dark" : "text.secondary",
                "& .MuiSvgIcon-root": {
                  fill: active ? "rgba(30, 58, 138, 1)" : ""
                }
              }}
            >
              {icon}
            </ListItemIcon>
          ) : (
            <Tooltip relationship="label" content={label} appearance="inverted">
              <ListItemIcon
                sx={{
                  color: active ? "primary.dark" : "text.secondary",
                  "& .MuiSvgIcon-root": {
                    fill: active ? "rgba(30, 58, 138, 1)" : ""
                  }
                }}
              >
                {icon}
              </ListItemIcon>
            </Tooltip>
          )}
          {showLabel && (
            <ListItemText
              primary={label}
              primaryTypographyProps={{
                color: active ? "primary.dark" : "inherit",
                fontSize: "14px"
              }}
            />
          )}
          {showLabel && children && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
      </Card>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default NavItem;
