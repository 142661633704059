import { useState, useCallback } from "react";
import { fetchSalesByDistributorEmail } from "../../../services/distributor.service";
import { SalesData } from "../../../common/types/DistributorSalesTypes";
import useAuthStore from "../../../store/authStore";

export const useDistributorSales = () => {
  const [sales, setSales] = useState<SalesData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user, userRole } = useAuthStore();

  const fetchSales = useCallback(async () => {
    if (!user?.email || !userRole) {
      console.log("Missing user data:", { email: user?.email, userRole });
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const salesData = await fetchSalesByDistributorEmail(
        user.email,
        userRole
      );
      setSales(salesData);
    } catch (error) {
      console.error("Error fetching sales:", error);
      setError("Failed to fetch sales data");
    } finally {
      setLoading(false);
    }
  }, [user?.email, userRole]);

  return {
    sales,
    loading,
    error,
    fetchSales
  };
};
