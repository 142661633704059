import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  styled,
  ListItemIcon,
  Snackbar,
  Alert
} from "@mui/material";
import {
  HelpOutline as HelpOutlineIcon,
  Settings as SettingsIcon,
  Message as MessageIcon,
  Info as InfoIcon,
  Chat as ChatIcon
} from "@mui/icons-material";
import { DeviceData } from "../../common/types/DeviceTypes";
import WarrantyForm from "./HelpComponents/WarrantyModal";
import FAQSection from "./HelpComponents/FAQ";
import Instructions from "./HelpComponents/Instructions";
import ContactUs from "./HelpComponents/ContactUs";
import ServiceManual from "./HelpComponents/ServiceManual";
import useAuthStore from "../../store/authStore";

interface UserSupportProps {
  devices: DeviceData[];
}

const IconWrapper = styled(ListItemIcon)({
  justifyContent: "center",
  minWidth: "30px",
  "& svg": {
    fill: "#9e9e9e",
    stroke: "black",
    strokeWidth: "0.8px"
  },
  "&:hover": {
    color: "primary.main",
    bgColor: "primary.main"
  }
});
const UserSupport: React.FC<UserSupportProps> = ({ devices }) => {
  const { userRole } = useAuthStore();
  const [isWarrantyModalOpen, setWarrantyModalOpen] = useState(false);
  const [view, setView] = useState<
    | "home"
    | "faq"
    | "orderStatus"
    | "warranty"
    | "contactUs"
    | "instructions"
    | "billing"
    | "serviceManual"
  >("home");
  const isAuthorizedUser =
    userRole === "Distributor" ||
    userRole === "SubDistributor" ||
    userRole === "Installer";

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleWarrantySubmit = async (_formData: any) => {
    setSnackbar({
      open: true,
      message: "Warranty claim submitted successfully.",
      severity: "success"
    });
  };

  const handleWarrantyError = (_message: string) => {
    setSnackbar({
      open: true,
      message: "Failed to submit warranty claim. Please try again.",
      severity: "error"
    });
  };

  type ViewType =
    | "home"
    | "faq"
    | "orderStatus"
    | "warranty"
    | "contactUs"
    | "instructions"
    | "billing"
    | "serviceManual";

  const supportItems: {
    icon: JSX.Element;
    title: string;
    description: string;
    view?: ViewType;
    onClick?: () => void;
  }[] = [
    {
      icon: (
        <IconWrapper>
          <HelpOutlineIcon style={{ fontSize: "2.5rem" }} />
        </IconWrapper>
      ),
      title: "FAQs",
      description: "Answers to common questions",
      view: "faq"
    },
    {
      icon: (
        <IconWrapper>
          <SettingsIcon style={{ fontSize: "2.5rem" }} />
        </IconWrapper>
      ),
      title: "Warranty",
      description: "Submit a claim",
      onClick: () => window.open("/warranty", "_blank")
    },
    {
      icon: (
        <IconWrapper>
          <MessageIcon style={{ fontSize: "2.5rem" }} />
        </IconWrapper>
      ),
      title: "Contact Us",
      description: "Connect with our team",
      view: "contactUs"
    },
    {
      icon: (
        <IconWrapper>
          <InfoIcon style={{ fontSize: "2.5rem" }} />
        </IconWrapper>
      ),
      title: "Product Instructions",
      description: "Instructions for assembly and care",
      view: "instructions"
    },
    {
      icon: (
        <IconWrapper>
          <InfoIcon style={{ fontSize: "2.5rem" }} />
        </IconWrapper>
      ),
      title: "Service Manual",
      description: "Instructions for service and maintenance",
      view: "serviceManual"
    }
  ];

  // Helper function to render the current view
  const renderView = () => {
    switch (view) {
      case "faq":
        return <FAQSection />;
      case "orderStatus":
        return <Typography variant="h6">Order Status Section</Typography>;
      case "contactUs":
        return <ContactUs />;
      case "instructions":
        return <Instructions />;
      case "serviceManual":
        return <ServiceManual />;
      case "billing":
        return (
          <Typography variant="h6">Billing and Payment Section</Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Box p={0}>
      {/* Conditionally render either the helpdesk or a specific view */}
      {view === "home" ? (
        <Box mt={0} sx={{ display: "flex" }}>
          <Box
            mt={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: { xs: "90%", md: "90%" }
            }}
          >
            <Box
              textAlign="center"
              my={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: 5
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  gap: 1
                }}
                variant="body1"
                gutterBottom
              >
                Chat <ChatIcon fontSize="medium" />
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px"
                }}
                variant="body1"
              >
                {" "}
                <a href="mailto:dev.team@tersano.com"> dev.team@tersano.com</a>
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px"
                }}
                variant="body2"
                gutterBottom
              >
                Monday - Friday: 6am - 5pm MT
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px"
                }}
                variant="body2"
                gutterBottom
              >
                Saturday & Sunday: 10am - 11:45am MT and 1pm - 3pm MT
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px"
                }}
                variant="body1"
                gutterBottom
              >
                or Call
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px"
                }}
                variant="body1"
                gutterBottom
              >
                <a href="tel:1-855-688-7269">1-226-961-4154</a>
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px"
                }}
                variant="body2"
              >
                Monday - Friday: 8am - 5pm MT
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px"
                }}
                variant="body2"
              >
                <a href="Watch our Training Tutorial Videos here">
                  Watch our Training Tutorial Videos here
                </a>
              </Typography>
            </Box>
            {isAuthorizedUser && (
              <Grid container spacing={4}>
                {supportItems.map((item, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Box
                      textAlign="center"
                      p={2}
                      bgcolor="background.default"
                      color="primary.contrastText"
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick();
                        } else {
                          if (item.view) {
                            setView(item.view);
                          }
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(30, 58, 138, 1)",
                          "& .MuiTypography-root": { color: "white" },
                          "& .MuiIconButton-root": { color: "white" },
                          "& .MuiSvgIcon-root": {
                            fill: "white"
                          },
                          transform: "scale(1.03)",
                          transition: "transform 0.2s"
                        },
                        borderRadius: 1,
                        border: "1px solid #e0e0e0",
                        boxShadow: "none",
                        borderBottom: "1.5px solid #bdbdbd"
                      }}
                    >
                      <IconButton
                        size="medium"
                        sx={{ color: "primary.dark", fontSize: "3rem" }}
                      >
                        {item.icon}
                      </IconButton>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: "black", fontSize: "1rem" }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{ color: "grey.800", fontSize: "0.9rem" }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          {/* Render the correct view based on the selected card */}
          <Box textAlign="right" mt={2}>
            <IconButton
              onClick={() => setView("home")} // Go back to the helpdesk
              sx={{
                color: "primary.main",
                ":hover": { backgroundColor: "transparent" }
              }}
            >
              <Typography variant="body1">Back to Helpdesk</Typography>
            </IconButton>
          </Box>
          {renderView()}
        </Box>
      )}

      <WarrantyForm
        open={isWarrantyModalOpen}
        onClose={() => setWarrantyModalOpen(false)}
        onSubmit={handleWarrantySubmit}
        onError={handleWarrantyError}
        devices={devices}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserSupport;
