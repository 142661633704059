import {
  DeviceData,
  DeviceErrors,
  DeviceFlushData,
  DeviceLog,
  DeviceLogEntry,
  ICleanDevice,
  ICleanLinesDeviceData
} from "../common/types/DeviceTypes";
import { updateTestStatusPayload } from "../common/types/payload";
import axios from "axios";
import useAuthStore from "../store/authStore";
import { EmailNotificationTypes } from "../common/types/DeviceTypes";

const API_URL = process.env.REACT_APP_API_URL; // LOTUS PRO
const ICLEANLINES_API_URL = process.env.REACT_APP_ICLEAN_API_URL; // ICLEANLINES

/* ========= Lotus Pro ========= */

export const fetchDevices = async (): Promise<DeviceData[]> => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.get(`${API_URL}/device/all`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch devices:", error);
    throw new Error("Failed to fetch devices");
  }
};

export const fetchDeviceDetails = async (
  deviceId: string
): Promise<ICleanDevice> => {
  const response = await axios.get(`${API_URL}/device/?deviceId=${deviceId}`);
  return response.data;
};

export const createICleanDevice = async (payload: any) => {
  try {
    const response = await axios.post(`${API_URL}/iclean/device`, payload);
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

export const updateDeviceTestStatus = async (
  deviceId: string,
  testStatus: updateTestStatusPayload
) => {
  try {
    const response = await axios.put(`${API_URL}/device/test-status`, {
      deviceId,
      testStatus
    });
    return response.data;
  } catch (error) {
    console.error("Error updating device test status:", error);
    throw error;
  }
};

export const fetchDeviceErrors = async (): Promise<DeviceErrors[]> => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.get(`${API_URL}/device-error/all`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data.deviceErrors;
  } catch (error) {
    console.error("Failed to fetch device errors:", error);
    throw new Error("Failed to fetch device errors");
  }
};

export const fetchTodaysDeviceErrors = async (): Promise<DeviceErrors[]> => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.get(`${API_URL}/device-error/today`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data.deviceErrors;
  } catch (error) {
    console.error("Failed to fetch today's device errors:", error);
    throw new Error("Failed to fetch today's device errors");
  }
};

export const fetchDeviceLogs = async (
  email: string,
  role: string
): Promise<DeviceLog[]> => {
  const response = await axios.get(`${API_URL}/device-logs/all`, {
    params: { email, role }
  });
  return response.data.deviceLogs;
};

export const fetchDeviceLogsByDeviceId = async (
  deviceId: string
): Promise<DeviceLogEntry[]> => {
  const response = await axios.get(`${API_URL}/device-logs`, {
    params: { deviceId }
  });
  return response.data.deviceLogs;
};

export const changeCartridgeType = async (
  deviceId: string,
  newCartridge: string
) => {
  try {
    const response = await axios.post(`${API_URL}/device/change-cartridge`, {
      deviceId,
      newCartridge
    });

    return response.data;
  } catch (error) {
    console.error("Error updating cartridge type:", error);
    throw error;
  }
};

export const addAdditionalUsers = async (
  deviceId: string,
  userEmail: string
) => {
  try {
    const response = await axios.post(`${API_URL}/device/add-user`, {
      deviceId,
      userEmail
    });
    return response.data;
  } catch (error) {
    console.error("Error adding additional users:", error);
    throw error;
  }
};

export const replaceICleanDevice = async (
  oldDeviceId: string,
  newDeviceId: string,
  reasonForReplacement: string | null
) => {
  try {
    const response = await axios.post(`${API_URL}/iclean/device/replace`, {
      oldDeviceId,
      newDeviceId,
      reasonForReplacement
    });
    return response.data;
  } catch (error) {
    console.error("Error replacing IClean device:", error);
    throw error;
  }
};

export const getICleanReplacementHistory = async (deviceId: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/iclean/device/replacement-history`,
      {
        deviceId
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching IClean replacement history:", error);
    throw error;
  }
};

export const sendDeviceInstallationEmail = async (
  email: string,
  deviceName: string
) => {
  try {
    const response = await axios.post(
      `${API_URL}/mailing/send-device-installation-email`,
      {
        email,
        deviceName
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending device installation email:", error);
    throw error;
  }
};

export const sendOldICleanlinesDeviceEmail = async (
  email: string,
  deviceName: string
) => {
  try {
    const response = await axios.post(
      `${API_URL}/mailing/send-device-old-icleanlines-email`,
      {
        email,
        deviceName
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending old ICleanlines device email:", error);
    throw error;
  }
};

export const fetchCoordinatesFromAddress = async (
  address: string
): Promise<{ lat: number; lng: number }> => {
  try {
    // Make a request to your backend API endpoint
    const response = await axios.get(`${API_URL}/geolocation/geocode`, {
      params: { address }
    });

    if (response.data.lat && response.data.lng) {
      return { lat: response.data.lat, lng: response.data.lng };
    } else {
      throw new Error("No results found");
    }
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    throw error;
  }
};

export const fetchDeviceInstaller = async (deviceId: string) => {
  try {
    const response = await axios.post(`${API_URL}/device/installer`, {
      deviceId
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching device installer:", error);
    throw error;
  }
};

export const updateDeviceNotifications = async (
  deviceId: string,
  emailNotifications: EmailNotificationTypes[]
): Promise<any> => {
  try {
    const response = await axios.patch(`${API_URL}/notifications/${deviceId}`, {
      emailNotifications
    });
    return response.data; // Return the response data
  } catch (error: any) {
    console.error("Error updating device notifications:", error);
    throw new Error(
      error.response?.data?.message || "Failed to update device notifications"
    );
  }
};

// ========= iCleanLines =========

// fetch all iCleanLines devices
export const fetchIcleanLinesDevices = async (): Promise<
  ICleanLinesDeviceData[]
> => {
  const response = await fetch(`${ICLEANLINES_API_URL}/device/all`);
  if (!response.ok) {
    throw Error("Failed to fetch devices");
  }
  return response.json();
};

export const setFlushCycle = async (serial: string, params: any) => {
  try {
    const response = await axios.post(`${ICLEANLINES_API_URL}/ota/flush`, {
      serial,
      params
    });
    return response.data;
  } catch (error) {
    console.error("Error setting flush cycle:", error);
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data.error;
    } else {
      throw error;
    }
  }
};

export const deleteFlushCycle = async (serial: string, setName: string) => {
  try {
    const response = await axios.post(
      `${ICLEANLINES_API_URL}/ota/delete-flush`,
      {
        serial,
        setName
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting flush cycle:", error);
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data.error;
    } else {
      throw error;
    }
  }
};

export const fetchIcleanLinesDeviceFlushes = async (): Promise<
  DeviceFlushData[]
> => {
  try {
    const response = await axios.get(`${ICLEANLINES_API_URL}/device-flush/all`);
    return response.data;
  } catch (error) {
    console.error("Error fetching flushes:", error);
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data.error;
    } else {
      throw error;
    }
  }
};
