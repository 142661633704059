import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Autocomplete,
  Chip,
  IconButton,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  ProductWarranty,
  DeepPartial
} from "../../../common/types/ProductWarrantyTypes";
import {
  LotusProErrors,
  iCleanMiniErrors
} from "../../../common/types/ProductWarrantyTypes";
import StyledButton from "../../../shared/Components/Styled/StyledButton";

// Function to convert enums to key-value pairs
const enumToKeyValueArray = (enumObj: any) => {
  return Object.keys(enumObj).map((key) => ({
    code: key,
    description: enumObj[key]
  }));
};

// Function to get relevant errors based on the selected device model
const getErrorCodeForDeviceModel = (deviceModel: string) => {
  const lotusProModels = [
    "Lotus PRO SAO Dispenser (LQFC225K)",
    "Lotus PRO SAO Dispenser (LQFC225K-FB)",
    "Lotus PRO SAO Dispenser (LQFC275-FB)",
    "Lotus PRO SAO Dispenser (LQFC275)"
  ];

  const iCleanMiniModels = [
    "iClean Mini (LQFC200)",
    "iClean PRO (LQFC300)",
    "SAO2GO (LQFC400)",
    "SAO2GO (LQFC401)",
    "iClean Flo (LQFC5XX)"
  ];

  if (lotusProModels.includes(deviceModel)) {
    return enumToKeyValueArray(LotusProErrors); // Convert LotusProErrors enum to key-value array
  } else if (iCleanMiniModels.includes(deviceModel)) {
    return enumToKeyValueArray(iCleanMiniErrors); // Convert iCleanMiniErrors enum to key-value array
  }

  return []; // Default to empty array if no matching device model
};

interface EditWarrantyModalProps {
  open: boolean;
  onClose: () => void;
  selectedWarranty: ProductWarranty | null;
  warrantyForm: DeepPartial<ProductWarranty>;
  handleUpdateWarranty: (event: any) => void;
  handleConfirm: () => void;
  handleStatusChange: (event: any) => void;
}

const EditWarrantyModal: React.FC<EditWarrantyModalProps> = ({
  open,
  onClose,
  selectedWarranty,
  warrantyForm,
  handleUpdateWarranty,
  handleConfirm,
  handleStatusChange
}) => {
  // Get the device model from the selected warranty
  const deviceModel =
    selectedWarranty?.productSiteInformation?.deviceModel || "";

  // Fetch the error codes based on the device model
  const availableErrors = getErrorCodeForDeviceModel(deviceModel);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="Edit Warranty Modal">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "70%", md: "40%" },
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "#f5f5f5",
          p: { xs: 2, sm: 3, md: 3 },
          fontFamily: "'Poppins', sans-serif",
          borderRadius: 1
        }}
      >
        {/* Modal Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              color: "grey.800",
              textAlign: "center"
            }}
          >
            Warranty Details
          </Typography>
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon sx={{ color: "primary.main" }} />
          </IconButton>
        </Box>

        {/* Warranty Details Form */}
        {selectedWarranty && (
          <Paper
            elevation={1}
            sx={{ marginBottom: 2, backgroundColor: "#fff", p: 1 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Serial No"
                  name="serialNumber"
                  value={warrantyForm.productSiteInformation?.serialNumber}
                  onChange={handleUpdateWarranty}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Distributor"
                  name="email"
                  value={warrantyForm.distributorInformation?.email}
                  onChange={handleUpdateWarranty}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Company"
                  name="company"
                  value={warrantyForm.productSiteInformation?.company}
                  onChange={handleUpdateWarranty}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel>Return Category</InputLabel>
                  <Select
                    label="Return Category"
                    name="returnReason"
                    value={warrantyForm.faultDescription?.returnReason}
                    onChange={handleUpdateWarranty}
                    fullWidth
                    //margin="normal"
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Return & Repair">Return & Repair</MenuItem>
                    <MenuItem value="High Priority">High Priority</MenuItem>
                    <MenuItem value="Low Flow">Low Flow</MenuItem>
                    <MenuItem value="Service">Service</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  select
                  label="Device Model"
                  name="deviceModel"
                  value={warrantyForm.productSiteInformation?.deviceModel}
                  onChange={handleUpdateWarranty}
                  sx={{ mt: 2 }}
                  SelectProps={{ native: true }}
                >
                  <option value="" />
                  <option value="Lotus PRO SAO Dispenser (LQFC225K)">
                    Lotus PRO SAO Dispenser (LQFC225K)
                  </option>
                  <option value="Lotus PRO SAO Dispenser (LQFC225K-FB)">
                    Lotus PRO SAO Dispenser (LQFC225K-FB)
                  </option>
                  <option value="Lotus PRO SAO Dispenser (LQFC275-FB)">
                    Lotus PRO SAO Dispenser (LQFC275-FB)
                  </option>
                  <option value="Lotus PRO SAO Dispenser (LQFC275)">
                    Lotus PRO SAO Dispenser (LQFC275)
                  </option>
                  <option value="iClean Mini (LQFC200)">
                    iClean Mini (LQFC200)
                  </option>
                  <option value="iClean PRO (LQFC300)">
                    iClean PRO (LQFC300)
                  </option>
                  <option value="SAO2GO (LQFC400)">SAO2GO (LQFC400)</option>
                  <option value="SAO2GO (LQFC401)">SAO2GO (LQFC401)</option>
                  <option value="iClean Flo (LQFC5XX)">
                    iClean Flo (LQFC5XX)
                  </option>
                </TextField>
              </Grid>

              {/* Autocomplete for Error Codes */}
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  limitTags={3}
                  options={availableErrors} // Dynamically load error codes based on the device model
                  getOptionLabel={(option) =>
                    option ? `${option.code} - ${option.description}` : ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.code === value?.code
                  }
                  value={
                    warrantyForm.faultDescription?.errorCode &&
                    Array.isArray(warrantyForm.faultDescription?.errorCode)
                      ? warrantyForm.faultDescription.errorCode
                      : []
                  }
                  onChange={(_event, value) => {
                    handleUpdateWarranty({
                      target: {
                        name: "errorCode",
                        value
                      }
                    });
                  }}
                  renderTags={(selectedErrors, getTagProps) =>
                    selectedErrors.map((selectedError, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={
                          selectedError ? `${selectedError.description}` : ""
                        }
                        size="small"
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Device Error"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      multiline
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            height: 190
                          }
                        },
                        "& .MuiOutlinedInput-multiline": {
                          padding: 0
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: 0
                        }
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Detailed Issue"
                  name="description"
                  value={warrantyForm.faultDescription?.description}
                  onChange={handleUpdateWarranty}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12} sm={6} container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontWeight: 500, mt: 1 }}>
                    Warranty Status:
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500, mt: 2.5 }}>
                    Credit Status:
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500, mt: 2 }}>
                    Replacement Unit:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <RadioGroup
                    row
                    aria-label="warrantyType"
                    name="warrantyType"
                    value={warrantyForm.faultDescription?.warrantyType}
                    onChange={handleUpdateWarranty}
                  >
                    <FormControlLabel
                      value="IN"
                      control={<Radio />}
                      label="IN"
                    />
                    <FormControlLabel
                      value="OUT"
                      control={<Radio />}
                      label="OUT"
                      sx={{ ml: "1px" }}
                    />
                  </RadioGroup>
                  <RadioGroup
                    row
                    aria-label="creditStatus"
                    name="creditStatus"
                    value={warrantyForm.faultDescription?.creditStatus}
                    onChange={handleUpdateWarranty}
                  >
                    <FormControlLabel
                      value="YES"
                      control={<Radio />}
                      label="YES"
                    />
                    <FormControlLabel
                      value="NO"
                      control={<Radio />}
                      label="NO"
                    />
                  </RadioGroup>
                  <RadioGroup
                    row
                    aria-label="replacementUnit"
                    name="replacementUnit"
                    value={warrantyForm.faultDescription?.replacementUnit}
                    onChange={handleUpdateWarranty}
                  >
                    <FormControlLabel
                      value="YES"
                      control={<Radio />}
                      label="YES"
                    />
                    <FormControlLabel
                      value="NO"
                      control={<Radio />}
                      label="NO"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Credit Amount"
                  name="creditAmount"
                  value={warrantyForm.faultDescription?.creditAmount}
                  onChange={handleUpdateWarranty}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item container xs={12} sm={12} spacing={1}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      label="Status"
                      name="status"
                      value={
                        warrantyForm.status as
                          | ""
                          | "Initial review"
                          | "Technical assessment"
                          | "Approved"
                          | "Closed"
                          | "Rejected"
                          | undefined
                      }
                      onChange={handleStatusChange}
                    >
                      <MenuItem value="Initial review">Initial review</MenuItem>
                      <MenuItem value="Technical assessment">
                        Technical Assessment
                      </MenuItem>
                      <MenuItem value="Approved">Approved</MenuItem>
                      <MenuItem value="Closed">Closed</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {warrantyForm.status === "Rejected" && (
                  <Grid item xs={12} sm={8}>
                    <TextField
                      label="Comment"
                      name="comment"
                      value={warrantyForm.comment}
                      onChange={handleUpdateWarranty}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      multiline
                    />
                  </Grid>
                )}
              </Grid>
              {selectedWarranty.faultDescription.photo && (
                <Grid item xs={12}>
                  <img
                    src={selectedWarranty.faultDescription.photo}
                    alt="Claim"
                    style={{ width: "100%", marginTop: "10px" }}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        )}

        {/* Confirm Button */}
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <StyledButton
            onClick={handleConfirm}
            variantType="primary"
            size="large"
          >
            Confirm
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditWarrantyModal;
