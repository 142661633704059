import React from "react";
import { Box, Typography } from "@mui/material";
import { DeviceData } from "../../common/types/DeviceTypes";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

type FinishProps = {
  createdDevice: DeviceData | null;
  pingStatus: string | null;
};

const Finish: React.FC<FinishProps> = ({ createdDevice, pingStatus }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 1
        }}
      >
        {createdDevice === null ? (
          <>
            <HighlightOffIcon
              sx={{ color: "error.main", fontSize: 50, mb: 1 }}
            />
            <Typography
              sx={{
                color: "error.main",
                textAlign: "center",
                mb: 2,
                fontWeight: 700
              }}
              variant="h5"
            >
              Device Setup Incomplete
            </Typography>
            <Typography sx={{ textAlign: "center", mb: 2 }} variant="body1">
              You need to register a device first, please repeat Step 2.
            </Typography>
          </>
        ) : (
          <>
            {pingStatus === "ONLINE" ? (
              <>
                <CheckCircleOutlineIcon
                  sx={{ color: "success.main", fontSize: 50, mb: 1 }}
                />
                <Typography
                  sx={{
                    color: "success.main",
                    textAlign: "center",
                    mb: 2,
                    fontWeight: 700
                  }}
                  variant="h5"
                >
                  {" "}
                  Device Setup Complete
                </Typography>
              </>
            ) : (
              <>
                <HighlightOffIcon
                  sx={{ color: "error.main", fontSize: 50, mb: 1 }}
                />{" "}
                <Typography
                  sx={{
                    color: "error.main",
                    textAlign: "center",
                    mb: 2,
                    fontWeight: 700
                  }}
                  variant="h5"
                >
                  {" "}
                  Device Setup Incomplete
                </Typography>
                <Typography
                  sx={{
                    color: "error.main",
                    textAlign: "center",
                    mb: 2,
                    fontWeight: 700
                  }}
                  variant="subtitle2"
                >
                  {" "}
                  Wait for a 10 seconds for device to receive ping for
                  connection confirmation. <br />
                  If the device setup has still not marked complete, verify from
                  dashboard if the device is online
                </Typography>
              </>
            )}
            {pingStatus === "ONLINE" ? (
              <Typography sx={{ textAlign: "center", mb: 1 }} variant="h6">
                setup completed successfully and device is online. Click on
                Finish to complete the installation.
              </Typography>
            ) : (
              <Typography sx={{ textAlign: "center", mb: 1 }} variant="h6">
                The device was unable to connect to network, please repeat Step
                3.
              </Typography>
            )}

            <Typography>
              Device Name: {createdDevice?.deviceInformation.deviceName}
            </Typography>
            <Typography>
              Device ID: {createdDevice?.deviceInformation.deviceId}
            </Typography>

            <>
              <Typography>Ping Status: {pingStatus}</Typography>
              {pingStatus === "ONLINE" ? (
                <Box display="flex" alignItems="center" mt={2} mb={1}>
                  <Typography variant="h6" color="green" ml={2}>
                    Device is online
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" mt={2}>
                  <Typography variant="h6" color="red" ml={2}>
                    Device is offline
                  </Typography>
                </Box>
              )}
            </>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Finish;
