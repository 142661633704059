import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Modal,
  Typography,
  Grid,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Stepper,
  Step,
  StepLabel,
  FormHelperText,
  useTheme
} from "@mui/material";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";
import CloseIcon from "@mui/icons-material/Close";
import PublicIcon from "@mui/icons-material/Public";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import HomeIcon from "@mui/icons-material/Home";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import useAuthStore from "../../../store/authStore";
import {
  createICleanDevice,
  fetchCoordinatesFromAddress
} from "../../../services/device.service";
import {
  fetchCustomersForUser,
  addCustomer
} from "../../../services/customer.service";
import {
  DeviceCreationPayload,
  DeviceLocation
} from "../../../common/types/DeviceTypes";
import { StyledTextFieldNew } from "../../../shared/Components/Styled/StyledTextFieldNew";
import { countryLabels } from "../../../common/types/constants";
import { Customer } from "../../../store/useCustomer";
import { Person } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import StyledIconButton from "../../../shared/Components/Styled/StyledIconButton";

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: (createdDevice: any) => void;
  onError: (message: string) => void;
}

type CustomerOption = Customer | { email: string; label: string };

const steps = ["Device Information", "Device Location"];

const RegisterDeviceModal: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  onError
}) => {
  const { user } = useAuthStore();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [incompleteFields, setIncompleteFields] = useState<string[]>([]);

  const [customerList, setCustomerList] = useState<Customer[]>([]);
  const [newCustomer, setNewCustomer] = useState<Partial<Customer>>({
    firstName: "",
    lastName: "",
    email: ""
  });
  const [formData, setFormData] = useState<
    DeviceCreationPayload & DeviceLocation
  >({
    deviceId: "",
    serial: "",
    deviceName: "",
    customerEmail: "",
    installerEmail: "",
    deviceType: "",
    country: "",
    state: "",
    city: "",
    address: "",
    postalCode: "",
    latitude: 0,
    longitude: 0,
    room: "",
    door: "",
    block: "",
    building: "",
    additionalDetails: "",
    installationCartridgeType: ""
  });
  const [isAddingCustomer, setIsAddingCustomer] = useState<boolean>(false);
  const customerOptions: CustomerOption[] = [
    { email: "New Customer", label: "New Customer" }, // Special option at the top
    ...customerList // Existing customers
  ];
  const { enqueueSnackbar } = useSnackbar(); // Add this line to use notistack

  useEffect(() => {
    const fetchCustomers = async () => {
      if (user?._id) {
        const customers = await fetchCustomersForUser();
        setCustomerList(customers);
      }
    };
    fetchCustomers();
  }, [user?._id]);

  const handleChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    const target = event.target as HTMLInputElement | HTMLSelectElement;
    const name = target.name as keyof typeof formData;
    const value = target.value;

    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));

    if (incompleteFields.includes(name)) {
      setIncompleteFields(incompleteFields.filter((field) => field !== name));
    }
  };

  const handleCustomChange = (event: {
    target: { name: string; value: string };
  }) => {
    handleChange(event as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  const fetchGeolocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            setFormData((prev) => ({
              ...prev,
              latitude,
              longitude
            }));
          } catch (error) {
            onError("Failed to fetch location data.");
          }
        },
        (error) => {
          console.error("Geolocation not permitted or not available:", error);
        }
      );
    } else {
      onError("Geolocation is not supported by this browser.");
    }
  };

  const validateStep = () => {
    const requiredFieldsStep1 = [
      "serial",
      "deviceName",
      "customerEmail",
      "installationCartridgeType"
    ];
    const requiredFieldsStep2 = ["country", "state", "city", "address"];

    // Additional required fields when adding a new customer
    const newCustomerFields = [
      { field: "newCustomerFirstName", value: newCustomer.firstName },
      { field: "newCustomerLastName", value: newCustomer.lastName },
      { field: "newCustomerEmail", value: newCustomer.email }
    ];

    let incomplete: string[] = [];

    // Validate Step 1 fields
    if (activeStep === 0) {
      // Regular fields
      incomplete = requiredFieldsStep1.filter(
        (field) => !formData[field as keyof typeof formData]
      );

      // Add validation for new customer fields if applicable
      if (isAddingCustomer) {
        incomplete = [
          ...incomplete,
          ...newCustomerFields
            .filter((field) => !field.value) // Check if the field's value is empty
            .map((field) => field.field) // Return the field name
        ];
      }
    } else if (activeStep === 1) {
      // Validate Step 2 fields
      incomplete = requiredFieldsStep2.filter(
        (field) => !formData[field as keyof typeof formData]
      );
    }

    setIncompleteFields(incomplete);

    if (incomplete.length > 0) {
      enqueueSnackbar(
        `Please fill all required fields: ${incomplete.join(", ")}`,
        { variant: "error" }
      );
    }

    return incomplete.length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      if (activeStep === steps.length - 1) {
        handleSubmit();
      } else {
        setActiveStep((prev) => prev + 1);
        if (activeStep === 0) {
          fetchGeolocation();
        }
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleSubmit = async () => {
    // Use geolocation if available, otherwise use address-based geocoding
    if (formData.latitude === 0 && formData.longitude === 0) {
      const { country, state, city, address, postalCode } = formData;
      const fullAddress = `${address}, ${city}, ${state}, ${postalCode}, ${country}`;

      try {
        const { lat, lng } = await fetchCoordinatesFromAddress(fullAddress);
        formData.latitude = lat;
        formData.longitude = lng;
      } catch (error) {
        console.log("Failed to fetch location data from address.");
        enqueueSnackbar("Failed to fetch location data from address.", {
          variant: "error"
        });
      }
    }

    // Create the customer if needed
    if (isAddingCustomer && newCustomer) {
      const newCustomerPayload = {
        firstName: newCustomer.firstName || "",
        lastName: newCustomer.lastName || "",
        email: formData.customerEmail,
        role: "Customer",
        distributorEmail: user?.email || ""
      };

      try {
        const customerResponse = await addCustomer(newCustomerPayload);

        // Show success Snackbar for customer creation
        enqueueSnackbar("Customer created successfully!", {
          variant: "success"
        });
      } catch (error: any) {
        console.error("Error creating customer:", error);
        if (error.response) {
          console.error("Backend response data:", error.response.data.details);
        }

        // Show error Snackbar for customer creation failure
        enqueueSnackbar(
          `Error creating customer: ${
            error.response ? error.response.data.details : error.message
          }`,
          { variant: "error" }
        );

        // Return early since we don't want to proceed with device registration if customer creation failed
        return;
      }
    }

    // Proceed with device registration after successful customer creation
    const payload = {
      deviceInformation: {
        deviceId: formData.serial,
        serial: formData.serial,
        deviceName: formData.deviceName,
        customerEmail: isAddingCustomer
          ? newCustomer?.email
          : formData.customerEmail,
        installerEmail: user?.email.toLowerCase(),
        installationCartridgeType: formData.installationCartridgeType
      },
      deviceLocation: {
        latitude: formData.latitude,
        longitude: formData.longitude,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        address: formData.address,
        postalCode: formData.postalCode,
        room: formData.room,
        door: formData.door,
        block: formData.block,
        building: formData.building,
        additionalDetails: formData.additionalDetails
      }
    };

    try {
      const response = await createICleanDevice(payload);

      // Show success Snackbar for device registration
      enqueueSnackbar("Device registered successfully!", {
        variant: "success"
      });

      onSuccess(response.device);
    } catch (error: any) {
      console.error("Error registering device:", error);
      if (error.response) {
        console.error("Backend response data:", error.response.data);
      }

      // Show error Snackbar for device registration failure
      enqueueSnackbar(
        `Error registering device: ${
          error.response ? error.response.data.details : error.message
        }`,
        { variant: "error" }
      );
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "70%", md: "40%" },
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "#f5f5f5",
          p: { xs: 2, sm: 3, md: 3 },
          borderRadius: 1
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 500, color: "grey.800", textAlign: "center" }}
          >
            Register New Device
          </Typography>
          <StyledIconButton
            variantType="transparent"
            iconColor={theme.palette.primary.main}
            onClick={onClose}
          >
            <CloseIcon />
          </StyledIconButton>
        </Box>

        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 2 }}>
          {activeStep === 0 && (
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <StyledTextFieldNew
                    label="Serial Number"
                    name="serial"
                    onChange={handleChange}
                    value={formData.serial}
                    required
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKeyIcon />
                        </InputAdornment>
                      )
                    }}
                    error={incompleteFields.includes("serial")}
                    helperText={
                      incompleteFields.includes("serial")
                        ? "This field is required"
                        : ""
                    }
                  />
                  <FormHelperText>
                    Enter the serial number located on the dispenser bottom.
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextFieldNew
                    label="Device Name"
                    name="deviceName"
                    onChange={handleChange}
                    value={formData.deviceName}
                    required
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <WorkIcon />
                        </InputAdornment>
                      )
                    }}
                    error={incompleteFields.includes("deviceName")}
                    helperText={
                      incompleteFields.includes("deviceName")
                        ? "This field is required"
                        : ""
                    }
                  />
                  <FormHelperText>
                    Give your device name that can help you easily locate and
                    identify it on the dashboard
                  </FormHelperText>{" "}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={customerOptions}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={"label" in option ? option.label : option.email}
                        style={{
                          fontWeight: "label" in option ? 700 : 400, // Bold font for "New customer"
                          color:
                            "label" in option
                              ? "rgba(30, 58, 138, 1)"
                              : "inherit" // Blue color for "New customer"
                        }}
                        className={props.className} // Allows hover effect from props
                      >
                        {"label" in option
                          ? option.label
                          : `${option.firstName} ${option.lastName} - ${option.email}`}
                      </li>
                    )}
                    getOptionLabel={(option) =>
                      "label" in option ? option.label : option.email
                    }
                    isOptionEqualToValue={(option, value) => {
                      // Custom equality check to ensure "New Customer" matches
                      if ("label" in option && "label" in value) {
                        return option.label === value.label;
                      }
                      return option.email === value.email;
                    }}
                    value={
                      isAddingCustomer
                        ? { email: "", label: "New Customer" }
                        : customerList.find(
                            (c) => c.email === formData.customerEmail
                          ) || null
                    } // Properly manage the value
                    onChange={(_, newValue) => {
                      if (newValue?.email === "New Customer") {
                        setIsAddingCustomer(true); // Trigger the state to show additional fields
                        setFormData((prev) => ({
                          ...prev,
                          customerEmail: "" // Clear the customer email to avoid confusion
                        }));
                      } else {
                        setIsAddingCustomer(false);
                        setFormData((prev) => ({
                          ...prev,
                          customerEmail: newValue ? newValue.email : "" // Update the form data
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <StyledTextFieldNew
                        {...params}
                        label="Customer Email"
                        name="customerEmail"
                        required
                        fullWidth
                        margin="normal"
                        InputProps={{
                          ...params.InputProps, // Spread default props to ensure all are included
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                  <FormHelperText>
                    Select an already existing customer or create a new customer
                  </FormHelperText>{" "}
                </Grid>
                {isAddingCustomer && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <StyledTextFieldNew
                        label="New Customer First Name"
                        name="newCustomerFirstName"
                        onChange={(event) => {
                          const firstName = event.target.value;
                          setNewCustomer((prev) => ({ ...prev, firstName })); // Correctly update first name
                          if (incompleteFields.includes(event.target.name)) {
                            setIncompleteFields(
                              incompleteFields.filter(
                                (field) => field !== event.target.name
                              )
                            );
                          }
                        }}
                        value={newCustomer?.firstName || ""}
                        required
                        fullWidth
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person />
                            </InputAdornment>
                          )
                        }}
                        error={incompleteFields.includes(
                          "newCustomerFirstName"
                        )}
                        helperText={
                          incompleteFields.includes("newCustomerFirstName")
                            ? "This field is required"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledTextFieldNew
                        label="New Customer Last Name"
                        name="newCustomerLastName"
                        onChange={(event) => {
                          const lastName = event.target.value;
                          setNewCustomer((prev) => ({ ...prev, lastName })); // Correctly update last name
                          if (incompleteFields.includes(event.target.name)) {
                            setIncompleteFields(
                              incompleteFields.filter(
                                (field) => field !== event.target.name
                              )
                            );
                          }
                        }}
                        value={newCustomer?.lastName || ""}
                        required
                        fullWidth
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person />
                            </InputAdornment>
                          )
                        }}
                        error={incompleteFields.includes("newCustomerLastName")}
                        helperText={
                          incompleteFields.includes("newCustomerLastName")
                            ? "This field is required"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledTextFieldNew
                        label="New Customer Email"
                        name="newCustomerEmail"
                        onChange={(event) => {
                          const email = event.target.value;
                          setNewCustomer((prev) => ({ ...prev, email }));
                          setFormData((prev) => ({
                            ...prev,
                            customerEmail: email // Set customerEmail to newCustomerEmail value
                          }));
                        }}
                        value={newCustomer?.email || ""}
                        required
                        fullWidth
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel
                      id="installation-cartridge-type-label"
                      sx={{ fontWeight: 700, fontSize: "14px" }}
                    >
                      Installation Cartridge Type
                    </InputLabel>
                    <Select
                      labelId="installation-cartridge-type-label"
                      label="Installation Cartridge Type"
                      name="installationCartridgeType"
                      value={formData.installationCartridgeType}
                      onChange={handleChange}
                      displayEmpty
                      required
                      startAdornment={
                        <InputAdornment position="start">
                          <DeviceHubIcon />
                        </InputAdornment>
                      }
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="SAO-24">SAO-24</MenuItem>
                      <MenuItem value="SAO-4">SAO-4</MenuItem>
                      <MenuItem value="AO/Desiccant">
                        AO/Desiccant Only
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}

          {activeStep === 1 && (
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={countryLabels}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) =>
                      handleCustomChange({
                        target: {
                          name: "country",
                          value: value ? value.label : ""
                        }
                      })
                    }
                    value={
                      countryLabels.find(
                        (option) => option.label === formData.country
                      ) || null
                    }
                    renderInput={(params) => (
                      <StyledTextFieldNew
                        {...params}
                        label="Country"
                        name="country"
                        required
                        fullWidth
                        margin="normal"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <PublicIcon />
                            </InputAdornment>
                          )
                        }}
                        error={incompleteFields.includes("country")}
                        helperText={
                          incompleteFields.includes("country")
                            ? "This field is required"
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextFieldNew
                    label="State/Province"
                    name="state"
                    required
                    onChange={handleChange}
                    value={formData.state}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PublicIcon />
                        </InputAdornment>
                      )
                    }}
                    error={incompleteFields.includes("state")}
                    helperText={
                      incompleteFields.includes("state")
                        ? "This field is required"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextFieldNew
                    label="City"
                    name="city"
                    required
                    onChange={handleChange}
                    value={formData.city}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationCityIcon />
                        </InputAdornment>
                      )
                    }}
                    error={incompleteFields.includes("city")}
                    helperText={
                      incompleteFields.includes("city")
                        ? "This field is required"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextFieldNew
                    label="Street Address"
                    name="address"
                    onChange={handleChange}
                    value={formData.address}
                    fullWidth
                    required
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HomeIcon />
                        </InputAdornment>
                      )
                    }}
                    error={incompleteFields.includes("address")}
                    helperText={
                      incompleteFields.includes("address")
                        ? "This field is required"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextFieldNew
                    label="Postal Code"
                    name="postalCode"
                    onChange={handleChange}
                    value={formData.postalCode}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocalPostOfficeIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextFieldNew
                    label="Room"
                    name="room"
                    onChange={handleChange}
                    value={formData.room}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextFieldNew
                    label="Door"
                    name="door"
                    onChange={handleChange}
                    value={formData.door}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextFieldNew
                    label="Block"
                    name="block"
                    onChange={handleChange}
                    value={formData.block}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextFieldNew
                    label="Building"
                    name="building"
                    onChange={handleChange}
                    value={formData.building}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextFieldNew
                    label="Additional Details"
                    name="additionalDetails"
                    onChange={handleChange}
                    value={formData.additionalDetails}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            alignItems: "center",
            gap: 1
          }}
        >
          <StyledButton disabled={activeStep === 0} onClick={handleBack}>
            Back
          </StyledButton>
          <StyledButton onClick={onClose} variantType="outline-red">
            Cancel
          </StyledButton>
          <StyledButton onClick={handleNext} variantType="primary">
            {activeStep === steps.length - 1 ? "Submit" : "Next"}
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default RegisterDeviceModal;
