import axios from "axios";
import {
  AddedInventory,
  InventoryPart
} from "../common/types/InventoryPartTypes";

const API_URL = process.env.REACT_APP_API_URL || "";

// Fetch all inventory parts
export const fetchInventoryParts = async (): Promise<InventoryPart[]> => {
  try {
    const response = await axios.get(`${API_URL}/inventory-parts/all`);
    return response.data;
  } catch (error) {
    console.error("Error fetching inventory parts:", error);
    throw new Error("Failed to fetch inventory parts");
  }
};

// Add a new inventory part
export const addInventoryPart = async (part: {
  name: string;
  number: string;
}): Promise<InventoryPart> => {
  try {
    const response = await axios.post(`${API_URL}/inventory-parts`, part);
    return response.data;
  } catch (error) {
    console.error("Error adding inventory part:", error);
    throw new Error("Failed to add inventory part");
  }
};

// Fetch added parts history
export const fetchAddedPartsHistory = async (): Promise<AddedInventory[]> => {
  try {
    const response = await axios.get(`${API_URL}/added-inventory/all`);
    return response.data;
  } catch (error) {
    console.error("Error fetching added parts history:", error);
    throw new Error("Failed to fetch added parts history");
  }
};

// Add recovered parts to the backend
export const addRecoveredParts = async (
  recoveredParts: AddedInventory[]
): Promise<AddedInventory[]> => {
  try {
    const response = await axios.post(
      `${API_URL}/added-inventory`,
      recoveredParts
    );
    return response.data;
  } catch (error) {
    console.error("Error adding recovered parts:", error);
    throw new Error("Failed to add recovered parts");
  }
};
