import { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  useMediaQuery,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Snackbar,
  Alert,
  AlertColor,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";
import StyledIconButton from "../../../../shared/Components/Styled/StyledIconButton";
import AddCycleModal from "./AddCycleModal";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { deleteFlushCycle } from "../../../../services/device.service";
import { ICleanLinesDevice } from "../../../../common/types/DeviceTypes";
import useICleanLinesStore from "../../../../store/useICleanLinesStore";
import StyledButton from "../../../../shared/Components/Styled/StyledButton";
dayjs.extend(relativeTime);

interface AutoCycleComponentProps {
  selectedDevice: ICleanLinesDevice;
}

const AutoCycleComponent: React.FC<AutoCycleComponentProps> = ({
  selectedDevice
}) => {
  const { fetchICleanLinesDeviceFlushes, deviceFlushes } =
    useICleanLinesStore();
  const filteredFlushes = selectedDevice?.deviceInformation?.serial
    ? deviceFlushes.find(
        (flush) => flush.serial === selectedDevice.deviceInformation.serial
      )
    : null;

  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isFlushLoading, setIsFlushLoading] = useState(false);
  const [flushToDelete, setFlushToDelete] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({ open: false, message: "", severity: "success" });
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleAddCycle = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSaveCycle = async (response: any) => {
    if (response === "success") {
      await fetchICleanLinesDeviceFlushes();
      setSnackbar({
        open: true,
        message: "Cycle added successfully",
        severity: "success"
      });
      setModalOpen(false);
    } else {
      setSnackbar({
        open: true,
        message: response,
        severity: "error"
      });
    }
  };

  const handleDeleteCycle = (setName: string) => {
    setDeleteModalOpen(true);
    setFlushToDelete(setName);
  };

  const confirmDeleteCycle = async () => {
    if (!flushToDelete) return;

    try {
      await deleteFlushCycle(
        selectedDevice.deviceInformation.serial,
        flushToDelete
      );

      setSnackbar({
        open: true,
        message: `Flush "${flushToDelete}" deleted successfully.`,
        severity: "success"
      });

      setIsFlushLoading(true);
      // Refresh the device flush data
      await fetchICleanLinesDeviceFlushes();
      setIsFlushLoading(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to delete flush "${flushToDelete}". ${error}`,
        severity: "error"
      });
      setIsFlushLoading(false);
    } finally {
      setDeleteModalOpen(false);
      setFlushToDelete(null);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setFlushToDelete(null);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        padding: 2,
        borderRadius: "8px",
        border: "1px solid #d1d1d1",
        backgroundColor: "background.paper",
        color: "text.primary",
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        alignItems={isMobile ? "flex-start" : "center"}
        justifyContent={isMobile ? "center" : "space-between"}
        pb={2}
        flexDirection={isMobile ? "column" : "row"}
        gap={1}
      >
        <Typography
          sx={{ flexShrink: 0 }}
          variant="h6"
          fontWeight="bold"
          textAlign="center"
        >
          Auto Cycle
        </Typography>
        <StyledIconButton onClick={handleAddCycle}>
          <AddIcon color="primary" />
        </StyledIconButton>
      </Box>

      {/* Device Cycle History */}
      <Box
        sx={{
          maxHeight: "100px",
          overflowY: "auto",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          padding: 1,
          scrollSnapType: "y mandatory", // Enable snapping
          "&::-webkit-scrollbar": {
            width: "8px"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0.5)", // Scrollbar color
            borderRadius: "4px"
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgba(0,0,0,0.7)" // Hover effect
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "rgba(0,0,0,0.1)" // Track color
          },
          "& > *": {
            scrollSnapAlign: "start" // Snap to each list item
          }
        }}
      >
        {isFlushLoading ? (
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Typography>
        ) : (
          <>
            {filteredFlushes?.flushes?.length ? (
              <List>
                {filteredFlushes.flushes.map((flush, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      borderBottom: "1px solid #e0e0e0",
                      padding: "2px 4px",
                      height: "120px",
                      scrollSnapAlign: "start" // Ensure snapping alignment
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "primary.main",
                              fontWeight: "600"
                            }}
                          >
                            Time:{" "}
                            {flush.flushConfigs[0]?.time.h
                              .toString()
                              .padStart(2, "0")}
                            :
                            {flush.flushConfigs[0]?.time.m
                              .toString()
                              .padStart(2, "0")}
                            :
                            {flush.flushConfigs[0]?.time.s
                              .toString()
                              .padStart(2, "0")}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.secondary"
                            }}
                          >
                            Set: {flush.setName}
                          </Typography>
                        </>
                      }
                      secondary={
                        <>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Forward:{" "}
                            {flush.flushConfigs
                              .filter((config) => config.type === "forward")
                              .map((config) => `${config.duration}s`)
                              .join(", ")}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Backward:{" "}
                            {flush.flushConfigs
                              .filter((config) => config.type === "backward")
                              .map((config) => `${config.duration}s`)
                              .join(", ")}
                          </Typography>
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Grid item>
                          <StyledButton
                            variantType="outline-red"
                            onClick={() => handleDeleteCycle(flush.setName)}
                            size="small"
                          >
                            Delete
                          </StyledButton>
                        </Grid>

                        <Grid
                          item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row"
                          }}
                        >
                          <QueryBuilderTwoToneIcon
                            sx={{ fontSize: 14 }}
                            color="primary"
                          />
                          <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
                            {dayjs(flush.createdAt).fromNow()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2" textAlign="center">
                No cycles available.
              </Typography>
            )}
          </>
        )}
      </Box>

      {/* Add Cycle Modal */}
      <AddCycleModal
        selectedDevice={selectedDevice}
        onSave={handleSaveCycle} // Pass the save handler to the modal
        onClose={handleModalClose}
        open={isModalOpen}
      />

      {/* Delete Cycle Modal */}
      <Dialog open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
        <DialogTitle>Delete Cycle</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this cycle?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton variantType="outline-red" onClick={confirmDeleteCycle}>
            Delete
          </StyledButton>
          <StyledButton variantType="primary" onClick={handleDeleteModalClose}>
            Cancel
          </StyledButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={10000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AutoCycleComponent;
