import axios from "axios";
import { Customer } from "../store/useCustomer";
import useAuthStore from "../store/authStore";

const API_URL = process.env.REACT_APP_API_URL;

export const addCustomer = async (customer: {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  distributorEmail: string;
  customerGroup?: string;
}) => {
  try {
    const response = await axios.post(`${API_URL}/customer/add`, {
      ...customer
    });
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

// API function to retrieve a customer by email
export const fetchCustomerByEmail = async (
  email: string
): Promise<Customer> => {
  try {
    const response = await axios.get(`${API_URL}/customer/email`, {
      params: { email }
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch customer by email:", error);
    throw new Error("Failed to fetch customer");
  }
};

export const addAdditionalCustomersToDevice = async (
  deviceId: string,
  customers: { email: string; firstName: string; lastName: string }[]
) => {
  try {
    const response = await axios.post(
      `${API_URL}/customer/device/customers/add`,
      {
        deviceId,
        customers
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to add additional users to device:", error);
    throw new Error("Failed to add additional users to device");
  }
};

export const removeAdditionalCustomerFromDevice = async (
  deviceId: string,
  customerEmail: string
) => {
  try {
    const response = await axios.post(
      `${API_URL}/customer/device/customers/remove`,
      {
        deviceId,
        customerEmail
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to remove additional customer from device:", error);
    throw new Error("Failed to remove additional customer from device");
  }
};

export const fetchCustomersForUser = async (): Promise<any[]> => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.get(`${API_URL}/customer/fetch-customers`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch customers for user:", error);
    throw new Error("Failed to fetch customers");
  }
};

export const updateGroupUsers = async (
  customerGroupEmail: string,
  customerEmails: string[] // Updated to only accept an array of email strings
) => {
  try {
    // Make a PATCH request to update the group users
    const response = await axios.post(`${API_URL}/customer/group/assign`, {
      customerEmails, // Send the 'users' array as the payload
      customerGroupEmail
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Failed to update users in group:",
      error.response?.data || error.message
    );
    throw new Error("Failed to update users in group");
  }
};

export const changeCustomerStatus = async (
  customerId: string,
  status: string
) => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.post(
      `${API_URL}/customer/accountStatus`,
      {
        customerId,
        status
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
