import { Box, Divider, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { Customer } from "../../../../store/useCustomer";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const CustomersByCityChart: React.FC<{ customers: Customer[] }> = ({
  customers
}) => {
  const countByCountry = (data: any[], countryField: string) => {
    return data.reduce((acc: { [key: string]: number }, item) => {
      const country = item[countryField];
      if (!acc[country]) {
        acc[country] = 0;
      }
      acc[country]++;
      return acc;
    }, {});
  };

  const customersByCountry = countByCountry(customers, "city");

  // Passed index to make it so we can color the charts dynamically
  const prepareBarChartData = (
    data: { [key: string]: number },
    index: number
  ) => {
    return {
      labels: Object.keys(data),
      datasets: [
        {
          label: "Count",
          backgroundColor:
            index === 0 ? "rgba(30, 58, 138, 0.5)" : "rgba(30, 58, 138, 0.8)",
          data: Object.values(data)
        }
      ]
    };
  };

  const customersByCountryData = prepareBarChartData(customersByCountry, 0);

  const commonStyles = {
    borderRadius: "8px",
    border: "1px solid #d1d1d1",
    backgroundColor: "background.paper",
    color: "text.primary",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
  };

  return (
    <Box
      sx={{
        ...commonStyles,
        p: 2,
        height: "230px",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          mb: 1,
          fontWeight: "700"
        }}
      >
        Customers by City
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Bar
          data={customersByCountryData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false
                },
                ticks: {
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
                }
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                mode: "index",
                intersect: false
              }
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomersByCityChart;
