import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Alert,
  Box,
  TextField,
  Autocomplete
} from "@mui/material";
import { format } from "date-fns";
import {
  getAllFirmwares,
  updateFirmwareV2
} from "../../../services/firmware.service";
import { DeviceData } from "../../../common/types/DeviceTypes";
import { Firmware } from "../../../common/types/FirmwareTypes";
import StyledButton from "../../../shared/Components/Styled/StyledButton";

interface UpdateFirmwareTabProps {
  devices: DeviceData[];
}

const UpdateFirmwareTab: React.FC<UpdateFirmwareTabProps> = ({ devices }) => {
  const [firmwares, setFirmwares] = useState<Firmware[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<DeviceData | null>(null);

  useEffect(() => {
    const fetchFirmwareList = async () => {
      try {
        const firmwares = await getAllFirmwares();
        setFirmwares(firmwares);
      } catch (error) {
        console.error("Error fetching firmware list:", error);
      }
    };
    fetchFirmwareList();
  }, []);

  const handleSelectDevice = (
    _event: React.ChangeEvent<{}>,
    value: DeviceData | null
  ) => {
    setSelectedDevice(value);
  };

  const handleFirmwareUpdate = async (version: string) => {
    if (!selectedDevice) {
      setError("Please select a device.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await updateFirmwareV2(
        version,
        selectedDevice.deviceInformation.deviceId
      );
      setSuccess("OTA update triggered successfully");
    } catch (error) {
      setError("Error triggering OTA update");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2, backgroundColor: "white", mt: 2 }}>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}

      <Autocomplete
        options={devices}
        getOptionLabel={(option) =>
          `${option.deviceInformation.deviceName} (${option.deviceInformation.serial})`
        }
        style={{ marginBottom: 16, marginTop: 16 }}
        onChange={handleSelectDevice}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Device"
            variant="outlined"
            fullWidth
          />
        )}
      />

      <List>
        {firmwares.map((file, index) => (
          <ListItem key={index} divider>
            <ListItemText primary={"IoT-Firmware v." + file.version} />
            <ListItemText
              secondary={
                file.description +
                " - " +
                format(new Date(file.uploadedAt), "PPPppp")
              }
            />
            <ListItemSecondaryAction>
              <StyledButton
                variantType="primary"
                onClick={() => handleFirmwareUpdate(file.version)}
                disabled={loading || !file.isActive}
              >
                {loading ? <CircularProgress size={24} /> : "Update Firmware"}
              </StyledButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default UpdateFirmwareTab;
