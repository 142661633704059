import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { FluentProvider, createLightTheme } from "@fluentui/react-components";
import type { BrandVariants, Theme } from "@fluentui/react-components";

const tersanoTheme: BrandVariants = {
  10: "#030207",
  20: "#17132D",
  30: "#1D1F51",
  40: "#1D2B6E",
  50: "#1D3787",
  60: "#264596",
  70: "#3253A2",
  80: "#3F62AD",
  90: "#4D70B7",
  100: "#5C7FC1",
  110: "#6B8EC9",
  120: "#7C9DD1",
  130: "#8DACD9",
  140: "#9FBAE0",
  150: "#B2C9E7",
  160: "#C6D8EE"
};

const lightTheme: Theme = {
  ...createLightTheme(tersanoTheme)
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <FluentProvider theme={lightTheme}>
        <App />
      </FluentProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
