import {
  Box,
  Divider,
  Typography,
  Grid,
  useTheme,
  ListItemText,
  ListItemSecondaryAction,
  ListItemButton
} from "@mui/material";

import dayjs from "dayjs";
import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const RecentErrorsCard: React.FC<{
  deviceErrors: any[];
  isErrorsLoading: boolean;
}> = ({ deviceErrors, isErrorsLoading }) => {
  const theme = useTheme();
  const commonStyles = {
    borderRadius: "8px",
    border: "1px solid #d1d1d1",
    backgroundColor: "background.paper",
    color: "text.primary",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
  };

  return (
    <Box
      sx={{
        ...commonStyles,
        height: "260px",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          pt: 2,
          mb: 1,
          fontWeight: "700"
        }}
      >
        Most Recent Errors
      </Typography>

      <Divider sx={{ mb: 1 }} />
      <Box
        sx={{
          px: 2,
          pb: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflowY: "auto",
          height: "100%"
        }}
      >
        {isErrorsLoading ? (
          <CircularProgress />
        ) : deviceErrors.length === 0 ? (
          <Typography>You have no errors! Woo hoo!</Typography>
        ) : (
          deviceErrors.slice(0, 3).map((error, index) => (
            <ListItemButton
              key={index}
              sx={{
                width: "100%",
                borderBottom: `1px solid ${theme.palette.divider}`
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: "600"
                    }}
                  >
                    {error.error}
                  </Typography>
                }
                secondary={
                  <Typography variant="subtitle2">
                    {error.deviceName}
                  </Typography>
                }
              />
              <ListItemSecondaryAction
                sx={{ top: "50%", transform: "translateY(-50%)" }}
              >
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <QueryBuilderTwoToneIcon
                      sx={{
                        color: theme.palette.primary.main
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">
                      {dayjs(error.createdAt).fromNow()}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemSecondaryAction>
            </ListItemButton>
          ))
        )}
      </Box>
    </Box>
  );
};

export default RecentErrorsCard;
