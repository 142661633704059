import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import TokenExpiryChecker from "./utils/TokenExpiryChecker";
import MainLayout from "./pages/MainLayout";
import LoginPage from "./pages/Login";
import ManageDeviceTests from "./pages/TesterRoute/ManageDeviceTests";
import ProtectedRoute from "./utils/ProtectedRoute";
import axios from "axios";
import useAuthStore from "./store/authStore";
import { Snackbar, Alert } from "@mui/material";
import CheckSerialVersion from "./pages/CheckSerialVersion";
import TrackPageViews from "./utils/google-analytics";
import ForgotPassword from "./pages/ForgotPassword";
import CreateAccount from "./pages/CreateAccount";
import GettingStartedPage from "./pages/GettingStarted";
import WarrantyClaimForm from "./pages/WarrantyRoute/SubmitWarranty";

const GA_ID = process.env.REACT_APP_GA_TRACKING_ID;
const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
const App: React.FC = () => {
  const { snackbar, setSnackbar, token } = useAuthStore((state) => state);

  useEffect(() => {
    if (GA_ID) {
      ReactGA.initialize(GA_ID);
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }, [token]);

  return (
    <Router>
      <TrackPageViews />
      {token && <TokenExpiryChecker />}{" "}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Positioning at the top center
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/version-check" element={<CheckSerialVersion />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<CreateAccount />} />
        <Route path="/getting-started" element={<GettingStartedPage />} />
        <Route path="/warranty" element={<WarrantyClaimForm />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<MainLayout />} />
          <Route path="/tests" element={<ManageDeviceTests />} />
          {/* Other protected routes */}
        </Route>
        {/* Add additional routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
