import React from "react";
import { IconButton as MuiButton, ButtonProps, styled } from "@mui/material";

interface CustomButtonProps extends ButtonProps {
  variantType?:
    | "primary"
    | "destructive"
    | "outline-red"
    | "outline-primary"
    | "transparent";
  size?: "small" | "medium" | "large";
  icon?: React.ReactNode;
  iconColor?: string;
  customColor?: string;
  customHoverColor?: string;
}

const CustomButton = styled(MuiButton, {
  shouldForwardProp: (prop) =>
    !["variantType", "customColor", "customHoverColor", "iconColor"].includes(
      prop as string
    )
})<CustomButtonProps>(
  ({ theme, variantType, size, customColor, customHoverColor, iconColor }) => ({
    borderRadius: "4px",
    minWidth: "unset",
    width: size === "small" ? "24px" : size === "large" ? "40px" : "32px",
    height: size === "small" ? "24px" : size === "large" ? "40px" : "32px",
    fontSize: size === "small" ? "16px" : size === "large" ? "24px" : "20px",
    padding: 0,
    backgroundColor:
      customColor ||
      (variantType === "primary"
        ? theme.palette.primary.main
        : variantType === "destructive"
          ? theme.palette.error.main
          : variantType === "outline-red"
            ? "transparent"
            : variantType === "outline-primary"
              ? "transparent"
              : variantType === "transparent"
                ? "transparent"
                : theme.palette.background.paper),
    color:
      iconColor ||
      (variantType === "primary" || variantType === "destructive"
        ? "white"
        : variantType === "outline-red"
          ? theme.palette.error.main
          : variantType === "outline-primary"
            ? theme.palette.primary.main
            : theme.palette.text.primary),
    border:
      variantType === "outline-red"
        ? `1px solid ${theme.palette.error.main}`
        : variantType === "outline-primary"
          ? `1px solid ${theme.palette.primary.main}`
          : variantType === "transparent"
            ? "none"
            : "1px solid #d1d1d1",
    "&:hover": {
      backgroundColor:
        customHoverColor ||
        (variantType === "primary"
          ? "rgba(30, 58, 138, 0.9)"
          : variantType === "destructive"
            ? theme.palette.error.dark
            : variantType === "outline-red"
              ? theme.palette.action.hover
              : variantType === "outline-primary"
                ? theme.palette.action.hover
                : variantType === "transparent"
                  ? theme.palette.action.hover
                  : "#F5F5F5"),
      color:
        iconColor ||
        (variantType === "primary" || variantType === "destructive"
          ? "white"
          : variantType === "outline-red"
            ? theme.palette.error.main
            : variantType === "outline-primary"
              ? theme.palette.primary.main
              : theme.palette.text.primary),
      border:
        variantType === "outline-red"
          ? `1.5px solid ${theme.palette.error.main}`
          : variantType === "outline-primary"
            ? `1.5px solid ${theme.palette.primary.main}`
            : variantType === "transparent"
              ? "none"
              : "1px solid #c7c7c7",
      transform: "none"
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled
    },
    // Ripple effect when button is clicked and held
    "& .MuiTouchRipple-root": {
      borderRadius: "4px"
    },
    "& .MuiTouchRipple-ripple": {
      borderRadius: "4px"
    },
    "& .MuiTouchRipple-child": {
      borderRadius: "4px"
    }
  })
);

const StyledIconButton: React.FC<CustomButtonProps> = ({
  variantType,
  size = "medium",
  iconColor,
  customColor,
  customHoverColor,
  children,
  ...props
}) => {
  return (
    <CustomButton
      variantType={variantType}
      size={size}
      customColor={customColor}
      customHoverColor={customHoverColor}
      iconColor={iconColor}
      {...props}
    >
      {children}
    </CustomButton>
  );
};

export default StyledIconButton;
