import { useState, useEffect } from "react";
import { Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import DevicesIcon from "@mui/icons-material/Devices";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import PeopleIcon from "@mui/icons-material/People";
import Error from "@mui/icons-material/Error";
import { PortableWifiOff } from "@mui/icons-material";
import useCustomerStore from "../../../store/useCustomer";
import useDistributorStore from "../../../store/useDistributor";
import useAuthStore from "../../../store/authStore";
import { fetchTodaysDeviceErrors } from "../../../services/device.service";
import { DeviceData } from "../../../common/types/DeviceTypes";
import Map from "./Map";
import RecentErrorsCard from "./Cards/RecentErrorsCard";
import ReportCard from "./Cards/ReportCard";
import CartridgeLifeCard from "./Cards/CartridgeLifeCard";
import CustomersByCityChart from "./Charts/CustomersByCityChart";
import DistributorsByCountryChart from "./Charts/DistributorsByCountryChart";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const SummaryTab: React.FC<{ devices: DeviceData[] }> = ({ devices }) => {
  const { user, userRole } = useAuthStore.getState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { customers, fetchCustomersForUser } = useCustomerStore();
  const { Distributors, fetchDistributors, fetchInstaller } =
    useDistributorStore();

  const [onlineDeviceCount, setOnlineDeviceCount] = useState(0);
  const [offlineDeviceCount, setOfflineDeviceCount] = useState(0);
  const [deviceErrors, setDeviceErrors] = useState<any[]>([]);
  const [isErrorsLoading, setIsErrorsLoading] = useState(true);

  useEffect(() => {
    if (!user || !userRole) {
      console.error("User not logged in");
      return;
    }
    const fetchData = async () => {
      try {
        if (userRole !== "Customer") {
          await fetchCustomersForUser();
        }

        await fetchDistributors();
        await fetchInstaller(user.email, userRole);

        setIsErrorsLoading(true);
        const errors = await fetchTodaysDeviceErrors();
        setDeviceErrors(errors);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsErrorsLoading(false);
      }
    };
    fetchData();
  }, [
    user,
    userRole,
    fetchCustomersForUser,
    fetchDistributors,
    fetchInstaller
  ]);

  useEffect(() => {
    const offlineDevices = devices.filter(
      (device) => device.pingStatus.status === "OFFLINE"
    );

    const onlineDevices = devices.filter(
      (device) => device.pingStatus.status === "ONLINE"
    );

    setOfflineDeviceCount(offlineDevices.length);
    setOnlineDeviceCount(onlineDevices.length);
  }, [devices, customers, Distributors, offlineDeviceCount]);

  const renderStatsCards = () => {
    const latestError = deviceErrors[0]?.error
      ? `Latest Device Error: ${deviceErrors[0].error}`
      : ".";

    const defaultCards = [
      {
        icon: DevicesIcon,
        value: devices.length,
        label: "All Devices",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(30, 58, 138, 1)",
        iconBackgroundColor: "rgba(30, 58, 138, 0.1)"
      },
      {
        icon: PodcastsIcon,
        value: onlineDeviceCount,
        label: "Online Devices",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(59, 130, 246, 1)",
        iconBackgroundColor: "rgba(59, 130, 246, 0.1)"
      },
      {
        icon: PortableWifiOff,
        value: offlineDeviceCount,
        label: "Offline Devices",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(255, 159, 64, 1)",
        iconBackgroundColor: "rgba(255, 159, 64, 0.1)"
      },
      {
        icon: Error,
        value: deviceErrors.length,
        label: "Errors Today",
        changeLabel: latestError,
        isPositive: false,
        color: "rgba(239, 68, 68, 1)",
        iconBackgroundColor: "rgba(239, 68, 68, 0.1)"
      }
    ];

    const specificCards = [
      {
        icon: DevicesIcon,
        value: devices.length,
        label: "All Devices",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(30, 58, 138, 1)",
        iconBackgroundColor: "rgba(30, 58, 138, 0.1)"
      },
      {
        icon: PodcastsIcon,
        value: onlineDeviceCount,
        label: "Online Devices",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(59, 130, 246, 1)",
        iconBackgroundColor: "rgba(59, 130, 246, 0.1)"
      },

      {
        icon: PeopleIcon,
        value: customers.length,
        label: "Customers",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(255, 159, 64, 1)",
        iconBackgroundColor: "rgba(255, 159, 64, 0.1)"
      },
      {
        icon: Error,
        value: deviceErrors.length,
        label: "Errors Today",
        changeLabel: latestError,
        isPositive: false,
        color: "rgba(239, 68, 68, 1)",
        iconBackgroundColor: "rgba(239, 68, 68, 0.1)"
      }
    ];

    const cardsToRender = ["Admin", "SuperAdmin", "Distributor"].includes(
      userRole || ""
    )
      ? specificCards
      : defaultCards;

    return cardsToRender.map((card, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <ReportCard
          primary={card.value.toString()}
          secondary={card.label}
          iconPrimary={card.icon}
          color={card.color}
          iconBackgroundColor={card.iconBackgroundColor}
        />
      </Grid>
    ));
  };

  const isCustomerByCityFullWidth = ![
    "Admin",
    "SuperAdmin",
    "Distributor"
  ].includes(userRole || "");

  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: "0.75rem",
        height: "100%"
      }}
    >
      <Grid container spacing={isMobile ? 1 : 1.5}>
        {/* COLUMN 1 */}
        <Grid item xs={12} md={6} sx={{}}>
          <Grid container spacing={1.5}>
            {renderStatsCards()}

            <Grid item xs={12} sm={12}>
              <DistributorsByCountryChart
                Distributors={Distributors}
                devices={devices}
              />
            </Grid>

            <Grid item xs={12} sm={isCustomerByCityFullWidth ? 12 : 6}>
              <RecentErrorsCard
                deviceErrors={deviceErrors}
                isErrorsLoading={isErrorsLoading}
              />
            </Grid>

            {["Admin", "SuperAdmin", "Distributor"].includes(
              userRole || ""
            ) && (
              <Grid item xs={12} sm={6}>
                <CustomersByCityChart customers={customers} />
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* COLUMN 2 */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <CartridgeLifeCard devices={devices} />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Map devices={devices} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SummaryTab;
