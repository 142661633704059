import React, { useState } from "react";
import { Typography, Modal, Box } from "@mui/material";
import StyledButton from "../../../shared/Components/Styled/StyledButton";

interface DocumentUploadProps {
  deviceId: string;
  onClose: () => void;
}

const DocumentUpload: React.FC<DocumentUploadProps> = ({
  deviceId,
  onClose
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
    }
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("deviceId", deviceId);

      const response = await fetch("/api/upload", {
        method: "POST",
        body: formData
      });

      if (response.ok) {
        alert("File uploaded successfully");
        onClose();
      } else {
        alert("File upload failed");
      }
    }
  };

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          p: 2,
          backgroundColor: "background.paper",
          width: "90%",
          maxWidth: 500,
          maxHeight: "90vh",
          margin: "auto",
          mt: 5,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto"
        }}
      >
        <Typography variant="h6">Scan and Upload Document</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 2
          }}
        >
          <input
            accept="image/*"
            type="file"
            capture="environment"
            onChange={handleFileChange}
            style={{ display: "none", textAlign: "center" }}
            id="upload-input"
          />
          <label htmlFor="upload-input">
            <StyledButton variantType="primary" component="span">
              Scan Checklist
            </StyledButton>
          </label>
        </Box>
        {previewUrl && (
          <Box
            sx={{
              overflowY: "auto",
              mt: 2,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Typography variant="subtitle1">Preview:</Typography>
            <img
              src={previewUrl}
              alt="Document Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "60vh",
                objectFit: "contain"
              }}
            />
          </Box>
        )}
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 1 }}
        >
          <StyledButton onClick={onClose}>Cancel</StyledButton>
          <StyledButton
            variantType="primary"
            onClick={handleUpload}
            disabled={!file}
          >
            Upload
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default DocumentUpload;
