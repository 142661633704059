import React from "react";
import { Typography, Divider } from "@mui/material";

interface SectionHeaderProps {
  title: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title }) => {
  return (
    <>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: 1,
          color: "#282828",
          fontWeight: "700",
          fontSize: "13px"
        }}
      >
        {title}
      </Typography>
      <Divider sx={{ mb: 2 }} />
    </>
  );
};

export default SectionHeader;
