import React, { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import StyledButton from "../../shared/Components/Styled/StyledButton";
import { changeCartridgeType } from "../../services/device.service";

interface ChangeCartridgeTypeModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (type: string) => void;
  deviceId: string;
  currentCartridgeType: string | undefined;
  onError: (message: string) => void;
}

const ChangeCartridgeTypeModal: React.FC<ChangeCartridgeTypeModalProps> = ({
  open,
  onClose,
  onSuccess,
  onError,
  deviceId,
  currentCartridgeType
}) => {
  const [cartridgeType, setCartridgeType] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setCartridgeType(event.target.value as string);
  };

  const handleSubmit = async () => {
    if (!cartridgeType) {
      onError("Please fill all required fields.");
      return;
    }
    try {
      await changeCartridgeType(deviceId, cartridgeType);
      onSuccess(`Cartridge type changed to ${cartridgeType} successfully.`);
      onClose();
    } catch (error) {
      console.error("Failed to change cartridge type:", error);
      alert("Failed to update cartridge type.");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Change Cartridge Type
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          Current Cartridge: {currentCartridgeType || "Not Set"}
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="cartridge-type-select-label">
            New Cartridge Type
          </InputLabel>
          <Select
            labelId="cartridge-type-select-label"
            id="cartridge-type-select"
            value={cartridgeType}
            label="New Cartridge Type"
            onChange={handleChange}
          >
            <MenuItem value="SAO-24">SAO-24</MenuItem>
            <MenuItem value="SAO-4">SAO-4</MenuItem>
            <MenuItem value="AO/Desiccant">AO/Desiccant Only</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 1.5 }}
        >
          <StyledButton onClick={onClose}>Cancel</StyledButton>
          <StyledButton variantType="primary" onClick={handleSubmit}>
            Submit
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangeCartridgeTypeModal;
