import React from "react";
import { Card, Avatar, Typography, Box } from "@mui/material";

interface UserCardProps {
  firstName: string;
  lastName: string;
  email: string;
}

const UserCard: React.FC<UserCardProps> = ({ firstName, lastName, email }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "8px",
        maxWidth: 300
      }}
    >
      <Avatar sx={{ marginRight: "12px", bgcolor: "secondary.dark" }}>
        {firstName.charAt(0)}
        {lastName.charAt(0)}
      </Avatar>
      <Box>
        <Typography variant="subtitle1">
          {firstName} {lastName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {email}
        </Typography>
      </Box>
    </Card>
  );
};

export default UserCard;
