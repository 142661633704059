import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import SectionHeader from "../SectionHeader";

interface SmallCardProps {
  title: string;
  subtitle1: string;
  subtitle1text: string;
  subtitle2: string;
  subtitle2text: string;
}

const commonCardStyle = {
  p: 2,
  borderRadius: "8px",
  border: "1px solid #d1d1d1",
  backgroundColor: "background.paper",
  color: "text.primary",
  boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
};

const SmallCard: React.FC<SmallCardProps> = ({
  title,
  subtitle1,
  subtitle1text,
  subtitle2,
  subtitle2text
}) => {
  return (
    <Grid item xs={12} sm={6} md={3} sx={{ display: "flex" }}>
      <Box sx={{ ...commonCardStyle, width: "100%" }}>
        <SectionHeader title={title} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <Box>
            <Typography variant="subtitle2" color="textSecondary">
              {subtitle1}
            </Typography>
            <Typography variant="body1">{subtitle1text}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" color="textSecondary">
              {subtitle2}
            </Typography>
            <Typography variant="body1">{subtitle2text}</Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default SmallCard;
