import { Typography, Box, LinearProgress } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const PeakUsageMonthsCard = ({
  peakMonths
}: {
  peakMonths: [string, number][];
}) => {
  const maxCount = Math.max(...peakMonths.map(([_, count]) => count), 1);

  return (
    <Box
      sx={{
        p: 2,
        height: "100%",
        borderRadius: "8px",
        border: "1px solid #d1d1d1",
        backgroundColor: "background.paper",
        color: "text.primary",
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 2.5 }}>
        <TrendingUpIcon sx={{ mr: 1, color: "primary.main" }} />
        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "15px" }}>
          Peak Usage Months
        </Typography>
      </Box>

      {peakMonths.map(([month, count], index) => (
        <Box
          key={month}
          sx={{
            mb: index !== peakMonths.length - 1 ? 2 : 0,
            "&:hover": {
              "& .MuiTypography-root": {
                color: "primary.main"
              }
            }
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                transition: "color 0.2s ease",
                fontWeight: 500
              }}
            >
              {month}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                transition: "color 0.2s ease"
              }}
            >
              {count} changes
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={(count / maxCount) * 100}
            sx={{
              height: 8,
              borderRadius: 4,
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              transition: "transform 0.2s ease",
              "& .MuiLinearProgress-bar": {
                borderRadius: 4,
                backgroundColor:
                  index === 0
                    ? "primary.main"
                    : index === 1
                      ? "primary.light"
                      : "primary.lighter"
              }
            }}
          />
        </Box>
      ))}

      {peakMonths.length === 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            backgroundColor: "rgba(0, 0, 0, 0.02)",
            borderRadius: "8px"
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontStyle: "italic" }}
          >
            No cartridge changes recorded yet
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PeakUsageMonthsCard;
