import { DataGrid, GridColDef } from "@mui/x-data-grid";

const CartridgeDeviceTable = ({
  rows,
  columns,
  selected,
  handleSelectionChange
}: {
  rows: any[];
  columns: GridColDef[];
  selected: string[];
  handleSelectionChange: (newSelection: any) => void;
}) => (
  <DataGrid
    rows={rows}
    columns={columns}
    getRowId={(row) => row._id}
    sx={{
      borderRadius: "8px",
      border: "1px solid #d1d1d1",
      backgroundColor: "background.paper",
      boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "700"
      }
    }}
    initialState={{
      pagination: {
        paginationModel: {
          pageSize: 15
        }
      }
    }}
    checkboxSelection
    disableRowSelectionOnClick
    disableColumnMenu
    onRowSelectionModelChange={handleSelectionChange}
    rowSelectionModel={selected}
    columnHeaderHeight={90}
    autoHeight
  />
);

export default CartridgeDeviceTable;
