import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Divider,
  Card,
  CardContent,
  IconButton,
  TextField,
  Snackbar,
  Alert,
  AlertColor,
  Autocomplete,
  useTheme
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  fetchCustomerByEmail,
  addAdditionalCustomersToDevice,
  removeAdditionalCustomerFromDevice,
  fetchCustomersForUser
} from "../../../services/customer.service";
import { DeviceData } from "../../../common/types/DeviceTypes";
import { Customer } from "../../../store/useCustomer";
import CustomerCard from "../../Shared/Cards/CustomerCard";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import StyledIconButton from "../../../shared/Components/Styled/StyledIconButton";

interface DeviceCustomersProps {
  device: DeviceData;
}

const DeviceCustomers: React.FC<DeviceCustomersProps> = ({ device }) => {
  const [mainCustomer, setMainCustomer] = useState<Customer | null>(null);
  const [additionalCustomers, setAdditionalCustomers] = useState<Customer[]>(
    []
  );
  const [isAddingCustomer, setIsAddingCustomer] = useState(false);
  const [newCustomer, setNewCustomer] = useState<{
    email: string;
    firstName: string;
    lastName: string;
  }>({ email: "", firstName: "", lastName: "" });
  const [openDialog, setOpenDialog] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor
  });
  const [existingCustomers, setExistingCustomers] = useState<
    (Customer | { label: string })[]
  >([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [isAddingNewCustomer, setIsAddingNewCustomer] = useState(false);
  const theme = useTheme();

  const extractCustomerFields = (customer: Customer) => ({
    email: customer.email,
    firstName: customer.firstName,
    lastName: customer.lastName
  });

  useEffect(() => {
    const loadCustomers = async () => {
      try {
        const main = await fetchCustomerByEmail(
          device.deviceInformation.customerEmail
        );
        setMainCustomer(main);

        const additional = await Promise.all(
          device.deviceInformation.additionalCustomers.map((email) =>
            fetchCustomerByEmail(email)
          )
        );
        setAdditionalCustomers(additional);

        const fetchedCustomers = await fetchCustomersForUser();
        const filteredCustomers = fetchedCustomers.filter(
          (customer) =>
            customer.email !== device.deviceInformation.customerEmail &&
            !device.deviceInformation.additionalCustomers.includes(
              customer.email
            )
        );

        // Include "New customer" option
        setExistingCustomers([
          ...filteredCustomers,
          { label: "Add A New Customer" }
        ]);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    loadCustomers();
  }, [device]);

  const handleAddCustomer = () => {
    setIsAddingCustomer(true);
  };

  const handleSaveCustomer = async () => {
    if (!newCustomer.email || !newCustomer.firstName || !newCustomer.lastName) {
      setSnackbar({
        open: true,
        message: "Please fill all required fields",
        severity: "error"
      });
      return;
    }

    try {
      // Prepare the payload with only allowed fields
      const customerToAdd = {
        email: newCustomer.email,
        firstName: newCustomer.firstName,
        lastName: newCustomer.lastName
      };

      await addAdditionalCustomersToDevice(device.deviceInformation.deviceId, [
        customerToAdd
      ]);

      setSnackbar({
        open: true,
        message: "Customer added successfully",
        severity: "success"
      });
      setNewCustomer({ email: "", firstName: "", lastName: "" });
      setIsAddingCustomer(false);
      setIsAddingNewCustomer(false);

      const addedCustomer = await fetchCustomerByEmail(newCustomer.email);

      setAdditionalCustomers((prev) => [...prev, addedCustomer]);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to add customer",
        severity: "error"
      });
    }
  };

  const handleConfirmDelete = async () => {
    if (customerToDelete) {
      try {
        await removeAdditionalCustomerFromDevice(
          device.deviceInformation.deviceId,
          customerToDelete
        );
        setSnackbar({
          open: true,
          message: "Customer removed successfully",
          severity: "success"
        });
        // Remove the customer from the state
        setAdditionalCustomers((prev) =>
          prev.filter((customer) => customer.email !== customerToDelete)
        );
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to remove customer",
          severity: "error"
        });
      } finally {
        setOpenDialog(false);
        setCustomerToDelete(null);
      }
    }
  };

  const handleOpenDialog = (email: string) => {
    setCustomerToDelete(email);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCustomerToDelete(null);
  };

  const handleCancelNewCustomer = () => {
    setIsAddingNewCustomer(false);
    setSelectedCustomer(null);
  };

  const handleSelectExistingCustomer = async () => {
    if (selectedCustomer) {
      try {
        const customerToAdd = extractCustomerFields(selectedCustomer);
        await addAdditionalCustomersToDevice(
          device.deviceInformation.deviceId,
          [customerToAdd]
        );
        setSnackbar({
          open: true,
          message: "Customer added successfully",
          severity: "success"
        });
        setAdditionalCustomers((prev) => [...prev, selectedCustomer]);
        setIsAddingCustomer(false);
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to add customer",
          severity: "error"
        });
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewCustomer((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Box mt={2} p={2}>
      {mainCustomer && <CustomerCard customer={mainCustomer} />}
      <Divider sx={{ marginY: 2 }} />
      <Box
        sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2 }}
      >
        {additionalCustomers.map((customer, index) => (
          <Card key={index} sx={{ position: "relative", width: 300 }}>
            <CustomerCard customer={customer} />
            <IconButton
              onClick={() => handleOpenDialog(customer.email)}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                backgroundColor: "white"
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Card>
        ))}
      </Box>
      <Box sx={{ marginTop: 2 }}>
        {!isAddingCustomer ? (
          <Card
            sx={{
              width: 300,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              borderRadius: 0,
              py: 2
            }}
          >
            <StyledIconButton
              variantType="transparent"
              onClick={handleAddCustomer}
              size="large"
            >
              <AddIcon fontSize="inherit" />
            </StyledIconButton>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Add Additional User
            </Typography>
          </Card>
        ) : (
          <Card sx={{ width: 300, px: 0 }}>
            <CardContent>
              {!isAddingNewCustomer ? (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 500, mb: 1 }}>
                    Select an Existing Customer or Add New
                  </Typography>
                  <Autocomplete
                    options={existingCustomers.sort(
                      (a, b) => ("label" in a ? -1 : 1) // Ensure "New customer" is at the top
                    )}
                    getOptionLabel={(option) =>
                      "label" in option
                        ? option.label
                        : `${option.firstName} ${option.lastName}`
                    }
                    fullWidth
                    onChange={(_, value) => {
                      if (
                        value &&
                        "label" in value &&
                        value.label === "Add A New Customer"
                      ) {
                        setIsAddingNewCustomer(true);
                      } else {
                        setSelectedCustomer(value as Customer);
                        setIsAddingNewCustomer(false);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    value={selectedCustomer || null}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={"label" in option ? option.label : option.email}
                        style={{
                          fontWeight: "label" in option ? 700 : 400, // Bold font for "New customer"
                          color:
                            "label" in option
                              ? "rgba(30, 58, 138, 1)"
                              : "inherit" // Blue color for "New customer"
                        }}
                        className={props.className} // Allows hover effect from props
                      >
                        {"label" in option
                          ? option.label
                          : `${option.firstName} ${option.lastName}`}
                      </li>
                    )}
                  />

                  <Box display="flex" justifyContent="flex-end" mt={1}>
                    <StyledIconButton
                      variantType="transparent"
                      iconColor={theme.palette.primary.main}
                      onClick={handleSelectExistingCustomer}
                      size="large"
                    >
                      <SaveIcon fontSize="inherit" />
                    </StyledIconButton>
                  </Box>
                </>
              ) : (
                <>
                  <TextField
                    name="email"
                    label="Email"
                    value={newCustomer.email}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                  />
                  <TextField
                    name="firstName"
                    label="First Name"
                    value={newCustomer.firstName}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                  />
                  <TextField
                    name="lastName"
                    label="Last Name"
                    value={newCustomer.lastName}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                  />
                  <Box display="flex" justifyContent="space-between" mt={1}>
                    <StyledIconButton
                      variantType="transparent"
                      iconColor={theme.palette.primary.main}
                      onClick={handleSaveCustomer}
                      size="large"
                    >
                      <SaveIcon fontSize="inherit" />
                    </StyledIconButton>

                    <StyledIconButton
                      variantType="transparent"
                      iconColor={theme.palette.secondary.main}
                      onClick={handleCancelNewCustomer}
                      size="large"
                    >
                      <CloseIcon fontSize="inherit" />
                    </StyledIconButton>
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        )}
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this customer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDialog}>Cancel</StyledButton>
          <StyledButton
            onClick={handleConfirmDelete}
            variantType="destructive"
            autoFocus
          >
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeviceCustomers;
