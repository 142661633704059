import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Divider, Chip, Dialog } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { fetchAllProductWarranties } from "../../../services/product-warranty.service";
import { ProductWarranty } from "../../../common/types/ProductWarrantyTypes";
import useAuthStore from "../../../store/authStore";
import StatsCard from "./StatsCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

dayjs.extend(isSameOrBefore);

interface Stats {
  total: number;
  byStatus: Record<string, number>;
  byFaultType: Record<string, number>;
  bySerialNo: Record<string, number>;
  todayClaims: number;
  monthClaims: number;
  yearClaims: number;
}

const ServiceSummary: React.FC = () => {
  const { user } = useAuthStore();
  const [stats, setStats] = useState<Stats>({
    total: 0,
    byStatus: {},
    byFaultType: {},
    bySerialNo: {},
    todayClaims: 0,
    monthClaims: 0,
    yearClaims: 0
  });
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [datePickerView, setDatePickerView] = useState<
    "year" | "month" | "day"
  >("day");

  useEffect(() => {
    const getWarrantyData = async () => {
      if (!user) return;
      const data = await fetchAllProductWarranties();
      const today = dayjs();
      const monthStart = dayjs().startOf("month");
      const yearStart = dayjs().startOf("year");

      let todayCount = 0;
      let monthCount = 0;
      let yearCount = 0;

      const statusCounts: Record<string, number> = {};
      const faultTypeCounts: Record<string, number> = {};
      const serialNoCounts: Record<string, number> = {};

      data.forEach((warranty: ProductWarranty) => {
        const submittedDate = dayjs(warranty.createdAt);
        if (submittedDate.isSame(today, "day")) {
          todayCount++;
        }
        if (
          submittedDate.isSameOrBefore(today) &&
          submittedDate.isAfter(monthStart)
        ) {
          monthCount++;
        }
        if (
          submittedDate.isSameOrBefore(today) &&
          submittedDate.isAfter(yearStart)
        ) {
          yearCount++;
        }

        // Replace "Technical assessment" with "Technical"
        const statusKey =
          warranty.status === "Technical assessment"
            ? "Assessment"
            : warranty.status;

        statusCounts[statusKey] = (statusCounts[statusKey] || 0) + 1;
        faultTypeCounts[warranty.faultDescription.returnReason] =
          (faultTypeCounts[warranty.faultDescription.returnReason] || 0) + 1;
        serialNoCounts[warranty.productSiteInformation.serialNumber] =
          (serialNoCounts[warranty.productSiteInformation.serialNumber] || 0) +
          1;
      });

      setStats({
        total: data.length,
        byStatus: statusCounts,
        byFaultType: faultTypeCounts,
        bySerialNo: serialNoCounts,
        todayClaims: todayCount,
        monthClaims: monthCount,
        yearClaims: yearCount
      });
    };

    getWarrantyData();
  }, [user]);

  const handleOpenDatePicker = (view: "year" | "month" | "day") => {
    setDatePickerView(view);
    setOpenDatePicker(true);
  };

  const handleCloseDatePicker = () => {
    setOpenDatePicker(false);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box sx={{ mb: 1 }}>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontSize: "12px" }}
              >
                Warranty Summary
              </Typography>
              <Divider />
            </Box>
          </Grid>

          {/* Warranty Statistics */}
          <Grid item xs={12} sm={6} md={4}>
            <StatsCard
              icon={<CheckCircleIcon />}
              title="Total Warranties"
              value={stats.total}
              iconColor="orange"
            />
          </Grid>

          {Object.keys(stats.byStatus).map((status) => (
            <Grid item key={status} xs={12} sm={3} md={2}>
              <StatsCard
                icon={<TaskAltIcon />}
                title={status}
                value={stats.byStatus[status]}
                iconColor={
                  status === "Closed"
                    ? "red"
                    : status === "Approved"
                      ? "green"
                      : "blue"
                }
              />
            </Grid>
          ))}

          {/* Date Picker Trigger Chips */}
          {/* Date Picker Trigger Chips in a unified section */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "background.paper",
                p: 1
                // mt: 1,
              }}
            >
              {[
                "Today's Warranties",
                "This Month's Warranties",
                "This Year's Warranties"
              ].map((label, index) => (
                <Box
                  key={label}
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    borderRight:
                      index < 2 ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
                    py: 1,
                    px: 1
                  }}
                >
                  <Chip
                    label={label}
                    onClick={() => handleOpenDatePicker(datePickerView)}
                  />
                  <Typography variant="h6" fontWeight="bold">
                    {index === 0
                      ? stats.todayClaims
                      : index === 1
                        ? stats.monthClaims
                        : stats.yearClaims}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>

          {/* Date Picker Dialog */}
          <Dialog open={openDatePicker} onClose={handleCloseDatePicker}>
            <DatePicker
              views={[datePickerView]}
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue);
                handleCloseDatePicker();
              }}
              open={openDatePicker}
              onClose={handleCloseDatePicker}
            />
            a
          </Dialog>
        </Grid>
      </LocalizationProvider>
    </Box>
  );
};

export default ServiceSummary;
