import React, { useState, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import weekOfYear from "dayjs/plugin/weekOfYear";
import updateLocale from "dayjs/plugin/updateLocale";
import isBetween from "dayjs/plugin/isBetween";
import {
  Box,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  CircularProgress
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Chart as ChartJS } from "chart.js/auto";
import useDeviceStore from "../../../../store/useDeviceStore";

ChartJS.defaults.color = "#282828";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);
dayjs.extend(isBetween);

dayjs.updateLocale("en", {
  weekStart: 0
});

interface OverallUsageGraphProps {
  onBarClick: (date: Date) => void;
  isGallons?: boolean;
  dateFilter?: Date | null;
}

const OverallUsageGraph: React.FC<OverallUsageGraphProps> = ({
  onBarClick,
  isGallons,
  dateFilter
}) => {
  const theme = useTheme();
  const { intervalFilter: interval, selectedIcleanDevices } = useDeviceStore();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(true);
  const [viewDate, setViewDate] = useState<Date | null>(null);

  const { startDate, endDate } = useMemo(() => {
    const end =
      viewDate || dateFilter
        ? dayjs(viewDate || dateFilter).endOf("day")
        : dayjs().endOf("day");

    let start;
    if (interval === "daily") {
      start = end.subtract(7, "days");
    } else if (interval === "weekly") {
      start = end.subtract(7, "weeks");
    } else {
      start = end.subtract(6, "months").startOf("month");
    }

    return { startDate: start, endDate: end };
  }, [viewDate, dateFilter, interval]);

  const chartData = useMemo(() => {
    const dataMap = new Map<string, number>();

    if (interval === "daily") {
      for (
        let date = startDate;
        date.isSameOrBefore(endDate);
        date = date.add(1, "day")
      ) {
        dataMap.set(date.toDate().toDateString(), 0);
      }
    } else if (interval === "weekly") {
      for (let i = 0; i < 7; i++) {
        const weekEnd = endDate.subtract(i, "week").endOf("week");
        const weekStart = weekEnd.startOf("week");
        dataMap.set(
          `${weekStart.format("DD/MMM")} - ${weekEnd.format("DD/MMM")}`,
          0
        );
      }
    } else {
      for (
        let date = startDate;
        date.isSameOrBefore(endDate);
        date = date.add(1, "month")
      ) {
        dataMap.set(date.format("MMM YYYY"), 0);
      }
    }

    selectedIcleanDevices.forEach((device) => {
      device.deviceUsage.forEach((usage) => {
        const usageDate = dayjs(usage.uploadTimestamp);
        if (!usageDate.isBetween(startDate, endDate, null, "[]")) return;

        let labelKey: string;
        if (interval === "daily") {
          labelKey = usageDate.toDate().toDateString();
        } else if (interval === "weekly") {
          const weekEnd = usageDate.endOf("week");
          const weekStart = weekEnd.startOf("week");
          labelKey = `${weekStart.format("DD/MMM")} - ${weekEnd.format("DD/MMM")}`;
        } else {
          labelKey = usageDate.format("MMM YYYY");
        }

        if (dataMap.has(labelKey)) {
          const currentTotal = dataMap.get(labelKey) || 0;
          const dispensedAmount = isGallons
            ? usage.litersDispensed * 0.264172
            : usage.litersDispensed;
          dataMap.set(labelKey, currentTotal + dispensedAmount);
        }
      });
    });

    let sortedData;
    if (interval === "daily") {
      sortedData = Array.from(dataMap.entries())
        .map(([label, value]) => ({ label, data: value }))
        .sort((a, b) => dayjs(a.label).valueOf() - dayjs(b.label).valueOf());
    } else if (interval === "weekly") {
      sortedData = Array.from(dataMap.entries())
        .map(([label, value]) => {
          const [startDate] = label.split(" - ");
          const date = dayjs(
            startDate + "/" + endDate.format("YYYY"),
            "DD/MMM/YYYY"
          );

          const adjustedDate = date.isAfter(endDate.add(6, "months"))
            ? date.subtract(1, "year")
            : date;

          return {
            label,
            data: value,
            sortDate: adjustedDate
          };
        })
        .sort((a, b) => a.sortDate.valueOf() - b.sortDate.valueOf());
    } else {
      sortedData = Array.from(dataMap.entries())
        .map(([label, value]) => ({ label, data: value }))
        .sort(
          (a, b) =>
            dayjs(a.label, "MMM YYYY").valueOf() -
            dayjs(b.label, "MMM YYYY").valueOf()
        );
    }

    sortedData = sortedData.slice(-7);

    setTimeout(() => setIsLoading(false), 100);

    return {
      labels: sortedData.map((item) => item.label),
      datasets: [
        {
          label: isGallons ? "Gallons Dispensed" : "Liters Dispensed",
          data: sortedData.map((item) => item.data),
          backgroundColor: "rgba(30, 58, 138, 0.5)"
        }
      ]
    };
  }, [selectedIcleanDevices, interval, startDate, endDate, isGallons]);

  const handleDateChange = (direction: "forward" | "backward") => {
    let newDate = dayjs(viewDate || dateFilter);

    if (interval === "daily") {
      newDate =
        direction === "forward"
          ? newDate.add(1, "day")
          : newDate.subtract(1, "day");
    } else if (interval === "weekly") {
      newDate =
        direction === "forward"
          ? newDate.add(1, "week")
          : newDate.subtract(1, "week");
    } else {
      newDate =
        direction === "forward"
          ? newDate.add(1, "month")
          : newDate.subtract(1, "month");
    }

    setViewDate(newDate.toDate());
  };

  const handleBarClick = (_event: any, elements: any[]) => {
    if (elements.length > 0 && chartData.labels) {
      // Ensure chartData.labels is not undefined
      const index = elements[0].index;
      const dateStr = chartData.labels[index];
      const date = dayjs(dateStr).toDate();
      onBarClick(date);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          p: 2,
          borderRadius: "8px",
          border: "1px solid #d1d1d1",
          backgroundColor: "background.paper",
          height: "250px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
        }}
      >
        <CircularProgress
          size={40}
          thickness={4}
          sx={{
            color: "rgba(30, 58, 138, 0.5)",
            mb: 2
          }}
        />
        <Typography variant="body2" color="textSecondary">
          Loading usage data...
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: "8px",
        border: "1px solid #d1d1d1",
        backgroundColor: "background.paper",
        color: "text.primary",
        height: "250px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: 1,
          color: "#282828",
          fontWeight: "700",
          fontSize: "13px"
        }}
      >
        Overall Tracking Usage
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Box sx={{ minWidth: "12px" }}>
          {!isMobile && (
            <ArrowBackIosIcon
              sx={{
                cursor: "pointer",
                color: theme.palette.primary.main,
                fontSize: "1rem",
                fontWeight: "bold"
              }}
              onClick={() => handleDateChange("backward")}
            />
          )}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            height: "100%",
            width: "100%",
            minWidth: 0
          }}
        >
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              onClick: handleBarClick,
              plugins: {
                legend: {
                  display: true,
                  position: "top"
                }
              },
              scales: {
                x: {
                  grid: {
                    display: false
                  },
                  ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0
                  }
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: isGallons ? "Gallons" : "Liters"
                  }
                }
              },
              layout: {
                padding: {
                  left: 10,
                  right: 10,
                  top: 0,
                  bottom: 0
                }
              }
            }}
          />
        </Box>
        <Box sx={{ minWidth: "12px" }}>
          {!isMobile && (
            <ArrowForwardIosIcon
              sx={{
                cursor: "pointer",
                color: theme.palette.primary.main,
                fontSize: "1rem",
                fontWeight: "bold"
              }}
              onClick={() => handleDateChange("forward")}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OverallUsageGraph;
