import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  Chip,
  Snackbar,
  TextField,
  Alert,
  AlertColor,
  useTheme,
  useMediaQuery,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Add, Delete, Edit, ManageAccounts } from "@mui/icons-material";
import CreateNewCustomerModal from "./CreateNewCustomerModal";
import useAuthStore from "../../../store/authStore";
import { Customer } from "../../../store/useCustomer";
import {
  changeCustomerStatus,
  fetchCustomersForUser
} from "../../../services/customer.service";
import {
  ChangeRequestPayload,
  ChangeRequestTypes
} from "../../../common/types/ChangeRequestTypes";
import { createChangeRequest } from "../../../services/change-request.service";
import {
  AccountStatusTypes,
  UserDetails
} from "../../../common/types/UserTypes";
import EditUserModal from "../Shared/EditUserModal";
import EditUserAccountStatusModal from "../Shared/EditUserStatusModal";
import useDeviceStore from "../../../store/useDeviceStore";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import StyledIconButton from "../../../shared/Components/Styled/StyledIconButton";

interface CustomerProps {
  onCustomerSelect?: (customer: Customer) => void;
}

const CustomerTable: React.FC<CustomerProps> = ({ onCustomerSelect }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [customers, setCustomers] = useState<any[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const { user, userRole } = useAuthStore.getState();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentDeleteUser, setCurrentDeleteUser] = useState<any>(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [accountStatusModalOpen, setAccountStatusModalOpen] = useState(false);
  const [currentEditUser, setCurrentEditUser] = useState<UserDetails | null>(
    null
  );
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });
  //const [groups, setGroups] = useState<Customer[]>([]);
  //const [groupModalOpen, setGroupModalOpen] = useState(false);
  // Remove _ when using the variable setSelectedTab
  const [selectedTab, _setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  // const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
  //   setSelectedTab(newValue);
  //   localStorage.setItem("selectedCustomerTableTab", newValue.toString());
  // };

  const fetchCustomersData = useCallback(async () => {
    try {
      const customers = await fetchCustomersForUser();
      if (customers && Array.isArray(customers)) {
        setCustomers(customers);
        //groupCustomersByParent(customers);
      } else {
        setCustomers([]);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      setCustomers([]);
    }
  }, []);

  useEffect(() => {
    fetchCustomersData();
  }, [fetchCustomersData]);

  // const groupCustomersByParent = (customers: any[]) => {
  //   const groupCustomers = customers.filter(
  //     (customer) => customer.role === "GroupCustomer"
  //   );
  //   const regularCustomers = customers.filter(
  //     (customer) => customer.role === "Customer"
  //   );

  //   const groupedData = groupCustomers.map((group) => {
  //     const groupMembers = regularCustomers.filter(
  //       (customer) => customer.customerGroup === group.email
  //     );

  //     return {
  //       ...group,
  //       members: groupMembers,
  //     };
  //   });

  //   setGroups(groupedData);
  // };

  const handleSnackbarChange = (snackbarData: {
    open: boolean;
    message: string;
    severity: AlertColor;
  }) => {
    setSnackbar(snackbarData);
    fetchCustomersData();
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      if (!user || !userRole) return;

      const { allDevices } = useDeviceStore.getState();
      const associatedDevices = allDevices.filter((device) => {
        const { customerEmail, additionalCustomers, additionalUsers } =
          device.deviceInformation;
        return (
          customerEmail === currentDeleteUser?.email ||
          additionalCustomers?.includes(currentDeleteUser?.email) ||
          additionalUsers?.includes(currentDeleteUser?.email)
        );
      });

      if (associatedDevices.length > 0) {
        const deviceNames = associatedDevices
          .map((device) => device.deviceInformation.deviceName)
          .join(", ");
        setSnackbar({
          open: true,
          message: `Cannot delete user: ${currentDeleteUser?.email}. User has ${associatedDevices.length} associated devices: ${deviceNames}. Please reassign the devices before deleting the user.`,
          severity: "error"
        });
        return;
      }

      if (userRole !== "SuperAdmin" && userRole !== "Admin") {
        const changeRequestPayload: ChangeRequestPayload = {
          type: ChangeRequestTypes.DeleteCustomer,
          userEmail: user?.email,
          userRole: userRole,
          details: {
            userId: currentDeleteUser?._id,
            status: AccountStatusTypes.Deleted
          }
        };
        // Send the change request to the server
        await createChangeRequest(changeRequestPayload);
        // Show success message
        setSnackbar({
          open: true,
          message: `Change Request to delete user: ${currentDeleteUser?.email} created successfully`,
          severity: "warning"
        });
      } else {
        await changeCustomerStatus(
          currentDeleteUser?._id,
          AccountStatusTypes.Deleted
        );
        // Show success message
        setSnackbar({
          open: true,
          message: `User: ${currentDeleteUser?.email} deleted successfully`,
          severity: "success"
        });
      }

      setDeleteModalOpen(false);
      setCurrentDeleteUser(null); // Clear after deletion

      // Refresh the list after deletion
      fetchCustomersData();
    } catch (error: any) {
      console.error("Error creating change request:", error.message);
      setSnackbar({
        open: true,
        message: error.message ? error.message : "Failed to delete user.",
        severity: "error"
      });
    }
  };

  const handleUpdateUser = async (updatedUser: UserDetails) => {
    try {
      if (!user || !userRole) return;

      // Get changed fields
      const changedFields = Object.keys(updatedUser).reduce((acc, key) => {
        if (
          currentEditUser &&
          updatedUser[key as keyof UserDetails] !==
            currentEditUser[key as keyof UserDetails]
        ) {
          acc[key as keyof UserDetails] = updatedUser[
            key as keyof UserDetails
          ] as any;
        }
        return acc;
      }, {} as Partial<UserDetails>);

      if (Object.keys(changedFields).length === 0) {
        setSnackbar({
          open: true,
          message: `No changes detected.`,
          severity: "info"
        });
        return; // Exit early if nothing changed
      }

      const changeRequestPayload: ChangeRequestPayload = {
        type: ChangeRequestTypes.UpdateUserDetails,
        userEmail: user?.email,
        userRole: userRole,
        details: { _id: updatedUser._id, ...changedFields } // Only send changed fields plus _id
      };

      await createChangeRequest(changeRequestPayload);

      setSnackbar({
        open: true,
        message: `User information change request created successfully`,
        severity: "success"
      });

      fetchCustomersData(); // Refresh the list
    } catch (error: any) {
      console.error("Error updating user:", error.message);
      setSnackbar({
        open: true,
        message: error.message || "Failed to update user.",
        severity: "error"
      });
    }
  };

  const handleUpdateAccountStatus = async (
    updatedStatus: AccountStatusTypes
  ) => {
    try {
      if (!user || !userRole) return;

      if (userRole === "SuperAdmin" || userRole === "Admin") {
        if (currentEditUser?._id) {
          await changeCustomerStatus(currentEditUser._id, updatedStatus);
        } else {
          throw new Error("User ID is undefined");
        }
        setSnackbar({
          open: true,
          message: `User account status updated successfully`,
          severity: "success"
        });
      }

      fetchCustomersData(); // Refresh the list
      setCurrentEditUser(null); // Clear the current user

      // TODO: Implement change request for account status update if user is not an admin
    } catch (error: any) {
      console.error("Error updating account status:", error.message);
      setSnackbar({
        open: true,
        message: error.message || "Failed to update account status.",
        severity: "error"
      });
    }
  };

  const handleEditClick = (user: UserDetails) => {
    setCurrentEditUser(user);
    setEditModalOpen(true);
  };

  const nationalAccounts = useMemo(() => {
    return customers.filter((customer) => customer.role === "NationalAccount");
  }, [customers]);

  const globalAccounts = useMemo(() => {
    return customers.filter((customer) => customer.role === "GlobalAccount");
  }, [customers]);

  const getFilteredCustomers = useMemo(() => {
    return customers.filter((customer) => {
      if (selectedTab === 1 && customer.role !== "Customer") {
        return false;
      }

      return true;
    });
  }, [customers, selectedTab]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredCustomers = useMemo(() => {
    return customers.filter(
      (customer) =>
        customer.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.country.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [customers, searchTerm]);

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Created Time",
      flex: 1,
      minWidth: 125,
      valueFormatter: (value) => new Date(value).toLocaleDateString()
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      minWidth: 130,
      valueFormatter: (value: string) =>
        value.toLowerCase() === "unknown" ? "-" : value
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      minWidth: 130,
      valueFormatter: (value: string) =>
        value.toLowerCase() === "unknown" ? "-" : value
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      minWidth: 130,
      valueFormatter: (value: string) =>
        value.toLowerCase() === "unknown" ? "-" : value
    },
    // {
    //   field: "state",
    //   headerName: "State",
    //   flex: 1,
    //   minWidth: 130,
    //   valueFormatter: (value: string) =>
    //     value.toLowerCase() === "unknown" ? "-" : value,
    // },
    // {
    //   field: "city",
    //   headerName: "City",
    //   flex: 1,
    //   minWidth: 130,
    //   valueFormatter: (value: string) =>
    //     value.toLowerCase() === "unknown" ? "-" : value,
    // },
    // {
    //   field: "address",
    //   headerName: "Address",
    //   flex: 1.5,
    //   minWidth: 200,
    //   valueFormatter: (value: string) =>
    //     value.toLowerCase() === "unknown" ? "-" : value,
    // },
    // {
    //   field: "phone",
    //   headerName: "Phone",
    //   flex: 1,
    //   minWidth: 130,
    //   valueFormatter: (value: string) =>
    //     value.toLowerCase() === "unknown" ? "-" : value,
    // },
    { field: "email", headerName: "Email", flex: 1.5, minWidth: 225 },
    {
      field: "accountStatus",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value === "Active" || params.value === "Inactive"
              ? params.value
              : "Deleted"
          }
          color={
            {
              Active: "success",
              Inactive: "warning",
              Deleted: "error"
            }[params.value as "Active" | "Inactive" | "Deleted"] as
              | "success"
              | "warning"
              | "error"
          }
        />
      )
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 125,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <StyledIconButton
              variantType="transparent"
              onClick={() => {
                handleEditClick(params.row);
              }}
            >
              <Edit />
            </StyledIconButton>
          </Tooltip>
          {userRole === "SuperAdmin" ||
            (userRole === "Admin" && (
              <Tooltip title="Edit Account Status">
                <StyledIconButton
                  variantType="transparent"
                  onClick={() => {
                    setCurrentEditUser(params.row);
                    setAccountStatusModalOpen(true);
                  }}
                >
                  <ManageAccounts />
                </StyledIconButton>
              </Tooltip>
            ))}
          <Tooltip title="Delete">
            <StyledIconButton
              variantType="transparent"
              onClick={() => {
                // handleDeleteClick(params.row)}
                setCurrentDeleteUser(params.row); // Set the user details
                setDeleteModalOpen(true);
              }}
            >
              <Delete />
            </StyledIconButton>
          </Tooltip>
        </>
      )
    }
  ];

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "start" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "98%" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
              mb: 2
            }}
          >
            {/* Search input field */}
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                width: isMobile ? "100%" : "300px",
                height: "28px",
                borderRadius: 1,
                backgroundColor: "#f5f5f5",
                marginBottom: isMobile ? 2 : 0
              }}
            />
            {userRole &&
              [
                "Admin",
                "SuperAdmin",
                "Installer",
                "Distributor",
                "SubDistributor"
              ].includes(userRole) && (
                <StyledButton
                  variantType="primary"
                  icon={<Add />}
                  onClick={handleOpenModal}
                >
                  Add Customer
                </StyledButton>
              )}
            {userRole && ["GroupCustomer"].includes(userRole) && (
              <StyledButton
                variantType="primary"
                icon={<Add />}
                onClick={handleOpenModal}
              >
                Add User
              </StyledButton>
            )}
          </Box>
          <CreateNewCustomerModal
            open={modalOpen}
            onClose={handleCloseModal}
            onSnackbarChange={handleSnackbarChange}
          />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <>
            {/* <Box
              sx={{
                backgroundColor: "transparent",
                borderRadius: 1,
                border: "1px solid grey.800",
                mb: 2,
              }}
            >
              <StyledTabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="Device tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <PlainTab label="All" />
                {userRole &&
                  [
                    "Admin",
                    "SuperAdmin",
                    "Installer",
                    "Distributor",
                    "SubDistributor",
                  ].includes(userRole) && <PlainTab label="Customers" />}

                {userRole &&
                  [
                    "Admin",
                    "SuperAdmin",
                    "Installer",
                    "Distributor",
                    "SubDistributor",
                  ].includes(userRole) && <PlainTab label="Groups" />}

                {userRole && ["Admin", "SuperAdmin"].includes(userRole) && (
                  <PlainTab label="National Accounts" />
                )}

                {userRole && ["Admin", "SuperAdmin"].includes(userRole) && (
                  <PlainTab label="Global Accounts" />
                )}
              </StyledTabs>
            </Box> */}
            {selectedTab === 0 && (
              <DataGrid
                rows={filteredCustomers}
                columns={columns}
                getRowId={(row) => row._id}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10
                    }
                  }
                }}
                pageSizeOptions={[5, 10, 25]}
                //checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelection) => {
                  setSelected(newSelection.map((id) => String(id)));
                }}
                rowSelectionModel={selected}
              />
            )}
            {selectedTab === 1 && (
              <DataGrid
                rows={getFilteredCustomers}
                columns={columns}
                getRowId={(row) => row._id}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "700"
                  }
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10
                    }
                  }
                }}
                pageSizeOptions={[5, 10, 25]}
                //checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelection) => {
                  setSelected(newSelection.map((id) => String(id)));
                }}
                rowSelectionModel={selected}
              />
            )}
            {selectedTab === 3 && (
              <DataGrid
                rows={nationalAccounts}
                columns={columns}
                getRowId={(row) => row._id}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "700"
                  }
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10
                    }
                  }
                }}
                pageSizeOptions={[5, 10, 25]}
                //checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelection) => {
                  setSelected(newSelection.map((id) => String(id)));
                }}
                rowSelectionModel={selected}
              />
            )}

            {selectedTab === 4 && (
              <DataGrid
                rows={globalAccounts}
                columns={columns}
                getRowId={(row) => row._id}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "700"
                  }
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10
                    }
                  }
                }}
                pageSizeOptions={[5, 10, 25]}
                //checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelection) => {
                  setSelected(newSelection.map((id) => String(id)));
                }}
                rowSelectionModel={selected}
              />
            )}
          </>
          <Dialog open={deleteModalOpen} onClose={handleClose}>
            <DialogContent>
              <Typography>
                Are you sure you want to delete this customer?
              </Typography>
            </DialogContent>
            <DialogActions>
              <StyledButton onClick={handleClose}>Cancel</StyledButton>
              <StyledButton
                variantType="destructive"
                onClick={() => {
                  handleDelete();
                  handleClose();
                }}
              >
                Delete
              </StyledButton>
            </DialogActions>
          </Dialog>
          <EditUserModal
            open={editModalOpen}
            onClose={() => setEditModalOpen(false)}
            user={currentEditUser}
            onSubmit={handleUpdateUser}
          />
          <EditUserAccountStatusModal
            open={accountStatusModalOpen}
            onClose={() => {
              setAccountStatusModalOpen(false);
              setCurrentEditUser(null);
            }}
            user={currentEditUser}
            onSubmit={handleUpdateAccountStatus}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerTable;
