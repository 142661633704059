import React from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import StyledButton from "../../../shared/Components/Styled/StyledButton";

interface HeaderProps {
  selectedTab: string;
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
}

const Header: React.FC<HeaderProps> = ({ selectedTab, handleTabChange }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        {/* First Row - Header */}
        <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Tersano Portal & Docs</Typography>
          <StyledButton
            variantType="outline-primary"
            customFontColor="white"
            customHoverColor="#669ac9"
            onClick={() => navigate("/")}
          >
            Login
          </StyledButton>
        </Box>

        {/* Second Row - Tabs */}
        <Box
          sx={{
            backgroundColor: "white"
          }}
        >
          <Tabs
            textColor="primary"
            indicatorColor="primary"
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Getting Started" value="getting-started" />
            <Tab label="Distributor Portal" value="distributor-portal" />
            <Tab label="Installer Portal" value="installer-portal" />
            <Tab label="Customer Portal" value="customer-portal" />
            <Tab label="Company Portal" value="company-portal" />
            <Tab label="Customer Integration" value="customer-integration" />
            <Tab label="Training Videos" value="training-videos" />
          </Tabs>
        </Box>
      </Box>
    </>
  );
};

export default Header;
