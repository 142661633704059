import { create } from "zustand";
import { DeviceData } from "../common/types/DeviceTypes";
import { fetchDistributors } from "../services/distributor.service";
import { fetchInstaller } from "../services/installer.service";

export interface Distributor {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  isActive: boolean;
  isEmailVerified: boolean;
  role: string;
  subDistributors: string[];
  installers: string[];
  devices: DeviceData[];
  phone: string;
  address: string;
  createdAt: string;
}

interface DistributorState {
  Distributors: Distributor[];
  // Not too sure but the distributor.service returns a Distributor[] for fetching Installers
  Installers: Distributor[];
  fetchDistributors: () => Promise<void>;
  fetchInstaller: (email: string, role: string) => Promise<void>;
}

const useDistributorStore = create<DistributorState>((set) => ({
  Distributors: [],
  fetchDistributors: async () => {
    try {
      const response = await fetchDistributors(); // Adjust the endpoint as needed
      set({ Distributors: response });
    } catch (error) {
      console.error("Failed to fetch Distributors:", error);
    }
  },
  Installers: [],
  fetchInstaller: async (email: string, role: string) => {
    try {
      const response = await fetchInstaller();
      set({ Installers: response });
    } catch (error) {
      console.error("Failed to fetch Installers:", error);
    }
  }
}));

export default useDistributorStore;
