import React from "react";
import { Box, Typography } from "@mui/material";

const ServiceManual = () => {
  return (
    <Box
      p={0}
      mt={4}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: { xs: "95%", md: "96%" }
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        Service Manual
      </Typography>
      <Typography variant="body1">
        You can find the service manual for your device by downloading the PDF
        below.
      </Typography>
      <Typography variant="body1">
        The password to access the service manual is:{" "}
        <strong>servman2019</strong>
      </Typography>
      <Box mt={2}>
        <a
          href="https://tersanocanada-my.sharepoint.com/:b:/g/personal/emmanuelj_tersano_com/EVOWDohzrXhCst1edUrMA0IBYiTNUuW_EgSKm2igZC7VoQ?e=QvC6JE"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Service Manual
        </a>
      </Box>
    </Box>
  );
};

export default ServiceManual;
