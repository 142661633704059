import React from "react";
import { DeviceData } from "../../../common/types/DeviceTypes";
import Timeline from "@mui/lab/Timeline";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import DeviceUsageTimelineItem from "./DeviceUsageTimelineItem";
import { ICleanDevice } from "../../../common/types/DeviceTypes";

interface DeviceUsageLogProps {
  device: DeviceData;
}

const DeviceUsageLog: React.FC<DeviceUsageLogProps> = ({ device }) => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0
        }
      }}
    >
      {(device as ICleanDevice).deviceUsage
        .sort(
          (a, b) =>
            new Date(b.uploadTimestamp).getTime() -
            new Date(a.uploadTimestamp).getTime()
        )
        .map((usage, index) => (
          <DeviceUsageTimelineItem key={index} usage={usage} />
        ))}
    </Timeline>
  );
};

export default DeviceUsageLog;
