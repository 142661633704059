import { useState } from "react";
import {
  Typography,
  Paper,
  Box,
  Tooltip,
  IconButton,
  Snackbar,
  Alert
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import logoWhite from "../../assets/images/tersanowhite.png";

import CustomerWarrantyModal from "./CustomerWarrantyModal";

const WarrantyClaimForm = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error" | "warning" | "info";
  }>({
    open: false,
    message: "",
    severity: "success"
  });

  // Function to open the modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        p: 3,
        pt: { xs: "15%", sm: "15%", md: "10%" },
        justifyContent: "center",

        minHeight: "100vh",
        background:
          "linear-gradient(45deg, rgba(30, 58, 138, 1) 55%, rgba(30, 58, 138, 0.6) 90%)"
      }}
    >
      <Tooltip title="Back to Dashboard">
        <IconButton
          sx={{
            position: "absolute",
            top: 20,
            left: 20,
            color: "white",
            "&:hover": {
              color: "secondary.light"
            }
          }}
          onClick={() => navigate("/dashboard")}
        >
          <HomeIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Tooltip>
      <Box
        sx={{
          position: "absolute",
          top: 32,
          left: 90,
          border: "1px solid white",
          p: 0.1
        }}
      >
        {" "}
        <img src={logoWhite} alt="logo" height={12} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "30%",
          p: 1
        }}
      >
        <Typography
          variant="h3"
          color="primary"
          sx={{ mb: 2, color: "white", textAlign: "center", fontWeight: 400 }}
        >
          Welcome to Tersano's Warranty Submission
        </Typography>

        {/* Subtitle */}
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ mb: 2, color: "white", textAlign: "center", fontSize: "13px" }}
        >
          Submit a new warranty claim on any Tersano product purchased and get
          the support you need!
        </Typography>

        <Paper
          onClick={handleOpenModal}
          elevation={8}
          sx={{
            mt: 4,
            width: "70%",
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "#e0e0e0",
              cursor: "pointer" // Change cursor to pointer on hover
            },
            textAlign: "center",
            py: 3,
            borderRadius: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {/* Title for Paper */}
          <Typography
            variant="h6"
            color="primary"
            gutterBottom
            sx={{ fontWeight: 600 }}
          >
            CLICK TO SUBMIT WARRANTY
          </Typography>
        </Paper>

        {/* Warranty Modal */}
        <CustomerWarrantyModal open={openModal} onClose={handleCloseModal} />
        {/* Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default WarrantyClaimForm;
