import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  Alert,
  Snackbar,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AlertColor,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Assessment,
  ImageSearch
} from "@mui/icons-material";
import StepperMenu from "../../../shared/Components/StepperMenu";
import { SelectChangeEvent } from "@mui/material/Select";
import useAuthStore from "../../../store/authStore";
import PDFWindowViewer from "../../../shared/Components/PDFWindowViewer";
import {
  fetchAllProductWarranties,
  fetchDeviceArrivals
} from "../../../services/product-warranty.service";
import { ProductWarranty } from "../../../common/types/ProductWarrantyTypes";
import StyledButton from "../../../shared/Components/Styled/StyledButton";

const steps = ["Select Distributor", "Report Details", "Generate Report"];
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

interface GenerateReportProps {
  reportCategory: "WarrantyReport";
}

const GenerateWarrantyReport: React.FC<GenerateReportProps> = ({
  reportCategory
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDistributors, setSelectedDistributors] = useState<string[]>(
    []
  );
  const [warranties, setWarranties] = useState<ProductWarranty[]>([]);
  const [dataPreview, setDataPreview] = useState<any>(null);
  const [reportGenerated, setReportGenerated] = useState(false);
  const [reportType, setReportType] = useState<string>("");
  const [reportName, setReportName] = useState<string>("");
  const [reportDescription, setReportDescription] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [previewGenerated, setPreviewGenerated] = useState<boolean>(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [arrivals, setArrivals] = useState<any[]>([]);
  const [dateOption, setDateOption] = useState("specificDate"); // default selection is specificDate
  const [isReturnsReport, setIsReturnsReport] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor
  });
  const { user, userRole } = useAuthStore();
  const theme = useTheme();

  useEffect(() => {
    const fetchWarranties = async () => {
      try {
        const data = await fetchAllProductWarranties();
        setWarranties(data);
      } catch (error) {
        console.error("Error fetching product warranties:", error);
      }
    };

    if (user && userRole) {
      fetchWarranties();
    }
  }, [user, userRole]);

  useEffect(() => {
    const fetchArrivals = async () => {
      try {
        const data = await fetchDeviceArrivals(
          user?.email || "",
          userRole || ""
        );
        setArrivals(data);
      } catch (error) {
        console.error("Error fetching device arrivals:", error);
      }
    };

    if (user && userRole) {
      fetchArrivals();
    }
  }, [user, userRole, isReturnsReport]);

  const handleDistributorChange = (
    _event: React.ChangeEvent<{}>,
    value: string[]
  ) => {
    if (value.includes("Select All")) {
      // If 'Select All' is selected, select all options except itself
      setSelectedDistributors(
        getUniqueDistributors().filter((d) => d !== "Select All")
      );
    } else if (
      selectedDistributors.includes("Select All") &&
      !value.includes("Select All")
    ) {
      // If 'Select All' was previously selected and is now deselected, reset to the new selection
      setSelectedDistributors(value);
    } else {
      setSelectedDistributors(value);
    }
  };

  const getUniqueDistributors = () => {
    const distributorSet = new Set<string>();
    warranties.forEach((warranty) => {
      distributorSet.add(warranty.distributorInformation.companyName);
    });
    const distributorsArray = Array.from(distributorSet);
    // Add 'Select All' as the first option
    return ["Select All", ...distributorsArray];
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (!reportGenerated) {
        generateReport();
      }
      setSelectedDistributors([]);
      setReportType("");
      setReportDescription("");
      setReportName("");
      setStartDate(null);
      setEndDate(null);
      setActiveStep(0);
      setReportGenerated(false);
      setDataPreview(null);
      setPreviewGenerated(false);
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleCancel = () => {
    setActiveStep(0);
    setSelectedDistributors([]);
    setReportType("");
    setDataPreview(null);
    setReportGenerated(false);
    setReportDescription("");
    setReportName("");
    setStartDate(null);
    setEndDate(null);
    setDataPreview(null);
    setPreviewGenerated(false);
  };

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  const handleReportTypeChange = (event: SelectChangeEvent<string>) => {
    setReportType(event.target.value as string);
    if (reportType === "Return") {
      setIsReturnsReport(true);
    } else {
      setIsReturnsReport(false);
    }
  };

  const handleDateOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedOption = event.target.value;
    setDateOption(selectedOption);

    if (selectedOption === "thisYear") {
      const currentYear = dayjs().year();
      setStartDate(dayjs(`${currentYear}-01-01`).toDate());
      setEndDate(dayjs(`${currentYear}-12-31`).toDate());
    } else if (selectedOption === "thisMonth") {
      const currentMonth = dayjs().month();
      const currentYear = dayjs().year();
      setStartDate(dayjs(`${currentYear}-${currentMonth + 1}-01`).toDate());
      setEndDate(dayjs().endOf("month").toDate());
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const previewData = async (distributors: string[]) => {
    if (!reportType) {
      return;
    }

    let previewData;

    if (reportType === "Credit") {
      previewData = calculateTotalCredit(distributors, startDate, endDate);
    } else if (reportType === "Return") {
      previewData = await calculateReturns(distributors, startDate, endDate);
    } else if (reportType === "Invalid Credit") {
      previewData = calculateInvalidCredits(distributors, startDate, endDate);
    } else {
      console.warn("Invalid report type selected");
      return;
    }

    setDataPreview(previewData);
    setPreviewGenerated(true);
    setIsPreviewOpen(true); // Open preview modal
    setSelectedReport({
      _id: "",
      reportInformation: {
        name: reportName,
        description: reportDescription,
        reportType: reportType,
        generatedAt: new Date(),
        generatedBy: user?.email || "Unknown",
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        distributors: distributors // Use the argument here
      },
      reportData: { data: previewData }
    }); // Set selected report data
  };

  // Calculate total credit for the Credit Report
  const calculateTotalCredit = (
    distributors: string[],
    startDate: Date | null,
    endDate: Date | null
  ) => {
    // Initialize an array to store the credit report data
    const creditReportData: {
      distributor: string;
      email: string;
      numberOfWarranties: number;
      creditAmount: number;
    }[] = [];

    // Loop through each selected distributor
    distributors.forEach((distributor) => {
      // Filter warranties for the current distributor and within the date range
      const filteredWarranties = warranties.filter((warranty) => {
        const warrantyDate = new Date(warranty.createdAt); // Assuming `createdAt` is the date field for filtering

        // Check if the warranty date falls within the specified date range
        const withinDateRange =
          (!startDate || warrantyDate >= startDate) &&
          (!endDate || warrantyDate <= endDate);

        return (
          warranty.distributorInformation.companyName === distributor &&
          withinDateRange
        );
      });

      // Calculate the total credit amount for the distributor
      const totalCredit = filteredWarranties.reduce((sum, warranty) => {
        if (warranty.faultDescription.creditStatus === "YES") {
          return sum + (warranty.faultDescription.creditAmount || 0);
        }
        return sum; // If creditStatus is "NO", do not add to sum
      }, 0);

      // Add an entry for the distributor
      creditReportData.push({
        distributor,
        email: filteredWarranties[0]?.distributorInformation.email || "",
        numberOfWarranties: filteredWarranties.length,
        creditAmount: totalCredit
      });
    });

    // Calculate the total credit across all selected distributors
    const totalCreditAmount = creditReportData.reduce(
      (sum, item) => sum + item.creditAmount,
      0
    );

    // Add total credit row at the end
    creditReportData.push({
      distributor: "Total",
      email: "",
      numberOfWarranties: creditReportData.reduce(
        (sum, item) => sum + item.numberOfWarranties,
        0
      ),
      creditAmount: totalCreditAmount
    });

    return creditReportData;
  };

  // calculate total returns
  const calculateReturns = async (
    distributors: string[],
    startDate: Date | null,
    endDate: Date | null
  ) => {
    // Initialize an array to store the return report data
    const returnReportData: {
      distributor: string;
      email: string;
      deviceModels: string[] | string;
      numberOfReturns: number;
    }[] = [];

    // **Step 1: Filter Arrivals with Status "Unknown"**
    const pendingArrivals = arrivals.filter((arrival) => {
      // Check if arrival is within the date range
      const arrivalDate = new Date(arrival.arrivalDate);
      const withinDateRange =
        (!startDate || arrivalDate >= startDate) &&
        (!endDate || arrivalDate <= endDate);

      return arrival.status === "UNKNOWN" && withinDateRange;
    });

    // **Step 2: Create a Map of Warranty Claims to Arrivals**
    const arrivalMap = new Map<string, number>(); // Map to store counts of pending arrivals per warrantyClaim

    pendingArrivals.forEach((arrival) => {
      if (arrival.warrantyClaim) {
        const claimId = arrival.warrantyClaim.toString();
        arrivalMap.set(claimId, (arrivalMap.get(claimId) || 0) + 1);
      }
    });

    // **Step 3: Filter Warranties by Date Range**
    const filteredWarranties = warranties.filter((warranty) => {
      // Check if warranty is within the date range
      const warrantyDate = new Date(warranty.createdAt); // Assuming `createdAt` is the field to filter by
      return (
        (!startDate || warrantyDate >= startDate) &&
        (!endDate || warrantyDate <= endDate)
      );
    });

    // **Step 4: Calculate Total Returns for Each Distributor**
    distributors.forEach((distributor) => {
      // Filter warranties for the current distributor
      const distributorWarranties = filteredWarranties.filter(
        (warranty) =>
          warranty.distributorInformation.companyName === distributor
      );

      // Calculate the total returns for the distributor
      const totalReturns = distributorWarranties.reduce((acc, warranty) => {
        const numberOfReturns = arrivalMap.get(warranty._id.toString()) || 0;
        return acc + numberOfReturns;
      }, 0);

      // **Step 5: Prepare the Return Data**
      if (totalReturns > 0) {
        returnReportData.push({
          distributor,
          email: distributorWarranties[0].distributorInformation.email, // Assumes all warranties from the same distributor have the same email
          deviceModels: Array.from(
            new Set(
              distributorWarranties.map(
                (w) => w.productSiteInformation.deviceModel
              )
            )
          ), // Unique device models for the distributor
          numberOfReturns: totalReturns
        });
      }
    });

    return returnReportData; // Return the prepared return report data
  };

  const calculateInvalidCredits = (
    distributors: string[],
    startDate: Date | null,
    endDate: Date | null
  ) => {
    // Initialize an array to store the invalid credit report data
    const invalidCreditReportData: {
      distributor: string;
      email: string;
      numberOfWarranties: number;
      numberOfInvalidCredits: number;
      creditAmount: number;
      totalInvalidCreditAmount: number;
    }[] = [];

    // Loop through each selected distributor
    distributors.forEach((distributor) => {
      // Filter warranties for the current distributor and within the date range
      const filteredWarranties = warranties.filter((warranty) => {
        const warrantyDate = new Date(warranty.createdAt); // Assuming `createdAt` is the date field for filtering

        // Check if the warranty date falls within the specified date range
        const withinDateRange =
          (!startDate || warrantyDate >= startDate) &&
          (!endDate || warrantyDate <= endDate);

        return (
          warranty.distributorInformation.companyName === distributor &&
          withinDateRange
        );
      });

      // Calculate the total number of invalid credits for the distributor
      let totalInvalidCredits = filteredWarranties.reduce((acc, warranty) => {
        if (warranty.isCreditValid === false) {
          return acc + 1;
        }
        return acc;
      }, 0);

      // calculate the total invalid credit amount for the distributor
      let totalInvalidCreditAmount = filteredWarranties.reduce(
        (acc, warranty) => {
          if (warranty.isCreditValid === false) {
            return acc + warranty.faultDescription.creditAmount;
          }
          return acc;
        },
        0
      );

      if (totalInvalidCredits > 0) {
        invalidCreditReportData.push({
          distributor,
          email: filteredWarranties[0].distributorInformation.email, // Assumes all warranties from the same distributor have the same email
          numberOfInvalidCredits: totalInvalidCredits,
          numberOfWarranties: filteredWarranties.length,
          creditAmount: filteredWarranties.reduce(
            (acc, warranty) => acc + warranty.faultDescription.creditAmount,
            0
          ),
          totalInvalidCreditAmount: totalInvalidCreditAmount
        });
      }
    });

    return invalidCreditReportData; // Return the prepared invalid credit report data
  };

  const generateReport = async () => {
    try {
      let reportData;

      switch (reportType) {
        case "Credit":
          reportData = calculateTotalCredit(
            selectedDistributors,
            startDate,
            endDate
          );
          break;

        case "Return":
          reportData = await calculateReturns(
            selectedDistributors,
            startDate,
            endDate
          );
          break;

        case "Invalid Credit":
          reportData = calculateInvalidCredits(
            selectedDistributors,
            startDate,
            endDate
          );
          break;

        default:
          console.warn("Invalid report type selected");
          return;
      }

      setDataPreview(reportData);
      setReportGenerated(true);
      setSnackbar({
        open: true,
        message: "Report generated successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Error generating report:", error);
      setSnackbar({
        open: true,
        message: "Error generating report",
        severity: "error"
      });
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Autocomplete
              multiple
              options={getUniqueDistributors()}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={
                      selected ||
                      (option === "Select All" &&
                        selectedDistributors.length ===
                          getUniqueDistributors().length - 1)
                    }
                  />
                  {option}
                </li>
              )}
              value={selectedDistributors}
              onChange={(event, value) => handleDistributorChange(event, value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Distributors"
                  placeholder="Select distributors"
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: 200, // Limit dropdown height
                  overflowY: "auto" // Make dropdown scrollable
                }
              }}
              limitTags={12}
              size="small"
            />
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h6">Report Details</Typography>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Report Name"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Description"
                value={reportDescription}
                onChange={(e) => setReportDescription(e.target.value)}
                multiline
                rows={2}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="report-type-select-label">Report Type</InputLabel>
              <Select
                labelId="report-type-select-label"
                value={reportType}
                onChange={handleReportTypeChange}
                label="Report Type"
              >
                {reportCategory === "WarrantyReport" && [
                  <MenuItem key="Credit" value="Credit">
                    Credit Report
                  </MenuItem>,
                  <MenuItem key="Return" value="Return">
                    Return Report
                  </MenuItem>,
                  <MenuItem key="Invalid Credit" value="Invalid Credit">
                    Invalid Credit Report
                  </MenuItem>
                ]}
              </Select>
            </FormControl>

            {/* Radio buttons for Date selection */}
            <FormControl component="fieldset" margin="normal">
              <RadioGroup
                row
                value={dateOption}
                onChange={handleDateOptionChange}
              >
                <FormControlLabel
                  value="thisYear"
                  control={<Radio />}
                  label="This Year"
                />
                <FormControlLabel
                  value="thisMonth"
                  control={<Radio />}
                  label="This Month"
                />
                <FormControlLabel
                  value="specificDate"
                  control={<Radio />}
                  label="Specific Date"
                />
              </RadioGroup>
            </FormControl>

            {/* Conditionally render DatePickers based on dateOption */}
            {dateOption === "specificDate" && (
              <>
                <FormControl sx={{ mr: 2 }} margin="normal">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      views={["year", "month", "day"]}
                      value={dayjs(startDate)}
                      onChange={(newDate) =>
                        setStartDate(newDate?.toDate() || null)
                      }
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl margin="normal">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      views={["year", "month", "day"]}
                      value={dayjs(endDate)}
                      onChange={(newDate) =>
                        setEndDate(newDate?.toDate() || null)
                      }
                    />
                  </LocalizationProvider>
                </FormControl>
              </>
            )}
          </Box>
        );
      case 2:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              width: "100%",
              flexDirection: "column"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1.5,
                mb: 1.5
              }}
            >
              <StyledButton
                onClick={() => previewData(selectedDistributors)}
                icon={<ImageSearch />}
                iconColor={theme.palette.primary.main}
              >
                Preview Data
              </StyledButton>
              {reportGenerated ? (
                <Typography variant="body1">
                  Report Generated Successfully
                </Typography>
              ) : (
                <StyledButton
                  onClick={generateReport}
                  icon={<Assessment />}
                  iconColor={theme.palette.primary.main}
                >
                  Generate Report
                </StyledButton>
              )}
            </Box>
            {dataPreview !== null && dataPreview !== undefined && (
              <Box>
                {reportType === "Credit" && Array.isArray(dataPreview) && (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Distributor</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>No. of Warranties</TableCell>
                          <TableCell>Total Credit</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataPreview.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.distributor}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.numberOfWarranties}</TableCell>
                            <TableCell>
                              {item.creditAmount.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                {reportType === "Return" && Array.isArray(dataPreview) && (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Distributor</TableCell>
                          <TableCell> Email </TableCell>
                          <TableCell>Device Models</TableCell>
                          <TableCell>No. of returning devices</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataPreview.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.distributor}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.deviceModels}</TableCell>
                            <TableCell>{item.numberOfReturns}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                {reportType === "Invalid Credit" &&
                  Array.isArray(dataPreview) && (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Distributor</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>No. of Warranties</TableCell>
                            <TableCell>Total Credit</TableCell>
                            <TableCell>No. of Invalid Warranties</TableCell>
                            <TableCell>Invalid Credit Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataPreview.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.distributor}</TableCell>
                              <TableCell>{item.email}</TableCell>
                              <TableCell>{item.numberOfWarranties}</TableCell>
                              <TableCell>
                                {item.creditAmount?.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {item.numberOfInvalidCredits}
                              </TableCell>
                              <TableCell>
                                {item.totalInvalidCreditAmount?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
              </Box>
            )}
          </Box>
        );

      default:
        return "Unknown step";
    }
  };

  return (
    <Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <StepperMenu
        steps={steps}
        activeStep={activeStep}
        handleStepClick={handleStepClick}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
        disableNext={
          (activeStep === 0 && selectedDistributors.length === 0) ||
          (activeStep === 1 && !reportType) ||
          (activeStep === 1 && !startDate) ||
          (activeStep === 1 && !endDate) ||
          (activeStep === 1 && !reportName) ||
          (activeStep === 1 && !reportDescription)
        }
        disableFinish={activeStep !== steps.length - 1 || !reportGenerated}
      >
        {getStepContent(activeStep)}
      </StepperMenu>
      <Dialog
        open={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{ sx: { height: "80%" } }}
      >
        <DialogTitle>Report Preview</DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
            overflow: "hidden",
            height: "100%"
          }}
        >
          {selectedReport && (
            <Box sx={{ height: "100%" }}>
              {" "}
              <PDFWindowViewer
                selectedReport={selectedReport}
                showToolbar={false}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton
            variantType="outline-red"
            iconColor={theme.palette.primary.main}
            onClick={() => setIsPreviewOpen(false)}
          >
            Close
          </StyledButton>
          <StyledButton
            onClick={generateReport}
            disabled={!previewGenerated || reportGenerated}
            icon={<Assessment />}
            iconColor={theme.palette.primary.main}
          >
            Generate Report
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GenerateWarrantyReport;
