import axios from "axios";
import { DistributorCreationPayload } from "../common/types/UserTypes";
import { SalesData } from "../common/types/DistributorSalesTypes";
import { AccountsData } from "../common/types/DistributorSalesTypes";
import { Distributor } from "../store/useDistributor";
import useAuthStore from "../store/authStore";
const API_URL = process.env.REACT_APP_API_URL;

export const createDistributor = async (
  payload: DistributorCreationPayload
) => {
  try {
    const response = await axios.post(`${API_URL}/distributor`, payload);
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

export const fetchDistributors = async (): Promise<any> => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.get(`${API_URL}/distributor/all`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch Distributors:", error);
    throw new Error("Failed to fetch Distributors");
  }
};

export const changeDistributorStatus = async (
  distributorId: string,
  status: string
) => {
  try {
    const response = await axios.post(
      `${API_URL}/distributor/accountStatus`,
      {
        distributorId,
        status
      },
      {
        headers: {
          Authorization: `Bearer ${useAuthStore.getState().getAuthToken()}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to change Distributor status:", error);
    throw new Error("Failed to change Distributor status");
  }
};

export const fetchSubDistributors = async (): Promise<Distributor[]> => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.get(`${API_URL}/distributor/sub/all`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch sub-distributors:", error);
    throw new Error("Failed to fetch sub-distributors");
  }
};

export const addSubDistributorsToDistributor = async (
  subDistributor: {
    firstName: string;
    lastName: string;
    email: string;
  },
  parentDistributorEmail: string
) => {
  try {
    const response = await axios.post(`${API_URL}/distributor/sub/add`, {
      firstName: subDistributor.firstName,
      lastName: subDistributor.lastName,
      email: subDistributor.email,
      parentDistributorEmail
    });
    return response.data;
  } catch (error) {
    console.error("Failed to add sub-distributors:", error);
    throw new Error("Failed to add sub-distributors");
  }
};

export const getDistributorByInstallerEmail = async (
  email: string
): Promise<Distributor | null> => {
  try {
    const response = await axios.post<Distributor>(
      `${API_URL}/distributor/parent`,
      {
        email: email
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching distributor by installer email:", error);
    return null;
  }
};

export const changeSubDistributorStatus = async (
  subDistributorId: string,
  status: string
) => {
  try {
    const response = await axios.post(
      `${API_URL}/distributor/sub/accountStatus`,
      {
        subDistributorId,
        status
      },
      {
        headers: {
          Authorization: `Bearer ${useAuthStore.getState().getAuthToken()}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error changing sub-distributor status:", error);
    throw error;
  }
};

// API function to retrieve sales by distributor email
export const fetchSalesByDistributorEmail = async (
  distributorEmail: string,
  role: string
): Promise<SalesData[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/distributor-sales-reporting/all`,
      {
        params: { distributorEmail, role } // Ensure the param name matches the backend
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch sales:", error);
    throw error;
  }
};

// API function to create a new sales
export const createSalesReport = async (
  salesReport: SalesData
): Promise<SalesData> => {
  try {
    const response = await axios.post(
      `${API_URL}/distributor-sales-reporting/`,
      salesReport
    );
    return response.data;
  } catch (error) {
    console.error("Failed to create sales report:", error);
    throw error;
  }
};

// API function to upload sales reports
export const uploadSalesReports = async (
  salesReports: SalesData[]
): Promise<SalesData[]> => {
  try {
    const response = await axios.post(
      `${API_URL}/distributor-sales-reporting/upload`,
      salesReports
    );
    return response.data;
  } catch (error) {
    console.error("Failed to upload sales reports:", error);
    throw error;
  }
};

// API function to add sales reports based on quantity
export const bulkAddSalesReports = async (
  salesReport: SalesData,
  quantity: number
): Promise<SalesData[]> => {
  try {
    // Construct the request payload using the spread operator
    const payload = { ...salesReport, quantity };

    // Make the POST request
    const response = await axios.post(
      `${API_URL}/distributor-sales-reporting/bulk`,
      payload
    );

    return response.data;
  } catch (error) {
    console.error("Failed to add sales reports:", error);
    throw error;
  }
};

// get al accounts by distributor email
export const fetchAccountsByDistributorEmail = async (
  distributorEmail: string
): Promise<AccountsData[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/distributor-sales-reporting/accounts`,
      {
        params: { distributorEmail } // Ensure the param name matches the backend
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch accounts:", error);
    throw error;
  }
};

// Update a specific sales report by its ID
export const updateSalesReport = async (
  reportId: string,
  updatedData: Partial<SalesData> // Use Partial to allow partial updates
): Promise<SalesData> => {
  try {
    // Create a shallow copy of updatedData to avoid directly mutating the original object
    const sanitizedData = { ...updatedData };

    // Delete the distributorCountry property if it exists
    delete sanitizedData.distributorCountry;

    const response = await axios.patch(
      `${API_URL}/distributor-sales-reporting/${reportId}`,
      sanitizedData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update sales report:", error);
    throw error;
  }
};

// Delete a specific sales report by its ID
export const deleteSalesReport = async (reportId: string): Promise<void> => {
  try {
    await axios.delete(`${API_URL}/distributor-sales-reporting/${reportId}`);
  } catch (error) {
    console.error("Failed to delete sales report:", error);
    throw error;
  }
};
