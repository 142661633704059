import { Box, Divider, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { DeviceData } from "../../../../common/types/DeviceTypes";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const CartridgeLifeCard: React.FC<{ devices: DeviceData[] }> = ({
  devices
}) => {
  const calculateCartridgeStatuses = () => {
    const statuses = { HIGH: 0, OKAY: 0, LOW: 0, EMPTY: 0 };
    devices.forEach((device) => {
      const cyclesRemaining =
        // @ts-ignore
        device.deviceUsage.length > 0
          ? // @ts-ignore
            device.deviceUsage[device.deviceUsage.length - 1]
              ?.cyclesRemaining || 0
          : 0;
      const totalCycles =
        device.deviceInformation.currentCartridgeType?.includes("SAO-24")
          ? 550
          : 920;
      const percentage = (cyclesRemaining / totalCycles) * 100;

      if (percentage > 70) statuses.HIGH++;
      else if (percentage <= 70 && percentage > 30) statuses.OKAY++;
      else if (percentage <= 30 && percentage > 0) statuses.LOW++;
      else statuses.EMPTY++;
    });
    return statuses;
  };

  const cartridgeStatuses = calculateCartridgeStatuses();

  const cartridgeStatusData = {
    labels: ["High", "Okay", "Low", "Empty"],
    datasets: [
      {
        data: [
          cartridgeStatuses.HIGH,
          cartridgeStatuses.OKAY,
          cartridgeStatuses.LOW,
          cartridgeStatuses.EMPTY
        ],
        backgroundColor: [
          "rgba(30, 58, 138, 0.7)",
          "rgba(59, 130, 246, 0.7)",
          "rgba(255, 159, 64, 0.7)",
          "rgba(239, 68, 68, 0.7)"
        ],
        borderColor: [
          "rgba(30, 58, 138, 1)",
          "rgba(59, 130, 246, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(239, 68, 68, 1)"
        ],
        borderWidth: 1
      }
    ]
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "60%",
    plugins: {
      legend: {
        position: "right" as const,
        labels: {
          boxWidth: 15,
          padding: 15
        }
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.label || "";
            const value = context.raw || 0;
            const total = context.dataset.data.reduce(
              (a: number, b: number) => a + b,
              0
            );
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          }
        }
      },
      datalabels: {
        color: "#555"
      }
    }
  };

  const commonStyles = {
    borderRadius: "8px",
    border: "1px solid #d1d1d1",
    backgroundColor: "background.paper",
    color: "text.primary",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)"
  };

  return (
    <Box
      sx={{
        ...commonStyles,
        p: 2,
        height: "200px",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          mb: 1,
          fontWeight: "700"
        }}
      >
        Cartridge Life Distribution
      </Typography>

      <Divider sx={{ mb: 2 }} />

      <Box
        sx={{
          flexGrow: 1,
          width: "90%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Doughnut data={cartridgeStatusData} options={pieOptions} />
      </Box>
    </Box>
  );
};

export default CartridgeLifeCard;
