import React from "react";
import {
  TimelineItem as MuiTimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent
} from "@mui/lab";
import { Box, Typography, Paper } from "@mui/material";
import { ICleanUsage } from "../../../common/types/DeviceTypes";

interface DeviceUsageTimelineItemProps {
  usage: ICleanUsage;
}

const DeviceUsageTimelineItem: React.FC<DeviceUsageTimelineItemProps> = ({
  usage
}) => {
  const uploadFormattedTime = new Date(
    usage.uploadTimestamp
  ).toLocaleTimeString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });

  const usageFormattedTime = new Date(usage.usageTimestamp).toLocaleTimeString(
    "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    }
  );

  return (
    <MuiTimelineItem>
      <TimelineSeparator>
        <TimelineDot color="primary" />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper
          elevation={0}
          sx={{
            padding: "6px 16px",
            margin: "0",
            border: "1px solid #bbb",
            borderRadius: 0
          }}
        >
          <Typography variant="body1" component="span" fontWeight={800}>
            {uploadFormattedTime}
          </Typography>
          <Box sx={{ marginTop: 1 }}>
            <Typography color="textSecondary" fontWeight={500}>
              Liters Dispensed: {usage.litersDispensed.toFixed(2)} L
            </Typography>
            <Typography color="textSecondary" fontWeight={500}>
              Cycles Remaining: {usage.cyclesRemaining}
            </Typography>
            <Typography color="textSecondary" fontWeight={500}>
              Seconds Run: {usage.secsRun}
            </Typography>
            <Typography color="textSecondary" fontWeight={500}>
              Usage at: {usageFormattedTime}
            </Typography>
          </Box>
        </Paper>
      </TimelineContent>
    </MuiTimelineItem>
  );
};

export default DeviceUsageTimelineItem;
