import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { DeviceErrors } from "../../../../common/types/DeviceTypes";

interface DeviceErrorsProps {
  error: DeviceErrors;
}

const ErrorCard: React.FC<DeviceErrorsProps> = ({ error }) => {
  return (
    <Card
      sx={{
        cursor: "pointer",
        height: "95%",
        width: "100%",
        borderRadius: 0
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1
          }}
        >
          <Typography
            sx={{
              marginTop: 0.2,
              fontSize: "12px",
              fontWeight: 600
            }}
          >
            {error.deviceName}
          </Typography>
          <Box
            sx={{
              bgcolor:
                error.error === "SERVICE"
                  ? "#f44336"
                  : error.error === "LOW FLOW"
                    ? "#ff9800"
                    : error.error === "LOW CARTRIDGE"
                      ? "#2196f3"
                      : "#4caf50",
              borderRadius: 1,
              px: 0.5,
              py: 0.2
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "11px",
                fontWeight: 600
              }}
            >
              {error.error}
            </Typography>
          </Box>
        </Box>

        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          Date: {new Date(error.date).toLocaleString()}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Device ID: {error.deviceId}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Serial Number: {error.serialNumber}
        </Typography>
      </CardContent>
      <Box
        sx={{
          bgcolor:
            error.error === "SERVICE"
              ? "#f44336"
              : error.error === "LOW FLOW"
                ? "#ff9800"
                : error.error === "LOW CARTRIDGE"
                  ? "#2196f3"
                  : "#4caf50",

          p: "4px"
        }}
      />
    </Card>
  );
};

export default ErrorCard;
