export const gridSpacing = 3;
export const drawerWidth = 270;
export const miniDrawerWidth = 58;
export const iCleanCartridgeLife = 920;
export const offlineThresholdDays = 30;

export type SelectedPortalType = "Lotus-Pro" | "iCleanLines";

export const countryLabels = [
  { label: "Canada" },
  { label: "United States" },

  { label: "Argentina" },
  { label: "Austria" },
  { label: "Australia" },
  { label: "Azerbaijan" },
  { label: "Belgium" },
  { label: "Bulgaria" },
  { label: "Brazil" },
  { label: "Bahamas" },
  { label: "Belarus" },
  { label: "Switzerland" },
  { label: "Chile" },
  { label: "China" },
  { label: "Colombia" },
  { label: "Costa Rica" },
  { label: "Cuba" },
  { label: "Cyprus" },
  { label: "Czech Republic" },
  { label: "Germany" },
  { label: "Denmark" },
  { label: "Estonia" },
  { label: "Egypt" },
  { label: "Finland" },
  { label: "Fiji" },
  { label: "France" },
  { label: "Georgia" },
  { label: "Greenland" },
  { label: "Greece" },
  { label: "Hong Kong" },
  { label: "Croatia" },
  { label: "Hungary" },
  { label: "Indonesia" },
  { label: "Ireland" },
  { label: "Israel" },
  { label: "India" },
  { label: "Iceland" },
  { label: "Italy" },

  { label: "Japan" },
  { label: "Korea, Democratic People's Republic of" },
  { label: "Kuwait" },
  { label: "Lithuania" },
  { label: "Luxembourg" },
  { label: "Morocco" },
  { label: "Monaco" },
  { label: "Mexico" },
  { label: "Malaysia" },
  { label: "Netherlands" },
  { label: "Norway" },
  { label: "New Zealand" },
  { label: "Oman" },
  { label: "Panama" },
  { label: "Philippines" },
  { label: "Poland" },
  { label: "Puerto Rico" },
  { label: "Portugal" },
  { label: "Paraguay" },
  { label: "Qatar" },
  { label: "Romania" },
  { label: "Serbia" },
  { label: "Saudi Arabia" },
  { label: "Spain" },
  { label: "Sweden" },
  { label: "Singapore" },
  { label: "Slovenia" },
  { label: "Slovakia" },
  { label: "Thailand" },
  { label: "Turkey" },
  { label: "Taiwan" },
  { label: "Ukraine" },
  { label: "United Arab Emirates" },
  { label: "United Kingdom" },
  { label: "Uruguay" },
  { label: "Venezuela" },
  { label: "South Africa" }
];
