import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import StyledButton from "../../../shared/Components/Styled/StyledButton";
import {
  AccountStatusTypes,
  UserDetails
} from "../../../common/types/UserTypes";
import authStore from "../../../store/authStore";

interface EditUserAccountStatusModalProps {
  open: boolean;
  user?: UserDetails | null;
  onClose: () => void;
  onSubmit: (updatedStatus: AccountStatusTypes) => Promise<void>;
}

const EditUserAccountStatusModal: React.FC<EditUserAccountStatusModalProps> = ({
  open,
  user,
  onClose,
  onSubmit
}) => {
  const [accountStatus, setAccountStatus] = useState<AccountStatusTypes>(
    (user?.accountStatus as AccountStatusTypes) || ""
  );

  useEffect(() => {
    if (user) {
      setAccountStatus(user?.accountStatus as AccountStatusTypes);
    }
  }, [user]);

  const { userRole } = authStore.getState() || {};

  const handleSubmit = async () => {
    await onSubmit(accountStatus);
    onClose();
  };

  if (!user) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Account Status</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Account Status</InputLabel>
          <Select
            value={accountStatus}
            label="Account Status *"
            onChange={(event) =>
              setAccountStatus(event.target.value as AccountStatusTypes)
            }
          >
            <MenuItem value={AccountStatusTypes.Active}>
              {AccountStatusTypes.Active}
            </MenuItem>
            <MenuItem value={AccountStatusTypes.Inactive}>
              {AccountStatusTypes.Inactive}
            </MenuItem>
            {userRole === "Admin" && (
              <MenuItem disabled value={AccountStatusTypes.Deleted}>
                {AccountStatusTypes.Deleted}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onClose} variantType="outline-red">
          Cancel
        </StyledButton>
        <StyledButton onClick={handleSubmit} variantType="primary">
          Save Changes
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserAccountStatusModal;
