import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Tooltip,
  Typography,
  MenuItem,
  Alert,
  Snackbar,
  AlertColor,
  useTheme,
  Menu,
  useMediaQuery,
  TextField,
  Autocomplete,
  Select,
  InputLabel,
  SelectChangeEvent,
  FormControl
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { SalesData } from "../../common/types/DistributorSalesTypes";
import useAuthStore from "../../store/authStore";
import AddSalesReportModal from "./Sales/Modal/AddSalesModal";
import UploadCsvModal from "./Sales/Modal/UploadSalesCsvModal";
import UploadFile from "@mui/icons-material/UploadFile";
import StyledButton from "../../shared/Components/Styled/StyledButton";
import { StyledTabs } from "../../shared/Components/Styled/StyledIconTab";
import StyledTab from "../../shared/Components/Styled/StyledTab";
import GenerateSalesReport from "./GenerateReport/GenerateSalesReport";
import SalesReportHistory from "./History/SalesReportHistory";
import MonthlySalesReportTable from "./Sales/Table/MonthlySalesReportTable";
import { useDistributorAccounts } from "./Hooks/useDistributorAccounts";
import { useDistributorSales } from "./Hooks/useDistributorSales";

const MonthlySalesReport: React.FC<{}> = () => {
  const { accounts, fetchAccounts } = useDistributorAccounts();
  const { sales, fetchSales } = useDistributorSales();
  const [openModal, setOpenModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isMultipleEntries, setIsMultipleEntries] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [distributorFilter, setDistributorFilter] = useState<string>("");
  const [countryFilter, setCountryFilter] = useState<string>("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({
    open: false,
    message: "",
    severity: undefined
  });

  const { user, userRole } = useAuthStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleSingleEntry = () => {
    handleClose();
    setIsMultipleEntries(false);
    setOpenModal(true); // Logic for adding a single entry
  };

  const handleMultipleEntries = () => {
    handleClose();
    setIsMultipleEntries(true);
    setOpenModal(true); // Logic for adding multiple entries
  };

  useEffect(() => {
    fetchAccounts();
    fetchSales();
  }, [fetchAccounts, fetchSales]);

  const handleAddReportSuccess = async () => {
    try {
      // Re-fetch the sales data after adding a new report
      await fetchSales(); // This function should fetch the latest data from your API
      await fetchAccounts();
      setSnackbar({
        open: true,
        message: "Report added successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Failed to fetch updated sales data:", error);
    }
  };

  const handleBulkAddReportSuccess = async () => {
    try {
      // Re-fetch the sales data after adding a new report
      await fetchSales(); // This function should fetch the latest data from your API
      setSnackbar({
        open: true,
        message: "Reports added successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Failed to fetch updated sales data:", error);
    }
  };

  const handleAddReportError = (message: string) => {
    console.error("Failed to add report:", message);
    setSnackbar({
      open: true,
      message,
      severity: "error"
    });
  };

  const handleSubmit = (isSuccess: boolean) => {
    if (isSuccess) {
      setSnackbar({
        open: true,
        message: "Report generated successfully",
        severity: "success"
      });
    } else {
      setSnackbar({
        open: true,
        message: "Failed to generate sales report",
        severity: "error"
      });
    }
  };

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleCountryFilterChange = (event: SelectChangeEvent<string>) => {
    setCountryFilter(event.target.value);
  };

  return (
    <Box sx={{ p: 0 }}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" }
          }}
        >
          <Typography sx={{ mb: 2 }} variant="h6">
            Distributor Monthly Sales Reporting
          </Typography>
          {userRole === "Admin" && (
            <StyledTabs
              value={selectedTab}
              onChange={handleChangeTab}
              aria-label="Profile tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <StyledTab label="Sales" />
              <StyledTab label="Generate Report" />
              <StyledTab label="History" />
            </StyledTabs>
          )}
          {selectedTab === 0 && (
            <Box>
              <Box
                sx={{
                  display: isMobile ? "flex-column" : "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  p: 0,
                  mt: 2
                }}
              >
                <Box sx={{ display: "flex", gap: 1.5 }}>
                  {/* Search input field */}
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{
                      width: isMobile ? "100%" : "300px",
                      borderRadius: 1,
                      backgroundColor: "#f5f5f5",
                      marginBottom: isMobile ? 2 : 0,
                      "& .MuiOutlinedInput-root": {
                        height: "32px"
                      }
                    }}
                  />
                  {userRole === "Admin" && (
                    <>
                      <Autocomplete
                        options={[
                          ...new Set(sales.map((sale) => sale.distributorEmail))
                        ]}
                        value={distributorFilter}
                        onChange={(_event, value) => {
                          setDistributorFilter(value as string);
                          console.log(distributorFilter);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Distributor"
                            variant="outlined"
                            size="small"
                          />
                        )}
                        sx={{
                          width: isMobile ? "100%" : "220px",
                          "& .MuiOutlinedInput-root": {
                            height: "32px"
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "8px 14px"
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "0.875rem"
                          }
                        }}
                      />
                      <FormControl
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "170px",

                          "& .MuiOutlinedInput-root": {
                            height: "32px"
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "8px 14px"
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "0.875rem"
                          }
                        }}
                      >
                        <InputLabel>Country</InputLabel>
                        <Select
                          value={countryFilter}
                          onChange={handleCountryFilterChange}
                          variant="outlined"
                          name="country"
                          label="Country"
                        >
                          <MenuItem value="">Country</MenuItem>
                          {[
                            ...new Set(
                              sales.map((sale) => sale.distributorCountry)
                            )
                          ].map((country) => (
                            <MenuItem key={country} value={country}>
                              {country}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    gap: 1.5,
                    mb: 1.5
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      height: "30px",
                      border: "1px solid #e0e0e0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      px: 1
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 700
                      }}
                    >
                      Total Sales :{" "}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 600,
                        ml: 0.5
                      }}
                    >
                      {" "}
                      {sales.length}
                    </Typography>
                  </Paper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      gap: 1.5
                    }}
                  >
                    <Tooltip title="Add New Sales" placement="bottom">
                      <>
                        <StyledButton
                          icon={<AddCircle />}
                          iconColor={theme.palette.primary.main}
                          onClick={handleClick}
                        >
                          Add Sales
                        </StyledButton>
                        {/* Dropdown Menu */}
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                          }}
                        >
                          <MenuItem onClick={handleSingleEntry}>
                            Add a Single Entry
                          </MenuItem>
                          <MenuItem onClick={handleMultipleEntries}>
                            Add Multiple Entries
                          </MenuItem>
                        </Menu>
                      </>
                    </Tooltip>
                    <Tooltip title="Upload Sales Report" placement="bottom">
                      <StyledButton
                        icon={<UploadFile />}
                        iconColor={theme.palette.primary.main}
                        onClick={() => setOpenUploadModal(true)}
                      >
                        Upload CSV
                      </StyledButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              {/* Monthly Sales Reporting Table */}
              <MonthlySalesReportTable
                distributorFilter={distributorFilter}
                countryFilter={countryFilter}
                searchTerm={searchTerm}
                setSnackbar={setSnackbar}
                sales={sales}
                accounts={accounts}
                onDeleteSuccess={fetchSales}
                onEditSuccess={() => {
                  fetchSales();
                  fetchAccounts();
                }}
              />
            </Box>
          )}

          {selectedTab === 1 && userRole === "Admin" && (
            <GenerateSalesReport sales={sales} onSubmit={handleSubmit} />
          )}

          {selectedTab === 2 && userRole === "Admin" && <SalesReportHistory />}
        </Box>
      </Box>
      {/* Add Sales Report Modal */}
      <AddSalesReportModal
        open={openModal}
        isMultipleEntries={isMultipleEntries}
        onClose={() => setOpenModal(false)}
        onSuccess={handleAddReportSuccess}
        onError={handleAddReportError}
        accounts={accounts}
        distributorEmail={user?.email || ""}
      />{" "}
      {/* Upload Sales Report Modal */}
      <UploadCsvModal
        open={openUploadModal} // control the modal open state
        onClose={() => setOpenUploadModal(false)}
        onUploadSuccess={(_csvData: SalesData[], errorMessage?: string) => {
          if (errorMessage) {
            setSnackbar({
              open: true,
              message: errorMessage,
              severity: "error"
            });
          } else {
            handleBulkAddReportSuccess();
          }
        }}
        distributorEmail={user?.email || ""}
      />
      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MonthlySalesReport;
