import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const TrackPageViews: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search
    });
  }, [location]); // Track page view on location change

  console.log("Tracking page view:", location.pathname + location.search);
  return null;
};

export default TrackPageViews;
