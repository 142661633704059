import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import {
  Badge,
  Button,
  ClickAwayListener,
  Chip,
  Fade,
  Paper,
  Popper,
  List,
  ListItemText,
  ListSubheader,
  Typography,
  ListItemButton,
  Box
} from "@mui/material";
import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";
import dayjs from "dayjs";
import { Alert20Regular } from "@fluentui/react-icons";

const API_URL = process.env.REACT_APP_API_URL;

type userEmail = string;

interface NotificationProps {
  email: userEmail;
  onChangeTab: (tab: string) => void;
}

type DeviceNotificationType = {
  _id: string;
  deviceId: string;
  type: string;
  title: string;
  message: string;
  recipients: Array<{
    email: string;
    isRead: boolean;
  }>;
  createdAt: string;
  isRead: boolean;
};

const Notification: React.FC<NotificationProps> = ({ email, onChangeTab }) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<any>(null);
  const [unreadNotifications, setUnreadNotifications] = useState<
    DeviceNotificationType[]
  >([]);
  const [readNotifications, setReadNotifications] = useState<
    DeviceNotificationType[]
  >([]);
  const [totalUnread, setTotalUnread] = useState<number>(0);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: React.MouseEvent | null) => {
    if (
      event &&
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef.current) {
        (anchorRef.current as HTMLElement).focus();
      }
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    // returns only 5 for now
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/web-notification/device/unread?email=${email}`
        );
        setUnreadNotifications(
          response.data.deviceWebNotifications.notifications
        );
        setTotalUnread(response.data.deviceWebNotifications.totalUnreadCount);
      } catch (error) {
        console.error("Failed to fetch web notifications:", error);
      }
    };

    fetchNotifications();
    const interval = setInterval(fetchNotifications, 3600000);
    return () => clearInterval(interval);
  }, [email]);

  const handleNotificationClick = async (
    notificationId: string,
    email: string,
    isRead: boolean
  ) => {
    if (isRead) return;
    try {
      await axios.post(`${API_URL}/web-notification/device/read`, {
        notificationId,
        email
      });

      setUnreadNotifications((prev) =>
        prev.filter((n) => n._id !== notificationId)
      );
      setReadNotifications((prev) => [
        ...prev,
        {
          ...unreadNotifications.find((n) => n._id === notificationId)!,
          recipients: [
            {
              email,
              isRead: true
            }
          ]
        }
      ]);
      setTotalUnread((prev) => prev - 1);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const getNotificationColor = (type: string, isRead: boolean) => {
    if (isRead) return "grey";
    switch (type.toLowerCase()) {
      case "alert":
        return "yellow";
      case "warning":
        return "orange";
      case "error":
        return "red";
      case "info":
      default:
        return "blue";
    }
  };

  const unreadCount = totalUnread;

  return (
    <>
      <Button
        sx={{
          minWidth: { sm: 48, xs: 35 },
          width: "48px",
          p: 2,
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)"
          }
        }}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        aria-label="Notification"
        onClick={handleToggle}
        color="inherit"
      >
        <Badge badgeContent={unreadCount} color="error">
          <Alert20Regular />
        </Badge>
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 10]
            }
          },
          {
            name: "preventOverflow",
            options: {
              altAxis: true
            }
          }
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper sx={{ maxHeight: 300, overflowX: "hidden" }}>
              <ClickAwayListener
                onClickAway={(event: any) => handleClose(event)}
              >
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 350,
                    minWidth: 250,
                    backgroundColor: theme.palette.background.paper,
                    pb: 0,

                    borderRadius: "10px"
                  }}
                >
                  <ListSubheader disableSticky>
                    <Chip
                      size="small"
                      color="primary"
                      sx={{ fontWeight: 700 }}
                      label="New"
                    />
                  </ListSubheader>

                  {unreadNotifications.length === 0 ? (
                    <ListItemButton>
                      <ListItemText primary="No new notifications, it's iClean!" />
                    </ListItemButton>
                  ) : (
                    unreadNotifications.map((notification) => (
                      <NotificationItem
                        key={notification._id}
                        notification={notification}
                        email={email}
                        handleNotificationClick={handleNotificationClick}
                        getNotificationColor={getNotificationColor}
                        theme={theme}
                      />
                    ))
                  )}
                  {readNotifications.length > 0 && (
                    <>
                      <ListSubheader disableSticky>
                        <Chip size="small" variant="outlined" label="EARLIER" />
                      </ListSubheader>
                      {readNotifications.map((notification) => (
                        <NotificationItem
                          key={notification._id}
                          notification={notification}
                          email={email}
                          handleNotificationClick={handleNotificationClick}
                          getNotificationColor={getNotificationColor}
                          theme={theme}
                          disabled
                        />
                      ))}
                    </>
                  )}
                  <ListItemButton
                    onClick={() => {
                      onChangeTab("notifications");
                      handleClose();
                    }}
                  >
                    <ListItemText
                      primary="View All Notifications"
                      sx={{
                        textAlign: "center",
                        color: theme.palette.primary.main
                      }}
                    />
                  </ListItemButton>
                </List>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

interface NotificationItemProps {
  notification: DeviceNotificationType;
  email: string;
  handleNotificationClick: (id: string, email: string, isRead: boolean) => void;
  getNotificationColor: (type: string, isRead: boolean) => string;
  theme: any;
  disabled?: boolean;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  email,
  handleNotificationClick,
  getNotificationColor,
  theme,
  disabled = false
}) => (
  <ListItemButton
    alignItems="flex-start"
    sx={{
      py: 1,
      position: "relative",
      overflow: "hidden"
    }}
    onClick={() =>
      handleNotificationClick(notification._id, email, notification.isRead)
    }
    disabled={disabled}
  >
    <Box
      sx={{
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        width: "4px",
        backgroundColor: getNotificationColor(
          notification.type,
          notification.isRead
        )
      }}
    />
    {/* Center content: title and message */}
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ml: "12px",
        flex: 1,
        overflow: "hidden"
      }}
    >
      <Typography
        sx={{ fontSize: "13px", fontWeight: 600, wordBreak: "break-word" }}
      >
        {notification.type}: {notification.title}
      </Typography>
      <Typography variant="subtitle2" sx={{ wordBreak: "break-word" }}>
        {notification.message}
      </Typography>
    </Box>
    {/* Right side: createdAt time */}
    <Box
      sx={{
        display: "flex",
        alignItems: "center",

        ml: "8px",

        flexShrink: 0
      }}
    >
      <QueryBuilderTwoToneIcon
        sx={{
          fontSize: "0.75rem",
          mr: 0.5,
          color: getNotificationColor(notification.type, notification.isRead)
        }}
      />
      <Typography
        variant="caption"
        display="block"
        sx={{ color: theme.palette.grey[400], whiteSpace: "nowrap" }}
      >
        {dayjs(notification.createdAt).fromNow()}
      </Typography>
    </Box>
  </ListItemButton>
);

export default Notification;
