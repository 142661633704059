import React from "react";
import { Box, Divider } from "@mui/material";
import NavItem from "./NavItem/NavItem";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import {
  Home20Regular,
  Board20Regular,
  ChatHelp20Regular,
  DocumentBulletListMultiple20Regular,
  People20Regular,
  Person20Regular,
  SignOut20Regular,
  PhoneLinkSetup20Regular,
  DeviceMeetingRoomRemote20Regular,
  PreviewLink20Regular,
  AddCircle20Regular,
  MailAlert20Regular,
  DualScreenClosedAlert20Regular,
  ArrowRedo20Regular,
  VehicleTruckCube20Regular,
  AirplaneLanding20Regular,
  BoxToolbox20Regular,
  BookExclamationMark20Regular,
  BoxMultiple20Regular,
  ChartMultiple20Regular,
  ArrowTurnDownRight20Regular
} from "@fluentui/react-icons";

interface SidebarProps {
  activeTab: string;
  onTabClick: (tabName: string) => void;
  drawerOpen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  activeTab,
  onTabClick,
  drawerOpen
}) => {
  const { logout, userRole } = useAuthStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Box sx={{ width: 250, pt: 1, pl: 2 }}>
      {userRole && (userRole === "SuperAdmin" || userRole === "Admin") && (
        <>
          <NavItem
            icon={<Board20Regular />}
            label="Dashboard"
            active={activeTab === "iClean"}
            onClick={() => onTabClick("iClean")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<DeviceMeetingRoomRemote20Regular />}
            label="Manage Devices"
            active={activeTab === "devices"}
            onClick={() => onTabClick("devices")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<People20Regular />}
            label="Manage Users"
            active={activeTab === "manageUsers"}
            onClick={() => onTabClick("manageUsers")}
            showLabel={drawerOpen}
          ></NavItem>
          <NavItem
            icon={<DocumentBulletListMultiple20Regular />}
            label="Reports"
            active={activeTab === "deviceReports"}
            onClick={() => onTabClick("deviceReports")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<PreviewLink20Regular />}
            label="Monthly Sales Report"
            active={activeTab === "retentionSheet"}
            onClick={() => onTabClick("retentionSheet")}
            showLabel={drawerOpen}
          />
          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<DualScreenClosedAlert20Regular />}
            label="Change Requests"
            active={activeTab === "changeRequests"}
            onClick={() => onTabClick("changeRequests")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<MailAlert20Regular />}
            label="Email Setup"
            active={activeTab === "notificationSettings"}
            onClick={() => onTabClick("notificationSettings")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "Distributor" && (
        <>
          <NavItem
            icon={<Home20Regular />}
            label="Home"
            active={activeTab === "home"}
            onClick={() => onTabClick("home")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<PhoneLinkSetup20Regular />}
            label="Install Device"
            active={activeTab === "install"}
            onClick={() => onTabClick("install")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<Board20Regular />}
            label="Dashboards"
            active={activeTab === "iClean"}
            onClick={() => onTabClick("iClean")}
            showLabel={drawerOpen}
          ></NavItem>
          <NavItem
            icon={<DeviceMeetingRoomRemote20Regular />}
            label="Manage Devices"
            active={activeTab === "devices"}
            onClick={() => onTabClick("devices")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<People20Regular />}
            label="Manage Users"
            active={activeTab === "manageUsers"}
            onClick={() => onTabClick("manageUsers")}
            showLabel={drawerOpen}
          ></NavItem>
          <NavItem
            icon={<DocumentBulletListMultiple20Regular />}
            label="Reports"
            active={activeTab === "deviceReports"}
            onClick={() => onTabClick("deviceReports")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<ChatHelp20Regular />}
            label="Help Desk"
            active={activeTab === "userSupport"}
            onClick={() => onTabClick("userSupport")}
            showLabel={drawerOpen}
          />
          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<Person20Regular />}
            label="My Account"
            active={activeTab === "profile"}
            onClick={() => onTabClick("profile")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<PreviewLink20Regular />}
            label="Monthly Sales Report"
            active={activeTab === "retentionSheet"}
            onClick={() => onTabClick("retentionSheet")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "SubDistributor" && (
        <>
          <NavItem
            icon={<Home20Regular />}
            label="Home"
            active={activeTab === "home"}
            onClick={() => onTabClick("home")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<Board20Regular />}
            label="Dashboards"
            active={activeTab === "iClean"}
            onClick={() => onTabClick("iClean")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<DeviceMeetingRoomRemote20Regular />}
            label="Manage Devices"
            active={activeTab === "devices"}
            onClick={() => onTabClick("devices")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<People20Regular />}
            label="Manage Users"
            active={activeTab === "manageUsers"}
            onClick={() => onTabClick("manageUsers")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<DocumentBulletListMultiple20Regular />}
            label="Reports"
            active={activeTab === ""}
            onClick={() => onTabClick("")}
            showLabel={drawerOpen}
          >
            <NavItem
              nested
              icon={<ArrowTurnDownRight20Regular />}
              label="Devices"
              active={activeTab === "deviceReports"}
              onClick={() => onTabClick("deviceReports")}
              showLabel={drawerOpen}
            />
          </NavItem>

          <NavItem
            icon={<ChatHelp20Regular />}
            label="Help Desk"
            active={activeTab === "userSupport"}
            onClick={() => onTabClick("userSupport")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<PreviewLink20Regular />}
            label="Monthly Sales Report"
            active={activeTab === "retentionSheet"}
            onClick={() => onTabClick("retentionSheet")}
            showLabel={drawerOpen}
          />

          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<Person20Regular />}
            label="My Account"
            active={activeTab === "profile"}
            onClick={() => onTabClick("profile")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "Installer" && (
        <>
          <NavItem
            icon={<Home20Regular />}
            label="Home"
            active={activeTab === "home"}
            onClick={() => onTabClick("home")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<PhoneLinkSetup20Regular />}
            label="Install Device"
            active={activeTab === "install"}
            onClick={() => onTabClick("install")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<Board20Regular />}
            label="Dashboards"
            active={activeTab === "iClean"}
            onClick={() => onTabClick("iClean")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<DeviceMeetingRoomRemote20Regular />}
            label="Manage Devices"
            active={activeTab === "devices"}
            onClick={() => onTabClick("devices")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<People20Regular />}
            label="Manage Customers"
            active={activeTab === "manageCustomers"}
            onClick={() => onTabClick("manageCustomers")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<DocumentBulletListMultiple20Regular />}
            label="Reports"
            active={activeTab === "device"}
            onClick={() => onTabClick("")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<ChatHelp20Regular />}
            label="Help Desk"
            active={activeTab === "userSupport"}
            onClick={() => onTabClick("userSupport")}
            showLabel={drawerOpen}
          />

          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<Person20Regular />}
            label="My Account"
            active={activeTab === "profile"}
            onClick={() => onTabClick("profile")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "Tester" && (
        <>
          <NavItem
            icon={<AddCircle20Regular />}
            label="Create Device Test"
            active={activeTab === "deviceTests"}
            onClick={() => onTabClick("deviceTests")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<ArrowRedo20Regular />}
            label="Create Device Rework"
            active={activeTab === "deviceReworks"}
            onClick={() => onTabClick("deviceReworks")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<VehicleTruckCube20Regular />}
            label="Device Returns"
            active={activeTab === "deviceArrivals"}
            onClick={() => onTabClick("deviceArrivals")}
            showLabel={drawerOpen}
          />
          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "Production" && (
        <>
          <NavItem
            icon={<VehicleTruckCube20Regular />}
            label="Create Device Return"
            active={activeTab === "createDeviceReturn"}
            onClick={() => onTabClick("createDeviceReturn")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<AirplaneLanding20Regular />}
            label="Device Arrivals"
            active={activeTab === "deviceArrivals"}
            onClick={() => onTabClick("deviceArrivals")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<BoxToolbox20Regular />}
            label="Device Parts"
            active={activeTab === "addRecoveredParts"}
            onClick={() => onTabClick("addRecoveredParts")}
            showLabel={drawerOpen}
          />

          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}
      {userRole && userRole === "Supervisor" && (
        <>
          <NavItem
            icon={<BookExclamationMark20Regular />}
            label="Warranty Claims"
            active={activeTab === "productWarranties"}
            onClick={() => onTabClick("productWarranties")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<AirplaneLanding20Regular />}
            label="Device Arrivals"
            active={activeTab === "deviceArrivals"}
            onClick={() => onTabClick("deviceArrivals")}
            showLabel={drawerOpen}
          />

          <Divider sx={{ my: 2 }} />

          <NavItem
            icon={<BoxMultiple20Regular />}
            label="Inventory Parts"
            active={activeTab === "inventoryParts"}
            onClick={() => onTabClick("inventoryParts")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<ArrowRedo20Regular />}
            label={
              userRole === "Supervisor"
                ? "Device Reworks"
                : "Create Device Rework"
            }
            active={activeTab === "deviceReworks"}
            onClick={() => onTabClick("deviceReworks")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<BoxToolbox20Regular />}
            label="Device Parts"
            active={activeTab === "addRecoveredParts"}
            onClick={() => onTabClick("addRecoveredParts")}
            showLabel={drawerOpen}
          />
          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<DocumentBulletListMultiple20Regular />}
            label="Reports"
            active={activeTab === ""}
            onClick={() => onTabClick("")}
            showLabel={drawerOpen}
          >
            {" "}
            <NavItem
              nested
              icon={<ArrowTurnDownRight20Regular />}
              label="Inventory"
              active={activeTab === "inventoryReports"}
              onClick={() => onTabClick("inventoryReports")}
              showLabel={drawerOpen}
            />
            <NavItem
              nested
              icon={<ArrowTurnDownRight20Regular />}
              label="Warranty"
              active={activeTab === "serviceReports"}
              onClick={() => onTabClick("serviceReports")}
              showLabel={drawerOpen}
            />
          </NavItem>

          <Divider sx={{ my: 2 }} />
          {/* Settings and other options */}
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "Customer" && (
        <>
          <NavItem
            icon={<Home20Regular />}
            label="Home"
            active={activeTab === "home"}
            onClick={() => onTabClick("home")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<Board20Regular />}
            label="Dashboard"
            active={activeTab === "iClean"}
            onClick={() => onTabClick("iClean")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<DeviceMeetingRoomRemote20Regular />}
            label="My Devices"
            active={activeTab === "myDevices"}
            onClick={() => onTabClick("myDevices")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<ChatHelp20Regular />}
            label="Help Desk"
            active={activeTab === "userSupport"}
            onClick={() => onTabClick("userSupport")}
            showLabel={drawerOpen}
          />

          {/* ...other nav items */}
          <Divider sx={{ my: 2 }} />
          {/* Settings and other options */}
          <NavItem
            icon={<Person20Regular />}
            label="My Account"
            active={activeTab === "profile"}
            onClick={() => onTabClick("profile")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "GroupCustomer" && (
        <>
          <NavItem
            icon={<Home20Regular />}
            label="Home"
            active={activeTab === "home"}
            onClick={() => onTabClick("home")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<Board20Regular />}
            label="Dashboard"
            active={activeTab === "iClean"}
            onClick={() => onTabClick("iClean")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<DeviceMeetingRoomRemote20Regular />}
            label="My Devices"
            active={activeTab === "myDevices"}
            onClick={() => onTabClick("myDevices")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<People20Regular />}
            label="Manage Users"
            active={activeTab === "manageCustomers"}
            onClick={() => onTabClick("manageCustomers")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<ChatHelp20Regular />}
            label="Help Desk"
            active={activeTab === "userSupport"}
            onClick={() => onTabClick("userSupport")}
            showLabel={drawerOpen}
          />

          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "NationalAccount" && (
        <>
          <NavItem
            icon={<Home20Regular />}
            label="Home"
            active={activeTab === "home"}
            onClick={() => onTabClick("home")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<Board20Regular />}
            label="Dashboard"
            active={activeTab === "iClean"}
            onClick={() => onTabClick("iClean")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<DeviceMeetingRoomRemote20Regular />}
            label="My Devices"
            active={activeTab === "myDevices"}
            onClick={() => onTabClick("myDevices")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<ChatHelp20Regular />}
            label="Customer Support"
            active={activeTab === "userSupport"}
            onClick={() => onTabClick("userSupport")}
            showLabel={drawerOpen}
          />
          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "GlobalAccount" && (
        <>
          <NavItem
            icon={<Home20Regular />}
            label="Home"
            active={activeTab === "home"}
            onClick={() => onTabClick("home")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<Board20Regular />}
            label="Dashboard"
            active={activeTab === "iClean"}
            onClick={() => onTabClick("iClean")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<ChatHelp20Regular />}
            label="Customer Support"
            active={activeTab === "userSupport"}
            onClick={() => onTabClick("userSupport")}
            showLabel={drawerOpen}
          />

          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "Service" && (
        <>
          <NavItem
            icon={<ChartMultiple20Regular />}
            label="Summary"
            active={activeTab === "serviceSummary"}
            onClick={() => onTabClick("serviceSummary")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<AddCircle20Regular />}
            label="Create Warranty Claim"
            active={activeTab === "createWarrantyClaim"}
            onClick={() => onTabClick("createWarrantyClaim")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<BookExclamationMark20Regular />}
            label="Warranty Claims"
            active={activeTab === "productWarranties"}
            onClick={() => onTabClick("productWarranties")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<AirplaneLanding20Regular />}
            label="Device Arrivals"
            active={activeTab === "deviceArrivals"}
            onClick={() => onTabClick("deviceArrivals")}
            showLabel={drawerOpen}
          />

          <NavItem
            icon={<DocumentBulletListMultiple20Regular />}
            label="Reports"
            active={activeTab === "serviceReports"}
            onClick={() => onTabClick("serviceReports")}
            showLabel={drawerOpen}
          />

          {/* ...other nav items */}
          <Divider sx={{ my: 2 }} />
          {/* Settings and other options */}
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "Inventory" && (
        <>
          <NavItem
            icon={<BoxMultiple20Regular />}
            label="Inventory Parts"
            active={activeTab === "inventoryParts"}
            onClick={() => onTabClick("inventoryParts")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<ArrowRedo20Regular />}
            label={
              userRole === "Inventory"
                ? "Device Reworks"
                : "Create Device Rework"
            }
            active={activeTab === "deviceReworks"}
            onClick={() => onTabClick("deviceReworks")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<DocumentBulletListMultiple20Regular />}
            label="Reports"
            active={activeTab === "inventoryReports"}
            onClick={() => onTabClick("inventoryReports")}
            showLabel={drawerOpen}
          />
          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}

      {userRole && userRole === "Viewer" && (
        <>
          <NavItem
            icon={<Board20Regular />}
            label="Lotus Pro"
            active={activeTab === "iClean"}
            onClick={() => onTabClick("iClean")}
            showLabel={drawerOpen}
          />

          <Divider sx={{ my: 2 }} />
          <NavItem
            icon={<Person20Regular />}
            label="My Account"
            active={activeTab === "profile"}
            onClick={() => onTabClick("profile")}
            showLabel={drawerOpen}
          />
          <NavItem
            icon={<SignOut20Regular />}
            label="Log Out"
            onClick={handleLogout}
            showLabel={drawerOpen}
          />
        </>
      )}
    </Box>
  );
};

export default Sidebar;
