export enum UserRoles {
  SuperAdmin = "SuperAdmin",
  Admin = "Admin",
  Distributor = "Distributor",
  Customer = "Customer",
  Tester = "Tester",
  Service = "Service",
  SubDistributor = "SubDistributor",
  SubCustomer = "SubCustomer",
  Viewer = "Viewer",
  Production = "Production",
  Installer = "Installer",
  Group = "Group",
  None = "None"
}

export enum AccountStatusTypes {
  Active = "Active",
  Inactive = "Inactive",
  Deleted = "Deleted"
}

export interface UserDetails {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  country: string;
  state: string;
  city: string;
  isActive: boolean;
  accountStatus: "Active" | "Inactive" | "Deleted";
}

export interface CustomerCreationPayload {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  state: string;
  city: string;
  address: string;
  postalCode: string;
  phone: string;
  password: string;
  distributor: string;
}

export interface DistributorCreationPayload {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  state: string;
  city: string;
  address: string;
  postalCode: string;
  phone: string;
}
