import React from "react";
import { DeviceLogEntry } from "../../../common/types/DeviceTypes";
import Timeline from "@mui/lab/Timeline";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineItem from "../../Shared/TimelineItem";

interface DeviceTimelineProps {
  deviceLogs: DeviceLogEntry[];
}

const DeviceTimeline: React.FC<DeviceTimelineProps> = ({ deviceLogs }) => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0
        }
      }}
    >
      {deviceLogs
        .filter((log) => log.logMessage.includes("Ping"))
        .map((log, index) => (
          <TimelineItem
            key={index}
            status="Ping Received"
            timestamp={log.timestamp}
          />
        ))}
    </Timeline>
  );
};

export default DeviceTimeline;
