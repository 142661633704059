import { create } from "zustand";
import {
  fetchIcleanLinesDevices,
  fetchIcleanLinesDeviceFlushes
} from "../services/device.service";
import {
  ICleanLinesDevice,
  DeviceFlushData
} from "../common/types/DeviceTypes";
import useAuthStore from "./authStore";

const startOfDay = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds
  return newDate;
};

interface ICleanLinesState {
  iCleanLinesDevices: ICleanLinesDevice[];
  selectedIcleanLinesDevice: ICleanLinesDevice;
  deviceFlushes: DeviceFlushData[];
  dateFilter: Date | null;
  setDateFilter: (date: Date | null) => void;
  intervalFilter: "daily" | "weekly" | "monthly";
  setIntervalFilter: (interval: "daily" | "weekly" | "monthly") => void;
  setSelectedIcleanLinesDevice: (device: ICleanLinesDevice) => void;
  fetchICleanLinesDevices: () => Promise<void>;
  fetchICleanLinesDeviceFlushes: () => Promise<void>;
  fetchICleanLinesDeviceDetails: (deviceId: string) => Promise<void>;
  isLoading: boolean;
  setIsLoading: (data: boolean) => void;
}

const useICleanLinesStore = create<ICleanLinesState>((set) => ({
  iCleanLinesDevices: [],
  selectedIcleanLinesDevice: {} as ICleanLinesDevice,
  deviceFlushes: [],
  dateFilter: startOfDay(new Date()),
  intervalFilter: "daily",

  isLoading: true,
  setIsLoading: (data: boolean) => set({ isLoading: data }),

  setSelectedIcleanLinesDevice: (device: ICleanLinesDevice) =>
    set({ selectedIcleanLinesDevice: device }),

  setDateFilter: (date: Date | null) => set({ dateFilter: date }),

  setIntervalFilter: (interval: "daily" | "weekly" | "monthly") =>
    set({ intervalFilter: interval }),

  fetchICleanLinesDevices: async () => {
    set({ isLoading: true });
    const { user } = useAuthStore.getState(); // Get the current user from the auth store
    if (!user) {
      console.error("User not logged in");
      return;
    }
    const devices = await fetchIcleanLinesDevices();
    const flushes = await fetchIcleanLinesDeviceFlushes();

    set({
      iCleanLinesDevices: devices,
      selectedIcleanLinesDevice: devices[0],
      deviceFlushes: flushes
    });
    set({ isLoading: false });
  },

  fetchICleanLinesDeviceFlushes: async () => {
    const flushes = await fetchIcleanLinesDeviceFlushes();
    set({ deviceFlushes: flushes });
  },

  fetchICleanLinesDeviceDetails: async (_deviceId: string) => {
    try {
      // const device = await fetchDeviceDetails(deviceId);
      //set({ selectedIcleanLinesDevice: device as ICleanLinesDevice });
    } catch (error) {}
  }
}));

export default useICleanLinesStore;
