import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItemIcon,
  ListItemText,
  styled,
  Divider,
  TextField,
  Dialog,
  DialogActions,
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  AlertColor,
  Autocomplete,
  List,
  ListItem,
  ListItemButton,
  Popper
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { impersonateUser } from "../../../services/auth.service";
import { getAllUsers } from "../../../services/user.service";
import StyledButton from "../Styled/StyledButton";
import {
  Person20Regular,
  Globe20Regular,
  SignOut20Regular,
  DeveloperBoardSearch20Regular,
  PanelLeftKey20Regular
} from "@fluentui/react-icons";
import useDeviceStore from "../../../store/useDeviceStore";

interface User {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
}

const roles = [
  "SuperAdmin",
  "Admin",
  "Distributor",
  "SubDistributor",
  "Customer",
  "GroupCustomer",
  "NationalAccount",
  "GlobalAccount",
  "SubCustomer",
  "Service",
  "Tester"
];

const Profile = ({ onChangeTab }: { onChangeTab: (tab: string) => void }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [impersonateDialogOpen, setImpersonateDialogOpen] = useState(false);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(roles[0]);
  const { fetchDevices } = useDeviceStore();

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const handleAutocompleteOpen = () => {
    setAutocompleteOpen(true);
  };

  const handleAutocompleteClose = () => {
    setAutocompleteOpen(false);
  };

  const logout = useAuthStore((state) => state.logout);
  const login = useAuthStore((state) => state.login);
  const user = useAuthStore((state) => state.user);
  const userRole = useAuthStore((state) => state.userRole);
  const navigate = useNavigate();

  useEffect(() => {
    if (userRole && (userRole === "Admin" || userRole === "SuperAdmin")) {
      getAllUsers()
        .then((res) => setUsers(res.data))
        .catch((err) => console.error(err));
    }
  }, [userRole]);

  const handleImpersonate = async () => {
    if (user && selectedUser) {
      try {
        const response = await impersonateUser(user.email, selectedUser.email);

        if (
          response.response &&
          response.response.token &&
          response.response.user
        ) {
          const { token, user: newUserDetails, expiresIn } = response.response;

          // Assuming newUserDetails is an actual user object and token is a string
          login(newUserDetails, token, expiresIn, true); // This updates the store and local storage
          setSnackbar({
            open: true,
            message: "Impersonation successfully completed.",
            severity: "success"
          });
          fetchDevices();
          handleCloseDialog();
        } else {
          throw new Error("Invalid response format from impersonation API.");
        }
      } catch (error) {
        console.error("Failed to impersonate user", error);
        setSnackbar({
          open: true,
          message: "Failed to impersonate User.",
          severity: "error"
        });
      }
    }
  };

  const handleCloseDialog = () => {
    setImpersonateDialogOpen(false);
    setSelectedUser(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    "& .MuiListItemIcon-root": {
      minWidth: 36,
      color: theme.palette.text.secondary
    },
    "& .MuiListItemText-root": {
      "& .MuiTypography-root": {
        fontSize: "14px"
      }
    }
  }));

  const StyledAvatar = styled(Avatar)<{ inMenu?: boolean }>(
    ({ theme, inMenu }) => ({
      width: 32,
      height: 32,
      border: `1px solid ${inMenu ? theme.palette.primary.main : "white"}`,
      backgroundColor: "transparent",
      color: inMenu ? theme.palette.primary.main : "white",
      fontSize: "13px"
    })
  );

  const StyledMenu = styled(Menu)(({ theme }) => ({
    "& .MuiPaper-root": {
      marginTop: theme.spacing(1),
      minWidth: 280,
      boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
      "& .MuiMenuItem-root": {
        padding: theme.spacing(1.5, 2),
        "&:hover": {
          backgroundColor: theme.palette.action.hover
        }
      }
    }
  }));

  const HeaderMenuItem = styled(MenuItem)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.background.default
    },
    padding: theme.spacing(2)
  }));

  // Group users by their role based on predefined roles array
  const groupedUsers = users.reduce(
    (acc, user) => {
      // Determine the group key based on the user's role
      const groupKey = getGroupRole(user.role);

      // Initialize the group if it doesn't exist
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }

      // Add users to the appropriate group
      acc[groupKey].push(user);

      return acc;
    },
    {} as Record<string, User[]>
  );

  // Helper function to determine the group key
  function getGroupRole(role: string): string {
    switch (role) {
      case "Admin":
        return "Admin";
      case "SuperAdmin":
        return "SuperAdmin";
      case "Installer":
        return "Installer";
      case "Customer":
      case "GroupCustomer":
      case "GlobalAccount":
      case "NationalAccount":
        return "Customer"; // Group all these roles under 'Customer'
      case "Tester":
        return "Tester";
      case "Distributor":
        return "Distributor";
      case "SubDistributor":
        return "SubDistributor";
      // Other roles...
      case "Service":
        return "Service";
      default:
        return role; // Fallback to role itself if not explicitly grouped
    }
  }

  const handleAccordionChange =
    (role: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? role : false);
    };

  const handleUserSelect = (user: User) => {
    setSelectedUser(user);
    setExpanded(false); // Optionally close the accordion after selecting a user
    setAutocompleteOpen(false);
  };

  const filterOptions = (
    options: string[],
    { inputValue }: { inputValue: string }
  ) => {
    const lowercasedInput = inputValue.toLowerCase();

    return options.filter((role) =>
      groupedUsers[role]?.some(
        // Use optional chaining to check if groupedUsers[role] exists
        (user) =>
          (user.email && user.email.toLowerCase().includes(lowercasedInput)) ||
          (user.firstName &&
            user.firstName.toLowerCase().includes(lowercasedInput)) ||
          (user.lastName &&
            user.lastName.toLowerCase().includes(lowercasedInput))
      )
    );
  };

  const getInitials = (
    firstName: string | undefined,
    lastName: string | undefined
  ) => {
    return `${firstName?.[0] || ""}${lastName?.[0] || ""}`.toUpperCase();
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          p: 1,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)"
          }
        }}
        onClick={handleClick}
      >
        <StyledAvatar>
          {user ? getInitials(user.firstName, user.lastName) : "U"}
        </StyledAvatar>
      </Box>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <HeaderMenuItem>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <StyledAvatar inMenu>
              {user ? getInitials(user.firstName, user.lastName) : "U"}
            </StyledAvatar>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {user ? `${user.firstName} ${user.lastName}` : "User"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {user?.email}
              </Typography>
            </Box>
          </Box>
        </HeaderMenuItem>

        <Divider />

        <StyledMenuItem onClick={() => onChangeTab("profile")}>
          <ListItemIcon>
            <Person20Regular />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </StyledMenuItem>

        {userRole && (userRole === "Admin" || userRole === "SuperAdmin") && (
          <StyledMenuItem
            onClick={() => {
              setImpersonateDialogOpen(true);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <PanelLeftKey20Regular />
            </ListItemIcon>
            <ListItemText>Login as Another User</ListItemText>
          </StyledMenuItem>
        )}

        <StyledMenuItem
          onClick={() => window.open("https://www.tersano.com", "_blank")}
        >
          <ListItemIcon>
            <Globe20Regular />
          </ListItemIcon>
          <ListItemText>Visit Our Website</ListItemText>
        </StyledMenuItem>

        <StyledMenuItem onClick={() => navigate("/version-check")}>
          <ListItemIcon>
            <DeveloperBoardSearch20Regular />
          </ListItemIcon>
          <ListItemText>Check Serial Version</ListItemText>
        </StyledMenuItem>

        <Divider />

        <StyledMenuItem onClick={handleLogout}>
          <ListItemIcon>
            <SignOut20Regular />
          </ListItemIcon>
          <ListItemText>Log Out</ListItemText>
        </StyledMenuItem>
      </StyledMenu>

      <Dialog
        open={impersonateDialogOpen}
        onClose={handleCloseDialog}
        sx={{
          // backgroundColor: "#E6E6E5",
          "& .MuiDialog-paper": { minWidth: { xs: "75%", sm: "35%" } }
        }}
      >
        <DialogTitle>Remote Access User's Account</DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Select/Enter the email of the user you wish to log in as.
          </DialogContentText>

          <Autocomplete
            fullWidth
            open={autocompleteOpen}
            onOpen={handleAutocompleteOpen}
            onClose={handleAutocompleteClose}
            options={roles} // Roles for organizing users
            getOptionLabel={(role) => role} // Display the role as label
            value={
              selectedUser
                ? `${selectedUser.firstName} ${selectedUser.lastName} - (${selectedUser.email})`
                : null
            }
            isOptionEqualToValue={(option, value) =>
              option === value ||
              option === value.split(" - ")[1]?.replace(/[()]/g, "")
            } // Custom equality check
            filterOptions={filterOptions} // Custom filter to search users by email, first name, last name
            renderInput={(params) => (
              <TextField {...params} label="User" variant="outlined" />
            )}
            renderOption={(props, role) => (
              <li
                {...props}
                key={role}
                style={{ padding: 0 }}
                onClick={(e) => e.stopPropagation()} // Prevent selection of the role in the input field
              >
                <Box
                  sx={{
                    bgcolor: "rgba(0, 0, 0, 0.12)",
                    py: 0.3,
                    px: 1,
                    width: "100%"
                  }}
                >
                  <Accordion
                    expanded={expanded === role}
                    onChange={handleAccordionChange(role)}
                    sx={{
                      width: "100%"
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ m: 1 }}
                    >
                      <Typography>
                        {role === "Customer" ? "Customers" : `${role} list`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {/* Render all types of customers together in the "Customers" accordion */}
                        {role === "Customer" ? (
                          <>
                            {[
                              "Customer",
                              "GroupCustomer",
                              "GlobalAccount",
                              "NationalAccount"
                            ].map((customerRole) => (
                              <Box key={customerRole}>
                                {/* Customer role title */}
                                <Typography
                                  variant="subtitle2"
                                  sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
                                >
                                  {customerRole} Users
                                </Typography>
                                <Divider sx={{ mb: 1 }} />
                                {/* Render users with the specific role */}
                                {groupedUsers["Customer"]
                                  ?.filter((user) => user.role === customerRole) // Correctly filter by customer role
                                  .map((user) => (
                                    <ListItem key={user._id} disablePadding>
                                      <ListItemButton
                                        onClick={() => handleUserSelect(user)}
                                      >
                                        <Box
                                          sx={{
                                            bgcolor:
                                              customerRole === "Customer"
                                                ? "#e0f7fa"
                                                : customerRole ===
                                                    "GroupCustomer"
                                                  ? "#ffe0b2"
                                                  : customerRole ===
                                                      "GlobalAccount"
                                                    ? "#e1bee7"
                                                    : "#c8e6c9", // Different bg colors for each type
                                            p: 1,
                                            borderRadius: 1
                                          }}
                                        >
                                          <Typography>
                                            {user.firstName} {user.lastName} - (
                                            {user.email})
                                          </Typography>
                                        </Box>
                                      </ListItemButton>
                                    </ListItem>
                                  ))}
                              </Box>
                            ))}
                          </>
                        ) : (
                          // Render non-customer roles normally
                          groupedUsers[role]?.map((user) => (
                            <ListItem key={user._id} disablePadding>
                              <ListItemButton
                                onClick={() => handleUserSelect(user)}
                              >
                                <Typography>
                                  {user.firstName} {user.lastName} - (
                                  {user.email})
                                </Typography>
                              </ListItemButton>
                            </ListItem>
                          ))
                        )}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </li>
            )}
            PopperComponent={(props) => (
              <Popper
                {...props}
                sx={{
                  "& .MuiAutocomplete-listbox": {
                    maxHeight: "300px", // Set max height for dropdown
                    overflowY: "auto", // Enable vertical scrolling
                    "&::-webkit-scrollbar": {
                      width: "6px" // Set scrollbar width for webkit browsers
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888", // Set thumb color
                      borderRadius: "10px" // Set thumb border radius
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#555" // Set thumb hover color
                    }
                  }
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDialog}>Cancel</StyledButton>
          <StyledButton onClick={handleImpersonate} variantType="primary">
            Access
          </StyledButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={8000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Profile;
