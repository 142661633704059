import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

const ViewToggle: React.FC<{
  view: string;
  setView: (view: string) => void;
}> = ({ view, setView }) => {
  const handleViewChange = (
    _event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    if (nextView !== null) {
      setView(nextView);
    }
  };

  return (
    <ToggleButtonGroup
      value={view}
      exclusive
      onChange={handleViewChange}
      aria-label="view toggle"
      size="small"
      sx={
        {
          // gap: 5,
        }
      }
    >
      <ToggleButton
        value="card"
        aria-label="card view"
        sx={{
          width: "40px",
          height: "28px",
          border: "1px solid #9e9e9e"
        }}
      >
        <ViewModuleIcon />
      </ToggleButton>
      <ToggleButton
        value="table"
        aria-label="table view"
        sx={{
          width: "40px",
          height: "28px",
          border: "1px solid #9e9e9e"
        }}
      >
        <ViewListIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ViewToggle;
