import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { DeviceLogEntry } from "../../../common/types/DeviceTypes";

interface DeviceLogsProps {
  deviceLogs: DeviceLogEntry[];
}

const DeviceLogs: React.FC<DeviceLogsProps> = ({ deviceLogs }) => {
  return (
    <>
      <Box
        component="div"
        sx={{
          backgroundColor: "black",
          color: "green",
          fontFamily: "'Roboto Mono', monospace",
          padding: 2,
          maxHeight: 800,
          overflowY: "auto",
          mt: 2
        }}
      >
        {deviceLogs.map((entry) => (
          <Typography
            sx={{ fontFamily: "'Roboto Mono', monospace" }}
            key={entry.timestamp}
          >
            <span style={{ display: "inline-block", width: "200px" }}>
              [{new Date(entry.timestamp).toLocaleString()}]
            </span>
            {entry.logMessage}
          </Typography>
        ))}
      </Box>
    </>
  );
};

export default DeviceLogs;
