import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuthStore from "../store/authStore";

const ProtectedRoute: React.FC = () => {
  const { user, token, setSnackbar } = useAuthStore();

  useEffect(() => {
    if (!user && !token) {
      // Checking both user and token
      setSnackbar({
        open: true,
        message: "Session expired, Please log in to view this page.",
        severity: "warning"
      });
    }
  }, [user, token, setSnackbar]); // Include token in dependency array

  // Render children if user and token are present, otherwise redirect to login
  return user && token ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
