import {
  Grid,
  Paper,
  Stack,
  Autocomplete,
  TextField,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Typography,
  AlertColor
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import StyledIconButton from "../../../shared/Components/Styled/StyledIconButton";
import AssignToGroup from "./AssignToGroupModal";
import CustomerCard from "../../Shared/Cards/CustomerCard";
import { fetchCustomersForUser } from "../../../services/customer.service";
import { Customer } from "../../../store/useCustomer";
import useAuthStore from "../../../store/authStore";

interface ManageGroupCustomersProps {
  onCustomerSelect?: (customer: Customer) => void;
}

const ManageGroupCustomers: React.FC<ManageGroupCustomersProps> = ({
  onCustomerSelect
}) => {
  const [customers, setCustomers] = useState<any[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  // Remove _ when using the variable snackbar
  const [_snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({ open: false, message: "", severity: "success" });
  const { user } = useAuthStore.getState();

  const fetchCustomersData = useCallback(async () => {
    try {
      const customers = await fetchCustomersForUser();
      if (customers && Array.isArray(customers)) {
        setCustomers(customers);
        groupCustomersByParent(customers);
      } else {
        setCustomers([]);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      setCustomers([]);
    }
  }, []);

  useEffect(() => {
    fetchCustomersData();
  }, [groupModalOpen, fetchCustomersData]);

  const groupCustomersByParent = (customers: any[]) => {
    const groupCustomers = customers.filter(
      (customer) => customer.role === "GroupCustomer"
    );
    const regularCustomers = customers.filter(
      (customer) => customer.role === "Customer"
    );

    const groupedData = groupCustomers.map((group) => {
      const groupMembers = regularCustomers.filter(
        (customer) => customer.customerGroup === group.email
      );

      return {
        ...group,
        members: groupMembers
      };
    });

    setGroups(groupedData);
  };

  const handleSnackbarChange = (snackbarData: {
    open: boolean;
    message: string;
    severity: AlertColor;
  }) => {
    setSnackbar(snackbarData);
  };

  const handleEditGroup = (group: Customer) => {
    setSelectedGroup(group);
    setGroupModalOpen(true);
  };

  const filteredCustomers = useMemo(() => {
    return customers.filter((customer) => {
      const isInSelectedGroup =
        !selectedGroup || customer.customerGroup === selectedGroup.email;

      return isInSelectedGroup;
    });
  }, [customers, selectedGroup]);

  const handleGroupSelect = (group: Customer | "All" | null) => {
    if (group === "All" || group === null) {
      setSelectedGroup(null);
    } else {
      setSelectedGroup(group);
    }
  };

  const handleCloseGroupModal = () => {
    setGroupModalOpen(false);
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        <AssignToGroup
          customers={customers}
          groupMembers={
            selectedGroup
              ? customers.filter(
                  (customer) => customer.customerGroup === selectedGroup.email
                )
              : []
          }
          onSubmit={() => {}}
          onClose={handleCloseGroupModal}
          open={groupModalOpen}
          distributor={user?._id || ""}
          customerGroup={selectedGroup?.email}
          onSnackbarChange={handleSnackbarChange}
          onSuccess={() => {
            fetchCustomersData();
            setGroupModalOpen(false);
          }}
        />
        {/* Sidebar as the first Grid item */}
        <Grid item xs={12} sm={3}>
          <Paper
            elevation={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 2,
              height: "100%",
              position: "sticky",
              borderRadius: 0
            }}
          >
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              {/* Autocomplete for Group Search */}
              <Autocomplete
                options={groups.map((group) => group)}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName} - (${option.email})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Group"
                    variant="outlined"
                  />
                )}
                size="small"
                sx={{ flexGrow: 1 }}
                onChange={(_, selectedGroup) => {
                  if (selectedGroup) {
                    handleGroupSelect(selectedGroup);
                  } else {
                    handleGroupSelect(null);
                  }
                }}
              />
            </Stack>

            {/* List of Groups */}

            <Divider />
            <List sx={{ width: "100%", mt: 1 }}>
              <ListItem
                disablePadding
                sx={{ borderBottom: " 1px solid #e0e0e0" }}
              >
                <ListItemButton
                  onClick={() => handleGroupSelect("All")}
                  selected={selectedGroup === null}
                >
                  <ListItemText
                    primary="All"
                    sx={{
                      fontWeight: "bold"
                    }}
                  />
                </ListItemButton>
              </ListItem>
              {groups.map((group) => (
                <ListItem
                  key={group._id}
                  disablePadding
                  sx={{
                    bgcolor:
                      selectedGroup === group
                        ? "secondary.light"
                        : "transparent",
                    borderBottom: " 1px solid #e0e0e0"
                  }}
                >
                  <ListItemButton
                    onClick={() => handleGroupSelect(group)}
                    selected={selectedGroup === group}
                  >
                    <ListItemText
                      primary={`${group.firstName} ${group.lastName} - (${group.email})`}
                    />
                  </ListItemButton>
                  <ListItemSecondaryAction>
                    <StyledIconButton
                      variantType="transparent"
                      onClick={() => handleEditGroup(group)}
                    >
                      <EditIcon />
                    </StyledIconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Customer Cards */}
        <Grid item xs={12} sm={9}>
          <Grid container spacing={2}>
            {filteredCustomers.length === 0 ? (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mt: 2 }}
                  >
                    No members in this group
                  </Typography>
                </Box>
              </Grid>
            ) : (
              filteredCustomers.map((customer) => (
                <Grid item xs={12} sm={4} key={customer._id}>
                  <CustomerCard
                    customer={customer}
                    onRowClick={onCustomerSelect}
                    sx={{ flexGrow: 1, minWidth: 270 }}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManageGroupCustomers;
