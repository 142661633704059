import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  useTheme
} from "@mui/material";
import { ICleanDevice } from "../../../../common/types/DeviceTypes";
import { makeStyles, Badge, Divider } from "@fluentui/react-components";
import { getCartridgeLifeRemaining } from "../../../../utils/helper";
import CartridgeIndicator from "../../../Shared/CartridgeIndicator";
import LowCartridgeAlert from "../../../Shared/LowCartridgeAlert";
import { offlineThresholdDays } from "../../../../common/types/constants";
import DeviceOfflineAlert from "../../../Shared/DeviceOfflineAlert";

const useStyles = makeStyles({
  divider: {
    margin: "10px 0"
  }
});

interface DeviceCardsProps {
  devices: ICleanDevice[];
  onRowClick: (device: ICleanDevice) => void;
}

const DeviceCards: React.FC<DeviceCardsProps> = ({ devices, onRowClick }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box>
      <Grid container spacing={2}>
        {devices.map((device) => {
          const cyclesRemaining = getCartridgeLifeRemaining(device);
          const isDeviceCartridgeLow = cyclesRemaining <= 30;

          const thresholdDate = new Date();
          thresholdDate.setDate(thresholdDate.getDate() - offlineThresholdDays);
          const isOffline =
            new Date(device.pingStatus.timestamp) < thresholdDate;

          return (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              key={device.deviceInformation.deviceId}
            >
              <Card
                onClick={() => onRowClick(device)}
                sx={{
                  borderRadius: "0px",
                  boxShadow: 2,
                  border: "1px solid #d1d1d1",
                  cursor: "pointer",
                  height: "auto",
                  width: "100%",
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  "&:hover": {
                    backgroundColor: "#ededed"
                  }
                }}
              >
                <CardContent sx={{ p: 2 }}>
                  {/* Header with device name and email */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          p: 0.25,
                          mr: 1.5
                        }}
                      >
                        {(() => {
                          const words =
                            device.deviceInformation.deviceName.split(" ");
                          const firstChar = words[0][0] || "";
                          const secondChar = words[1] ? words[1][0] : "";
                          return `${firstChar}${secondChar}`.toUpperCase();
                        })()}
                      </Avatar>
                      <Box
                        sx={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden"
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600 }}
                        >
                          {device.deviceInformation.deviceName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {device.deviceInformation.customerEmail}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {isDeviceCartridgeLow && (
                        <LowCartridgeAlert cyclesRemaining={cyclesRemaining} />
                      )}
                      {isOffline && (
                        <DeviceOfflineAlert
                          timestamp={device.pingStatus.timestamp}
                        />
                      )}
                    </Box>
                  </Box>

                  <Divider className={classes.divider} />

                  {/* Device details list */}
                  <Box
                    sx={{
                      ml: 0.5,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1
                    }}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        Status
                      </Typography>
                      <Badge
                        color={
                          device.pingStatus.status === "ONLINE"
                            ? "success"
                            : device.pingStatus.status === "OFFLINE"
                              ? "danger"
                              : "informative"
                        }
                      >
                        {device.pingStatus.status.charAt(0).toUpperCase() +
                          device.pingStatus.status.slice(1).toLowerCase()}
                      </Badge>
                    </Box>

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        Serial
                      </Typography>
                      <Typography variant="body2">
                        {device.deviceInformation.deviceId}
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        Cartridge Type
                      </Typography>
                      <Typography variant="body2">
                        {device.deviceInformation.currentCartridgeType}
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        Cartridge Life
                      </Typography>
                      <Typography variant="body2">
                        <Badge appearance="outline" color="informative">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              height: "100%"
                            }}
                          >
                            <CartridgeIndicator
                              cyclesRemaining={
                                device.deviceUsage.length > 0
                                  ? device.deviceUsage[
                                      device.deviceUsage.length - 1
                                    ]?.cyclesRemaining || 0
                                  : 0
                              }
                              currentCartridgeType={
                                device.deviceInformation.currentCartridgeType
                              }
                              isGallons={false}
                            />
                          </Box>
                        </Badge>
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default DeviceCards;
