import { useState, useCallback } from "react";
import { fetchAccountsByDistributorEmail } from "../../../services/distributor.service";
import { AccountsData } from "../../../common/types/DistributorSalesTypes";
import useAuthStore from "../../../store/authStore";

export const useDistributorAccounts = () => {
  const [accounts, setAccounts] = useState<AccountsData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuthStore();

  const fetchAccounts = useCallback(async () => {
    if (!user?.email) {
      console.log("Missing user email:", user?.email);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const fetchedAccounts = await fetchAccountsByDistributorEmail(user.email);
      // remove duplicate accounts
      const uniqueAccounts = fetchedAccounts.filter(
        (account, index, self) =>
          index === self.findIndex((t) => t.accountName === account.accountName)
      );
      setAccounts(uniqueAccounts);
    } catch (error) {
      console.error("Error fetching accounts:", error);
      setError("Failed to fetch accounts");
    } finally {
      setLoading(false);
    }
  }, [user?.email]);

  return {
    accounts,
    loading,
    error,
    fetchAccounts
  };
};
