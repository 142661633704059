import React from "react";
import { Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineItem from "../../Shared/TimelineItem";

interface DeviceCartridgeChangeLogProps {
  cartridgeLogs: { logMessage: string; timestamp: string }[];
}

const DeviceCartridgeChangeLog: React.FC<DeviceCartridgeChangeLogProps> = ({
  cartridgeLogs
}) => {
  return (
    <>
      {cartridgeLogs.length === 0 ? (
        <Typography variant="h6" sx={{ mt: 5, textAlign: "center" }}>
          No cartridge changes yet.
        </Typography>
      ) : (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0
            }
          }}
        >
          {cartridgeLogs.map((log, index) => {
            const logData = JSON.parse(log.logMessage);
            return (
              <TimelineItem
                key={index}
                status={`Cartridge Changed: ${logData.cartridgeChanged}`}
                timestamp={log.timestamp}
              />
            );
          })}
        </Timeline>
      )}
    </>
  );
};

export default DeviceCartridgeChangeLog;
